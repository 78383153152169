import { ReactNode, useRef, useState } from 'react';

import { useLocale } from 'mycs/shared/state/LocaleContext';
import AnalyticsService from 'mycs/shared/services/AnalyticsService/AnalyticsService';
import Button from 'mycs/shared/components/Button/Button';
import Checkbox from 'mycs/shared/components/Checkbox/Checkbox';
import EmailInput from 'mycs/shared/components/EmailInput/EmailInput';
import FlagModeService from 'mycs/shared/services/FlagModeService/FlagModeService';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import Logger from 'mycs/shared/services/Logger';
import SafeText from 'mycs/shared/components/SafeText/SafeText';

import styles from './SaveDesignForm.scss';
import { useUser } from 'mycs/hooks/useUser';
import { useUserTools } from 'mycs/hooks/useUserTools';
import { trackNewsletterSubEvent } from 'mycs/services/UserAnalyticsService';
import { SignupSource } from 'mycs/api/UserAPI';

interface Props {
  onSubmit?: (email: string) => void;
  button?: ReactNode;
  withLabel?: boolean;
  source?: SignupSource;
}

export default function SaveDesignForm({
  onSubmit,
  button,
  withLabel = false,
  ...props
}: Props) {
  const { locale, countryCode } = useLocale();
  const isShowroom = FlagModeService.isShowroom();
  const isSubscriptionRequired = !isShowroom;
  const emailRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubscriptionAdded, setIsSubscriptionAdded] = useState(false);

  const { user, mutateUser } = useUser();
  const { signUp } = useUserTools(user, mutateUser);

  return (
    <div ref={containerRef}>
      <form
        onSubmit={onFormSubmit}
        className={styles.form}
        name="save-design-form"
      >
        <div className={styles.input}>
          <label>
            E-mail
            <EmailInput
              isRequired
              errorMessageBottom
              nativeProps={{
                name: 'email',
                ref: emailRef,
              }}
              label={withLabel ? 'Email' : undefined}
            />
          </label>
        </div>

        {isShowroom ? (
          <div>
            <Checkbox
              isLeftAlignedLayout
              className={styles.optionalCheckbox}
              onChange={toggleSubscription}
              checked={isSubscriptionAdded}
              nativeProps={{ required: false }}
            >
              <SafeText
                className={styles.labelText}
                content={I18nUtils.localize(locale, 'Join our mailing list')}
              />
            </Checkbox>

            {renderSaveDesignButton()}

            <SafeText
              className={styles.informationParagraph}
              content={I18nUtils.localize(
                locale,
                'Subscription information paragraph'
              )}
            />
          </div>
        ) : (
          <div>
            {renderSaveDesignButton()}
            <Checkbox
              isLeftAlignedLayout
              onChange={toggleSubscription}
              checked={isSubscriptionAdded}
              isErrorHighlighted={isSubmitted && !isSubscriptionAdded}
              nativeProps={{ required: true }}
            >
              <SafeText
                className={styles.labelText}
                content={I18nUtils.localize(locale, 'Required Subscription')}
              />
            </Checkbox>
          </div>
        )}
      </form>
    </div>
  );

  function onFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setIsSubmitted(true);

    const email = emailRef?.current?.value;
    if (!email) {
      Logger.error('[SaveDesignForm] no email submitted');
      return;
    }

    if (isSubscriptionRequired && !isSubscriptionAdded) {
      return;
    }

    signUp({
      email,
      shouldStaySignedIn: true,
      newsletterSignUpMeta: { source: props.source, isSubscriptionAdded },
    }).then((resp) => {
      if (isSubscriptionAdded) {
        trackNewsletterSubEvent({
          locale,
          countryCode,
          user: resp,
          componentPath: AnalyticsService.getComponentPath(
            containerRef.current!
          ),
        });
      }
    });

    onSubmit?.(email);
  }

  function toggleSubscription() {
    setIsSubscriptionAdded((state) => !state);
  }

  function renderSaveDesignButton() {
    return (
      button || (
        <Button
          text="Save Design"
          isPrimaryCta
          className={styles.button}
          nativeProps={{ type: 'submit' }}
        />
      )
    );
  }
}
