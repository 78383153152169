import sortBy from 'lodash/sortBy';

import { CfRibbon } from '@mycs/contentful';
import ContentfulService from 'mycs/shared/services/ContentfulService/ContentfulService';
import LocalStorage from 'mycs/shared/utilities/LocalStorageUtils/LocalStorageUtils';

const storeKey = {
  ribbonId: 'ribbon.id',
  visitCount: 'ribbon.visitCount',
};

type UserInfo = {
  numberOfVisits: number;
  isExpired: boolean;
};

/**
 * Ribbon logic and helpers
 *
 * @class RibbonService
 */
class RibbonService {
  now: Date | null = null;

  /**
   * Creates an instance of RibbonService
   */
  init() {
    this.now = new Date();
  }

  /**
   * Load all activated ribbons, some maybe be expired
   */
  loadRibbons(locale: string, countryCode: string): Promise<CfRibbon[]> {
    return ContentfulService.getRibbons(locale, countryCode);
  }

  /**
   * Get the 2 latest active ribbons
   */
  processRawRibbons(ribbons: CfRibbon[]): CfRibbon[] {
    const validRibbons = ribbons.filter((item) => this.isVisible(item));

    // Order ribbons by start date. The newest go first.
    return sortBy(validRibbons, (item) => -new Date(item.startDate || '0'));
  }

  /**
   * Load ribbons and return the one that is supposed to be displayed
   */
  getRibbon(rawRibbons: CfRibbon[]): CfRibbon | null {
    if (!rawRibbons || !rawRibbons.length) return null;

    const ribbons = this.processRawRibbons(rawRibbons);
    const firstRibbon = ribbons[0];

    return firstRibbon ? this.newRibbon(firstRibbon) : null;
  }

  /**
   * Increment visits
   */
  incrementVisits() {
    const currentVisits = LocalStorage.get(storeKey.visitCount) || 0;
    LocalStorage.set(storeKey.visitCount, currentVisits + 1);
  }

  /**
   * Returns number of visits in this ribbon campaign
   */
  getVisits(): number {
    return LocalStorage.get(storeKey.visitCount);
  }

  /**
   * Checks if the fetched ribbon different from the last one
   */
  hasRibbonChanged(ribbon: CfRibbon): boolean {
    return LocalStorage.get(storeKey.ribbonId) !== ribbon._id;
  }

  /**
   * Handle newly fetched ribbon
   */
  newRibbon(ribbon: CfRibbon): CfRibbon {
    if (this.hasRibbonChanged(ribbon)) {
      LocalStorage.set(storeKey.ribbonId, ribbon._id);
      LocalStorage.set(storeKey.visitCount, 1);
    }

    return ribbon;
  }

  /**
   * Filter ribbons by start and end date
   */
  checkDates(ribbon: CfRibbon): boolean {
    if (ribbon.startDate && ribbon.endDate) {
      if (
        this.now! < new Date(ribbon.startDate) ||
        this.now! > new Date(ribbon.endDate)
      )
        return false;
    } else if (ribbon.startDate && !ribbon.endDate) {
      if (this.now! < new Date(ribbon.startDate)) return false;
    } else if (!ribbon.startDate && ribbon.endDate) {
      if (this.now! > new Date(ribbon.endDate)) return false;
    }
    return true;
  }

  /**
   * Decide whether or not to show the ribbon
   */
  isVisible(ribbon: CfRibbon): boolean {
    const numberOfVisits = this.getVisits();
    const isExpired = !this.checkDates(ribbon);

    return this.defineVisibility(ribbon, {
      numberOfVisits,
      isExpired,
    });
  }

  /**
   * Logic for showing the ribbon
   */
  defineVisibility(
    ribbon: CfRibbon,
    { numberOfVisits, isExpired }: UserInfo
  ): boolean {
    if (isExpired) return false;
    //a long running lambda would react a high numberOfVisits and return a null
    //ribbon what would be valid in CSR
    if (
      !MYCS_IS_SSR_BUILD &&
      ribbon.showTimes &&
      ribbon.showTimes < numberOfVisits
    )
      return false;

    return true;
  }
}

export default new RibbonService();
export const RibbonServiceClass = RibbonService;
