import pick from 'lodash/pick';
import flatten from 'lodash/flatten';
import cloneDeep from 'lodash/cloneDeep';
import assign from 'lodash/assign';
import traverse from 'traverse';

import ElementService from './ElementService';
import ElementApiService from './ElementApiService';

// import RendererService from '../services/RendererService';
import cushionElements from '../elements/cushion-elements';
import joynElements from '../elements/joyn-elements';
import pyllowElements from '../elements/pyllow-elements';
import flayrElements from '../elements/flayr-elements';
import shelfElements from '../elements/shelf-elements';
import wardrobeElements from '../elements/wardrobe-elements';
import CushionElement from '../components/cushion/CushionElement';
import JoynSofaElement from '../components/joyn/JoynSofaElement';
import PyllowSofaElement from '../components/pyllow/PyllowSofaElement';
import FlayrSofaElement from '../components/flayr/FlayrSofaElement';
import ShelfElement from '../components/shelf/ShelfElement';
import WardrobeElement from '../components/wardrobe/WardrobeElement';
import Component from '../components/Component';
import ShelfUtils from '../rules/ShelfUtils';

class ComponentService {
  /**
   *
   */
  getGeneratedConfig() {
    const modules = [
      {
        modules: cushionElements,
        furniture_type: 'cushion',
        component: CushionElement,
      },
      {
        modules: joynElements,
        furniture_type: 'joyn',
        component: JoynSofaElement,
      },
      {
        modules: pyllowElements,
        furniture_type: 'pyllow',
        component: PyllowSofaElement,
      },
      {
        modules: flayrElements,
        furniture_type: 'flayr',
        component: FlayrSofaElement,
      },
      {
        modules: shelfElements,
        furniture_type: 'shelf',
        component: ShelfElement,
      },
      {
        modules: wardrobeElements,
        furniture_type: 'wardrobe',
        component: WardrobeElement,
      },
    ];
    const generatedConfig = {};
    for (const module of modules) {
      generatedConfig[module.furniture_type] = module;
    }

    return generatedConfig;
  }

  /**
   * Generate the list of modules from config file & furniture type
   *
   */
  generateAllModules(expandColorGroup = false) {
    let modules = flatten(
      Object.values(this.getGeneratedConfig()).map(
        ({
          modules,
          furniture_type,
        }: {
          modules: any;
          furniture_type: string;
        }) => {
          return modules.map((module: any) => {
            return { ...module, furniture_type };
          });
        }
      )
    );

    if (expandColorGroup) {
      modules = flatten(
        modules.map((module) => {
          return module.color.map((colorGroup) => {
            return { ...module, color: [colorGroup] };
          });
        })
      );
    }

    // modules = modules.slice(0, 30);

    return modules;
  }

  /**
   * Generate the list of asset from elements
   *
   * @param {string} furnitureType
   * @return {array} of assets
   */
  *generateAssets(furnitureType = '') {
    let elements = ElementService.generateAllElement();
    if (furnitureType) {
      elements = elements.filter(
        (element) => element.furniture_type === furnitureType
      );
    }

    for (const element of elements) {
      const { furniture_type } = element;
      const structure = {
        props: {
          ...element,
        },
      };

      /**
       * Rely on the new dynamic asset resolution flow
       */
      const { props } = this.decorateStructureAssets(structure, furniture_type);

      const assets = pick(props, ['shape', 'material']);

      yield { element, assets };
    }
  }

  /**
   * Function decorateStructureSkus
   *
   * Dynamic resolution of SKUs for an input structure.
   *
   * @param {object} structure
   * @param {string} furniture_type
   * @return {object} new structure with SKUs
   */
  decorateStructureSkus(structure, furniture_type) {
    const decoratedStructure = cloneDeep(structure);
    const generatedConfig = this.getGeneratedConfig();
    const { component } = generatedConfig[furniture_type];
    const { mappingCover, mappingShape } = component;

    traverse(decoratedStructure).forEach((node) => {
      if (
        !(
          node &&
          node.props &&
          node.props.furniture_type &&
          node.props.section &&
          node.props.type &&
          node.props.length &&
          node.props.width &&
          node.props.height &&
          node.props.color
        )
      ) {
        return;
      }

      const elementProps = Component.getKeys(node.props);

      // Dynamic SKU resolution for shapes
      // Old version:
      //   const confElement = Component.getKeys(node.props);
      //   const element: any = Component.getSKU(mappingShape(confElement));
      const skuProps: any = {};
      const shapeProps: any = mappingShape
        ? mappingShape(elementProps)
        : elementProps;

      if (shapeProps !== null) {
        try {
          // const shapeElement: any = ElementApiService.getSKU(shapeProps);
          const shapeElement: any = Component.getSKU(shapeProps);
          skuProps.sku = shapeElement.sku;
        } catch (error) {
          throw new Error(JSON.stringify({ elementProps, shapeProps }));
        }
      }

      // Dynamic SKU resolution for covers
      // Old version:
      //   const coverElement: any = Component.getSKU(mappingCover(confElement));
      if (shapeProps !== null && mappingCover) {
        const coverProps = mappingCover(elementProps);
        if (coverProps !== null) {
          // const coverElement: any = ElementApiService.getSKU(coverProps);
          const coverElement: any = Component.getSKU(coverProps);
          skuProps.cover_sku = coverElement.sku;
        }
      }

      // Update the props with new SKUs
      // Old version:
      //   if (element) node.props.sku = element.sku;
      //   if (coverElement) node.props.cover_sku = coverElement.sku;
      assign(node.props, skuProps);

      // console.log({skuProps});
    });

    return decoratedStructure;
  }

  /**
   * Function getFinalElements
   *
   * Get list of elements information from mappingShape and mappingCover
   * to get all the info to fetch SKUs on BBD side
   *
   * @param {object} structure
   * @param {string} furniture_type
   * @return {Array} of final elements
   */
  getFinalElements(structure, furniture_type) {
    const decoratedStructure = cloneDeep(structure);
    const generatedConfig = this.getGeneratedConfig();
    const { component } = generatedConfig[furniture_type];
    const { mappingCover, mappingShape } = component;
    const elements = [];

    traverse(decoratedStructure).forEach((node) => {
      if (
        !(
          node &&
          node.props &&
          node.props.furniture_type &&
          node.props.section &&
          node.props.type &&
          node.props.length &&
          node.props.width &&
          node.props.height &&
          node.props.color
        )
      ) {
        return;
      }
      const elementProps = Component.getKeys(node.props);
      if (mappingShape) {
        const shapeProps = mappingShape(elementProps);
        if (shapeProps !== null) {
          elements.push(shapeProps);
        }
      }
      if (mappingCover) {
        const coverProps = mappingCover(elementProps);
        if (coverProps !== null) {
          elements.push(coverProps);
        }
      }
    });
    return elements;
  }

  /** Function unDecorateStructureSkus
   * Remove SKUs from structure
   * @param {object} structure
   * @returns {object} new structure without SKUs
   */
  unDecorateStructureSkus(structure) {
    const decoratedStructure = cloneDeep(structure);
    traverse(decoratedStructure).forEach((node) => {
      if (
        !(
          node &&
          node.props &&
          node.props.furniture_type &&
          node.props.section &&
          node.props.type &&
          node.props.length &&
          node.props.width &&
          node.props.height &&
          node.props.color
        )
      ) {
        return;
      }
      delete node.props.sku;
      delete node.props.cover_sku;
    });
    return decoratedStructure;
  }

  /**
   * Function decorateStructureAssets
   *
   * Dynamic resolution of assets for an input structure.
   *
   * @param {object} structure
   * @param {string} furniture_type
   * @return {object} new structure with assets
   */
  decorateStructureAssets(structure, furniture_type) {
    const decoratedStructure = cloneDeep(structure);
    const generatedConfig = this.getGeneratedConfig();
    const { component } = generatedConfig[furniture_type];
    const {
      mappingAsset,
      //  hardcode
    } = component;
    let assetId = 0;

    traverse(decoratedStructure).forEach((node) => {
      if (
        !(
          node &&
          node.props &&
          node.props.furniture_type &&
          node.props.section &&
          node.props.type &&
          node.props.length &&
          node.props.width &&
          node.props.height &&
          node.props.color
        )
      ) {
        return;
      }

      const elementProps = Component.getKeys(node.props);

      let newProps = {};

      // Standart assets
      const element = Component.getElement(elementProps);

      const assetProps = Component.getAssets(element);
      assign(newProps, assetProps);

      // Compute hardcode
      // It should not require any hardcode but rely only on mapping Assets
      // let hardcodeProps = hardcode(node.props, elementProps);
      // hardcodeProps = pick(hardcodeProps, ['material', 'shape']);
      // assign(newProps, hardcodeProps);

      // Compute mappingAsset (can override assets)
      if (mappingAsset) {
        const assetPropsOverride = mappingAsset(elementProps, assetId);
        assign(newProps, assetPropsOverride);
      }

      assign(node.props, newProps);

      // console.log({newProps});
      assetId = assetId + 1;
    });

    return decoratedStructure;
  }

  /**
   * Scan the list of SKU to extract a pattern of similar version
   * @param {boolean} fetchSku
   * @param {string} furnitureType
   * @return {array} of module & versions of the module
   */
  *skuValidation(fetchSku: boolean = true, furnitureType: string = '') {
    let elements = ElementService.generateAllElement();
    const generatedConfig = this.getGeneratedConfig();
    if (furnitureType) {
      elements = elements.filter(
        (element) => element.furniture_type === furnitureType
      );
    }

    for (const element of elements) {
      const { furniture_type } = element;
      const { component } = generatedConfig[furniture_type];
      const ComponentElement = component;

      const shapeProps: any = ComponentElement.mappingShape
        ? ComponentElement.mappingShape(cloneDeep(element))
        : element;

      let skus = [];
      if (shapeProps !== null) {
        if (fetchSku) {
          skus = ElementApiService.getSKUs(shapeProps);
          yield { element, elementProps: shapeProps, skus };
        } else {
          yield { element, elementProps: shapeProps };
        }
      }

      if (ComponentElement.mappingCover) {
        const coverProps = ComponentElement.mappingCover(element);
        if (coverProps !== null) {
          if (fetchSku) {
            skus = ElementApiService.getSKUs(coverProps);
            yield { element, elementProps: coverProps, skus };
          } else {
            yield { element, elementProps: coverProps };
          }
        }
      }
    }
  }

  /**
   *
   * @param elements
   * @param furnitureType
   * @returns
   */
  decorateElements(elements, furnitureType: string = '') {
    return elements.map((element) => {
      const fullElement = ElementApiService.getSKU(element);

      const services = element.services.map((service) => {
        const serviceProps = {
          ...Component.getKeys(service.props),
          furniture_type: furnitureType,
        };

        const serviceElement = Component.getElement(serviceProps);

        const serviceSKU = Component.getSKU(serviceElement).sku;

        return { ...service, sku: serviceSKU };
      });

      return {
        ...fullElement,
        services,
      };
    });
  }

  /**
   * Extract the list of elements from a design.
   * - Support for workshop SKUs (baseboard cuts)
   *
   * @param design
   * @param baseboardCuts
   * @returns
   */
  extractElements(
    design: any,
    baseboardCuts: { depth: number; height: number } | null = null
  ) {
    const DBLayout = design.structure;

    let elements = null;
    // Shelf specific behavior to support workshop service SKU
    if (design.furniture_type === 'shelf') {
      elements = ShelfUtils.getServiceElements(DBLayout, baseboardCuts);
    } else {
      elements = this.getFinalElements(DBLayout, design.furniture_type).map(
        (element) => {
          return {
            ...element,
            services: [],
          };
        }
      );
    }
    const decoratedElements = this.decorateElements(
      elements,
      design.furniture_type
    );

    return decoratedElements;
  }
}

const componentService = new ComponentService();

export default componentService;
