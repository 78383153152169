import { Observable } from 'rxjs';
import isString from 'lodash/isString';

import Logger from 'mycs/shared/services/Logger';

const easing = (t: number, b: number, c: number, d: number) =>
  -c * (t /= d) * (t - 2) + b;

export default class UIUtils {
  /**
   * Animate an arbitrary value change in time
   */
  static animate(start: number, end: number, duration: number) {
    return new Observable<number>((observer) => {
      const startDate = Date.now();

      const frame = () => {
        const progress = Date.now() - startDate;

        // Complete with the final value
        if (progress >= duration) {
          observer.next(end);
          observer.complete();
          return;
        }

        // Provide next value
        const value = easing(progress, start, end - start, duration);
        observer.next(value);

        // Recur
        window.requestAnimationFrame.call(window, frame);
      };

      frame();
    });
  }

  /**
   * Scroll to a specific position
   */
  static scrollToPosition(
    y: number,
    options: { offset?: number; duration?: number } = {}
  ) {
    const duration = options.duration || 1500;
    const offset = options.offset || 0;
    const currentY = window.pageYOffset || document.documentElement.scrollTop;
    const scrollToY = y + offset;

    UIUtils.animate(currentY, scrollToY, duration).subscribe((frameY) => {
      scrollTo(0, frameY);
    });
  }

  static scrollToElement(
    element: Element | string,
    options: { offset?: number; duration?: number } = {}
  ) {
    const htmlElement = isString(element)
      ? document.querySelector(element)
      : element;

    if (!htmlElement) {
      // TODO (Valentyn) ideally it should not be called unless the element is on the page
      Logger.error(
        new Error(`Failed to find the element on the page: ${element}`)
      );
      return;
    }

    const elementRect = htmlElement.getBoundingClientRect();
    const scrollToY = window.pageYOffset + elementRect.top;

    UIUtils.scrollToPosition(scrollToY, options);
  }
}
