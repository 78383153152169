import { useState } from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';

import { CfPageMain } from '@mycs/contentful';
import { getPageContentByKeyFromReduxState } from 'mycs/shared/state/slices/pageSlice';
import { RelativeUrlService } from 'mycs/shared/services/RelativeUrlService';
import { useAppSelector } from 'mycs/shared/state/store';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import Alink from 'mycs/shared/components/Alink/Alink';
import AnalyticsService from 'mycs/shared/services/AnalyticsService/AnalyticsService';
import Button from 'mycs/shared/components/Button/Button';
import cfg from 'mycs/config';
import ClickOutside from 'mycs/shared/components/ClickOutside/ClickOutside';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';

import styles from './LanguageDropdown.scss';

export default function LanguageDropdown() {
  const { locale, countryCode } = useLocale();
  const { pathname, search } = useLocation();
  const locUrl =
    useAppSelector(
      (state) =>
        getPageContentByKeyFromReduxState<CfPageMain>(state, {
          locale: locale,
          pathname,
        })?.locUrl
    ) || {};
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const languages = getLanguageOptions(locale);

  if (languages.length <= 1 || locale.indexOf('CH') === -1) return null;

  const [currentLanguage] = locale.split('_');

  /**
   * Toggle The language dropDown
   */
  const toggleLanguageDropDown = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  /**
   * Track language change
   */
  const track = (val: string) => {
    const tag = {
      pageCountry: countryCode.toUpperCase(),
      event: 'langSelected',
      langCode: val,
    };

    AnalyticsService.eventTrack('languageMenu', locale, tag);
  };

  const originalPathname = RelativeUrlService.getUntranslatedUrl(
    pathname,
    locale
  );

  return (
    <span data-track-path="LanguageDropDown" className={styles.dropdownWrapper}>
      <Button
        className={styles.currentLanguage}
        iconAfter
        isInline
        iconName="general/arrow-chevron-down"
        iconClassName={styles.icon}
        text={currentLanguage.toUpperCase()}
        onClick={toggleLanguageDropDown}
      />
      {isOpen && (
        <ClickOutside onClickOutside={toggleLanguageDropDown}>
          <div
            className={classnames(styles.dropdown, { [styles.isOpen]: isOpen })}
          >
            {isOpen &&
              languages.map((lang, key) => {
                const locale = lang.code.replace('_', '-');
                let href = locUrl[locale];

                /**
                 * The below allow us to use the language dropdown with
                 * PDP paths as well as configurator paths.
                 */
                if (!href) {
                  href = RelativeUrlService.getTranslatedUrl(
                    `${originalPathname}${search}`,
                    lang.code
                  );
                }

                return (
                  <Alink
                    key={key}
                    trackName="languageMenu"
                    className={styles.dropdownOption}
                    text={lang.name}
                    href={href}
                    target="_top"
                    onMouseDown={() => track(lang.code)}
                  />
                );
              })}
          </div>
        </ClickOutside>
      )}
    </span>
  );
}

function getLanguageOptions(locale: string): any[] {
  const languages: { code: string; name: string }[] = [];

  for (const [key, lang] of Object.entries(cfg.mainLanguages)) {
    languages.push({
      code: key,
      name: I18nUtils.localize(locale, lang),
    });
  }

  return languages;
}
