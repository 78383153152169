import flatten from 'lodash/flatten';
import { FurnitureConfig } from './types';
import { Option } from 'src/types';
import { screenFactor } from '../utils/configUtils';
import joynconfig from './joyn-config';
import nyceConfig from './nyce-config';

const config: FurnitureConfig = {
  hasScene: false,
  scenesOptions: [],
  stage: 'default',
  label: 'SVG',
  hasAssembly: false,
  hasViewInterior: false,
  showRelatedProduct: false,
  noBackgroundCarousel: false,
  translationDimensionMapping: true,
  furnitureType: 'cushion',
  invalidUuidFallback: 'zFQRUBLIw',
  backUrl: '/accessories/cushions',
  productDetailText: '',
  tabs: {
    desktop: ['default'],
    mobile: ['elements', 'colors', 'review'],
  },
  default: {
    color: 'velvetex_blue_1007',
    width: 400,
    length: 400,
  },
  nyce: {
    animation: {
      camera: {
        fov: 45,
        angle: 0,
        vAngle: 0,
        coreAngle: 0,
        coreVAngle: 0,
        autozoom: true,
        bboxScalingFactor: [1, 1, 1, 1, 1, 1],
        ...screenFactor(760, 760, 50, 50, 50, 50),
      },
      processing: {
        noise: 0.1,
        samples: 200,
        no_intermediate: true,
        floor: [0.8863, 0.8902, 0.898],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.shelf,
      },
    },
    normal: {
      camera: {
        fov: 45,
        angle: 0,
        vAngle: 0,
        coreAngle: 0,
        coreVAngle: 0,
        autozoom: true,
        bboxScalingFactor: [1, 1, 1, 1, 1, 1],
        ...screenFactor(1500, 875, 98.33, 98.33, 98.33, 98.33),
      },
      processing: {
        noise: 0.1,
        samples: 350,
        no_intermediate: false,
        floor: [0.8863, 0.8902, 0.898],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.shelf,
      },
    },
    mobile: {
      camera: {
        fov: 45,
        angle: 0,
        vAngle: 0,
        coreAngle: 0,
        coreVAngle: 0,
        autozoom: true,
        bboxScalingFactor: [1, 1, 1, 1, 1, 1],
        ...screenFactor(800, 900, 50, 150, 50, 50),
      },
      processing: {
        noise: 0.1,
        samples: 350,
        no_intermediate: false,
        floor: [0.8863, 0.8902, 0.898],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.shelf,
      },
    },
    hires: {
      camera: {
        fov: 45,
        angle: 0,
        vAngle: 0,
        coreAngle: 0,
        coreVAngle: 0,
        autozoom: true,
        bboxScalingFactor: [1, 1, 1, 1, 1, 1],
        ...screenFactor(1440, 1440, 150, 150, 150, 150),
      },
      processing: {
        noise: 0.1,
        samples: 350,
        no_intermediate: true,
        floor: [1.0, 1.0, 1.0],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.shelf,
      },
    },
    thumbnail: {
      camera: {
        height: 74,
        width: 74,
        fov: 45,
        angle: 0,
        vAngle: 0,
        coreAngle: 0,
        coreVAngle: 0,
        autozoom: true,
        bboxScalingFactor: [1, 1, 1, 1, 1, 1],
        ...screenFactor(1440, 1440, 150, 150, 150, 150),
      },
      processing: {
        noise: 0.1,
        samples: 350,
        no_intermediate: true,
        floor: [1.0, 1.0, 1.0],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.shelf,
      },
    },
  },
  options: [
    /* Elements */
    {
      action: 'update',
      group: 'elements',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'element',
      value: {
        length: 300,
        width: 500,
      },
      iconName: 'configurator/cushion/cushion-30x50',
      text: '30 x 50 cm',
    },
    {
      action: 'update',
      group: 'elements',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'element',
      value: {
        length: 400,
        width: 400,
      },
      iconName: 'configurator/cushion/cushion-40x40',
      text: '40 x 40 cm',
    },
    {
      action: 'update',
      group: 'elements',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'element',
      value: {
        length: 500,
        width: 500,
      },
      iconName: 'configurator/cushion/cushion-50x50',
      text: '50 x 50 cm',
    },
    {
      action: 'update',
      group: 'elements',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'element',
      value: {
        length: 400,
        width: 600,
      },
      iconName: 'configurator/cushion/cushion-40x60',
      text: '40 x 60 cm',
    },
    {
      action: 'update',
      group: 'elements',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'element',
      value: {
        length: 480,
        width: 650,
      },
      iconName: 'configurator/cushion/cushion-48x65',
      text: '48 x 65 cm',
    },
    {
      action: 'update',
      group: 'elements',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'element',
      value: {
        length: 400,
        width: 800,
      },
      iconName: 'configurator/cushion/cushion-40x80',
      text: '40 x 80 cm',
    },

    /* Colors */
    ...flatten(
      Object.keys(joynconfig.covers).map(
        (category: string): Option =>
          joynconfig.covers[category].map(
            (color: string): Option => ({
              action: 'update',
              group: 'colors',
              isDisabled: null,
              isSelected: null,
              message: '',
              type: 'color',
              value: color,
              iconName: '',
              text: '',
            })
          )
      )
    ),
  ],
  viewModes: {
    elements: {
      zoomMax: 1.8,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'whole',
      resetSelection: false,
      group: 'elements',
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      labelMode: 'whole',
    },
    colors: {
      zoomMax: 1.8,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'multi',
      resetSelection: false,
      group: 'colors',
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      labelMode: 'colors',
    },
    review: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: false,
      group: '',
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      labelMode: '',
    },
    default: {
      zoomMax: 1.8,
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      group: 'colors',
      groupStructure: '',
      iconName: '',
      labelMode: 'colors',
      selectionMode: 'all',
      resetSelection: false,
      subTabsName: ['sizes', 'colors'],
      text: '',
    },
    sizes: {
      zoomMax: 1.8,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'whole',
      resetSelection: false,
      group: 'elements',
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      labelMode: 'whole',
    },
  },
  notifications: {},
  defaultDimensions: {},
  dimensionsFilters: ['length', 'width', 'height'],
};

export default config;
