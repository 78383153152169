import Icon from 'mycs/shared/components/Icon/Icon';
import styles from './ParagraphClarifyingText.scss';

type Props = {
  clarifyingText: string;
};

export default function ParagraphClarifyingText({ clarifyingText }: Props) {
  return (
    <div className={styles.clarifyingText}>
      <Icon
        iconContainerClass={styles.clarifyingTextIconContainer}
        className={styles.clarifyingTextIcon}
        iconName="configurator/info"
      />
      {clarifyingText}
    </div>
  );
}
