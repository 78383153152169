import traverse from 'traverse';
import { BboxScreenScalingFactor } from 'src/config/types';

export function computeIDName(notifications) {
  traverse(notifications).forEach(function (node) {
    if (node.text) {
      this.node.idName = `notification-${this.path.join('-')}`;
    }
  });
  return notifications;
}

export function screenFactor(
  width: number,
  height: number,
  top: number,
  bottom: number,
  left: number,
  right: number
): {
  width: number;
  height: number;
  top: number;
  bottom: number;
  right: number;
  left: number;
  bboxScreenScalingFactor: BboxScreenScalingFactor;
} {
  const bboxScreenScalingFactor = {
    x: {
      m: (width * 0.5 - left) / (width * 0.5),
      M: (width * 0.5 - right) / (width * 0.5),
    },
    y: {
      m: (height * 0.5 - top) / (height * 0.5),
      M: (height * 0.5 - bottom) / (height * 0.5),
    },
  };

  return {
    width,
    height,
    top,
    bottom,
    left,
    right,
    bboxScreenScalingFactor,
  };
}
