const modules = [
  {
    module: ['board', 'normal'],
    dimensions: [
      [464, 571, 28],
      [764, 571, 28],
      [964, 571, 28],
    ],
    color: ['oak', 'walnut', 'laminate_white', 'laminate_grey'],
  },
  {
    module: ['cloth_rail', 'interiors'],
    dimensions: [
      [464, 19, 39],
      [764, 19, 39],
      [964, 19, 39],
    ],
    color: ['grey_7037'],
  },
  {
    module: ['corpus_module_1', 'normal'],
    dimensions_expand: [[500, 800, 1000], [572], [2204]],
    color: ['oak', 'walnut', 'laminate_white', 'laminate_grey'],
  },
  {
    module: ['corpus_module_2', 'normal'],
    dimensions_expand: [[500, 800, 1000], [572], [2204]],
    color: ['oak', 'walnut', 'laminate_white', 'laminate_grey'],
  },
  {
    module: ['backwall', 'normal'],
    dimensions_expand: [[498, 798, 998], [3], [2201]],
    color: ['laminate_white', 'laminate_grey', 'oak', 'walnut'],
    custom: true,
  },
  {
    module: ['door', 'sliding'],
    dimensions: [
      [823, 18, 2304],
      [1023, 18, 2304],
    ],
    color: ['laminate', 'wood_3_shelves', 'mirror'],
  },
  {
    module: ['door', 'sliding_plain'],
    dimensions: [
      [823, 18, 2304],
      [1023, 18, 2304],
    ],
    color: ['laminate', 'wood_3_shelves', 'mirror'],
  },
  {
    module: ['door', 'turning'],
    dimensions: [
      [397, 18, 2304],
      [497, 18, 2304],
    ],
    color: ['laminate', 'wood_3_shelves', 'mirror'],
  },
  {
    module: ['door', 'turning_plain'],
    dimensions: [
      [397, 18, 2304],
      [497, 18, 2304],
    ],
    color: ['laminate', 'wood_3_shelves', 'mirror'],
  },
  {
    module: ['drawer_box', 'interiors'],
    dimensions_expand: [[464, 764, 964], [495], [50, 146]],
    color: ['oak'],
  },
  {
    module: ['drawer_front_glass_glass', 'interiors'],
    dimensions_expand: [[464, 764, 964], [18], [146]],
    color: ['glass_drawer'],
  },
  {
    module: ['drawer_front_glass_module', 'interiors'],
    dimensions_expand: [[386, 686, 886], [146], [18]],
    color: ['oak', 'walnut', 'laminate_white', 'laminate_grey'],
  },
  {
    module: ['drawer_front_module', 'interiors'],
    dimensions_expand: [[386, 686, 886], [50, 146], [18]],
    color: ['oak', 'walnut', 'laminate_white', 'laminate_grey'],
  },
  {
    module: ['drawer', 'distance'],
    dimensions_expand: [[495], [36], [50, 146]],
    color: ['oak', 'walnut', 'laminate_white', 'laminate_grey'],
  },
  {
    module: ['handles', 'normal_wooden'],
    dimensions: [[50, 18, 590]],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['handles', 'metal_short'],
    dimensions: [[20, 44, 424]],
    color: ['laminate', 'metal_aluminum', 'metal_gold'],
  },
  {
    module: ['handles', 'metal_long'],
    dimensions: [[20, 44, 1000]],
    color: ['laminate', 'metal_aluminum', 'metal_gold'],
  },
  {
    module: ['lift_metal_plate', 'interiors'],
    dimensions_expand: [[150], [150], [10]],
    color: ['white'],
  },
  {
    module: ['lift_module', 'interiors'],
    dimensions_expand: [[464, 764, 964], [149], [853]],
    color: ['grey_7037'],
  },
  {
    module: ['pant_hanger_module', 'interiors'],
    dimensions_expand: [[464, 764, 964], [495], [50]],
    color: ['oak'],
  },
  {
    module: ['shoe_bar_module', 'interiors'],
    dimensions: [
      [350, 256, 54],
      [650, 218, 54],
      [850, 218, 54],
    ],
    color: ['white'],
  },
  {
    module: ['socle', 'socle'],
    dimensions_expand: [[500, 800, 1000], [500], [60]],
    color: ['oak', 'walnut', 'laminate_white', 'laminate_grey'],
  },
  {
    module: ['wall', 'deep'],
    dimensions: [[18, 647, 2324]],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['wall', 'shallow'],
    dimensions: [[18, 617, 2324]],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['accessory', 'soft_close'],
    dimensions: [[1, 1, 1]],
    color: ['metal_stainless'],
  },
  {
    module: ['accessory', 'push_to_open'],
    dimensions: [[1, 1, 1]],
    color: ['metal_stainless'],
  },
  {
    module: ['accessory', 'rail_top'],
    dimensions: [
      [1600, 85, 75],
      [2400, 85, 75],
      [2000, 100, 80],
      [3000, 100, 80],
    ],
    color: ['metal_stainless'],
  },
  {
    module: ['accessory', 'rail_bottom'],
    dimensions: [
      [1600, 85, 75],
      [2400, 85, 75],
      [2000, 100, 80],
      [3000, 100, 80],
    ],
    color: ['metal_stainless'],
  },
];

export default modules;
