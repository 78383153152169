import Loader from 'mycs/shared/components/Loader/Loader';

import styles from './AsyncLoader.scss';

export function AsyncLoader() {
  return (
    <div className={styles.loader}>
      <Loader />
    </div>
  );
}
