import { BehaviorSubject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { asyncRequest } from 'mycs/shared/utilities/FetchUtils/FetchUtils';
import { LocationData } from './types';
import cfg from './GeolocationConfig';
import CookieService from 'mycs/shared/services/CookieService/CookieService';
import LocalStorageUtils from 'mycs/shared/utilities/LocalStorageUtils/LocalStorageUtils';
import Logger from 'mycs/shared/services/Logger';
import UrlProviderService from 'mycs/shared/services/UrlProviderService/UrlProviderService';

export { LocationData } from './types';

class GeolocationService {
  cache: LocationData;
  locationSubject: BehaviorSubject<LocationData>;

  constructor() {
    this.cache = this._loadLocationFromCookie();
    this.locationSubject = new BehaviorSubject(this.cache);
  }

  /**
   * Init the cache
   */
  init() {
    CookieService.state$
      .pipe(
        map(({ cookieConsent }) => cookieConsent),
        filter((value) => !!value),
        take(1)
      )
      .subscribe((cookie) => {
        if (cookie?.preferences) {
          this.getLocationData().then(() => {
            this.locationSubject.next(this.cache);
          });
        }
      });
  }

  /**
   * Get cached geolocation data for the current user/ip
   */
  getLocationDataCache() {
    return this.cache;
  }

  /**
   * Get geolocation data for the current user/ip
   */
  async getLocationData(): Promise<LocationData | undefined> {
    if (!CookieService.state.cookieConsent?.preferences) {
      return;
    }

    if (Object.keys(this.cache).length !== 0) {
      return this.cache;
    }

    const url = UrlProviderService.getGeolocationApiUrl();

    let response;
    try {
      response = await asyncRequest(url);
    } catch (err) {
      Logger.error(err);
    }

    if (response && response.data) {
      const geoData = this._reduceGeolocationData(
        response.data as LocationData
      );
      LocalStorageUtils.setCookie(
        cfg.cookie.key,
        JSON.stringify(geoData),
        cfg.cookie.exdays
      );
      this.cache = geoData;
    }

    return this.cache;
  }

  /**
   * Return an Observable to get geolocation info
   *
   * @returns {BehaviorSubject<LocationData>}
   */
  getLocationSubject() {
    return this.locationSubject;
  }

  /**
   * Reduce the geolocation data
   *
   * Info: Safari wouldn't let us store the whole object in the cookie,
   * so JSON.parse failed when reading it from the cookie again.
   *
   * @returns reduced geolocation data
   */
  _reduceGeolocationData(data: LocationData): LocationData {
    const geoData: LocationData = {
      country_name: data.country_name || '',
      country_code_iso3166alpha2: data.country_code_iso3166alpha2 || '',
      region_name: data.region_name || '',
      city: data.city || '',
      postal_code: data.postal_code || '',
      user_ip: data.user_ip || '',
      latitude: data.latitude || 0,
      longitude: data.longitude || 0,
      distance_from_showrooms: [],
    };

    if (data.distance_from_showrooms) {
      geoData.distance_from_showrooms = data.distance_from_showrooms.map(
        (location) => {
          return {
            distance: location.distance,
            city: location.city,
          };
        }
      );
    }

    return geoData;
  }

  /**
   * Load geolocation from cookie if available.
   * Return an empty object if no geolocation is saved in the cookie or it's impossible to parse the cookie.
   */
  _loadLocationFromCookie(): LocationData {
    const location = LocalStorageUtils.getCookie(cfg.cookie.key);
    let result = {};
    if (location) {
      try {
        result = JSON.parse(location);
      } catch (err) {
        result = {};
      }
    }
    return result as LocationData;
  }
}

export const GeolocationServiceClass = GeolocationService;
export default new GeolocationService();
