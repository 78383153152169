import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import { useLocale } from 'mycs/shared/state/LocaleContext';

type Props = {
  children: string;
};

export default function Localize({ children }: Props) {
  const { locale } = useLocale();
  return <>{I18nUtils.localize(locale, children)}</>;
}
