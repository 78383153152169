import ReactDOM from 'react-dom';
import { StaticRouter } from 'react-router-dom/server';

import { LocaleContext } from 'mycs/shared/state/LocaleContext';
import ErrorBoundary from 'mycs/landing-page/components/ErrorBoundary/ErrorBoundary';
import LocationUtils from 'mycs/shared/utilities/LocationUtils/LocationUtils';
import Logger from 'mycs/shared/services/Logger';

/**
 * Some hooks depends on context providers (like useLocation) that when used outside
 * the react app hook might crash the application
 */
export function reactSafeRenderer(
  jsx: JSX.Element,
  container: ReactDOM.Container | null
) {
  const { origin, href } = window.location;
  const locale = LocationUtils.getLocaleFromUrl(href);
  const countryCode = LocationUtils.getCountryCodeFromUrl(href);

  if (MYCS_IS_SSR_BUILD) {
    Logger.warn('reactSafeRenderer should be used only in the client side');
  }

  // FIXME: This is deprecated and should be replaced with createRoot
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(
    <ErrorBoundary>
      <LocaleContext.Provider value={{ locale, countryCode, baseUrl: origin }}>
        <StaticRouter location={href}>{jsx}</StaticRouter>
      </LocaleContext.Provider>
    </ErrorBoundary>,
    container
  );
}
