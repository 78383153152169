import _flatten from 'lodash/flatten';
import { Component, createRef } from 'react';

import Logger from 'mycs/shared/services/Logger';

export type MouseOrTouch =
  | React.MouseEvent<HTMLDivElement>
  | React.TouchEvent<HTMLDivElement>;

type ClickOutsideProps = {
  children: React.ReactNode;
  onClickOutside: (e?: Event | MouseOrTouch) => void;
  clickOutsideWhiteList: (string | Element | React.RefObject<any>)[];
  clickOutsideEvents: string[];
};

/**
 * Wraps other components providing clickoutside
 *
 * e.g. MyExampleComponent
 * <ClickOutside ... >
 *    <div>...</div>
 * </ClickOutside>
 *
 * Use it
 * <MyExampleComponent onClickOutside={ ...} />
 *
 * You can provide clickOutsideWhiteList array with components
 * that are ignored.
 */
export default class ClickOutside extends Component<ClickOutsideProps> {
  static defaultProps = {
    clickOutsideWhiteList: [],
    clickOutsideEvents: ['mouseup', 'touchstart'],
  };

  private wrapper = createRef<HTMLDivElement>();

  /**
   * Call callback on ClickOutside and pass the event
   *
   * @param {event} e
   */
  handleClickOutside = (e: Event) => {
    const el = this.wrapper;

    const whiteListEls = _flatten(
      this.props.clickOutsideWhiteList.map((wle) => {
        if (typeof wle === 'string') {
          return Array.from(document.querySelectorAll(wle));
        }

        if (isReactRef(wle)) {
          return (wle as React.RefObject<any>).current;
        }

        return wle;
      })
    );

    // White-list the ClickOutside element itself
    whiteListEls.unshift(el.current);

    try {
      const eventOnWhiteListedElem = whiteListEls.some(
        (wle) => wle && wle.contains(e.target)
      );

      if (!eventOnWhiteListedElem) {
        this.props.onClickOutside(e);
      }
    } catch (e) {
      Logger.error(e);
    }
  };

  /**
   * Attach listeners
   */
  componentDidMount() {
    this.props.clickOutsideEvents.forEach((e) =>
      document.addEventListener(e, this.handleClickOutside)
    );
  }

  /**
   * Remove the listener in case the props change and there is not ClickOutside handler
   */
  componentDidUpdate(prevProps: ClickOutsideProps) {
    if (prevProps.onClickOutside !== this.props.onClickOutside) {
      this.props.clickOutsideEvents.forEach((e) =>
        document.removeEventListener(e, this.handleClickOutside)
      );
      this.props.clickOutsideEvents.forEach((e) =>
        document.addEventListener(e, this.handleClickOutside)
      );
    }
  }

  /**
   * Remove listeners when component unmounts
   */
  componentWillUnmount() {
    this.props.clickOutsideEvents.forEach((e) =>
      document.removeEventListener(e, this.handleClickOutside)
    );
  }

  /**
   * Render the Elements that are Wrapped by the ClickOutside
   * @returns {any}
   */
  render() {
    return (
      <div ref={this.wrapper} style={{ display: 'contents' }}>
        {this.props.children}
      </div>
    );
  }
}

/**
 * Check if an object is a React.RefObject
 */
function isReactRef(value: object) {
  return Object.keys(value).length == 1 && Object.keys(value)[0] === 'current';
}
