import { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import ClickOutside from 'mycs/shared/components/ClickOutside/ClickOutside';
import Alink from 'mycs/shared/components/Alink/Alink';
import styles from './Collapsible.scss';

type Props = {
  title?: string;
  defaultCollapsed?: boolean;
  isAutoCollapsible?: boolean;
  onExpand?(): void;
  onCollapse?(): void;
  header: ReactNode;
  content: ReactNode;
  clickOutsideWhiteList?: string[];
  disableClickOutside?: boolean;
};

export default function Collapsible({
  title = '',
  defaultCollapsed = true,
  isAutoCollapsible = true,
  onExpand = () => null,
  onCollapse = () => null,
  header,
  content,
  clickOutsideWhiteList,
  disableClickOutside = false,
}: Props) {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  useEffect(() => {
    if (defaultCollapsed !== collapsed) {
      setCollapsed(defaultCollapsed);
    }
  }, [defaultCollapsed]);

  const toggleState = () => {
    setCollapsed(!collapsed);
    if (!collapsed && onCollapse) onCollapse();
    if (collapsed && onExpand) onExpand();
  };

  const onClickOutside = () => {
    if (collapsed || !isAutoCollapsible || disableClickOutside) return;
    toggleState();
  };

  const containerClass = classNames({
    [styles.open]: !collapsed,
  });

  return (
    <div className={containerClass} data-testid="collapsible-container">
      <ClickOutside
        onClickOutside={onClickOutside}
        clickOutsideEvents={['mouseup']}
        clickOutsideWhiteList={clickOutsideWhiteList}
      >
        <div>
          <Alink onClick={toggleState} text={title} aria-expanded={!collapsed}>
            {header}
          </Alink>
          <div className={styles.content} role="region">
            {content}
          </div>
        </div>
      </ClickOutside>
    </div>
  );
}
