import CookieService from 'mycs/shared/services/CookieService/CookieService';
import { filter, take } from 'rxjs/operators';

declare global {
  interface Window {
    // Econda API.
    emos3: {
      send(event: {
        // [**enter_transactionID_here**, null, null, **enter_revenue_here**]
        billing: Array<null | string | number>;
      }): void;
    };
  }
}

/**
 * Feed Dynamix Performance Measurement.
 * Loaded in index.template.html.
 */
class EcondaAnalyticsService {
  readonly privacyModeStorageKey = 'emos_privacy';

  async init() {
    CookieService.state$
      .pipe(
        filter((state) => state.isInitialized),
        take(1)
      )
      .subscribe(({ cookieConsent }) => {
        // From Econda docs:
        // If the permission for analysis/statistics is given on your data protection banner,
        // the privacy mode should be set to 3 if not 2 (anonymous)
        if (cookieConsent?.statistics || cookieConsent?.marketing) {
          // We are purposely bypassing LocalStorageUtils,
          // as it adds "mycs-" prefix for every storage item.
          localStorage.setItem(this.privacyModeStorageKey, '3');
        } else {
          localStorage.setItem(this.privacyModeStorageKey, '2');
        }
      });
  }

  /**
   * Track order.
   *
   * @param {string} orderID
   * @param {number} orderTotal in major units
   */
  trackOrder(orderID: string, orderTotal: number) {
    window.emos3.send({
      billing: [orderID, null, null, orderTotal],
    });
  }
}

export default new EcondaAnalyticsService();
