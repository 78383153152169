import { useEffect, useState } from 'react';

import CookieService, {
  CookieConsent,
} from 'mycs/shared/services/CookieService/CookieService';

export function useCookieConsent() {
  const [{ cookieConsent, isInitialized }, setState] = useState({
    cookieConsent: null as null | CookieConsent,
    isInitialized: false,
  });

  useEffect(() => {
    const subscription = CookieService.state$.subscribe((state) => {
      setState(state);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return {
    cookieConsent,
    hasInitializedCookieConsent: isInitialized,
  };
}
