import { useLocation } from 'react-router-dom';
import { CfFooter } from '@mycs/contentful';

import { getPageContentByKeyFromReduxState } from 'mycs/shared/state/slices/pageSlice';
import { useAppSelector } from 'mycs/shared/state/store';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import AnnouncementModal from 'mycs/shared/components/AnnouncementModal/AnnouncementModal';
import BottomMenu from 'mycs/shared/components/BottomMenu/BottomMenu';
import useUI from 'mycs/hooks/useUI';
import ExitIntentModal from 'mycs/shared/components/ExitIntentModal/ExitIntentModal';
import MenuGroups from 'mycs/shared/components/MenuGroups/MenuGroups';

import styles from './Footer.scss';
import PromotionalPopup from '../PromotionalPopup/PromotionalPopup';

export default function Footer() {
  const { pathname } = useLocation();
  const { showHeader } = useUI(pathname);
  const { locale, countryCode } = useLocale();

  const data = useAppSelector((state) =>
    getPageContentByKeyFromReduxState<{ footer: CfFooter }>(state, {
      locale,
      pathname,
    })
  );

  const footerGroups = data?.footer?.footerGroups || [];

  const isAnnouncementEnabled = data?.footer?.isAnnouncementEnabled || false;

  if (!showHeader) return <></>;

  return (
    <div className="hidden-print">
      <footer className={styles.footerMenu}>
        <MenuGroups groups={footerGroups} />
      </footer>

      <BottomMenu />
      <ExitIntentModal
        locale={locale}
        pathname={pathname}
        countryCode={countryCode}
      />
      {isAnnouncementEnabled && <AnnouncementModal />}
      <PromotionalPopup />
    </div>
  );
}
