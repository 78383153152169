import { ShelfFurnitureConfig } from '../config/shelf-config';
import ComponentService from '../services/ComponentService';
import Component from '../components/Component';
import traverse from 'traverse';
import { Layout } from '../types';
import isMatch from 'lodash/isMatch';
import { convertToMM } from '../components/shelf/ShelfUtils';
/**
 * @class ShelfUtils
 */
export default class ShelfUtils {
  /**
   * Get SKUs which we should cut, when we have baseboard
   * @param {object} SKULayout
   * @returns {object} {
   *   boards: string[] array of SKUs
   *   innerWalls: string[] array of SKUs
   *   outerWalls: {left, right}
   * }
   */
  static getBaseboardSKUs(SKULayout, config: ShelfFurnitureConfig) {
    const boards = [];
    const innerWalls = [];
    const outerWalls = {
      right: [],
      left: [],
    };

    // invalid layout
    if (!SKULayout.children[1]) return { boards, innerWalls, outerWalls };

    SKULayout.children[1].children.forEach((decoratedCol) => {
      traverse(decoratedCol).forEach((node) => {
        if (!(node && typeof node === 'object' && node.props)) return;

        switch (node.props.section) {
          case 'board':
            if (node.props.z === 0) {
              boards.push(node.props.sku);
            }
            break;
          case 'wall':
            switch (node.props.type) {
              case config.wallTypeOuter:
                if (node.props.rotation_z === 180) {
                  outerWalls.left.push(node.props.sku);
                } else {
                  outerWalls.right.push(node.props.sku);
                }
                break;
              case config.wallTypeInner:
                innerWalls.push(node.props.sku);
                break;
            }
            break;
        }
      });
    });

    return { boards, innerWalls, outerWalls };
  }

  /**
   *
   * @param DBLayout
   * @param baseboardCuts
   * @returns
   */
  static getServiceElements(
    DBLayout: any,
    baseboardCuts: { depth: number; height: number } | null = null
  ) {
    const elements = [];
    const generatedConfig = ComponentService.getGeneratedConfig();
    const { component } = generatedConfig['shelf'];
    const { mappingShape } = component;
    let baseboardCutsMM = null;
    if (baseboardCuts) {
      baseboardCutsMM = {
        depth: convertToMM(baseboardCuts.depth),
        height: convertToMM(baseboardCuts.height),
      };
    }

    traverse(DBLayout).forEach((node) => {
      // Skip every thing except Element
      if (
        !(
          node &&
          node.props &&
          node.props.furniture_type &&
          node.props.section &&
          node.props.type &&
          node.props.length &&
          node.props.width &&
          node.props.height &&
          node.props.color
        )
      ) {
        return;
      }
      const elementProps = Component.getKeys(node.props);
      let element = {
        furniture_type: node.props.furniture_type,
        section: node.props.section,
        type: node.props.type,
        length: node.props.length,
        width: node.props.width,
        height: node.props.height,
        color: node.props.color,
        services: [],
      };
      if (mappingShape) {
        const shapeProps = mappingShape(elementProps);
        if (shapeProps !== null) {
          element.furniture_type = shapeProps.furniture_type;
          element.section = shapeProps.section;
          element.type = shapeProps.type;
          element.length = shapeProps.length;
          element.width = shapeProps.width;
          element.height = shapeProps.height;
          element.color = shapeProps.color;
          // Add a baseboard cut service SKU for board
          if (
            baseboardCutsMM &&
            isMatch(node.props, { section: 'board', z: 0 })
          ) {
            element.services.push({
              props: {
                section: 'baseboard_cut',
                type: 'board',
                width: baseboardCutsMM.depth,
              },
            });
          }

          // Add a baseboard cut service SKU for inner wall
          if (
            baseboardCutsMM &&
            isMatch(node.props, { section: 'wall', type: 'inner' })
          ) {
            element.services.push({
              props: {
                section: 'baseboard_cut',
                type: 'inner_sidewall',
                width: baseboardCutsMM.depth,
                height: baseboardCutsMM.height,
              },
            });
          }

          // Add a baseboard cut service SKU for outer wall
          if (
            baseboardCutsMM &&
            isMatch(node.props, { section: 'wall', type: 'outer' })
          ) {
            const direction = node.props.rotation_z === 180 ? 'left' : 'right';

            element.services.push({
              props: {
                section: 'baseboard_cut',
                type: `outer_sidewall_${direction}`,
                width: baseboardCutsMM.depth,
                height: baseboardCutsMM.height,
              },
            });
          }

          elements.push(element);
        }
      }
    });

    return elements;
  }

  /**
   * Test a node of the 3DLayout to see if the node require specific workshop work: drilling
   *
   * @param node of the 3DLayout
   * @returns boolean
   */
  static isDrillingNode(node) {
    return node && typeof node === 'object' && node.drillPosition !== undefined;
  }

  /**
   * Get the drilling height of the replaced inner walls by sku
   *
   * @param {object} SKULayout
   * @returns {object} SKUs and drill height per SKU
   */
  static getDrillingHeights(SKULayout: Layout): {
    innerWallSkus: string[];
    drillPositions: number[];
  } {
    const innerWallReplacement = {
      innerWallSkus: [],
      drillPositions: [],
    };

    traverse(SKULayout).forEach((node) => {
      if (ShelfUtils.isDrillingNode(node) && node.sku) {
        innerWallReplacement.innerWallSkus.push(node.sku);
        innerWallReplacement.drillPositions.push(node.drillPosition);
      }
    });

    return innerWallReplacement;
  }

  /**
   * Calculate drill heights and side for inner walls,
   * which will be replaces with outer walls
   * @param shelfColumns
   */
  static getDrillingProperty(shelfColumns: any[]) {
    const innerWallReplacements: {
      drillHeight: number;
      drillSide: 'right' | 'left';
    }[] = [];
    for (let i = 0; i < shelfColumns.length - 1; i++) {
      const leftColumn = shelfColumns[i];
      const rightColumn = shelfColumns[i + 1];
      const topLeftBoard = Math.max(
        ...leftColumn.boards.map((b) => b.sectionIndex)
      );
      const topRightBoard = Math.max(
        ...rightColumn.boards.map((b) => b.sectionIndex)
      );
      const drillHeight = Math.min(topLeftBoard, topRightBoard);
      const innerWallHeight = leftColumn.wallRight.heightInSections;

      // if manual drills are necessary
      if (innerWallHeight > drillHeight) {
        // the outer wall rotation
        // from which side we should place drills
        const drillSide = topLeftBoard < topRightBoard ? 'right' : 'left';
        innerWallReplacements.push({
          drillHeight,
          drillSide,
        });
      } else {
        innerWallReplacements.push(null);
      }
    }
    return innerWallReplacements;
  }

  /**
   * Test a simple structure for visibible drillings
   *
   * @param simpleStructure {object}
   */
  static hasVisibleDrillings(simpleStructure: any) {
    const shelfColumns = simpleStructure.columns;

    let visibleDrillings = false;

    for (let i = 0; i < shelfColumns.length - 1; i++) {
      const leftColumn = shelfColumns[i];
      const rightColumn = shelfColumns[i + 1];
      const topLeftBoard = Math.max(
        ...leftColumn.boards.map((b) => b.sectionIndex)
      );
      const topRightBoard = Math.max(
        ...rightColumn.boards.map((b) => b.sectionIndex)
      );
      const drillHeight = Math.min(topLeftBoard, topRightBoard);
      const innerWallHeight = leftColumn.wallRight.heightInSections;

      // if manual drills are necessary
      if (innerWallHeight > drillHeight) {
        visibleDrillings = true;
      }
    }

    return visibleDrillings;
  }
}
