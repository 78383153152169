import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { CfMetaTags } from '@mycs/contentful';
import { MycsURL } from '@mycs/edge-lambdas';

import { getMeta } from 'mycs/shared/services/MetaTagsService/MetaTagsService';
import { getPageContentByKeyFromReduxState } from 'mycs/shared/state/slices/pageSlice';
import { useAppSelector } from 'mycs/shared/state/store';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import config from 'mycs/config';

type PageMetaTags = {
  canonical?: string;
  robots?: 'index, follow' | 'noindex, nofollow' | 'noindex, follow';
  metaTitle?: string;
  metaDescription?: string;
  keywords?: string[];
  metaTags?: ExtendedCfMetaTags;
  metaLocUrl?: {
    [locale: string]: string;
  };
};

type ExtendedCfMetaTags = CfMetaTags & {
  locUrl?: {
    [locale: string]: string;
  };
};

export default function MetaTags() {
  const { locale, countryCode, baseUrl } = useLocale();
  const { pathname, search } = useLocation();
  const page = useAppSelector((state) =>
    getPageContentByKeyFromReduxState<PageMetaTags>(state, {
      locale,
      pathname,
    })
  );

  const url = new MycsURL(pathname + search, baseUrl);

  const fallbackMetaTags = page?.metaTags;
  const metaFields = {
    canonical: page?.canonical || fallbackMetaTags?.canonical,
    robots: page?.robots || fallbackMetaTags?.robots,
    title: page?.metaTitle || fallbackMetaTags?.title,
    description: page?.metaDescription || fallbackMetaTags?.description,
    keywords: page?.keywords || fallbackMetaTags?.keywords,
    locUrl: page?.metaLocUrl || fallbackMetaTags?.locUrl,
    hreflangs: [],
    fb_url: '',
    fb_image: '',
    fb_locale: '',
    fb_app_id: '',
  };

  const meta = getMeta(metaFields, locale, countryCode, url.toString());

  const {
    title,
    language,
    languageCode,
    placename,
    hreflangs = [],
    keywords = [],
    robots = '',
    description = '',
    canonical = '',
    fb_app_id = '',
    fb_locale = '',
    fb_url = '',
    fb_title = '',
    fb_image = '',
    fb_description = '',
  } = meta;
  /**
   * Get the i18n links for the header
   */
  const i18nLinks = hreflangs
    .filter(({ lang }) => !config.geoSettings.skipLocaleHrefLang.includes(lang))
    .map(({ lang, url }, index) => {
      return <link key={index} rel="alternate" hrefLang={lang} href={url} />;
    });

  /**
   * Render the title, meta and link tags to the head tag
   */
  return (
    <Helmet defaultTitle="MYCS" htmlAttributes={{ lang: languageCode }}>
      <title>{title}</title>

      {canonical ? <link rel="canonical" href={canonical} /> : null}

      {i18nLinks}

      {/* Main meta tags */}
      <meta httpEquiv="language" content={language} />
      <meta name="geo.placename" content={placename} />
      <meta name="robots" content={robots} />
      <meta name="description" content={description || ''} />

      {keywords && keywords.length ? (
        <meta name="keywords" content={keywords.join(',')} />
      ) : null}

      {/* Facebook meta tags */}
      <meta property="og:site_name" content="MYCS" />
      <meta property="og:type" content="website" />
      <meta property="fb:app_id" content={fb_app_id || ''} />
      <meta property="og:locale" content={fb_locale || ''} />
      <meta property="og:url" content={fb_url || ''} />
      <meta property="og:title" content={fb_title || ''} />
      <meta property="og:image" content={fb_image || ''} />
      <meta property="og:description" content={fb_description || ''} />
    </Helmet>
  );
}
