import joynConfig, { SofaFurnitureConfig } from './joyn-config';

const config: SofaFurnitureConfig = {
  ...joynConfig,
  hasAssembly: false,
  defaultDimensions: {
    ...joynConfig.defaultDimensions,
    regular_depth: 600,
  },
  viewModes: {
    ...joynConfig.viewModes,
    colorsCushionBackcushion: {
      ...joynConfig.viewModes.colorsCushionBackcushion,
      text: 'Cushion',
      iconName: 'configurator/joyn/ottoman_color_cushion',
      selectionMode: 'all',
    },
    colorsBaseBackrest: {
      ...joynConfig.viewModes.colorsBaseBackrest,
      text: 'Base',
      iconName: 'configurator/joyn/ottoman_color_base',
      selectionMode: 'all',
    },
    colorsLegs: {
      ...joynConfig.viewModes.colorsLegs,
      iconName: 'configurator/joyn/ottoman_color_feet',
    },
    colorsWhole: {
      ...joynConfig.viewModes.colorsWhole,
      text: 'Whole Ottoman',
      iconName: 'configurator/joyn/ottoman_color_all',
    },
    colors: {
      ...joynConfig.viewModes.colors,
      iconName: 'configurator/joyn/ottoman_tyme_60',
    },
  },
  options: [
    // Add ottoman options
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: '60 x 60 cm',
      length: 600,
      iconName: 'configurator/joyn/ottoman_tyme_60',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: '80 x 60 cm',
      length: 800,
      iconName: 'configurator/joyn/ottoman_tyme_80',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: '100 x 60 cm',
      length: 1000,
      iconName: 'configurator/joyn/ottoman_tyme_100',
    },
    // Fetch all options except module options
    ...joynConfig.options.filter((option) => option.group !== 'module'),
  ],
  isOttoman: true,
};

export default config;
