import {
  cookieStringToMap,
  formatCookie,
} from 'mycs/shared/utilities/CookieUtils/CookieUtils';
import { createContext, useCallback, useContext } from 'react';

// Contains the cookies passed from the server
type CookieMap = Record<string, string | undefined>;

export const CookieContext = createContext<CookieMap>({});

export function CookieContextProvider({
  cookieMap,
  children,
}: {
  cookieMap: CookieMap;
  children: React.ReactNode;
}) {
  return (
    <CookieContext.Provider value={cookieMap}>
      {children}
    </CookieContext.Provider>
  );
}

export const useCookie = (cookieName: string) => {
  const cookieMap = useContext(CookieContext);
  let cookieValue = cookieMap[cookieName];

  if (typeof document !== 'undefined') {
    const documentCookieMap = cookieStringToMap(document.cookie);
    // If we are on the client, document.cookie needs time to be updated with Set-Cookie header value.
    // Hence the fallback to the cookie map passed from the server.
    cookieValue = documentCookieMap[cookieName] || cookieMap[cookieName];
  }

  const setCookie = useCallback(
    (cookieValue: string, cookieMaxAge: number) => {
      // We currently do not have a mechanism to update the cookie map on the server
      // and pass the updated map to the client.
      if (typeof document === 'undefined') {
        throw new Error('noop');
      }

      document.cookie = formatCookie(
        cookieName,
        cookieValue,
        cookieMaxAge,
        '/' // Make sure the cookie is available on all pages.
      );
    },
    [cookieName]
  );

  return [cookieValue, setCookie] as const;
};
