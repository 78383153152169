import { memo } from 'react';

type Props = {
  className?: string;
  content?: string;
  isInline?: boolean;
};

export default memo(function SafeText({
  isInline = false,
  className,
  content,
}: Props) {
  if (!content) return null;

  return isInline ? (
    <span
      className={className}
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  ) : (
    <div
      className={className}
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  );
});
