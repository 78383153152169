import { useEffect, useRef } from 'react';

/**
 * Used as a function placeholder
 */
export const noop = () => { };

/**
 * Used in functions to force the presence of
 * the parameters
 * .e.g function something(param = mandatory()){}
 * @param {?string} msg
 */
export const mandatory = (msg = 'Missing parameter') => {
  throw Error(msg);
};

export const usePrevious = (value: any) => {
  const ref = useRef<any>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
