import { PureComponent } from 'react';

import Alert from 'mycs/shared/components/Alert/Alert';
import WindowLocationUtils from 'mycs/shared/utilities/WindowLocationUtils/WindowLocationUtils';

import styles from './MsgAlert.scss';

type Props = {};

type State = {
  msg: string;
};

export default class MsgAlert extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      msg: '',
    };
  }

  /**
   * URL change handler
   */
  onUrlChange() {
    const { msg } = WindowLocationUtils.getSearch();
    if (!msg) return;

    WindowLocationUtils.removeSearchParam('msg');

    this.setState({ msg });
    setTimeout(() => {
      this.setState({ msg: '' });
    }, 8000);
  }

  /**
   * Add listeners on mount
   */
  componentDidMount() {
    this.onUrlChange();
    WindowLocationUtils.onUrlChange(() => this.onUrlChange());
  }

  render() {
    const { msg } = this.state;
    return msg ? (
      <div role="alert" aria-live="assertive">
        <Alert
          className={styles.alert}
          iconName="configurator/info"
          text={msg}
        />
      </div>
    ) : null;
  }
}
