import { ContentfulClientApi, createClient } from 'contentful';

import cfg from 'mycs/config';

export class ContentfulClients {
  clientsBySpace: {
    [space: string]: {
      [countryCode: string]: ContentfulClientApi;
    };
  } = {};

  private getClient(
    space: 'frontend' | 'internal',
    countryCode: string
  ): ContentfulClientApi {
    if (!this.clientsBySpace[space]) {
      this.clientsBySpace[space] = {};
    }

    if (!this.clientsBySpace[space][countryCode]) {
      this.clientsBySpace[space][countryCode] = createClient({
        host: cfg.contentful.host.replace(/^xx/, countryCode),
        ...cfg.contentful.spaces[space],
      });
    }

    return this.clientsBySpace[space][countryCode];
  }

  getFrontendSpaceClient(countryCode: string) {
    // "Mycs Frontend" space
    const space = 'frontend';

    return this.getClient(space, countryCode);
  }

  getInternalSpaceClient(countryCode: string) {
    // "Mycs Internal" space
    const space = 'internal';

    return this.getClient(space, countryCode);
  }
}
