import union from 'lodash/union';
import { Subject } from 'rxjs';

import { mandatory } from 'mycs/shared/utilities/GeneralUtils/GeneralUtils';
import cfg from 'mycs/config';

const { storageSettings } = cfg;

/**
 * Facade to localStorage with cookie fallback
 * and reactive support
 */
class LocalStorage extends Subject<any> {
  domain: undefined;
  prefix: string;
  isLocalStorageSupported: boolean;
  /**
   * Create an instance of LocalStorage.
   */
  constructor() {
    super();

    /**
     * @type {string | undefined}
     */
    this.domain = undefined;
    this.prefix = storageSettings.prefix;
    this.isLocalStorageSupported = this.hasLocalStorageSupport();
  }

  /**
   * @returns {string}
   */
  static _documentCookies() {
    if (typeof document === 'undefined') return '';
    return document.cookie || '';
  }

  /**
   * Check if the localstorage is supported
   *
   * @returns {boolean}
   */
  hasLocalStorageSupport() {
    try {
      localStorage.setItem('isLocalStorageSupported', 'true');
      localStorage.removeItem('isLocalStorageSupported');
      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   * Get a key used for storing
   */
  getKey(key: string): string {
    return `${this.prefix}.${key}`;
  }

  /**
   * Save the value to local storage or cookie
   *
   * @param {string} key
   * @param {any} value
   * @param {number} [exdays] (Cookie expiration days)
   */
  set(
    key: string = mandatory(),
    value: any = mandatory(),
    exdays: number = storageSettings.exdays
  ) {
    value = JSON.stringify(value);
    key = this.getKey(key);

    let type;
    if (this.isLocalStorageSupported) {
      localStorage.setItem(key, value);
      type = 'localStorage';
    } else {
      this.setCookie(key, value, exdays);
      type = 'cookie';
    }

    this.next({ action: 'set', type, value });
  }

  /**
   * Get a stored value
   */
  get(key: string, omitPrefix = false) {
    if (key == null) return;

    if (!omitPrefix) {
      key = this.getKey(key);
    }

    let data, type;
    if (this.isLocalStorageSupported) {
      data = localStorage.getItem(key);
      type = 'localStorage';
    } else {
      data = this.getCookie(key);
      type = 'cookie';
    }

    let value;
    try {
      value = JSON.parse(data as any);
    } catch (e) {
      value = data;
    }

    this.next({ action: 'get', type, value });

    return value;
  }

  /**
   * Remove a value from localstorage
   */
  remove(key: string) {
    if (key == null) return;

    key = this.getKey(key);

    let type;
    if (this.isLocalStorageSupported) {
      localStorage.removeItem(key);
      type = 'localStorage';
    } else {
      this.setCookie(key, '', -1);
      type = 'cookie';
    }

    this.next({ action: 'remove', type, key });
  }

  /**
   * Create new cookie (or removes cookie by setting negative expiration)
   */
  setCookie(key: string, value: any, exdays: number = storageSettings.exdays) {
    if (typeof document === 'undefined') return;

    const date = new Date();
    date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    let cookie = `${key}=${value}; ${expires};`;
    cookie = this.domain ? `${cookie} domain=${this.domain}` : cookie;
    cookie = `${cookie}; path=/`;
    document.cookie = cookie;
  }

  /**
   * Get contents of a cookie
   */
  getCookie(key: string): any {
    const name = `${key}=`;
    const cookies = LocalStorage._documentCookies().split(';');
    for (let i = 0; i < cookies.length; i++) {
      let c = cookies[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
    }
    return null;
  }

  /**
   * Get all the cookies from the current domain
   *
   * @param {boolean} filterForPrefix - if the entries should be filtered for prefixed keys
   * @returns {any[]}
   */
  getAllCookies(filterForPrefix = true) {
    const cookies = LocalStorage._documentCookies()
      .split(';')
      .map((cookie) => {
        const [key, value] = cookie.split('=');

        return {
          [key.trim()]: value,
        };
      });

    return filterForPrefix
      ? cookies.filter((cookie) => Object.keys(cookie)[0].includes(this.prefix))
      : cookies;
  }

  /**
   * Get all the items from the localStorage
   *
   * @param {boolean} filterForPrefix - if the entries should be filtered for prefixed keys
   * @returns {any}
   */
  getAllLocalStorageItems(filterForPrefix = true) {
    if (!this.isLocalStorageSupported) return [];

    let keys = Object.keys(localStorage);
    keys = filterForPrefix
      ? keys.filter((key) => key.startsWith(this.prefix))
      : keys;
    return keys.map((item) => ({
      [item]: localStorage[item],
    }));
  }

  setStorageVersion(version = storageSettings.version) {
    this.remove('version');
    this.set('version', version);
  }

  /**
   * Clear items from the localStorage/cookies
   */
  removeTmpItems() {
    const storedItems = union(
      this.getAllCookies(),
      this.getAllLocalStorageItems()
    );
    // Define the cookies that will be deleted
    const filteredStoredItems = storedItems
      .map((item) => Object.keys(item)[0].split('.')[1])
      .filter((key) => !storageSettings.removeWhiteList.includes(key))
      .filter(Boolean);

    const storedVersion = this.get('version');
    // Remove cookies/localstorage items from previous versions
    // or if there isnt any version
    if (+storedVersion !== +storageSettings.version || !storedVersion) {
      filteredStoredItems.forEach((key) => this.remove(key));
      this.next({ action: 'clearAll' });
    }
    if (!storedVersion) {
      this.setStorageVersion();
    }
  }
}

export default new LocalStorage();
