import groupBy from 'lodash/groupBy';
import omit from 'lodash/omit';
import filter from 'lodash/filter';
import memoize from 'lodash/memoize';
import values from 'lodash/values';

const ELEMENT_PROPERTIES = [
  'furniture_type',
  'section',
  'type',
  'length',
  'width',
  'height',
  'color',
];

/**
 * class ElementApi
 * Service to retrieve data from ElementAPI
 */
class ElementApiService {
  elements: any;
  skus: any;
  bboxes: any;
  raw: any;
  elementByKey: any;

  /**
   * Constructor
   */
  constructor() {
    this.elements = {}; // lists of elements per furniture type
    this.skus = { bboxes: {} }; // map { sku: element }
    this.raw = {};
    this.filter = memoize(this.filter, JSON.stringify);
    this.elementByKey = {};
  }

  /**
   * Set SKUs per furniture type
   *
   * @param {array} elements
   * @param {string} furnitureType
   */
  _setElementsPerType(elements, furnitureType) {
    this.elements[furnitureType] = elements;
    elements.forEach((element) => (this.skus[element.sku] = element));
  }

  /**
   * Set SKUs for all types
   *
   * @param {array} elements
   */
  setElements(elements) {
    // Keep higher version of color/scene_object_name skus
    const uniqElements = values(
      groupBy(
        elements,
        (element) => `${element.color}_${element.scene_object_name}`
      )
    ).map((skus) => {
      skus.sort((a, b) => b.version - a.version);
      return skus[0];
    });
    const elementByFurnitureType = groupBy(uniqElements, 'furniture_type');

    this.raw = elements.reduce((acc, element) => {
      acc[element.sku] = element;
      return acc;
    }, this.raw);
    Object.keys(elementByFurnitureType).forEach((furnitureType) => {
      this.elements[furnitureType] = elementByFurnitureType[furnitureType];
      elementByFurnitureType[furnitureType].forEach(
        (element) => (this.skus[element.sku] = element)
      );
    });

    const elementByKey = groupBy(elements, this._getKey);
    Object.keys(elementByKey).map((key) => {
      const skus = elementByKey[key];
      skus.sort((a, b) => b.version - a.version);
      this.elementByKey[key] = skus;
    });
  }

  setBboxes(rawBboxes) {
    const bboxes = {};
    for (const rawBbox of rawBboxes) {
      bboxes[rawBbox.name] = {
        ...rawBbox.bbox,
      };
    }
    this.bboxes = bboxes;
  }

  _getKey(element) {
    return ELEMENT_PROPERTIES.map((k) => element[k]).join('_');
  }

  getSKU(element) {
    const key = this._getKey(element);
    return this.elementByKey[key][0];
  }

  getSKUs(element) {
    if (element === null) {
      return [];
    }
    const key = this._getKey(element);
    const skus = this.elementByKey[key];
    if (skus === undefined) {
      return [];
    }
    return skus;
  }

  /**
   * Get an element by SKU
   *
   * @param {string} sku
   * @returns {any}
   */
  get(sku) {
    return this.skus[sku];
  }

  getRaw(sku) {
    return this.raw[sku];
  }

  /**
   * Filter elements by params
   *
   * @param {any} params
   * @returns {array}
   */
  filter(params) {
    const filterParams = omit(params, 'furniture_type');
    return filter(this.elements[params.furniture_type], filterParams);
  }

  /**
   * get mapped_sku
   *
   * @param {string} sku
   * @returns {string}
   */
  getMappedSku(sku) {
    return (this.raw && this.raw[sku] && this.raw[sku].mapped_skus) || '';
  }
}

const elementApiService = new ElementApiService();

export default elementApiService;
