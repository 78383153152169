import { getConfig, ShelfUtils } from 'furniture-engine';
import {
  AddonSKU,
  Cart,
  DiscountType,
  LineItem,
  LineItemAddon,
  LineItemType,
  PostCartPayloadLineItem,
  PostCartPayloadLineItemAddon,
  SampleBoxType,
  SampleBoxUUID,
} from 'mycs/api/CheckoutAPI';
import StructureSpecsService from 'mycs/shared/services/StructureSpecsService/StructureSpecsService';
import URLProviderService from 'mycs/shared/services/UrlProviderService/UrlProviderService';
import { CfMaterial } from '@mycs/contentful';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';

interface ItemAssemblyComplexity {
  isHardToAssembly: boolean;
  assemblyServiceMessage: string;
}

/**
 * Adds a new item to the array.
 */
export function addLineItem(
  locale: string,
  cartItems: PostCartPayloadLineItem[],
  uuid: string,
  quantity: number,
  baseboardCutMeta?: {
    depth: number;
    height: number;
  },
  sampleBoxItems?: CfMaterial[]
): PostCartPayloadLineItem[] {
  let updatedItems: PostCartPayloadLineItem[] = [
    ...cartItems,
    {
      uuid,
      quantity,
      addons: sampleBoxItems?.map((sample) => ({
        sku: sample.sku,
        meta: {
          // TODO Move it to Checkout API.
          localizedTitle: `${sample.name} (${sample.category.name})`,
          category: sample.category.label || '',
        },
      })),
      meta: {
        // TODO Move it to Checkout API.
        productDetailsPageURL:
          uuid === SampleBoxUUID
            ? URLProviderService.getSamplesUrl(window.location.href, locale)
            : URLProviderService.getProductUrl(
                uuid,
                locale,
                window.location.href
              ),
      },
    },
  ];

  // Group assembly needs to be reset when an item from the same delivery group is added.
  // However, we currently cannot determine when it's the same group item being added,
  // as the delivery group meta is fetched in Checkout API.
  // As a tmp workaround, we reset the assembly service for the entire cart.
  updatedItems.forEach((_, i) => {
    updatedItems = removeAssemblyService(updatedItems, i);
  });

  if (baseboardCutMeta) {
    updatedItems = addBaseboardCut(
      locale,
      updatedItems,
      updatedItems.length - 1,
      baseboardCutMeta
    );
  }

  return updatedItems;
}

export function restoreLineItem(
  items: PostCartPayloadLineItem[],
  itemToRestore: LineItem,
  index: number
) {
  const [item] = toPostCartPayloadLineItems([itemToRestore]);
  const updatedItems = [...items];
  updatedItems.splice(index, 0, item);

  return updatedItems;
}

/**
 * Update line item quantity
 *
 * @param items line item array
 * @param index item array index
 * @param quantity
 * @returns updated line item array
 */
export function updateLineItemQuantity(
  items: PostCartPayloadLineItem[],
  index: number,
  quantity: number
): PostCartPayloadLineItem[] {
  const updatedItems = [...items];

  if (quantity === 0) {
    updatedItems.splice(index, 1);
  } else {
    updatedItems.splice(index, 1, {
      ...items[index],
      quantity,
    });
  }

  return updatedItems;
}

/**
 * Add line item addon
 *
 * @param items line item array
 * @param index item array index
 * @param addon
 * @returns updated line item array
 */
function addLineItemAddon(
  items: PostCartPayloadLineItem[],
  index: number,
  addon: PostCartPayloadLineItemAddon
): PostCartPayloadLineItem[] {
  const updatedItems = [...items];
  updatedItems.splice(index, 1, {
    ...items[index],
    addons: [...(items[index].addons || []), addon],
  });

  return updatedItems;
}

export function toggleBulkAssemblyService(
  shouldAdd: boolean,
  lineItemToToggleUUIDs: string[],
  lineItems: PostCartPayloadLineItem[]
) {
  let updatedItems: PostCartPayloadLineItem[] = [...lineItems];

  lineItems.forEach((lineItem, i) => {
    if (lineItemToToggleUUIDs.includes(lineItem.uuid)) {
      updatedItems = shouldAdd
        ? addAssemblyService(updatedItems, i)
        : removeAssemblyService(updatedItems, i);
    }
  });

  return updatedItems;
}

export function addAssemblyService(
  items: PostCartPayloadLineItem[],
  index: number
) {
  // check if any of the item contains already assembly service then skip adding it
  const item = items[index];
  const hasAssemblyService = item.addons?.some(
    (addon) => addon.sku === AddonSKU.AssemblyService
  );
  if (hasAssemblyService) return items;

  return addLineItemAddon(items, index, {
    sku: AddonSKU.AssemblyService,
  });
}

function addBaseboardCut(
  locale: string,
  items: PostCartPayloadLineItem[],
  index: number,
  baseboardCutMeta: {
    depth: number;
    height: number;
  }
) {
  const { depth, height } = baseboardCutMeta;
  // TODO (Valentyn) Checkout API should fetch Baseboard Cut meta from Design API.
  const shelfConfig = getConfig('shelf');

  return addLineItemAddon(items, index, {
    sku: AddonSKU.BaseboardCut,
    meta: {
      depth,
      height,
      titleLocalized: I18nUtils.localize(
        locale,
        shelfConfig.baseboardCuts.name
      ),
    },
  });
}

/**
 * Remove line item addon
 *
 * @param items line item array
 * @param index item array index
 * @param addon
 * @returns updated line item array
 */
function removeLineItemAddon(
  items: PostCartPayloadLineItem[],
  index: number,
  addonSKU: string
): PostCartPayloadLineItem[] {
  const updatedItems = [...items];
  updatedItems.splice(index, 1, {
    ...items[index],
    addons: items[index].addons?.filter((addon) => addon.sku !== addonSKU),
  });

  return updatedItems;
}

export function removeAssemblyService(
  items: PostCartPayloadLineItem[],
  index: number
) {
  return removeLineItemAddon(items, index, AddonSKU.AssemblyService);
}

/**
 * Format line items for POST /carts request.
 *
 * @param items
 * @returns updated line item array
 */
export function toPostCartPayloadLineItems(
  items: LineItem[]
): PostCartPayloadLineItem[] {
  return items.map(({ uuid, quantity, addonItems, meta }) => ({
    uuid,
    quantity,
    meta,
    addons: addonItems?.map(({ sku, meta }) => ({ sku, meta })),
  }));
}

export interface CartStoragePayload {
  lineItems: PostCartPayloadLineItem[];
  couponCode?: string;
}

export function toCartStoragePayload(
  cart: undefined | Cart
): CartStoragePayload {
  if (!cart || !cart.lineItems?.length) {
    return { lineItems: [] };
  }

  return {
    lineItems: toPostCartPayloadLineItems(cart.lineItems),
    couponCode: cart.couponCode,
  };
}

export function addOrUpdateSampleBox(
  locale: string,
  items: PostCartPayloadLineItem[],
  sampleBoxItems: CfMaterial[]
) {
  const sampleBoxIndex = items.findIndex((item) => item.uuid === SampleBoxUUID);
  if (sampleBoxIndex >= 0) {
    items = updateLineItemQuantity(items, sampleBoxIndex, 0);
  }

  return addLineItem(
    locale,
    items,
    SampleBoxUUID,
    1,
    undefined,
    sampleBoxItems
  );
}

function getAddedAddon(lineItem: LineItem, addonSKU: AddonSKU) {
  return lineItem.addonItems?.find((addon) => addon.sku === addonSKU);
}

export function hasAssemblyService(lineItem: LineItem) {
  return !!getAddedAddon(lineItem, AddonSKU.AssemblyService);
}

export function hasQuantityDiscount(lineItem: LineItem) {
  return !!lineItem.discountItems?.some(
    (discount) => discount.type === DiscountType.QuantityDiscount
  );
}

export function getAssemblyServicePrice(lineItem: LineItem) {
  const singleItemPrice = lineItem.meta.availableAddons?.find(
    (addon) => addon.sku === AddonSKU.AssemblyService
  )?.price;
  if (!singleItemPrice) {
    return;
  }

  return singleItemPrice * lineItem.quantity;
}

export function getBaseboardCutMeta(lineItem: LineItem) {
  const baseboardCut = lineItem.addonItems?.find(
    (addon) => addon.sku === AddonSKU.BaseboardCut
  );

  if (!baseboardCut || !('depth' in baseboardCut.meta)) {
    return null;
  }

  return baseboardCut.meta;
}

export function getCurrPriceWithoutAssembly(lineItem: LineItem) {
  let currLineItemPrice = lineItem.quantity * lineItem.calculation.total;

  const assemblyServicePrice = getAssemblyServicePrice(lineItem);
  if (hasAssemblyService(lineItem) && assemblyServicePrice) {
    currLineItemPrice -= assemblyServicePrice;
  }

  return currLineItemPrice;
}

export function getPrevPriceWithoutAssembly(lineItem: LineItem) {
  let prevLineItemPrice =
    lineItem.quantity * (lineItem.price + lineItem.addonPrice);

  const assemblyServicePrice = getAssemblyServicePrice(lineItem);
  if (hasAssemblyService(lineItem) && assemblyServicePrice) {
    prevLineItemPrice -= assemblyServicePrice;
  }

  return prevLineItemPrice;
}

export function getAddonLocalizedTitle(lineItemAddon: LineItemAddon) {
  if ('localizedTitle' in lineItemAddon.meta) {
    return lineItemAddon.meta.localizedTitle;
  }

  return lineItemAddon.meta.titleLocalized;
}

export function shouldShowVAT(countryCode: string) {
  return !['be', 'lu'].includes(countryCode);
}

export function shouldShowFinancingOptions(countryCode: string) {
  return ['de', 'fr'].includes(countryCode);
}

/**
 * Get price in major units (e.g. EUR).
 *
 * @param price (in cents)
 */
export function toPriceInMajorUnits(price: number) {
  return price / 100;
}

/**
 * Get price precision.
 *
 * @param price (in cents)
 */
export function getPricePrecision(price: number) {
  const priceInMajorUnits = toPriceInMajorUnits(price);
  return priceInMajorUnits - Math.floor(priceInMajorUnits) > 0 ? 2 : 0;
}

/**
 * Get Max Line Item Number. A limit to the number of line items.
 *
 * @param type line item type
 * @returns max line item number
 */
export function getMaxLineItemNumber(type: string) {
  if (type === SampleBoxType) {
    return 1;
  }

  return 99;
}

/**
 * Get shelf drilling params.
 *
 * @param lineItem line item
 * @returns
 */
export function getLineItemDrillMeta(lineItem: LineItem) {
  if (lineItem.type !== LineItemType.Shelf) {
    return null;
  }

  const { drillPositions, innerWallSkus: innerWallSKUs } =
    ShelfUtils.getDrillingHeights(lineItem.meta.structure as any);

  if (!drillPositions.length && !innerWallSKUs.length) {
    return null;
  }

  return {
    drillPositions,
    innerWallSKUs,
  };
}

/**
 * Makes cart/order UUIDv4 user-friendly.
 * @returns first 8 chars of the given ID.
 */
export function toShortCartOrderID(id: string) {
  return id.substring(0, 8);
}

export function getCartMaterialSampleSKUs(cart: undefined | Cart) {
  const sampleBox = (cart?.lineItems || []).find(
    (item) => item.uuid === SampleBoxUUID
  );
  return (sampleBox?.addonItems || []).map((sample) => sample.sku);
}

export function areMaterialSamplesInCart(
  cart: undefined | Cart,
  sampleSKUs: string[]
) {
  const cartSampleSKUs = getCartMaterialSampleSKUs(cart);
  const intersectedSKUs = sampleSKUs.filter((sampleSKU) =>
    cartSampleSKUs.some((cartSampleSKU) => sampleSKU === cartSampleSKU)
  );

  return intersectedSKUs.length === sampleSKUs.length;
}

export function getUUIDQuantityMap(
  lineItems: { uuid: string; quantity: number }[]
) {
  const uuidQuantityMap = {} as { [key: string]: number };

  lineItems.forEach((item) => {
    if (uuidQuantityMap[item.uuid] === undefined) {
      uuidQuantityMap[item.uuid] = item.quantity;
    } else {
      uuidQuantityMap[item.uuid] += item.quantity;
    }
  });

  return uuidQuantityMap;
}

export function getCartItemCount(cart: undefined | Cart) {
  return (
    cart?.lineItems?.reduce(
      (prevValue, lineItem) => prevValue + lineItem.quantity,
      0
    ) || 0
  );
}
/**
 * Calculate the assembly complexity of a cart item based on the furniture type,
 * specs and dimensions
 */
function getCartItemAssemblyComplexity(
  lineItem: LineItem
): ItemAssemblyComplexity {
  const notHardToAssemblyResponse = {
    isHardToAssembly: false,
    assemblyServiceMessage: 'assembly-service-description',
  };
  const { dimensions, specs } = lineItem.meta;
  const furnitureType = lineItem.type;

  switch (furnitureType) {
    case 'shelf':
      if (
        specs &&
        typeof specs.hanging_features === 'number' &&
        specs.hanging_features > 0
      ) {
        return {
          isHardToAssembly: true,
          assemblyServiceMessage: 'hanging-shelf-strongly-recommended-assembly',
        };
      }

      if (
        specs &&
        typeof specs.extensions === 'number' &&
        specs.extensions > 0
      ) {
        return {
          isHardToAssembly: true,
          assemblyServiceMessage:
            'shelf-with-extensions-strongly-recommended-assembly',
        };
      }

      if (dimensions && dimensions.length > 1500 && dimensions.height > 1500) {
        return {
          isHardToAssembly: true,
          assemblyServiceMessage: 'shelf-recommended-assembly',
        };
      }

      if (
        dimensions &&
        (dimensions.length > 1500 || dimensions.height > 1500)
      ) {
        return {
          isHardToAssembly: true,
          assemblyServiceMessage: 'shelf-consider-assembly',
        };
      }

      return notHardToAssemblyResponse;

    case 'wardrobe':
      if (specs && typeof specs.slidings === 'number' && specs.slidings > 0) {
        return {
          isHardToAssembly: true,
          assemblyServiceMessage:
            'wardrobe-with-sliding-doors-strongly-recommended-assembly',
        };
      }

      return {
        isHardToAssembly: true,
        assemblyServiceMessage:
          'wardrobe-without-sliding-doors-recommended-assembly',
      };

    default:
      return notHardToAssemblyResponse;
  }
}

export function getGroupAssemblyData(lineItems: LineItem[]) {
  let assemblyPrice = 0;
  let hasAssembly = true;

  lineItems.forEach((lineItem) => {
    let currentAssemblyPrice = getAssemblyServicePrice(lineItem);

    const itemHasAssembly = hasAssemblyService(lineItem);
    if (!itemHasAssembly) {
      hasAssembly = false;
    }

    assemblyPrice += currentAssemblyPrice ?? 0;
  });

  return { assemblyPrice, hasAssembly };
}

export function hasHangingShelfInLineItems(lineItems: LineItem[]) {
  let hasHangingShelf = false;
  lineItems.forEach((item) => {
    const { specs } = item.meta;
    const subtype = specs
      ? StructureSpecsService.getSubtype(item.type, specs)
      : '';
    const furnitureType = item.type;
    if (furnitureType === 'shelf' && subtype === 'ayr') {
      hasHangingShelf = true;
    }
  });
  return hasHangingShelf;
}

export function getGroupAssemblyComplexity(
  lineItems: LineItem[]
): ItemAssemblyComplexity {
  let assemblyResponsePayload: ItemAssemblyComplexity = {
    isHardToAssembly: false,
    assemblyServiceMessage: '',
  };

  const multipleItems = lineItems.length > 1;

  for (let i = 0; i < lineItems.length; i++) {
    const { assemblyServiceMessage, isHardToAssembly } =
      getCartItemAssemblyComplexity(lineItems[i]);

    assemblyResponsePayload.assemblyServiceMessage = assemblyServiceMessage;
    if (isHardToAssembly) {
      assemblyResponsePayload.isHardToAssembly = isHardToAssembly;
      break;
    }
  }

  if (multipleItems) {
    assemblyResponsePayload.assemblyServiceMessage =
      'assembly-service-description-multiple-items';
  }
  return assemblyResponsePayload;
}

export function isEqualProductCount(
  itemArr1: LineItem[],
  itemArr2: LineItem[]
) {
  const map1 = getUUIDQuantityMap(itemArr1);
  const map2 = getUUIDQuantityMap(itemArr2);

  if (Object.keys(map1).length !== Object.keys(map2).length) {
    return false;
  }

  return Object.keys(map1).every((uuid) => map1[uuid] === map2[uuid]);
}
