import { BehaviorSubject } from 'rxjs';

/**
 * Maintain the help panel value
 */
class HelpStore extends BehaviorSubject<any> {
  state: { showHelp: boolean; showChat: boolean };
  /**
   * Create a store instance
   */
  constructor() {
    super(false);

    this.state = {
      showHelp: false,
      showChat: false,
    };
  }

  /**
   * Getter for this.state
   *
   * @param {string} param
   * @returns {boolean}
   */
  get(param: 'showHelp' | 'showChat') {
    if (!Object.getOwnPropertyNames(this.state).includes(param)) {
      throw Error(`Invalid param ${param}`);
    }

    return this.state[param];
  }

  /**
   * Toggle the help panel
   */
  toggle() {
    this.state.showHelp = !this.state.showHelp;
    this.next(this.state);
  }

  /**
   * Show help panel
   */
  show() {
    this.state.showHelp = true;
    this.next(this.state);
  }

  /**
   * Toggle the chat tab
   */
  toggleChat() {
    this.state.showChat = !this.state.showChat;
    this.next(this.state);
  }

  /**
   * Show the live chat
   */
  showChat() {
    this.state.showChat = true;
    this.next(this.state);
  }
}

export default new HelpStore();
