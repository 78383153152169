const env = process.env.APP_STAGE;

let cfg: typeof import('mycs/config/config.default');

try {
  cfg = require(`mycs/config/config.${env}`); // eslint-disable-line
} catch (e) {
  cfg = require('mycs/config/config.dev');
}

export default cfg.default;
