import { Fragment, PureComponent } from 'react';
import classNames from 'classnames';

import { useLocale } from 'mycs/shared/state/LocaleContext';
import Button from 'mycs/shared/components/Button/Button';
import ClickOutside from 'mycs/shared/components/ClickOutside/ClickOutside';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import ReactModal from 'mycs/shared/utilities/ReactModal';
import SafeText from 'mycs/shared/components/SafeText/SafeText';
import UrlProviderService from 'mycs/shared/services/UrlProviderService/UrlProviderService';

import styles from './Modal.scss';

type Props = {
  header?: string;
  headerImage?: string;
  intro?: string;
  children?: React.ReactNode;
  isOpen?: boolean;
  contentLabel?: string;
  onClose?: () => void;
  // The message that is emitted by the iframe to close the modal
  closeMessage?: string;
  // Extra class for the body of the modal
  contentClass?: string;
  headerClass?: string;
  overlayClass?: string;
  mediaLayout?: boolean;
  clickOutsideWhiteList?: any[];
  closeOnClickOutside?: boolean;
};

type State = {
  isMounted: boolean;
};

export default function Modal(props: Props) {
  const { locale } = useLocale();
  return <InnerModal {...props} locale={locale} />;
}

type InnerProps = Props & { locale: string };

export class InnerModal extends PureComponent<InnerProps, State> {
  static defaultProps: Partial<InnerProps> = {
    header: '',
    headerImage: '',
    intro: '',
    isOpen: false,
    contentLabel: Math.random().toString(32).slice(2),
    onClose: () => null,
    closeMessage: 'Close Modal',
    mediaLayout: false,
    clickOutsideWhiteList: [],
    closeOnClickOutside: true
  };

  constructor(props: InnerProps) {
    super(props);

    this.state = {
      isMounted: false,
    };
  }

  onKeydown = (event: any) => {
    if (this.props.isOpen && event.key === 'Escape') {
      this.props.onClose!();
    }
  };

  onMessage = (event: any) => {
    const { onClose, closeMessage } = this.props;
    if (event.data === closeMessage) onClose!();
  };

  /**
   * Listen to ESC button to close the modal
   */
  componentDidMount() {
    // Close the modal when the user press the ESC button
    window.addEventListener('keydown', this.onKeydown);

    // Close the modal when the iframe say so
    window.addEventListener('message', this.onMessage, false);

    // We want to avoid rendering this component on the server
    setTimeout(() => {
      this.setState({ isMounted: true });
    }, 0);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeydown);
    window.removeEventListener('message', this.onMessage);
  }

  render() {
    if (!this.state.isMounted) return <Fragment />;

    const {
      header,
      headerImage,
      intro,
      isOpen,
      onClose,
      contentLabel,
      children,
      mediaLayout,
      contentClass,
      overlayClass,
      clickOutsideWhiteList,
      closeOnClickOutside
    } = this.props;
    const contentStyles = classNames(styles.content, contentClass);
    const classes = classNames(styles.modal, {
      [styles.headerImageLayout]: headerImage,
      [styles.mediaLayout]: mediaLayout,
    });
    const overlayClasses = classNames(overlayClass, styles.overlay, {
      [styles.lightOverlay]: headerImage,
      [styles.mediaLayoutOverlay]: mediaLayout,
    });

    return (
      <ReactModal
        className={classes}
        isOpen={isOpen!}
        overlayClassName={overlayClasses}
        contentLabel={contentLabel}
        shouldCloseOnEsc={false}
        ariaHideApp={false}
        //shouldCloseOnEsc is true by default, but it does not work out of box
      >
        <ClickOutside
          onClickOutside={closeOnClickOutside ? onClose! : () => {}} 
          clickOutsideWhiteList={clickOutsideWhiteList}
        >
          <div className={styles.panel}>
            <div className={classNames(styles.header, this.props.headerClass)}>
              {headerImage ? (
                <div
                  className={styles.headerImage}
                  style={{
                    backgroundImage: headerImage
                      ? `url(${UrlProviderService.getImageUrl(headerImage)})`
                      : undefined,
                  }}
                />
              ) : (
                ''
              )}

              <div className={styles.headerText}>
                {I18nUtils.localize(this.props.locale, header)}
              </div>

              <Button
                className={styles.closeButton}
                onClick={onClose}
                iconName="general/close"
              />
            </div>

            <div className={contentStyles}>
              {intro ? (
                <div className={styles.intro}>
                  <SafeText
                    content={I18nUtils.localize(this.props.locale, intro)}
                  />
                </div>
              ) : null}

              {children}
            </div>
          </div>
        </ClickOutside>
      </ReactModal>
    );
  }
}
