import traverse from 'traverse';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import some from 'lodash/some';
import every from 'lodash/every';
import assign from 'lodash/assign';
import invert from 'lodash/invert';
import ElementApiService from '../services/ElementApiService';
import ElementService from '../services/ElementService';

const skuLookupProps = [
  'furniture_type',
  'section',
  'type',
  'length',
  'width',
  'height',
  'color',
];
const commonProps = ['getMaterialId', 'getAssetId'];
const labelsProps = ['label_id', 'in_focus'];

/**
 * @class Component
 */
export default class Component {
  props: any;
  children: any[];
  container: any;

  /**
   * Creates an instance of Component.
   *
   * @param {any} props
   */
  constructor(props, children = null) {
    this.props = { ...props };
    this.children = children;
    this.container = '';
  }

  /**
   * Clean the structure
   *
   * @param {any} structure
   * @param {string} mode
   * @param {boolean} onlyWorkingProps
   * @returns {any}
   */
  static clean(structure, mode, onlyWorkingProps = false) {
    let attributes = onlyWorkingProps
      ? commonProps
      : [...skuLookupProps, ...commonProps];
    if (mode === 'DB') attributes = [...attributes, ...labelsProps];

    traverse(structure).forEach((node) => {
      if (!(node && node.props)) return;

      attributes.forEach((attr) => {
        if (node.props && attr in node.props) delete node.props[attr];
      });
    });

    return structure;
  }

  /**
   * Get an sku with a set of parameters
   *
   * @param {object} params
   * @returns {string}
   */
  static getSKU(params) {
    const skus = ElementApiService.filter(params);

    if (skus.length === 1) {
      return skus[0];
    }

    return null;
  }

  static decoratePostProcessElement(structure) {
    traverse(structure).forEach(function (node) {
      if (node === undefined || node === null || node.props === undefined) {
        return;
      }
      if (
        node &&
        node.props &&
        every(skuLookupProps.map((k) => k in node.props))
      ) {
        return;
      }
      if (node.props.sku) {
        const sku = ElementApiService.getRaw(node.props.sku);
        const attrs = skuLookupProps;
        if (sku) attrs.forEach((attr) => (node.props[attr] = sku[attr]));
      }
      if (node.props.cover_sku) {
        const coverSku = ElementApiService.getRaw(node.props.cover_sku);
        if (coverSku) node.props.color = coverSku.color;
      }
    });

    return structure;
  }

  /**
   * Add additional information to structure
   * //TODO: desygnr use this for closeup, but it relies on SKU
   * @param {any} structure
   * @returns {any}
   */
  static decoratePostProcess(structure) {
    traverse(structure).forEach(function (node) {
      if (node === undefined || node === null || node.props === undefined) {
        return;
      }

      if (node.props.sku) {
        const sku = ElementApiService.getRaw(node.props.sku);
        const attrs = skuLookupProps;
        if (sku) attrs.forEach((attr) => (node.props[attr] = sku[attr]));
      }

      if (node.props.cover_sku) {
        const coverSku = ElementApiService.getRaw(node.props.cover_sku);
        if (coverSku) node.props.color = coverSku.color;
      }
    });

    return structure;
  }

  static getKeys(props) {
    return pick(props, skuLookupProps);
  }

  static cleanKeys(props) {
    return omit(props, [...skuLookupProps, ...commonProps]);
  }

  static getAssets(element) {
    return {
      shape: element.scene_object_name,
      material: element.color,
    };
  }

  static getElement(partialElement) {
    const elements = ElementService.filter(partialElement);
    if (elements.length > 0) {
      return elements[0];
    }
    return {};
  }

  /**
   * Generate a scene object name
   *
   * @param {any} element
   * @param {number} variationNb
   * @returns {string} scene object name
   */
  static generateSceneObjectName(element, variationNb = 0) {
    const { length, width, height } = element;
    const dimensions = [length, width, height].join('x');
    const { furniture_type, section, type } = element;
    return variationNb > 0
      ? [furniture_type, section, variationNb, type, dimensions, 'obj'].join(
          '_'
        )
      : [furniture_type, section, type, dimensions, 'obj'].join('_');
  }

  /**
   * Debug helper
   */
  showProps() {
    console.log(JSON.stringify(this.props, null, 2)); // eslint-disable-line
  }

  /**
   * isValidLayout return true is the layout is valid:
   * - Shapes are associated with an element
   *
   * @param {object} layout
   * @returns {boolean}
   */
  static isValidLayout(layout) {
    const shapeNodes = [];
    if (!layout) {
      console.error('empty layout');
      return false;
    }

    traverse(layout).forEach((node) => {
      if (node && node.container && node.container === 'shape') {
        shapeNodes.push(node);
      }
    });

    if (shapeNodes.length === 0) {
      console.error('no shape in the layout');
      return false;
    }

    // TODO: to be removed when SKUs are added
    // return true;

    return !some(shapeNodes, (node) => {
      const validElement =
        node && node.props && every(skuLookupProps.map((k) => k in node.props));

      const validShape = node && node.props && node.props.shape;
      if (!validElement && !validShape)
        console.error('invalid sku: ', node.props);
      return !validElement && !validShape;
    });
  }

  /**
   * For each SKU in a structure, create a string containing info about the sku
   * It's used during migration process to ensure the new design are consistent with old ones
   * @param {object} structure
   */
  static getDesignDescription(structure) {
    const result = [];
    traverse(structure).forEach((node) => {
      if (
        !(
          node &&
          node.props &&
          node.props.furniture_type &&
          node.props.section &&
          node.props.type &&
          node.props.length &&
          node.props.width &&
          node.props.height &&
          node.props.color &&
          !node.invisible
        )
      ) {
        return;
      }
      result.push(
        `${node.props.furniture_type}_${node.props.section}_${node.props.type}_${node.props.color}_${node.props.length}_${node.props.height}_${node.props.width}`
      );
    });
    return result.sort();
  }

  /**
   * Extract diff between two list of string
   *
   * @param {string} list1
   * @param {string} list2
   */
  static descriptionDifference(list1, list2) {
    const diff = [];
    let i1 = 0;
    let i2 = 0;
    let j1 = 0;
    let j2 = 0;
    let found1 = 0;
    let found2 = 0;

    while (i1 <= list1.length - 1 || i2 <= list2.length - 1) {
      // List1 is finished
      if (i1 > list1.length - 1) {
        j2 = i2;
        while (j2 < list2.length) {
          diff.push(['+', list2[j2]]);
          j2++;
        }
        i2 = j2;
        // List2 is finished
      } else if (i2 > list2.length - 1) {
        j1 = i1;
        while (j1 < list1.length) {
          diff.push(['-', list1[j1]]);
          j1++;
        }
        i1 = j1;
        // line are equal
      } else if (list1[i1] === list2[i2]) {
        i1++;
        i2++;
        // line are not equal
      } else {
        j2 = i2;
        found1 = -1;
        found2 = -1;
        // find the next equal line in list2
        while (j2 < list2.length) {
          if (list1[i1] === list2[j2]) {
            found2 = j2;
            break;
          }
          j2++;
        }

        // find the next equal line in list1
        if (found2 === -1) {
          j1 = i1;
          while (j1 < list1.length) {
            if (list1[j1] === list2[i2]) {
              found1 = j1;
              break;
            }
            j1++;
          }
        }

        // if not found in both lists, then we have to add item of list 2 and remove item from list 1
        if (found1 === -1 && found2 === -1) {
          diff.push(['+', list2[i2]]);
          i2++;
          diff.push(['-', list1[i1]]);
          i1++;
          // if found in list 2, then we can flag as added all elements of list 2 until the item
        } else if (found2 !== -1) {
          j2 = i2;
          while (j2 < found2) {
            diff.push(['+', list2[j2]]);
            j2++;
          }
          i2 = found2;
          /// if found in list 1, then we can flag as removed all elements of list 1 until the item
        } else if (found1 !== -1) {
          j1 = i1;
          while (j1 < found1) {
            diff.push(['-', list1[j1]]);
            j1++;
          }
          i1 = found1;
        } else {
          // there should not be any case here, since lists are sorted, so we should not have elements different
          // AND finding them in the opposite list further
        }
      }
    }

    return diff;
  }

  /**
   * Get list of Elements contained in a design
   *
   * @param {object} SKULayout
   * @returns {Array} list of elements {furniture_type, section, type, length, height, width, color }
   */
  static getDesignElements(SKULayout) {
    const result = [];

    traverse(SKULayout).forEach((node) => {
      if (
        !(
          node &&
          node.props &&
          node.props.furniture_type &&
          node.props.section &&
          node.props.type &&
          node.props.length &&
          node.props.width &&
          node.props.height &&
          node.props.color &&
          !node.invisible
        )
      ) {
        return;
      }

      const element = Component.getKeys(node.props);

      result.push(element);
    });

    return result;
  }

  /**
   * Get list of SKUs by traversing the layout
   * @param {object} layout
   * @returns {array}
   */
  static getSKUs(layout) {
    const skus = [];
    traverse(layout).forEach((node) => {
      if (node && typeof node === 'object' && node.sku) {
        skus.push(node.sku);
        if (node.cover_sku) {
          skus.push(node.cover_sku);
        }
      }
    });
    return skus;
  }

  /**
   * Extract interior
   *
   * @param {object} layout
   * @param {array} filter by sections
   * @param {array} filter by types
   * @returns {object}
   */
  static extractInterior(layout, sections, types = null) {
    const interiors = [];
    traverse(layout).forEach((node) => {
      const element = Component.getKeys(node.props);
      if (
        node &&
        node.props &&
        every(skuLookupProps.map((k) => k in node.props)) &&
        // node.sku &&
        Array.from(sections).includes(node.props.section)
      ) {
        if (types) {
          if (Array.from(types).includes(node.props.type)) {
            interiors.push(element);
          }
        } else {
          interiors.push(element);
        }
      }
    });
    return interiors;
  }

  static lacqueredToLaminate(simpleStructure, config) {
    const { laminateMapping } = config;

    const woodLaminateMapping = config.woodLaminateMapping
      ? config.woodLaminateMapping
      : {};
    const invertWoodLaminateMapping = invert(woodLaminateMapping);

    const mapping = {};

    assign(mapping, laminateMapping, invertWoodLaminateMapping);

    traverse(simpleStructure).forEach(function (value) {
      if (typeof value !== 'string') return;
      if (!(value in mapping)) return;
      const newColor = mapping[value];
      this.update(newColor);
    });
  }
  /**
   * Function decorateElement
   *
   * @param props
   * @param furnitureType
   * @param ComponentElement
   * @param exportData
   * @returns
   */
  static decorateElement(
    props,
    furnitureType,
    ComponentElement,
    exportData = {
      custom: true,
      element: true,
      assets: true,
      hardcode: true,
      skus: true,
      extend: false,
    }
  ) {
    let newProps = {};
    if (exportData.extend) {
      assign(newProps, props);
    }

    // partialElement
    const partialElement = Component.getKeys({
      ...props,
      furniture_type: furnitureType,
    });

    // customProps
    const customProps = Component.cleanKeys(props);
    if (exportData.custom) {
      assign(newProps, customProps);
    }

    // Get conf element
    const element = Component.getElement(partialElement);
    const elementProps = Component.getKeys(element);
    if (exportData.element) {
      assign(newProps, elementProps);
    }

    // Standart assets
    if (exportData.assets) {
      const assetProps = Component.getAssets(element);
      assign(newProps, assetProps);
    }

    // Compute hardcode (can override assets)
    if (exportData.hardcode && ComponentElement.hardcode) {
      const hardcodeProps = ComponentElement.hardcode(props, element);
      assign(newProps, hardcodeProps);
    }

    // Fetch SKUs
    if (exportData.skus && !props.noSku) {
      const skuProps: any = {};

      const shapeProps: any = ComponentElement.mappingShape
        ? ComponentElement.mappingShape(elementProps)
        : elementProps;
      if (shapeProps !== null) {
        try {
          const shapeElement: any = ElementApiService.getSKU(shapeProps);
          skuProps.sku = shapeElement.sku;
        } catch (error) {
          throw new Error(
            JSON.stringify({ partialElement, elementProps, shapeProps })
          );
        }
      }

      if (ComponentElement.mappingCover) {
        const coverProps = ComponentElement.mappingCover(elementProps);
        if (coverProps !== null) {
          const coverElement: any = ElementApiService.getSKU(coverProps);
          skuProps.cover_sku = coverElement.sku;
        }
      }

      assign(newProps, skuProps);
    }

    return newProps;
  }
}
