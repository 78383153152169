import type { CloudFrontHeaders } from 'aws-lambda';

import config from '../config.json';

// headers shouldn't be case-sensitive, but the object is
// note: also returning first element in array
// I didn't transform the headers in case they need to be changed

export function headerGetter(headers: CloudFrontHeaders) {
  return (header: string) => {
    const lowerHeader = header.toLowerCase();
    return headers[lowerHeader]
      ? headers[lowerHeader][0].value.toLowerCase()
      : null;
  };
}

// create a header field with key/value pair. (to save some lines)
export function headerField(k: string, v: string) {
  return [{ key: k, value: v }];
}

/**
 * Get the country sub-domain
 *
 * 1. Check the User-Agent
 * 2. If bot, use Accept-Language to define country
 * 3. If not a bot, use CF header to define country
 * 4. Make a 301 redirect to the country-specific website
 */
export function getCountryCode(headers: CloudFrontHeaders) {
  const getHeader = headerGetter(headers);

  const code = getHeader('cloudfront-viewer-country') ?? '';
  console.log(`CloudFront-Viewer-Country header: ${code}`);

  const SUPPORTED_COUNTRY_CODES = config.SUPPORTED_COUNTRY_CODES as Record<
    string,
    string
  >;

  return (
    SUPPORTED_COUNTRY_CODES[code] ||
    SUPPORTED_COUNTRY_CODES[config.DEFAULT_COUNTRY_CODE]
  );
}
