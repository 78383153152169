import { useMemo } from 'react';

const disabledRoutesFooter = ['/closed-store'];
const disabledRoutesHeader = ['/closed-store'];

export default function useUI(location: string) {
  const showFooter = useMemo(
    () => !disabledRoutesFooter.includes(location),
    [location]
  );
  const showHeader = useMemo(
    () => !disabledRoutesHeader.includes(location),
    [location]
  );
  return {
    showHeader,
    showFooter,
  };
}
