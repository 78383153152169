import type { Callback, CloudFrontRequestEvent, Context } from 'aws-lambda';

import { createRedirectResponse } from './redirect';
import { getRequestFromEvent } from './event';
import { headerGetter } from './headers';
import { MycsURL } from './MycsURL';

import config from '../config.json';

const LANG_CODES = config.langCodes as { [key: string]: string | undefined };

export function assetsOriginRequest(
  event: CloudFrontRequestEvent,
  _: Context,
  callback: Callback
) {
  const request = getRequestFromEvent(event);
  const { headers, uri } = request;

  console.log('Origin headers:', JSON.stringify(request.headers));

  const getHeader = headerGetter(headers);
  const host = getHeader('x-forwarded-host') ?? '';

  const url = new MycsURL(`https://${host}${uri}`);
  const originalUrl = url.toString();

  url.redirectToCountry({
    headers,
    passthroughUrls: config.passthroughUrls,
  });

  url.removeTrailingSlashes();
  const redirectTo = url.toString();

  if (redirectTo !== originalUrl) {
    console.log(
      `Redirecting from ${originalUrl} to ${redirectTo} with ${request.querystring}`
    );
    const response = createRedirectResponse(redirectTo, request.querystring);

    return callback(null, response);
  }

  const countryCode = url.countryCode;

  const langCode = LANG_CODES[countryCode] || countryCode;

  // Robots.txt localization
  if (uri === '/robots.txt') {
    console.log(`Selected lang code: ${langCode}`);
    request.uri = `/robots.${langCode}.txt`;
    console.log(`Request uri set to "${request.uri}"`);
  }

  // Sitemap.xml localization
  if (uri === '/sitemap.xml') {
    console.log(`Selected lang code: ${langCode}`);
    request.uri = `/sitemap.${langCode}.xml`;
    console.log(`Request uri set to "${request.uri}"`);
  }

  const domainParts = host.split('.');

  // Dev branch deployment.
  if (
    host.includes('mycs.ninja') &&
    domainParts.length > 3 &&
    // Should direct to the default Gateway stage
    // and "main" Lambda alias, if it's an infra replica.
    // See infrastructure/website/README for more info.
    !host.includes('-dwi.')
  ) {
    const s3Origin = request.origin!.s3!;
    request.origin!.s3 = {
      ...s3Origin,
      // Direct the request to the correspondent stage directory in the s3 Bucket.
      path: s3Origin.path.replace('main', domainParts[1]),
    };
  }

  callback(null, request);
}
