const colors = {
  // lacquered
  lacquered_no_white_black: [
    'blue_2_5000',
    'blue_5009',
    'dark_blue_5013',
    'dark_green_6009',
    'dark_red_3005',
    'green_6029',
    'green_grey_7009',
    'grey_7024',
    'grey_7037',
    'light_grey_7004',
    'light_yellow_1016',
    'mint_6033',
    'red_3016',
    'stone_grey_7030',
    'violet_4009',
    'yellow_1021',
  ],
  lacquered: [
    'black_9004',
    'blue_2_5000',
    'blue_5009',
    'dark_blue_5013',
    'dark_green_6009',
    'dark_red_3005',
    'green_6029',
    'green_grey_7009',
    'grey_7024',
    'grey_7037',
    'light_grey_7004',
    'light_yellow_1016',
    'mint_6033',
    'red_3016',
    'stone_grey_7030',
    'violet_4009',
    'white_9003',
    'yellow_1021',
  ],
  lacquered_couch: [
    'black_9004',
    'blue_5009',
    'grey_7024',
    'grey_7037',
    'red_3016',
    'white_9003',
    'yellow_1021',
  ],
  lacquered_wardrobe: ['grey_7024', 'white_9003'],
  lacquered_black_white: ['black_9004', 'white_9003'],
  // single lacquered
  grey_7037: ['grey_7037'],
  white: ['white_9003'],
  black: ['black_9004'],
  // veneered couch
  veneered_couch: [
    'veneered_anthracite',
    'veneered_black',
    'veneered_blue',
    'veneered_grey',
    'veneered_red',
    'veneered_white',
    'veneered_yellow',
  ],
  // laminate
  laminate: [
    'black_u12001',
    'blue_u18004',
    //'burgundy_red_u17031',
    'forest_green_u19008',
    'graphite_u12233',
    'grey_u12091',
    'light_grey_u12044',
    'mint_green_u19006',
    'petrol_green_u18006',
    'taupe_u15133',
    'terracotta_u16166',
    'white_u11209',
    'yellow_u15579',
  ],
  laminate_no_white_black: [
    'blue_u18004',
    //'burgundy_red_u17031',
    'forest_green_u19008',
    'graphite_u12233',
    'grey_u12091',
    'light_grey_u12044',
    'mint_green_u19006',
    'petrol_green_u18006',
    'taupe_u15133',
    'terracotta_u16166',
    'yellow_u15579',
  ],

  laminate_wood: [
    'beech_laminate',
    'wenge_laminate',
    'oak_laminate',
    'walnut_laminate',
  ],
  roll: ['black_u12001', 'grey_u12091', 'white_u11209'],
  laminate_black_white: ['black_u12001', 'white_u11209'],
  laminate_black: ['black_u12001'],
  laminate_white: ['white_u11209'],
  laminate_grey: ['graphite_u12233'],
  laminate_oak: ['oak_laminate'],
  laminate_walnut: ['walnut_laminate'],
  laminate_beech: ['beech_laminate'],
  // metal
  metal_black: ['metal_black'],
  metal_stainless: ['metal_stainless'],
  metal_aluminum: ['aluminum_brushed', 'aluminum_polished'],
  metal_gold: ['matt_gold'],
  metal_chrome: ['chrome'],
  // glass_colorless
  glass_colorless: ['clear_colorless'],
  glass_grey: ['clear_grey', 'opaque_grey'],
  glass_drawer: ['glass_drawer'],
  // wood
  oak: ['oak'],
  beech: ['beech'],
  walnut: ['walnut'],
  wood_3: ['beech', 'oak', 'walnut'],
  wood_3_shelves: ['oak', 'walnut', 'wenge'],
  wood_4: ['beech', 'birch', 'oak', 'walnut'],
  wood_couch: ['beech', 'oak_wenge_stain', 'oak', 'walnut'],
  // mirror
  mirror: ['mirror'],
  // marble
  marble: [
    'brown_emperador',
    'green_guatemala',
    'nero_marquina',
    'white_carrara',
  ],
  // others...
  other: ['other'],
  default_shd: ['default_shd'],
  NOT_RENDERED: ['NOT_RENDERED'],
  leather: [
    // Vegan Leather
    'belize_23',
    'belize_25',
    'belize_27',
    'belize_32',
    'belize_561',
    'belize_64',
    'belize_74',
    // Nubuck Leather
    'kenia_brown',
    'kenia_olive',
    'kenia_taupe',
    'kenia_walnut',
    // Aniline Leather
    'rancho_nero',
    'rancho_steel',
    // Pigmented Leather
    'toledo_15',
    'toledo_2',
    'toledo_4',
    'toledo_6',
  ],
  fabric: [
    'bahama_3',
    'bahama_30',
    'bahama_34',
    'bahama_8',
    'bahama_9',
    // Vegan Leather
    'belize_23',
    'belize_25',
    'belize_27',
    'belize_32',
    'belize_561',
    'belize_64',
    'belize_74',
    'brunei_azur_38',
    'brunei_azur_39',
    'brunei_azur_40',
    'brunei_beige_4',
    'brunei_beige_9',
    'brunei_black_1',
    'brunei_blue_33',
    // 'brunei_brown_11',
    'brunei_brown_14',
    'brunei_green_44',
    'brunei_green_45',
    'brunei_grey_2',
    'brunei_grey_5',
    'brunei_magenta_26',
    'brunei_pink_29',
    'brunei_pink_30',
    'brunei_pink_31',
    'brunei_purpule_24',
    'brunei_salmon_20',
    'brunei_violet_28',
    'brunei_yellow_41',
    'diamante_anthracite_4045',
    'diamante_beige_12107',
    'diamante_beige_2530',
    'diamante_beige_4104',
    'diamante_blue_12104',
    'diamante_blue_2535',
    'diamante_blue_2610',
    'diamante_brown_3995',
    'diamante_brown_4099',
    // 'diamante_green_3394',
    'diamante_green_4909',
    'diamante_grey_12108',
    'diamante_grey_2608',
    // 'diamante_purpule_2570',
    // 'diamante_red_2635',
    // 'diamante_red_4005',
    'diamante_white_12106',
    'fargotex_river_1',
    'fargotex_river_10',
    'fargotex_river_11',
    'fargotex_river_12',
    'fargotex_river_13',
    'fargotex_river_14',
    'fargotex_river_5',
    'fargotex_river_6',
    'galaxy_anthra_0074',
    'galaxy_dark_blue_0041',
    'galaxy_light_beige_0025',
    'galaxy_light_grey_0101',
    'galaxy_taupe_0056',
    'galaxy_olive_green_0062',
    'galaxy_jean_blue_0053',
    'galaxy_bramble_red_0063',
    'lech_erie_1',
    'lech_erie_12',
    'lech_erie_15',
    'lech_erie_16',
    'lech_erie_6',
    'lech_erie_8',
    'lech_kreta_2',
    'lech_kreta_7',
    'lech_kreta_9',
    'lech_lawa_11',
    'lech_lawa_6',
    'lech_lawa_7',
    // 'lech_pablo_1',
    // 'lech_pablo_10',
    // 'lech_pablo_4',
    // 'lech_pablo_9',
    'lech_peru_1',
    'lech_peru_6',
    'lech_sumatra_6',
    // 'madison_g2g2',
    // 'madison_g4u1',
    'oxford_22',
    'oxford_4',
    'oxford_9',
    'ribcord_anthracite_67',
    'ribcord_chocolate_17',
    'ribcord_grey_180',
    'ribcord_onyx_169',
    'ribcord_petrol_56',
    'ribcord_taupe_12',
    'scala_anthracite_2',
    'scala_ash_8',
    'scala_chocolate_4',
    'scala_forest_13',
    'scala_lighter_green_12',
    'scala_mustard_16',
    'scala_navy_10',
    'scala_toffee_6',
    'scala_truffle_22',
    'scala_yellow_15',
    // 'strangetex_dark_blue_white',
    // 'strangetex_green',
    // 'strangetex_green_grey',
    // 'strangetex_orange_blue',
    // 'strangetex_orange_white',
    // 'strangetex_petrol_blue',
    // 'strangetex_white',
    // 'strangetex_yellow',
    'velvetex_anthracite_1002',
    // 'velvetex_beige_104619',
    'velvetex_beige_2237',
    'velvetex_beige_2256',
    'velvetex_blue_1007',
    'velvetex_blue_104041',
    'velvetex_blue_1203',
    'velvetex_blue_1372',
    'velvetex_blue_1524',
    'velvetex_blue_2019',
    'velvetex_blue_2277',
    // 'velvetex_brown_102120',
    'velvetex_brown_2134',
    'velvetex_brown_9202',
    'velvetex_green_104612',
    'velvetex_green_1375',
    'velvetex_green_210',
    'velvetex_green_2253',
    'velvetex_green_9139',
    'velvetex_grey_1000',
    'velvetex_grey_101012',
    'velvetex_grey_101013',
    'velvetex_grey_1042',
    'velvetex_grey_9115',
    'velvetex_orange_103230',
    'velvetex_orange_104224',
    'velvetex_orange_1090',
    'velvetex_pink_103637',
    'velvetex_pink_2315',
    'velvetex_red_2306',
    'velvetex_violet_101',
    // 'velvetex_violet_103444',
    // 'velvetex_violet_103538',
    // 'velvetex_violet_103542',
    'velvetex_violet_104250',
    'velvetex_violet_1573',
    'velvetex_violet_2255',
    'velvetex_yellow_2272',
    'zepel_fenno_1',
    'zepel_fenno_12',
    'zepel_fenno_13',
    'zepel_fenno_15',
    'zepel_fenno_3',
    'zepel_fenno_5',
    'zepel_fenno_6',
    'zepel_fenno_7',
    'zepel_fenno_8',
    'zepel_fenno_9',
  ],
  // textile
  textile: [
    'bahama_3',
    'bahama_30',
    'bahama_34',
    'bahama_8',
    'bahama_9',
    'belize_23',
    'belize_25',
    'belize_27',
    'belize_32',
    'belize_561',
    'belize_64',
    'belize_74',
    'brunei_azur_38',
    'brunei_azur_39',
    'brunei_azur_40',
    'brunei_beige_4',
    'brunei_beige_9',
    'brunei_black_1',
    'brunei_blue_33',
    //'brunei_brown_11',
    'brunei_brown_14',
    'brunei_green_44',
    'brunei_green_45',
    'brunei_grey_2',
    'brunei_grey_5',
    'brunei_magenta_26',
    'brunei_pink_29',
    'brunei_pink_30',
    'brunei_pink_31',
    'brunei_purpule_24',
    'brunei_salmon_20',
    'brunei_violet_28',
    'brunei_yellow_41',
    'diamante_anthracite_4045',
    'diamante_beige_12107',
    'diamante_beige_2530',
    'diamante_beige_4104',
    'diamante_blue_12104',
    'diamante_blue_2535',
    'diamante_blue_2610',
    'diamante_brown_3995',
    'diamante_brown_4099',
    //'diamante_green_3394',
    'diamante_green_4909',
    'diamante_grey_12108',
    'diamante_grey_2608',
    //'diamante_purpule_2570',
    //'diamante_red_2635',
    //'diamante_red_4005',
    'diamante_white_12106',
    'fargotex_river_1',
    'fargotex_river_10',
    'fargotex_river_11',
    'fargotex_river_12',
    'fargotex_river_13',
    'fargotex_river_14',
    'fargotex_river_5',
    'fargotex_river_6',
    'galaxy_anthra_0074',
    'galaxy_dark_blue_0041',
    'galaxy_light_beige_0025',
    'galaxy_light_grey_0101',
    'galaxy_taupe_0056',
    'galaxy_olive_green_0062',
    'galaxy_jean_blue_0053',
    'galaxy_bramble_red_0063',
    'kenia_brown',
    'kenia_olive',
    'kenia_taupe',
    'kenia_walnut',
    'lech_erie_1',
    'lech_erie_12',
    'lech_erie_15',
    'lech_erie_16',
    'lech_erie_6',
    'lech_erie_8',
    'lech_kreta_2',
    'lech_kreta_7',
    'lech_kreta_9',
    'lech_lawa_11',
    'lech_lawa_6',
    'lech_lawa_7',
    //'lech_pablo_1',
    //'lech_pablo_10',
    //'lech_pablo_4',
    //'lech_pablo_9',
    'lech_peru_1',
    'lech_peru_6',
    'lech_sumatra_6',
    //'madison_g2g2',
    //'madison_g4u1',
    'oxford_22',
    'oxford_4',
    'oxford_9',
    'rancho_nero',
    'rancho_steel',
    'ribcord_anthracite_67',
    'ribcord_chocolate_17',
    'ribcord_grey_180',
    'ribcord_onyx_169',
    'ribcord_petrol_56',
    'ribcord_taupe_12',
    'scala_anthracite_2',
    'scala_ash_8',
    'scala_chocolate_4',
    'scala_forest_13',
    'scala_lighter_green_12',
    'scala_mustard_16',
    'scala_navy_10',
    'scala_toffee_6',
    'scala_truffle_22',
    'scala_yellow_15',
    //'strangetex_dark_blue_white',
    //'strangetex_green',
    //'strangetex_green_grey',
    //'strangetex_orange_blue',
    //'strangetex_orange_white',
    //'strangetex_petrol_blue',
    //'strangetex_white',
    //'strangetex_yellow',
    'toledo_15',
    'toledo_2',
    'toledo_4',
    'toledo_6',
    'velvetex_anthracite_1002',
    //'velvetex_beige_104619',
    'velvetex_beige_2237',
    'velvetex_beige_2256',
    'velvetex_blue_1007',
    'velvetex_blue_104041',
    'velvetex_blue_1203',
    'velvetex_blue_1372',
    'velvetex_blue_1524',
    'velvetex_blue_2019',
    'velvetex_blue_2277',
    //'velvetex_brown_102120',
    'velvetex_brown_2134',
    'velvetex_brown_9202',
    'velvetex_green_104612',
    'velvetex_green_1375',
    'velvetex_green_210',
    'velvetex_green_2253',
    'velvetex_green_9139',
    'velvetex_grey_1000',
    'velvetex_grey_101012',
    'velvetex_grey_101013',
    'velvetex_grey_1042',
    'velvetex_grey_9115',
    'velvetex_orange_103230',
    'velvetex_orange_104224',
    'velvetex_orange_1090',
    'velvetex_pink_103637',
    'velvetex_pink_2315',
    'velvetex_red_2306',
    'velvetex_violet_101',
    //'velvetex_violet_103444',
    //'velvetex_violet_103538',
    //'velvetex_violet_103542',
    'velvetex_violet_104250',
    'velvetex_violet_1573',
    'velvetex_violet_2255',
    'velvetex_yellow_2272',
    'zepel_fenno_1',
    'zepel_fenno_12',
    'zepel_fenno_13',
    'zepel_fenno_15',
    'zepel_fenno_3',
    'zepel_fenno_5',
    'zepel_fenno_6',
    'zepel_fenno_7',
    'zepel_fenno_8',
    'zepel_fenno_9',
  ],
};

export default colors;
