import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { ComponentProps, useEffect, useMemo, useState } from 'react';

import { getCartItemCount } from 'mycs/services/CartService';
import { RelativeUrlService } from 'mycs/shared/services/RelativeUrlService';
import { useCart } from 'mycs/hooks/useCart';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import { useUser } from 'mycs/hooks/useUser';
import Alink from 'mycs/shared/components/Alink/Alink';
import Icon from 'mycs/shared/components/Icon/Icon';

import styles from './IconLink.scss';

export enum LinkType {
  ShoppingCart = '/shopping_cart',
  MyDesigns = '/my-designs',
}

interface Props {
  to: LinkType;
  iconContent?: string;
  iconPath?: string;
  iconPathActive?: string;
  text?: string;
  activeClassName?: string;
  useOnClick?: boolean;
}

export default function IconLink(props: Props) {
  const { pathname } = useLocation();
  const { locale } = useLocale();
  const { cart } = useCart();
  const navigate = useNavigate();

  const [animating, setAnimating] = useState(false);
  const isActive =
    RelativeUrlService.getTranslatedUrl(props.to, locale) === pathname;

  const { user } = useUser();

  const count = useMemo(() => {
    if (props.to === LinkType.ShoppingCart) {
      return getCartItemCount(cart);
    } else if (props.to === LinkType.MyDesigns) {
      return user?.designs.length || 0;
    }

    return 0;
  }, [cart, user, props.to]);

  useEffect(() => {
    if (count > 0) {
      setAnimating(true);

      setTimeout(() => {
        setAnimating(false);
      }, 600);
    }
  }, [count]);

  const activeClassNameStyle = props.activeClassName
    ? {
        [props.activeClassName]: isActive,
      }
    : {};
  const classes = classNames(styles.linkWithBadge, {
    [styles.animating]: animating,
    ...activeClassNameStyle,
  });
  const iconPath =
    isActive && props.iconPathActive ? props.iconPathActive : props.iconPath;

  const targetUrl = RelativeUrlService.getTranslatedUrl(props.to, locale);

  const linkProps: ComponentProps<typeof Alink> = {
    className: classes,
    text: props.to,
  };

  if (count > 0) {
    linkProps['data-count'] = count;
  }

  if (props.useOnClick) {
    linkProps.onClick = (e) => {
      e.stopPropagation();
      e.preventDefault();
      navigate(targetUrl);
    };
  } else {
    linkProps.href = targetUrl;
  }

  return (
    <Alink {...linkProps}>
      <Icon
        iconContent={props.iconContent}
        iconPath={iconPath}
        iconContainerClass={styles.iconContainer}
        className={styles.icon}
      />
      {props.text ? <div className={styles.text}>{props.text}</div> : null}
    </Alink>
  );
}
