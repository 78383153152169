import toPairs from 'lodash/toPairs';
import traverse from 'traverse';
import { FurnitureConfig } from '../config/types';

export default class Utils {
  /**
   * Get primary secondary colo
   * from a structure containing elements
   *
   * @param layout
   * @param primarySections
   * @param cbCalculateArea
   */
  static extractPrimaryColorsElements(
    layout,
    primarySections,
    cbCalculateArea: (props: {
      length: number;
      width: number;
      height: number;
    }) => number,
    cbFilterElement: (element: any) => boolean
  ): { primary: string; secondary: string } {
    // Primary and secondary colors policy
    // calculate bounding box area for each color value
    // color with the biggest area it's a primary color
    const colorArea: {
      // index: color name
      // value: area
      [index: string]: number;
    } = {};
    traverse(layout).forEach((node) => {
      if (
        !(
          node &&
          node.props &&
          node.props.furniture_type &&
          node.props.section &&
          node.props.type &&
          node.props.length &&
          node.props.width &&
          node.props.height &&
          node.props.color
        )
      ) {
        return;
      }

      const furniture_type = node.props.furniture_type;
      const section = node.props.section;
      const type = node.props.type;
      const length = node.props.length;
      const width = node.props.width;
      const height = node.props.height;
      const color = node.props.color;

      const element = {
        furniture_type,
        section,
        type,
        length,
        width,
        height,
        color,
      };

      const weight = primarySections[section];

      if (!weight) return;

      if (cbFilterElement && !cbFilterElement(element)) return;

      let area;
      if (cbCalculateArea) {
        area = cbCalculateArea({ length, width, height });
      } else {
        area = 2 * (length * width + length * height + width * height);
      }
      area *= weight;

      if (colorArea[color]) {
        colorArea[color] += area;
      } else {
        colorArea[color] = area;
      }
    });

    const sortedColorByArea = toPairs(colorArea)
      .sort((a: any[], b: any[]) => b[1] - a[1])
      .map((v) => v[0]);

    const primary = sortedColorByArea[0];
    const secondary = sortedColorByArea[1] || '';

    return {
      primary,
      secondary,
    };
  }

  /**
   * Get the max occurence of colors
   * @param {object} colors
   */
  static getMaxColor(colors) {
    let max = 0;
    let maxColor = undefined;
    if (colors) {
      Object.keys(colors).forEach((color) => {
        if (colors[color] > max) {
          max = colors[color];
          maxColor = color;
        }
      });
    }
    return maxColor;
  }

  /**
   * Get the occurence of colors
   * @param {object} colors
   */
  static getColorsNumber(colors) {
    return colors.reduce((acc, curr) => {
      acc[curr] = (acc[curr] || 0) + 1;
      return acc;
    }, {});
  }

  /**
   * Get the list of all colors
   * @param {object} colors
   */
  static getAllColors(colors) {
    return Object.keys(colors).sort().join('/');
  }

  /**
   * Extract a list of element regarding the specs
   * @param specs
   */
  static extractTypes(specs, types) {
    return types.filter((type) => specs[type] > 0).join('/');
  }
  /**
   * Convert mm to cm
   *
   * @param {number} value in mm
   * @returns {number} value in cm
   */
  static dimensionsToExport(value) {
    return Math.ceil(value / 10);
  }

  /**
   * Get the color name regarding the name of the material
   * This mapping is used for chairs who has a different material name (for rendering purpose)
   * But the same color as for shelves, wardrobe
   * @param material
   */
  static getColorFromMaterial(material) {
    return material.replace(/chair_/, '');
  }

  /**
   * Get Nyce config name for configurator base on device type
   * @param config
   * @param isPhone
   */
  static getNyceConfigName(
    config: FurnitureConfig,
    isPhone: boolean,
    isZoomed = false
  ) {
    if (isPhone && isZoomed) return 'normal';
    if (isPhone && config.nyce.mobile) return 'mobile';

    return 'normal';
  }

  /**
   * Compute sequence of number in a sorted array
   * @param {array} array
   */
  static _getRanges(array) {
    const ranges = [];
    let range = [];

    for (let i = 0; i < array.length; i++) {
      if (range.length === 0) {
        range.push(array[i]);
      }
      if (i + 1 === array.length || array[i + 1] !== array[i] + 1) {
        range.push(array[i]);
        ranges.push(range);
        range = [];
      }
    }

    return ranges;
  }

  static cloneDeep(object: any) {
    return JSON.parse(JSON.stringify(object));
  }
}
