import { Subject, Subscription } from 'rxjs';

import cfg from 'mycs/config';
import { mandatory } from '../GeneralUtils/GeneralUtils';
import { updateUrlWithoutReload } from 'mycs/router/history';

type UrlChangeSubject = {
  previous: string;
  current: string;
};

const urlChange$ = new Subject<UrlChangeSubject>();
const isBrowser = !MYCS_IS_SSR_BUILD;
let currentUrl = '';

function setCurrentUrl(url: string) {
  currentUrl = url;
}

type ParsedUrl = {
  protocol: string;
  pathname: string;
  pathSteps: string[];
  hostname: string;
  tld: string;
  port: string;
  search: Search;
  query: string;
  hash: string;
  lang: string | null;
};

type Search = {
  [key: string]: string;
};

/**
 * @deprecated Use react-router-dom Link, useLocation or useNavigate with LocationUtils instead.
 * WindowLocationUtils
 */
export default class WindowLocationUtils {
  /**
   * Get the current URL of the page
   */
  static getCurrentUrl(): string {
    return isBrowser
      ? // Allow the browser to have a more immediate access to the URL
        window.location.href
      : currentUrl;
  }

  /**
   * Parse the location into parts.
   */
  static parse(url = this.getCurrentUrl()): ParsedUrl {
    // Make sure the URL is absolute
    if (!/^https?:/.test(url)) {
      const loc = new URL(this.getCurrentUrl());
      url = `${loc.protocol}//${loc.host}${url}`;
    }

    const urlObject = new URL(url);

    // We just want 'https' instead of 'https:'
    const protocol = urlObject.protocol.slice(0, -1);

    const { pathname } = urlObject;

    // Extract path Steps
    const pathSteps = urlObject.pathname
      .split('/')
      .filter((step) => step !== '');

    // Extract a language code
    const lang =
      pathSteps[0] && pathSteps[0].length === 2 ? pathSteps[0] : null;
    if (lang && lang in cfg.languages) {
      pathSteps.shift();
    }

    const hostname = urlObject.hostname;
    const [tld] = urlObject.hostname.split('.').slice(-1);
    const port = urlObject.port !== '' ? urlObject.port : '';
    const hash =
      urlObject.hash === ''
        ? ''
        : urlObject.hash.charAt(0) === '#'
        ? urlObject.hash.slice(1)
        : urlObject.hash;
    const query = urlObject.search;

    const search = Object.fromEntries(urlObject.searchParams);

    return {
      protocol,
      pathname,
      pathSteps,
      hostname,
      tld,
      port,
      search,
      query,
      hash,
      lang,
    };
  }

  /**
   * Replace the search parameter with the provided object and return the new search parameter.
   *
   * @param search - the object to replace the search parameter with
   * @param url - optional, if provided it will set the search parameter to it
   * @param replaceUrl
   * @returns the url including the provided search parameter
   */
  static setSearch(
    search: Search = mandatory(),
    url?: string,
    replaceUrl = false
  ): string {
    const parsedUrl = this.parse(url);
    const returnUrl = this.format({ ...parsedUrl, search });
    if (replaceUrl) {
      this.updateCurrentURL(returnUrl);
    } else {
      updateUrlWithoutReload(returnUrl);
    }
    return returnUrl;
  }

  /**
   * Set search parameter. Replace it if it already exists.
   *
   * @param param - The object to add to the search parameter
   * @param url - Optional, if provided it will set the specified search parameter to it
   * @param replaceUrl
   * @returns Modified search parameter
   */
  static setSearchParam(
    param: Search = mandatory(),
    url?: string,
    replaceUrl = false
  ): string {
    const parsedUrl = this.parse(url);
    const newSearch = Object.assign({}, parsedUrl.search, param);
    return this.setSearch(newSearch, url, replaceUrl);
  }

  /**
   * Remove param from search
   * @returns {string} url without the param
   */
  static removeSearchParam(
    paramKey: string | string[] = mandatory(),
    url?: string
  ) {
    const parsedUrl = this.parse(url);
    const newSearch: Search = Object.assign({}, parsedUrl.search);
    const keys = Array.isArray(paramKey) ? paramKey : [paramKey];

    for (const key of keys) delete newSearch[key];

    return this.setSearch(newSearch, url);
  }

  /**
   * Format url created from the provided parsedUrl object
   */
  static format(parsedUrl: Partial<ParsedUrl>): string {
    const port = parsedUrl.port ? `:${parsedUrl.port}` : '';
    const searchParams = new URLSearchParams(parsedUrl.search);
    const search =
      parsedUrl.search && searchParams.toString() ? `?${searchParams}` : '';
    const hash = parsedUrl.hash ? `#${parsedUrl.hash}` : '';
    const protocol = parsedUrl.protocol ? `${parsedUrl.protocol}://` : '';
    const hostname = parsedUrl.hostname ? parsedUrl.hostname : '';
    const pathname = parsedUrl.pathname ? parsedUrl.pathname : '';
    const formatedUrl = `${protocol}${hostname}${port}${pathname}${search}${hash}`;
    return formatedUrl;
  }

  /**
   * Return path of current URL or the provided url
   */
  static getPath(url?: string): string {
    return this.parse(url).pathname;
  }

  /**
   * Scroll to the element with anchor hash id
   */
  static scrollToHash() {
    const { hash } = window.location;
    if (hash) {
      const id = hash.replace('#', '');

      const element = document.getElementById(id);
      if (element) element.scrollIntoView({ block: 'start' });
    }
  }

  /**
   * Get the search part of the current URL as an object
   */
  static getSearch(url?: string): Search {
    return this.parse(url).search;
  }

  /**
   * Update current url. Modifies the current history entry.
   */
  static updateCurrentURL(url: string) {
    history.replaceState(null, '', url);
  }

  /**
   * Get the current URL's path with search parameters.
   *
   * E.g. given the URL http://example.com/#/some/path?foo=bar&baz=xoxo
   * => "/some/path?foo=bar&baz=xoxo"
   */
  static getUrlPathAndSearch(): string {
    const { pathname, query } = this.parse();
    return `${pathname}${query}`;
  }

  /**
   * Url Change handler
   */
  static onUrlChange(handler: (data: UrlChangeSubject) => void): Subscription {
    return urlChange$.subscribe(handler);
  }

  /**
   * Init the URL on the server-side
   */
  static initServer(url: string) {
    setCurrentUrl(url);
  }

  /**
   * Init the URL on the client-side
   */
  static initBrowser() {
    const loc = window.location;
    let prevUrl = '';

    setInterval(() => {
      const url = loc.href;
      if (url !== prevUrl) {
        setCurrentUrl(loc.href);
        urlChange$.next({ previous: prevUrl, current: url });
        prevUrl = url;
      }
    }, 100);

    // scroll to the element with anchor link
    window.addEventListener('load', () => {
      // Some elements will appear after Lazy Loading, during some time they will also change there position in the viewport,
      // because of other elements with Lazy Loading. So, unfortunately no we can't identify time
      // when we ready to scroll to the element with anchor id.
      // That why we need setInterval, which we will cancel after 2 seconds, when all elements should be ready.
      const scrollToHashInterval = setInterval(this.scrollToHash, 100);
      setTimeout(() => {
        clearInterval(scrollToHashInterval);
      }, 3000);
    });

    setCurrentUrl(loc.href);
  }
}
