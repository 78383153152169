import joynConfig from '../../config/joyn-config';
import colorConfig from '../../config/color-config';
import Component from '../../components/Component';
import RendererService from '../../services/RendererService';
import isMatch from 'lodash/isMatch';

/**
 * JoynSofaElement
 *
 * @class JoynSofaElement
 * @extends {Component}
 */
export default class JoynSofaElement extends Component {
  /**
   * Add/Update props based on their characteristic
   *
   * @param {any} props
   * @param {any} element
   * @returns {any} new props
   */
  static hardcode(props, element) {
    const overlapArmrest = 3;
    const overlapLeatherArmrest = 1.5;
    const overlapArmrestZ = 1;
    const overlapBigArmrestZ = 1.5;
    const overlapLeatherBigArmrestZ = 0.5;
    const overlapBigArmrestY = 0.5;
    const overlapBase = 0.3;
    const overlapPoufBaseAndCushion = 0.3;
    const overlapBackrestZ = 0.5;
    const overlapExt = 2;
    const overlapLoungeSeat = 1;

    const newProps: any = {};
    const length = element.length / 10;
    const width = element.width / 10;
    const height = element.height / 10;

    const isLeather = [
      ...joynConfig.covers.kenia,
      ...joynConfig.covers.rancho,
      ...joynConfig.covers.toledo,
    ].includes(element.color);

    if (
      [
        'armrest_left',
        'armrest_right',
        'backrest',
        'sofa_bed_backrest',
      ].includes(element.section)
    ) {
      newProps.size_y = width;
      newProps.size_z = height;
    }

    if (element.section === 'sofa_bed_box') {
      newProps.margin_z = [0, -1];
    }

    // Overlapping of armrest
    // - Use negative margin tell let the shape overlap other shapes
    // - Strech the shape to have a correct dimension of the element
    // - In case of 240 armrest, we have to add slight margin
    if (element.section === 'armrest_left') {
      newProps.margin_x = [0, -overlapArmrest];
      newProps.size_x = length + overlapArmrest;
      if (isLeather) {
        newProps.margin_x = [0, -overlapLeatherArmrest];
        newProps.size_x = length + overlapLeatherArmrest;
      }
      newProps.margin_z = [-overlapArmrestZ, 0];
      newProps.size_z = height + overlapArmrestZ;
      if (element.length === 240) {
        newProps.size_y = width + overlapBigArmrestY * 2;
        newProps.margin_y = [-overlapBigArmrestY, -overlapBigArmrestY];
        newProps.margin_z = [-overlapBigArmrestZ, 0];
        newProps.size_z = height + overlapBigArmrestZ;
        if (isLeather) {
          newProps.margin_z = [-overlapLeatherBigArmrestZ, 0];
          newProps.size_z = height + overlapLeatherBigArmrestZ;
        }
      }
    }

    if (element.section === 'armrest_right') {
      newProps.margin_x = [-overlapArmrest, 0];
      newProps.size_x = length + overlapArmrest;
      if (isLeather) {
        newProps.margin_x = [-overlapLeatherArmrest, 0];
        newProps.size_x = length + overlapLeatherArmrest;
      }
      newProps.margin_z = [-overlapArmrestZ, 0];
      newProps.size_z = height + overlapArmrestZ;
      if (element.length === 240) {
        newProps.size_y = width + overlapBigArmrestY * 2;
        newProps.margin_y = [-overlapBigArmrestY, -overlapBigArmrestY];
        newProps.margin_z = [-overlapBigArmrestZ, 0];
        newProps.size_z = height + overlapBigArmrestZ;
      }
    }

    // Overlapping of base & backrest
    if (
      ['base', 'sofa_bed', 'cushion', 'corner_cushion'].includes(
        element.section
      )
    ) {
      newProps.margin_x = JoynSofaElement.addTabValues(
        props.margin_x,
        -overlapBase,
        -overlapBase
      );
      newProps.size_x = length + 2 * overlapBase;
    }

    // Overlapping of backrest
    if (
      ['backrest', 'sofa_bed_backrest', 'corner_backrest'].includes(
        element.section
      )
    ) {
      newProps.margin_z = [-overlapBackrestZ, 0];
    }

    // Force dimension
    // - give an accurate dimension of the whole sofa
    if (
      ['back_cushion', 'l_cushion_left', 'end_cushion_left'].includes(
        element.section
      )
    ) {
      newProps.size_x = length;
    }
    if (['end_cushion_right', 'l_cushion_right'].includes(element.section)) {
      newProps.size_x = length;
      newProps.shape = element.scene_object_name.replace('_left_', '_right_');
    }

    // Force shape
    // temporary: update the flow to Nyce
    if (element.section === 'sofa_bed_box') {
      newProps.shape = element.scene_object_name;
      newProps.material = element.color;
    }
    if (element.section === 'sofa_bed_box_high') {
      newProps.shape = element.scene_object_name;
      newProps.material = element.color;
    }
    if (isMatch(element, { section: 'base', type: 'normal', length: 600 })) {
      newProps.shape = element.scene_object_name;
      newProps.material = element.color;
    }
    if (isMatch(element, { section: 'base', type: 'normal', length: 1000 })) {
      newProps.shape = element.scene_object_name;
      newProps.material = element.color;
    }

    // - Reduce the size and add margin to lounge cushion to make them less high than regular
    if (['end_cushion_left', 'end_cushion_right'].includes(element.section)) {
      newProps.size_z = height - overlapLoungeSeat;
      newProps.margin_z = JoynSofaElement.addTabValues(
        props.margin_z,
        0,
        overlapLoungeSeat
      );
    }

    // Overlapping of back_cushion in the backrest
    if (
      element.section.includes('back_cushion') ||
      (element.section.includes('corner_cushion') && element.width !== 780)
    ) {
      newProps.margin_z = [-5, 0];
    }

    // Negative margin for extensions
    if (element.section.includes('base_extension')) {
      newProps.size_x = length;

      newProps.size_y = width + overlapExt;
      newProps.margin_y = [0, -overlapExt];
    }
    if (element.section.includes('sofa_bed_high')) {
      newProps.shape = element.scene_object_name.replace(
        'sofa_bed_high',
        'sofa_bed'
      );
    }

    // change shape for leather covers
    if (isLeather) {
      newProps.shape = newProps.shape
        ? newProps.shape.replace('_normal_', '_normal_leather_')
        : element.scene_object_name.replace('_normal_', '_normal_leather_');
    }

    // Overlapping base_pouf and pouf_cushion
    if (element.section === 'pouf_cushion') {
      // add gap to fix ottoman dimensions
      const gapForCushion = 1;
      newProps.margin_z = [-overlapPoufBaseAndCushion, gapForCushion];
      newProps.size_z = height + overlapPoufBaseAndCushion;
    }

    if (element.type === 'support') {
      // Reuse existing support leg shape
      newProps.shape = 'shelf_legs_support_25x25x120_obj';
    }

    return newProps;
  }

  /**
   * Return an array containing first and second value, and eventually in addition of existing values of tab
   *
   * @param {array} tab
   * @param {number} firstValue
   * @param {number} secondValue
   * @returns {array}
   */
  static addTabValues(tab, firstValue, secondValue) {
    return tab
      ? [tab[0] + firstValue, tab[1] + secondValue]
      : [firstValue, secondValue];
  }

  /**
   * Mapping of some properties in order to fetch the SKU related to the shape.
   *
   * @param {object} props
   * @returns {object} props to fetch the SKUs
   */
  static mappingShape(props) {
    if (props.section === 'sofa_bed_box') {
      return null;
    }
    if (props.section === 'sofa_bed_box_high') {
      return null;
    }
    if (isMatch(props, { section: 'base', type: 'normal', length: 600 })) {
      return null;
    }
    if (isMatch(props, { section: 'base', type: 'normal', length: 1000 })) {
      return null;
    }
    if (colorConfig.laminate_black_white.includes(props.color)) {
      return {
        ...props,
        color: joynConfig.lacquerMapping[props.color],
      };
    }
    if (!colorConfig.textile.includes(props.color)) {
      return { ...props };
    }
    if (props.section === 'l_cushion_right') {
      return {
        ...props,
        section: 'l_cushion_left',
        color: 'default_shd',
      };
    } else if (props.section === 'end_cushion_right') {
      return {
        ...props,
        section: 'end_cushion_left',
        color: 'default_shd',
      };
    }

    return { ...props, color: 'default_shd' };
  }

  /**
   * Mapping of some properties in order to fetch the SKU related to the cover.
   *
   * @param {object} props
   * @returns {object} props to fetch the SKUs
   */
  static mappingCover(props) {
    if (!colorConfig.textile.includes(props.color)) {
      return null;
    }

    if (props.section === 'armrest_left') {
      return {
        ...props,
        section: 'armrest_right',
        type: 'cover',
      };
    } else if (props.section === 'sofa_bed_high') {
      return {
        ...props,
        section: 'sofa_bed',
        type: 'cover',
      };
    }
    return {
      ...props,
      type: 'cover',
    };
  }

  /**
   * Mapping of some properties in order to compute
   * - shape
   * - material
   *
   * @param {object} element
   * @returns {object} assets override
   */
  static mappingAsset(element) {
    const newProps: any = {};
    let overrideShape = false;

    const newElement = { ...element };

    // mappingShape
    if (colorConfig.laminate_black_white.includes(newElement.color)) {
      newProps.material = joynConfig.lacquerMapping[newElement.color];
    }

    // harcode override
    if (['end_cushion_right', 'l_cushion_right'].includes(newElement.section)) {
      overrideShape = true;
      newElement.section = newElement.section.replace('left', 'right');
    }

    // Force shape: is working with the standard case
    // if (element.section === 'sofa_bed_box') {
    // if (element.section === 'sofa_bed_box_high') {
    // if (isMatch(element, { section: 'base', type: 'normal', length: 600 })) {
    // if (isMatch(element, { section: 'base', type: 'normal', length: 1000 })) {

    if (newElement.section.includes('sofa_bed_high')) {
      overrideShape = true;
      newElement.section = 'sofa_bed';
    }

    const isLeather = [
      ...joynConfig.covers.kenia,
      ...joynConfig.covers.rancho,
      ...joynConfig.covers.toledo,
    ].includes(newElement.color);
    if (isLeather) {
      overrideShape = true;
      newElement.type = 'normal_leather';
    }

    if (overrideShape === true) {
      newProps.shape = Component.generateSceneObjectName(newElement);
    }

    if (newElement.type === 'support') {
      // Reuse existing support leg shape
      newProps.shape = 'shelf_legs_support_25x25x120_obj';
    }

    return newProps;
  }

  /**
   * Render an element
   *
   * @returns {JSX.Element}
   */
  render() {
    const props = Component.decorateElement(
      this.props,
      joynConfig.furnitureType,
      JoynSofaElement,
      {
        custom: true,
        element: true,
        assets: false,
        hardcode: true,
        skus: false,
        extend: false,
      }
    );
    return <shape {...props} />;
  }
}
