import pyllowOptions from '../../config/pyllow-config';
import joynConfig from '../../config/joyn-config';
import Component from '../../components/Component';
import RendererService from '../../services/RendererService';

/**
 * PyllowSofaElement
 *
 * @class PyllowSofaElement
 * @extends {Component}
 */
export default class PyllowSofaElement extends Component {
  /**
   * Add/Update props based on their characteristic
   *
   * @param {any} props
   * @param {any} element
   * @returns {any} new props
   */
  static hardcode(props, element) {
    const newProps: any = {};
    const length = element.length / 10;
    const width = element.width / 10;
    const height = element.height / 10;

    const overlapSeat = 0;

    const overlapBackrestX = 0;
    const overlapBackrestY = 0;
    const overlapBackrestZ = 2;

    const overlapNewModule = 10;
    const overlapNewExtension = 10;

    if (props.className === 'newModule') {
      newProps.margin_x = PyllowSofaElement.updateMargin(props.margin_x, [
        -overlapNewModule,
        -overlapNewModule,
      ]);
      newProps.size_x = PyllowSofaElement.updateSize(
        props.size_x,
        length + 2 * overlapNewModule
      );
    }

    if (props.className === 'newExtension') {
      newProps.margin_y = PyllowSofaElement.updateMargin(props.margin_y, [
        0,
        -overlapNewExtension,
      ]);
      newProps.size_y = PyllowSofaElement.updateSize(
        props.size_y,
        props.size_y + overlapNewExtension
      );
    }

    if (props.className === 'seat') {
      newProps.margin_x = PyllowSofaElement.updateMargin(props.margin_x, [
        -overlapSeat,
        -overlapSeat,
      ]);
      newProps.size_x = PyllowSofaElement.updateSize(
        props.size_x,
        length + 2 * overlapSeat
      );
    }

    if (props.className === 'backrest') {
      newProps.margin_x = PyllowSofaElement.updateMargin(props.margin_x, [
        -overlapBackrestX,
        -overlapBackrestX,
      ]);
      newProps.size_x = PyllowSofaElement.updateSize(
        props.size_x,
        length + 2 * overlapBackrestX
      );
      newProps.margin_y = PyllowSofaElement.updateMargin(props.margin_y, [
        -overlapBackrestY,
        0,
      ]);
      newProps.size_y = PyllowSofaElement.updateSize(
        props.size_y,
        width + overlapBackrestY
      );
      newProps.size_z = PyllowSofaElement.updateSize(
        props.size_z,
        height - overlapBackrestZ
      );
    }

    if (props.className === 'corner_backrest') {
      newProps.size_z = PyllowSofaElement.updateSize(
        props.size_z,
        height - overlapBackrestZ
      );
    }
    if (element.section === 'armrest_left') {
      newProps.shape = element.scene_object_name.replace('_right_', '_left_');
    }

    // change shape for leather covers
    if (
      [
        ...joynConfig.covers.kenia,
        ...joynConfig.covers.rancho,
        ...joynConfig.covers.toledo,
      ].includes(element.color)
    ) {
      newProps.shape = newProps.shape
        ? newProps.shape.replace('_normal_', '_normal_leather_')
        : element.scene_object_name.replace('_normal_', '_normal_leather_');
    }

    return newProps;
  }

  /**
   * Update margin with new values
   *
   * @param {array} margin
   * @param {array} values
   * @returns {array}
   */
  static updateMargin(margin, values) {
    if (!margin) {
      return values;
    } else {
      return [margin[0] + values[0], margin[1] + values[1]];
    }
  }

  /**
   * Update size with new values
   *
   * @param {number} size
   * @param {number} newSize
   *
   * @returns {number}
   */
  static updateSize(size, newSize) {
    if (size && size !== 0) {
      const sign = size / Math.abs(size);
      return sign * newSize;
    } else {
      return newSize;
    }
  }

  /**
   * Mapping of some properties in order to fetch the SKU related to the shape.
   *
   * @param {object} props
   * @returns {object} props to fetch the SKUs
   */
  static mappingShape(props) {
    if (props.section === 'armrest_left') {
      return {
        ...props,
        section: 'armrest_right',
        color: 'default_shd',
      };
    }
    return { ...props, color: 'default_shd' };
  }

  /**
   * Mapping of some properties in order to fetch the SKU related to the cover.
   *
   * @param {object} props
   * @returns {object} props to fetch the SKUs
   */
  static mappingCover(props) {
    if (['sofa_bed_left', 'sofa_bed_right'].includes(props.section)) {
      return {
        ...props,
        section: 'sofa_bed',
        type: 'cover',
      };
    }
    return {
      ...props,
      type: 'cover',
    };
  }

  /**
   * Mapping of some properties in order to compute
   * - shape
   * - material
   *
   * @param {object} element
   * @returns {object} assets override
   */
  static mappingAsset(element) {
    const newProps: any = {};

    // harcode override
    if (element.section === 'armrest_left') {
      newProps.shape = Component.generateSceneObjectName({
        ...element,
        type: element.type.replace('right', 'left'),
      });
    }

    const isLeather = [
      ...joynConfig.covers.kenia,
      ...joynConfig.covers.rancho,
      ...joynConfig.covers.toledo,
    ].includes(element.color);
    if (isLeather) {
      newProps.shape = Component.generateSceneObjectName({
        ...element,
        type: element.type.replace('normal', 'normal_leather'),
      });
    }

    const isCorduroy = [
      ...joynConfig.covers.ribcord,
      ...joynConfig.covers.scala,
    ].includes(element.color);
    if (isCorduroy) {
      newProps.material = `${element.color}_rotated90`;
    }
    return newProps;
  }

  /**
   * Render an element
   *
   * @returns {JSX.Element}
   */
  render() {
    const props = Component.decorateElement(
      this.props,
      pyllowOptions.furnitureType,
      PyllowSofaElement,
      {
        custom: true,
        element: true,
        assets: false,
        hardcode: true,
        skus: false,
        extend: false,
      }
    );

    return <shape {...props} />;
  }
}
