import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { postLogin } from 'mycs/api/UserAPI';
import { setUserID } from 'mycs/services/UserService';
import { useUser } from 'mycs/hooks/useUser';
import LocationUtils from 'mycs/shared/utilities/LocationUtils/LocationUtils';

export function useUserURLSignin() {
  const { search } = useLocation();
  const { user, mutateUser, isValidatingUser } = useUser();

  // Sign in the user with the ID from the search query (email sign-in).
  useEffect(() => {
    if (isValidatingUser) {
      return;
    }

    const userID = LocationUtils.getUserIDFromSearch(search);
    if (!userID || user?.id === userID) {
      return;
    }

    const token =
      LocationUtils.getUserTokenFromSearch(search) ||
      // Tmp token.
      'd012c62c4514469ca234a4719b40431e1c40e37a798f47f622dd875ead764d933';

    postLogin(userID, token).then(() => {
      setUserID(userID);
      mutateUser();
    });
  }, [user, isValidatingUser, mutateUser, search]);
}
