import SafeText from 'mycs/shared/components/SafeText/SafeText';
import styles from './Fallback.scss';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import logoSVG from 'mycs-img/mycs-logos/logo.svg';
import Icon from 'mycs/shared/components/Icon/Icon';

function Fallback() {
  const { locale } = useLocale();

  return (
    <div className={styles.container}>
      <div className={styles.mobileBackgroundContainer}></div>
      <div className={styles.contentContainer}>
        <Icon
          iconContainerClass={styles.iconContainer}
          className={styles.logo}
          iconContent={logoSVG}
        />
        <div className={styles.textContainer}>
          <SafeText
            content={I18nUtils.localize(
              locale,
              'Website is down for maintenance'
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default Fallback;
