import { useCallback, useState } from 'react';
import UrlProviderService from 'mycs/shared/services/UrlProviderService/UrlProviderService';
import Logger from 'mycs/shared/services/Logger';

const imageAPIurl = UrlProviderService.getImageApiUrl();

type Props = {
  src?: string;
  className?: string;
  sizes?: string; // A set of media conditions,
  // indicates what image size would be best to choose.
  // e.g. "(max-width: 320px) 50vw, 80vw" - for screens < 320px
  // the image width is 50% of the viewport width else 80%
  alt?: string;
  title?: string;
  onClick?: (e: any) => void;
  onMouseOver?: () => void;
  width?: number;
  height?: number;
  aspectRatio?: string;
};

export default function SmartImage(props: Props) {
  const [retryPeriod, setRetryPeriod] = useState(1000);
  /**
   * Creates a string containing (image url)-(image width) pairs,
   * transforming the original image url into an Image API url.
   *
   * @param {string} src The original image url
   * @param {'webp' | 'jpeg'} format The image format
   * @returns {string} e.g. "https://url400 400w, https://url800 800w"
   */
  function createSRCset(src: string, format: 'webp' | 'jpeg') {
    const minWidth = 500,
      maxWidth = 2000,
      step = 500;
    let srcSet = '';

    for (let width = minWidth; width <= maxWidth; width += step) {
      srcSet += `${imageAPIurl}/fit/${width}/${width}/no/0/plain/${src}@${format} ${width}w`;

      if (width < maxWidth) {
        srcSet += ', ';
      }
    }

    return srcSet;
  }

  function getCleanImageUrl() {
    return props.src?.replace(/\?.+$/, '');
  }

  function canBeOptimized() {
    const url = getCleanImageUrl();
    return url?.match(/\.(jpg|jpeg|png)$/) || url?.includes('images/0');
  }

  const handleError = useCallback(() => {
    const second = 1000;
    const maxRetryPeriod = 10 * second;

    if (retryPeriod > maxRetryPeriod) {
      Logger.error(`Image [${props.src}] failed to load`);

      return;
    }

    setTimeout(() => {
      // double the retry period.
      setRetryPeriod(retryPeriod * 2);
    }, retryPeriod);
  }, [props.src, retryPeriod, setRetryPeriod]);

  const {
    className,
    src,
    sizes = '100vw', // by default it assumes the image width is 100% of the viewport width,
    alt,
    title,
    onClick,
    onMouseOver,
    width = 'auto',
    height = 'auto',
    aspectRatio,
  } = props;

  let imgStyle: React.CSSProperties = {};

  if (aspectRatio) {
    imgStyle.aspectRatio = aspectRatio;
  }

  if (!canBeOptimized()) {
    return (
      <img
        className={className}
        src={src}
        alt={alt}
        title={title}
        onClick={onClick}
        onMouseOver={onMouseOver}
        width={width}
        height={height}
        style={imgStyle}
      />
    );
  }

  // Browser picks up the image from the "srcSet" attr
  // that fits best the conditions from the "sizes" attr.
  // If "image/webp" is not supported it falls back to "image/jpeg".
  // If "picture" is not supported it falls back to "img".
  return (
    // key is used to force the browser to fetch the image again on failure.
    <picture key={retryPeriod}>
      {/* If there is no cache hit it will take much more time for
        ImageProcessingAPI to produce a webp image comparing to jpeg
        so for now it's disabled. */}
      <source
        type="image/webp"
        srcSet={createSRCset(src!, 'webp')}
        sizes={sizes}
      />
      <source
        type="image/jpeg"
        srcSet={createSRCset(src!, 'jpeg')}
        sizes={sizes}
      />
      <img
        className={className}
        srcSet={createSRCset(src!, 'jpeg')}
        sizes={sizes}
        src={src}
        alt={alt}
        title={title}
        onClick={onClick}
        onMouseOver={onMouseOver}
        width={width}
        height={height}
        style={imgStyle}
        loading="lazy"
        onError={handleError}
      />
    </picture>
  );
}
