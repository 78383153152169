import { ComponentProps, useRef } from 'react';

import { SignupSource } from 'mycs/api/UserAPI';
import { trackNewsletterSubEvent } from 'mycs/services/UserAnalyticsService';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import { useUser } from 'mycs/hooks/useUser';
import { useUserTools } from 'mycs/hooks/useUserTools';
import AnalyticsService from 'mycs/shared/services/AnalyticsService/AnalyticsService';
import Button from 'mycs/shared/components/Button/Button';
import cfg from 'mycs/config';
import EmailInput from 'mycs/shared/components/EmailInput/EmailInput';

import './NewsletterForm.scss';

type Props = {
  containerClassName?: string;
  formClassName?: string;
  inputClassName?: string;
  buttonClassName: string;
  buttonProps: ComponentProps<typeof Button>;
  label?: string;
  labelClassName?: string;
  withPlaceholder?: boolean;
  onSubmit?: () => void;
  mailingListId?: string;
  errorMessageBottom?: boolean;
  isWithLabel?: boolean;
  source?: SignupSource;
};

export default function NewsletterForm({
  formClassName,
  inputClassName,
  buttonClassName,
  buttonProps,
  label,
  labelClassName,
  errorMessageBottom,
  isWithLabel,
  source,
  withPlaceholder,
  mailingListId,
  onSubmit,
}: Props) {
  const { locale, countryCode: _countryCode } = useLocale();
  const countryCode = _countryCode.toUpperCase();
  const containerRef = useRef<HTMLFormElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const { user, mutateUser } = useUser();
  const { signUp } = useUserTools(user, mutateUser);

  const [langKey] = locale.split('_');
  const mailChimpID = mailingListId
    ? mailingListId
    : cfg.mailchimp.langsIds[langKey];

  const formActionUrl = cfg.mailchimp.newsletterSubscribeUrl + mailChimpID;

  const inputProps = {
    required: true,
    name: 'EMAIL',
    placeholder: withPlaceholder ? 'Insert email address' : '',
    ref: emailRef,
  };

  const nativeProps = {
    type: 'submit',
  };

  return (
    <form
      className={formClassName}
      action={formActionUrl}
      method="post"
      //rel="noopener" //FIXME: TS complains about this attribute
      target="_blank"
      name="newsletter_form"
      onSubmit={onFormSubmit}
      ref={containerRef}
    >
      <input type="hidden" name="COUNTRY" value={countryCode} />
      <input type="hidden" name="LANG" value={locale} />
      <input type="hidden" name="SOURCE" value={source} />
      <div className={inputClassName}>
        {isWithLabel ? (
          <label>
            Email
            <EmailInput
              nativeProps={inputProps}
              errorMessageBottom={errorMessageBottom}
            />
          </label>
        ) : (
          <EmailInput
            nativeProps={inputProps}
            label={label}
            labelClassName={labelClassName}
            errorMessageBottom={errorMessageBottom}
          />
        )}
      </div>

      <div className={buttonClassName}>
        <Button
          isFullWidth
          nativeProps={nativeProps}
          text="Subscribe"
          {...buttonProps}
        />
      </div>
    </form>
  );

  /**
   * Tell GA a new user has registered for the newsletter
   */
  function onFormSubmit() {
    const email = emailRef.current?.value;
    if (!email) {
      return;
    }

    signUp({
      email,
    }).then((resp) => {
      trackNewsletterSubEvent({
        locale,
        countryCode,
        user: resp,
        componentPath: AnalyticsService.getComponentPath(containerRef.current!),
      });
    });

    setTimeout(() => {
      onSubmit?.();
    }, 100); // Timeout, otherwise the subscription confirmation page isn't opening
  }
}
