import { BehaviorSubject } from 'rxjs';

import Logger from 'mycs/shared/services/Logger';
import isEqual from 'lodash/isEqual';

export interface CookieConsent {
  marketing: boolean;
  necessary: boolean;
  preferences: boolean;
  statistics: boolean;
}

enum CookiebotEvent {
  // The event is triggered when the user's consent state is ready,
  // either from being submitted or loaded from an existing cookie.
  ConsentReady = 'ConsentReady',
  // Fires when the cookie consent banner is displayed to the end user.
  DialogDisplay = 'DialogDisplay',
  // Custom event attached to Cookiebot script tag.
  ScriptLoad = 'ScriptLoad',
  // Custom event attached to Cookiebot script tag.
  ScriptError = 'ScriptError',
}

class CookieService {
  static get defaultState() {
    return {
      cookieConsent: null as null | CookieConsent,
      isInitialized: false,
    };
  }

  state$: BehaviorSubject<typeof CookieService.defaultState> =
    new BehaviorSubject(CookieService.defaultState);

  get state() {
    return this.state$.value;
  }

  init() {
    try {
      // Replay the buffered events.
      window.cookiebotEvents.forEach((event) => {
        this.onEvent(event);
      });

      // Proxy the events.
      const _onCookiebotEvent = window.onCookiebotEvent;
      window.onCookiebotEvent = (event: string) => {
        _onCookiebotEvent(event);
        this.onEvent(event);
      };

      // Tmp monitoring.
      // Catch the case, when Cookiebot was loaded
      // but not initialised properly (no dialog, no consent).
      setTimeout(() => {
        if (this.state.isInitialized) {
          return;
        }

        // The user might take time to accept the cookies,
        // so we wait for the DialogDisplay event.
        // Or if the consent is already set, we wait for the ConsentReady event.
        const initEvent = window.cookiebotEvents.find(
          (event) =>
            event === CookiebotEvent.ConsentReady ||
            event === CookiebotEvent.DialogDisplay
        );
        // Ad blockers can block the Cookiebot script.
        const scriptErrorEvent = window.cookiebotEvents.find(
          (event) => event === CookiebotEvent.ScriptError
        );

        if (!initEvent && !scriptErrorEvent) {
          Logger.error(
            `Cookiebot is not ready after 30s; event array [${window.cookiebotEvents.join(
              ','
            )}]`
          );
        }
        // Slow 3G mode in Chrome DevTools can cause the Cookiebot script to init in around 25s.
      }, 30000);

      this.state$.subscribe((state) => {
        Logger.info('CookieService state update', state);
      });
    } catch (error) {
      Logger.error('CookieService failed to init', error);
    }
  }

  private onEvent(event: string) {
    switch (event) {
      case CookiebotEvent.ConsentReady:
        const cookiebotConsent = window.Cookiebot?.consent;
        if (!cookiebotConsent) {
          Logger.error('missing Cookiebot consent object');

          return;
        }

        this.onCookiebotInitSuccess({
          marketing: cookiebotConsent.marketing,
          necessary: cookiebotConsent.necessary,
          preferences: cookiebotConsent.preferences,
          statistics: cookiebotConsent.statistics,
        });
        break;
      case CookiebotEvent.ScriptError:
        this.onCookiebotInitFailure();
        break;
    }
  }

  private onCookiebotInitSuccess(cookieConsent: CookieConsent) {
    if (
      this.state.isInitialized &&
      !isEqual(cookieConsent, this.state.cookieConsent)
    ) {
      // Page reload for all app components to reinitialise with the new cookie consent state.
      location.reload();
    }

    this.state$.next({
      isInitialized: true,
      cookieConsent: {
        ...cookieConsent,
      },
    });
  }

  private onCookiebotInitFailure() {
    this.state$.next({
      isInitialized: true,
      cookieConsent: null,
    });
  }

  renewCookieDialog() {
    if (!window.Cookiebot) {
      Logger.error('CookieBot is not loaded');
      return;
    }

    window.Cookiebot.renew();
  }
}

export default new CookieService();
