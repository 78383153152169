import {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
} from 'react';
import ContentfulService from 'mycs/shared/services/ContentfulService/ContentfulService';
import { useLocale } from 'mycs/shared/state/LocaleContext';

const ContentfulContext = createContext(ContentfulService.state);

// This context provider is passed to any component requiring the context
export function ContentfulContextProvider(props: {
  children: ReactNode;
}): JSX.Element {
  const [contentfulState, setContentfulState] = useState(
    ContentfulService.state
  );
  const { locale, countryCode } = useLocale();

  useEffect(() => {
    const subscription = ContentfulService.state$.subscribe(setContentfulState);

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    // Reinit the service if SSR failed.
    // Or if it's a local server with no SSR.
    if (!ContentfulService.state.preloaded) {
      ContentfulService.init(locale, countryCode);
    }
  }, [countryCode, locale]);

  return (
    <ContentfulContext.Provider value={contentfulState}>
      {props.children}
    </ContentfulContext.Provider>
  );
}

export function useContentfulContext() {
  return useContext(ContentfulContext);
}
