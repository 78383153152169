import urlProviderService from 'mycs/shared/services/UrlProviderService/UrlProviderService';
import FetchAPI from 'mycs/shared/utilities/FetchAPI/FetchAPI';
import { Section, Option } from 'furniture-engine';

const apiURL = urlProviderService.getDesignApiUrl();

export interface Price {
  basePrice: number;
  totalPriceInclTaxes: number;
  totalPrice: number;
  taxes: number;
  taxPercentage: number;
  currencyCode: string; // 'EUR'
  roundingDifference: number;
  shippingPriceInclTaxes: number;
  shippingPrice: number;
  shippingTaxes: number;
  assemblingPriceInclTaxes: number;
  assemblingPrice: number;
  assemblingTaxes: number;
  assemblingUnits: number;
  baseboardPrice: number;
  baseboardPriceInclTaxes: number;
  baseboardTaxes: number;
  discountRate: string; // '40%';
  discountName: string; // '01.12.-05.12._MYCS_Sale';
  discountValidUntil: string; // '2022-12-05T23:59+01:00';
  oldTotalPriceInclTaxes: number;
  shippingDuration?: number; // shows up only with "with_shipping_details" query param.
  eligibleForAlmaDeferredPayment?: boolean; // shows up only with "???" query param.
  priceHistory: {
    history: {
      [date: string]: number;
    };
    lowestPrice: number;
  };
}

interface DesignPriceRequest {
  furniture_type: string;
  compressedStructure: string;
  quantity?: number;
  with_shipping_details?: boolean;
  explanation?: boolean;
  assembly?: boolean;
  workshop?: boolean;
  baseboard_cut?: boolean;
  // Not used
  estimate?: boolean;
  skip_exceptions?: boolean;
}

interface DesignFiltersRequest {
  country_code: string;
  furniture_group: string;
  furniture_type?: string;
  color_group?: string;
  material?: string;
  kidPetsFriendly?: boolean;
  price_min?: number;
  price_max?: number;
  width_min?: number;
  width_max?: number;
  length_min?: number;
  length_max?: number;
  height_min?: number;
  height_max?: number;
  sofa_shapes?: string;
  sofa_is_sleeping?: boolean;
  sofa_is_ottoman?: boolean;
  limit?: number;
  offset?: number;
  show_relevance?: boolean;
  explanation?: boolean;
}

interface DesignInfoRequest {
  furniture_type: string;
  compressedStructure: string;
}

interface DesignFiltersRangeRequest {
  country_code: string;
  furniture_group: string;
}

interface DesignPriceResponse {
  [countryCode: string]: Price;
}

export interface Dimensions {
  length: number;
  height: number;
  width: number;
}

export interface Design {
  uuid: string;
  hash: string;
  furniture_type: string;
  structure: object;
  name: string;
  colors: {
    primary: string;
    secondary: string;
    cover: string;
  };
  specs: Record<string, string | number>;
  dimensions: Dimensions | Dimensions[];
  is_inspiration: boolean;
  is_verified: boolean;
  created_at: string;
  updated_at: string;
  //to be removed soon
  camera: object;
  image_type: string;

  last_sold_at: string | null;
  is_deleted: boolean;
  id: number;
  user_created: boolean;
  images: (OldImage | NewImage)[];
  image_url: string;
  price: { [country_code: string]: Price };
}

export interface NewImage {
  type: string;
  url: string;
  hash: string;
  size: string;
  description: string;
  mode: string | null;
  status: string;
  updated_at: string;
  quality: string;
  position: number;
}

export interface OldImage {
  type: string;
  url: string;
  hash: string;
  quality: string;
  camera: {
    width: number;
    height: number;
  };
  processing: {
    floor: number[];
  };
  stage: string;
  status: string;
  updated_at: string;
  created_at: string;
}

export interface Description {
  title: string;
  description: string;
  uuid: string;
}

export interface PostDesignPayload {
  furniture_type: string;
  structure: any;
  user_created?: boolean;
  stage?: string;
}

export interface PostLinkResponse {
  parent_uuid: string;
  child_uuid: string;
}

export type DesignGraphPath = Array<{
  sections: Section[];
  // Only essential info from Option
  // (what is needed to apply a path).
  option: {
    action: Option['action'];
    group: Option['group'];
    type: Option['type'];
    value: Option['value'];
  };
}>;

export interface PostLinkPayload {
  furniture_type: string;
  parent_structure: any;
  child_structure: any;
  path: DesignGraphPath;
  user_session?: string;
  user_id?: number;
}

export type GetBreadcrumbsByUuidResponse = {
  title: string;
  url: string;
}[];

export function priceCalculateByStructure(
  countryCode: string,
  furniture_type: string,
  compressedStructure: string,
  quantity?: number,
  with_shipping_details?: boolean,
  assembly?: boolean,
  explanation?: boolean,
  workshop?: boolean,
  baseboard_cut?: boolean
): Promise<DesignPriceResponse> {
  const searchParams: DesignPriceRequest = {
    furniture_type,
    compressedStructure,
    quantity,
    with_shipping_details,
    explanation,
    assembly,
    workshop,
    baseboard_cut,
  };
  const params = formatSearchParams(searchParams);
  return FetchAPI.get(
    `${apiURL}/prices/calculate/${countryCode}?${new URLSearchParams(
      params
    ).toString()}`
  );
}

function formatSearchParams(searchParams: object): string[][] {
  type searchParamsKeys = keyof typeof searchParams;
  const params: string[][] = [];
  Object.keys(searchParams).forEach((key: searchParamsKeys) => {
    if (searchParams[key] !== undefined) {
      if (typeof searchParams[key] === 'string') {
        params.push([key, searchParams[key] as string]);
      } else {
        params.push([key, JSON.stringify(searchParams[key])]);
      }
    }
  });
  return params;
}

export function descriptionsByUuid(
  countryCode: string,
  uuid: string,
  languageCode: string
): Promise<Description[]> {
  return FetchAPI.get(
    `${apiURL}/${uuid}/descriptions/${countryCode}/${languageCode}`
  );
}

export function getRelatedShapesByUuid(
  countryCode: string,
  uuid: string
): Promise<Design[]> {
  return FetchAPI.get(`${apiURL}/${countryCode}/${uuid}/related/shapes`);
}

export function getRelatedColorsByUuid(
  countryCode: string,
  uuid: string
): Promise<Design[]> {
  return FetchAPI.get(`${apiURL}/${countryCode}/${uuid}/related/colors`);
}

export function getRelatedProductsByUuid(
  countryCode: string,
  uuid: string
): Promise<Design[]> {
  return FetchAPI.get(`${apiURL}/${countryCode}/${uuid}/related/products`);
}

export function designInfo(
  countryCode: string,
  furnitureType: string,
  compressedStructure: string
): Promise<Design> {
  const searchParams: DesignInfoRequest = {
    furniture_type: furnitureType,
    compressedStructure,
  };
  const params = formatSearchParams(searchParams);
  return FetchAPI.get(
    `${apiURL}/info/${countryCode}?${new URLSearchParams(params).toString()}`
  );
}

export function designFiltersRanges(
  countryCode: string,
  furnitureGroup: string
): Promise<any> {
  const searchParams: DesignFiltersRangeRequest = {
    country_code: countryCode,
    furniture_group: furnitureGroup,
  };
  const params = formatSearchParams(searchParams);
  return FetchAPI.get(
    `${apiURL}/filters/ranges?${new URLSearchParams(params).toString()}`
  );
}

export function designsFiltered(queryParams: any): Promise<any> {
  const searchParams: DesignFiltersRequest = queryParams;
  const params = formatSearchParams(searchParams);
  return FetchAPI.get(
    `${apiURL}/filters?${new URLSearchParams(params).toString()}`
  );
}

export function getDesignsByFilter(
  countryCode: string,
  queryParams: any
): Promise<any> {
  const params = formatSearchParams(queryParams);
  return FetchAPI.get(
    `${apiURL}/filter/${countryCode}/?${new URLSearchParams(params).toString()}`
  );
}

export function getDesigns(
  countryCode: string,
  queryParams: any
): Promise<any> {
  const params = formatSearchParams(queryParams);
  return FetchAPI.get(
    `${apiURL}/countries/${countryCode}/?${new URLSearchParams(
      params
    ).toString()}`
  );
}

export function getDesignsByUuids(
  countryCode: string,
  uuids: string,
  queryParams: any
): Promise<Design[]> {
  const params = formatSearchParams(queryParams);
  return FetchAPI.get(
    `${apiURL}/${countryCode}/${uuids}?${new URLSearchParams(
      params
    ).toString()}`
  );
}

export function getDesignsByCategory(
  category: string,
  countryCode: string,
  queryParams: Record<string, unknown> = {}
): Promise<{ designs: Design[] }> {
  const params = formatSearchParams({
    ...queryParams,
    is_favorite: true,
    country_code: countryCode,
    category,
    page_size: 25,
    sort_by: '+rank_c',
  });
  const url = `${apiURL}/catalog?${new URLSearchParams(params).toString()}`;

  return FetchAPI.get(url);
}

export function postLink(data: PostLinkPayload): Promise<PostLinkResponse> {
  return FetchAPI.post(`${apiURL}/links`, data);
}

export function postDesign(data: PostDesignPayload): Promise<Design> {
  return FetchAPI.post(`${apiURL}`, data);
}
