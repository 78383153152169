// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

type PageSlicePayload = {
  locale: string;
  pathname: string;
  data: {
    page?: { [key: string]: any };
    statusCode?: number;
    redirectTo?: string;
  };
};

type PageSliceState = {
  [key: string]: {
    content?: { [key: string]: any } | null;
    statusCode?: number | null;
    redirectTo?: string;
  };
};

type PageKey = {
  locale: string;
  pathname: string;
};

const initialState: PageSliceState = {};

export const pagesSlice = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<PageSlicePayload>) {
      const {
        locale,
        pathname,
        data: { page, statusCode, redirectTo },
      } = action.payload;

      const key = getPageSliceKey({
        locale,
        pathname,
      });

      return {
        ...state,
        [key]: {
          content: page || null,
          statusCode: statusCode || null,
          redirectTo: redirectTo,
        },
      };
    },
  },
});

export const { setPage } = pagesSlice.actions;

export function getPageContentByKeyFromReduxState<T>(
  state: RootState,
  key: PageKey
) {
  const sliceKey = getPageSliceKey(key);
  return state.pages[sliceKey]?.content as T | null;
}

export function getStatusCodeByKeyFromReduxState(
  state: RootState,
  key: PageKey
) {
  const sliceKey = getPageSliceKey(key);
  return state.pages[sliceKey]?.statusCode;
}

export function getRedirectUrlByKeyFromReduxState(
  state: RootState,
  key: PageKey
) {
  const sliceKey = getPageSliceKey(key);
  return state.pages[sliceKey]?.redirectTo;
}

export function getPageSliceKey({ locale, pathname }: PageKey) {
  return `${locale}-${pathname}`;
}
