import { Component } from 'react';
import { Subscription } from 'rxjs';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { getPageContentByKeyFromReduxState } from 'mycs/shared/state/slices/pageSlice';
import { RelativeUrlService } from 'mycs/shared/services/RelativeUrlService';
import { useAppSelector } from 'mycs/shared/state/store';
import { useDevice } from 'mycs/router/DeviceContext';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import Button from 'mycs/shared/components/Button/Button';
import cfg from 'mycs/config';
import ContentfulService, {
  CfChatData,
} from 'mycs/shared/services/ContentfulService/ContentfulService';
import Help from 'mycs/shared/components/Help/Help';
import HelpStore from 'mycs/shared/stores/HelpStore/HelpStore';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import LiveChatService from 'mycs/shared/services/LiveChatService/LiveChatService';
import LocalStorageUtils from 'mycs/shared/utilities/LocalStorageUtils/LocalStorageUtils';
import LocationUtils from 'mycs/shared/utilities/LocationUtils/LocationUtils';
import Logger from 'mycs/shared/services/Logger';
import Sticky from 'mycs/shared/components/Sticky/Sticky';
import WindowLocationUtils from 'mycs/shared/utilities/WindowLocationUtils/WindowLocationUtils';

import styles from './BottomMenu.scss';

type Props = {};
type State = {
  isVisible: boolean;
  showHelp: boolean;
  email: string;
  phoneNumber: string;
};

export default function BottomMenu(props: Props) {
  const { locale, countryCode } = useLocale();
  const { pathname } = useLocation();
  const { hasPhoneDimensions } = useDevice();
  const { hasTabletDimensions } = useDevice();

  const pageData = useAppSelector((state) =>
    getPageContentByKeyFromReduxState<any>(state, {
      locale,
      pathname,
    })
  );

  return (
    <InnerBottomMenu
      {...props}
      locale={locale}
      countryCode={countryCode}
      pathname={pathname}
      hasPhoneDimensions={hasPhoneDimensions}
      hasTabletDimensions={hasTabletDimensions}
      pageData={pageData}
    />
  );
}

type InnerProps = Props & {
  locale: string;
  countryCode: string;
  pathname: string;
  hasPhoneDimensions: boolean;
  hasTabletDimensions: boolean;
  pageData: any;
};

export class InnerBottomMenu extends Component<InnerProps, State> {
  liveChatGroupId: number = cfg.livechat.groups[this.props.locale];
  subscriptions: Subscription[] = [];

  constructor(props: InnerProps) {
    super(props);

    this.state = {
      isVisible: this.checkIfVisible(props.pathname),
      showHelp: HelpStore.get('showHelp'),
      email: '',
      phoneNumber: '',
    };
  }

  /**
   * Check if the path corresponds to the configurators
   */
  isConfigurator(path?: string): boolean {
    return RelativeUrlService.isConfiguratorPage(
      path || this.props.pathname,
      this.props.locale
    );
  }

  /**
   * Check if the menu should be displayed
   */
  checkIfVisible(path: string): boolean {
    return !this.isConfigurator(path);
  }

  /**
   * Toggle the help panel
   *
   */
  toggleHelp = () => {
    HelpStore.toggle();
  };

  /**
   * Show the help panel + chat tab
   */
  showChat = () => {
    HelpStore.show();
    HelpStore.showChat();
  };

  /**
   * Set the visibility of the menu
   */
  setVisibility = () => {
    this.setState({
      isVisible: this.checkIfVisible(this.props.pathname),
    });
  };

  /**
   * Check whether there are any chat agents online
   * for the current language
   *
   * @returns {boolean}
   */
  async areAgentsOnline() {
    const agents = await LiveChatService.getAvailableAgents(
      this.liveChatGroupId
    );

    return agents.length > 0;
  }

  /**
   * Check whether to automatically display the chat window
   *
   */
  async autoDisplayChat(chatConfig: CfChatData) {
    try {
      if (chatConfig.autoDisplayAfter > 0) {
        // Don't trigger more often than once every 30 mins (chatConfig.retriggerDelay)
        const cookieHelpPanel = LocalStorageUtils.get('HelpPanel');
        const now = Math.round(new Date().getTime() / 1000);
        const isOldEnough =
          !cookieHelpPanel ||
          (cookieHelpPanel.lastAutoDisplayChat &&
            now - cookieHelpPanel.lastAutoDisplayChat >
              chatConfig.retriggerDelay);

        const pageType = LocationUtils.getPageType(
          this.props.locale,
          this.props.pathname,
          this.props.pageData
        );

        // Only trigger on certain pages
        const isValidUrl = pageType
          ? chatConfig.validUrls.includes(pageType)
          : false;

        if (isOldEnough && isValidUrl) {
          setTimeout(async () => {
            const areAgentsOnline = await this.areAgentsOnline();

            if (areAgentsOnline) {
              this.showChat();
              LocalStorageUtils.set('HelpPanel', { lastAutoDisplayChat: now });
            }
          }, chatConfig.autoDisplayAfter);
        }
      }
    } catch (err) {
      Logger.error('Error with auto chat display', err);
    }
  }

  componentDidUpdate(prevProps: InnerProps) {
    if (
      prevProps.pathname !== this.props.pathname ||
      prevProps.pageData !== this.props.pageData
    ) {
      this.setVisibility();
    }
  }

  /**
   * Setup listeners and initialize the help panel on mounting
   */
  async componentDidMount() {
    ContentfulService.getHelpConfig(this.props.locale, this.props.countryCode)
      .then((data) => {
        const { chat, email, phoneNumber } = data;

        this.setState({ phoneNumber: phoneNumber, email });
        if (LocalStorageUtils.hasLocalStorageSupport()) {
          this.autoDisplayChat(chat);
        }

        this.subscriptions.push(
          WindowLocationUtils.onUrlChange(() => {
            this.setVisibility();

            if (LocalStorageUtils.hasLocalStorageSupport()) {
              this.autoDisplayChat(chat);
            }
          })
        );
      })
      .catch((err) => {
        Logger.error('Error with fetching helpConfig from Contentful: ', err);
      });

    this.subscriptions.push(
      HelpStore.subscribe((ev) => {
        this.setState({ showHelp: ev.showHelp || false });
      })
    );
  }

  /**
   * Dispose of subscriptions
   *
   */
  componentWillUnmount() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  render() {
    if (!this.state.isVisible) return null;

    const mobileButtonId = 'mobileHelpToggleButton';
    const desktopButtonId = 'desktopHelpToggleButton';
    const { hasPhoneDimensions, hasTabletDimensions } = this.props;
    return (
      <Sticky
        dockOnScrollUp
        className={classNames(styles.container, {
          [styles.isOpenHelp]: this.state.showHelp,
          [styles.isCentered]: this.isConfigurator() && hasTabletDimensions,
        })}
      >
        <div>
          {hasPhoneDimensions ? (
            <Button
              id={mobileButtonId}
              className={styles.mobileToggle}
              onClick={this.toggleHelp}
              isSecondaryCta
              iconName="mobile-toolbar/contact-us"
            />
          ) : (
            <Button
              id={desktopButtonId}
              className={styles.desktopToggle}
              onClick={this.toggleHelp}
              text={I18nUtils.localize(this.props.locale, 'Talk to us')}
              isSecondaryCta
            />
          )}
          <Help
            onClose={this.toggleHelp}
            isVisible={this.state.showHelp}
            phoneNumber={this.state.phoneNumber}
            email={this.state.email}
            elementIdsWithinScope={[mobileButtonId, desktopButtonId]}
          />
        </div>
      </Sticky>
    );
  }
}
