/**
 * Render structure class
 *
 * @class StructureRenderer
 */
export default class RendererService {
  /**
   * Create a new container
   *
   * @param {any} type
   * @param {any} props
   * @param {array} children
   * @returns {any}
   */
  static createContainer = (type, props, ...children) => {
    const _props = props || {};
    let _children = [];

    if (children instanceof Array) {
      children.forEach((child) => {
        if (child instanceof Array) {
          child
            .filter((c) => typeof c === 'object')
            .forEach((c) => _children.push(c));
          // eslint-disable-next-line no-empty
        } else if (typeof child === 'boolean' && child === false) {
        } else if (typeof child === 'object' && child !== null) {
          _children.push(child);
        }
      });
    }

    // render children that can be rendered
    _children = _children.map((child) => {
      if (child.render) {
        return child.render();
      }
      return child;
    });

    // Create a component
    if (
      typeof type === 'function' &&
      typeof type.prototype.render === 'function'
    ) {
      // Class component
      const container = new type(_props, _children);
      return container.render();
    } else if (
      typeof type === 'function' &&
      typeof type.prototype.render !== 'function'
    ) {
      // Function component
      return type({ ..._props, children: _children });
    } else {
      // Create a container
      const container = {
        container: type,
        props: _props,
        children: _children,
      };
      return container;
    }
  };
}

declare global {
  // The namespace should match the class name used for transform our JSX tag
  namespace RendererService {
    namespace JSX {
      interface IntrinsicElements {
        [key: string]: any;
      }
      interface ElementClass {
        render: any;
      }
    }
  }
}
