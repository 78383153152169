import defaultsDeep from 'lodash/defaultsDeep';
import defaultConfig from './config.default';

export default defaultsDeep(
  {
    environment: 'dev',

    API: {
      assetApi: {
        protocol: 'https://',
        host: 'api.mycs.ninja',
        // ALB is not configured for this path, so /api could not be used here.
        path: '/gw/master/monorepo/assets',
      },

      // Target local API
      // designApi: {
      //   protocol: 'http://',
      //   host: 'localhost:3000',
      //   path: '/api/designs',
      //   explanation: true,
      // },

      // Target custom container
      // designApi: {
      //   protocol: 'https://',
      //   host: 'api.mycs.ninja',
      //   path: '/gw/MY_BRANCH_NAME/designs',
      //   explanation: true,
      // },

      designApi: {
        protocol: 'https://',
        host: 'api.mycs.ninja',
        path: '/api/designs',
        explanation: true,
      },

      imageApi: {
        protocol: 'https://',
        host: 'api.mycs.ninja',
        path: '/api/images',
      },

      livechatApi: {
        protocol: 'https://',
        host: 'lapi.mycs.ninja',
        path: '/livechat-api',
      },

      translationApi: {
        protocol: 'https://',
        host: 'mycs.ninja',
        path: '/assets/translations_',
      },

      userApi: {
        protocol: 'https://',
        host: 'api.mycs.ninja',
        path: '/api/users',
      },

      reviewApi: {
        protocol: 'https://',
        host: 'api.mycs.ninja',
        path: '/api/reviews',
      },

      reportingApi: {
        protocol: 'https://',
        host: 'lapi.mycs.ninja',
        path: '/reporting-api/report',
      },

      geolocationApi: {
        protocol: 'https://',
        host: 'lapi.mycs.ninja',
        path: '/geolocation/location',
      },

      timestampApi: {
        protocol: 'https://',
        host: 'mycs.ninja',
        path: '/timestamp',
      },

      manualApi: {
        protocol: 'http://',
        host: 'cluster.mycs.ninja',
        path: '/manual-api',
      },

      checkoutApi: {
        protocol: 'https://',
        host: 'cluster.mycs.ninja',
        path: '/api/checkout',
      },

      wmsApi: {
        protocol: 'https://',
        host: 'cluster.mycs.ninja',
        path: '/api/wms',
      },

      unrealBBoxes: {
        protocol: 'https://',
        host: 'api.rendering.mycs.ninja',
        path: '/bboxes',
      },

      // Uncomment locally for dev to override config from Contentful
      // renderingApi: {
      //   protocol: 'https://',
      //   host: 'api.rendering.mycs.ninja',
      //   path: '',
      // },
    },

    // Preview API for Contentful (shows draft entries)
    contentful: {
      host: 'xx.mycs.ninja',
      spaces: {
        frontend: {
          accessToken:
            '2ba364efaa37e174980a6edf49d53d589cbc50f2407553327ab7de4b55e3b29d',
        },
        internal: {
          accessToken:
            'a6c151195c866cb16f1e06b33d32be5aa0b526264d2e5cddb4dbcd14b2190922',
        },
      },
      imagesCacheHost: 'mycs.ninja',
      videosCacheHost: 'mycs.ninja',
      displayEditButton: true,
    },

    /**
     * GA4 Measurement ID
     */
    ga4MeasurementId: {
      de: 'G-ZF2BZJG1X4',
      fr: 'G-ZF2BZJG1X4',
      at: 'G-ZF2BZJG1X4',
      ch: 'G-ZF2BZJG1X4',
    },
  },
  defaultConfig
);
