import { Component, ErrorInfo } from 'react';
import Logger from 'mycs/shared/services/Logger';
import Fallback from 'mycs/landing-page/components/ErrorBoundary/Fallback';

interface Props {
  children: any;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: Boolean(error) };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Logger.error(`[ErrorBoundary] ${error} ${JSON.stringify(errorInfo)}`);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <Fallback />;
    }

    return children;
  }
}

export default ErrorBoundary;
