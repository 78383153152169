import { AnchorHTMLAttributes } from 'react';

import { useTrackEvent } from 'mycs/shared/components/TrackEvent/useTrackEvent';
import PathUtils from 'mycs/shared/utilities/PathUtils/PathUtils';
import { useLocale } from 'mycs/shared/state/LocaleContext';

type Props = {
  // Optional text inside the link
  text?: string;
  // An optional prop defining the name of the tracking event
  trackName?: string;
  // Optional tracking event payload
  trackData?: object;
  href?: string;
  target?: 'none' | '_self' | '_top' | '_blank';
  [key: `data-${string}`]: unknown;
} & Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>;

export default function Alink({ trackName, trackData, text, ...props }: Props) {
  const { baseUrl } = useLocale();
  const linkProps = Object.assign({}, props);
  linkProps.children = props.children || text;

  linkProps.href = getHref(linkProps.href, baseUrl);

  const { wrapProps } = useTrackEvent({
    name: trackName,
    data: trackData,
    label: text,
  });

  return <a {...wrapProps(linkProps)} />;
}

function getHref(href: string | undefined, baseUrl: string) {
  if (!href) {
    return href;
  }

  const relativeHref = PathUtils.toRelativePath(href);
  const isRelativeHref = relativeHref === href;

  if (!isRelativeHref) {
    const url = new URL(href);
    const isInternalLink = /^[a-z]{2}\.mycs\.[^\./]+$/.test(url.hostname);
    const isBlogUrl = /^\/blog/.test(relativeHref);
    const isSameOrigin = url.origin === baseUrl;

    if (!isInternalLink || isBlogUrl || !isSameOrigin) {
      return href;
    }
  }

  //remove the origin from all internal url
  return relativeHref;
}
