import { Cart, postCart } from 'mycs/api/CheckoutAPI';
import {
  CartStoragePayload,
  toCartStoragePayload,
} from 'mycs/services/CartService';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import useSWR from 'swr';

const key = 'cart:v1';

export function useCart() {
  const { countryCode, languageCode } = useLocale();

  const {
    data: cart,
    mutate,
    isValidating: isValidatingCart,
  } = useSWR(key, () => {
    // TODO The cart should be persisted into Checkout API (set up Redis?).
    // The cart ID should be persisted into (server-side?) session state.
    const storageItem = localStorage.getItem(key);
    if (!storageItem) {
      return;
    }

    const { lineItems, couponCode }: CartStoragePayload =
      JSON.parse(storageItem);

    return postCart({
      countryCode,
      couponCode,
      lineItems,
      meta: {
        language: languageCode,
      },
    });
  });

  function setCart(cart: Cart | undefined) {
    if (!cart || !cart.lineItems?.length) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(toCartStoragePayload(cart)));
    }

    mutate();
  }

  return {
    cart,
    setCart,
    isValidatingCart,
  };
}
