import last from 'lodash/last';
import classNames from 'classnames';

import { noop } from 'mycs/shared/utilities/GeneralUtils/GeneralUtils';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import { useTrackEvent } from '../TrackEvent/useTrackEvent';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import IconColor from 'mycs/shared/components/IconColor/IconColor';
import Loader from 'mycs/shared/components/Loader/Loader';

import styles from './Button.scss';

type Props = {
  id?: string;
  className?: string;
  counter?: number;
  href?: string;
  iconClassName?: string;
  iconContainerClass?: string;
  iconName?: string;
  iconContent?: string;
  iconSmall?: boolean;
  iconAfter?: boolean;
  isActive?: boolean;
  isRound?: boolean;
  isBigCircle?: boolean;
  isBigRectangle?: boolean;
  isBigRectangleFilled?: boolean;
  isBigSquare?: boolean;
  isBigSquareCta?: boolean;
  isBigPrimarySquare?: boolean;
  isBigSecondarySquare?: boolean;
  isBigSquareFilled?: boolean;
  isColorButton?: boolean;
  isDropShadow?: boolean;
  isPrimaryCta?: boolean;
  isPrimaryCtaFallback?: boolean;
  isSecondaryCta?: boolean;
  isSecondaryLightCta?: boolean;
  isSecondaryCtaFallback?: boolean;
  isThirdLevelCta?: boolean;
  isThirdLevelCtaFallback?: boolean;
  isThirdLevelCtaFallbackInverse?: boolean;
  isThirdLevelCtaInverse?: boolean;
  isThirdLevelPrimaryCta?: boolean;
  isFlatPrimaryCta?: boolean;
  isFlatPrimaryCtaInverse?: boolean;
  isFlatSecondaryCta?: boolean;
  isOvalBlackWhiteCta?: boolean;
  isFullWidthMobile?: boolean;
  isBlackWhite?: boolean;
  isDisabled?: boolean;
  isFullHeight?: boolean;
  isFullWidth?: boolean;
  isInline?: boolean;
  isInlineLeft?: boolean;
  isLoading?: boolean;
  isOpen?: boolean;
  isSelected?: boolean;
  isSmallCircle?: boolean;
  isSmallSquare?: boolean;
  isSmallSquareCta?: boolean;
  isSplitButtonLeft?: boolean;
  isCentered?: boolean;
  isWithBottomMargin?: boolean;
  isSplitButtonRight?: boolean;
  isIconCta?: boolean;
  isExpandingIconCta?: boolean;
  itemScope?: boolean;
  itemType?: string;
  nativeProps?: object;
  spinnerClassName?: string;
  target?: 'none' | '_self' | '_blank';
  text?: string;
  trackLabel?: string;
  trackName?: string;
  trackData?: object;
  transform?: any;
  onClick?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ...rest: any
  ) => void;
  onChange?: () => void;
  onDoubleClick?: () => void;
  onMouseDown?: () => void;
  onMouseEnter?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ...rest: any
  ) => void;
  onMouseLeave?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ...rest: any
  ) => void;
  onMouseMove?: () => void;
  onMouseOut?: () => void;
  onMouseOver?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ...rest: any
  ) => void;
  onMouseUp?: () => void;
  children?: React.ReactNode;
  elementAfterLabel?: React.ReactNode;
  /**
   * FIXME: a few components are using the props below but they are not being
   * used by this component
   */
  labelID?: any; //Configurator depends on it
  centerTag?: any; //Configurator depends on it
  inline?: any;
};

export default function Button({
  children,
  className = '',
  counter,
  elementAfterLabel,
  href,
  iconAfter = false,
  iconClassName,
  iconContainerClass,
  iconContent,
  iconName,
  iconSmall = false,
  id,
  isActive = false,
  isBigCircle = false,
  isBigPrimarySquare = false,
  isBigRectangle = false,
  isBigRectangleFilled = false,
  isBigSecondarySquare = false,
  isBigSquare = false,
  isBigSquareCta = false,
  isBigSquareFilled = false,
  isBlackWhite = false,
  isCentered = false,
  isColorButton = false,
  isDisabled = false,
  isDropShadow = false,
  isExpandingIconCta = false,
  isFlatPrimaryCta = false,
  isFlatPrimaryCtaInverse = false,
  isFlatSecondaryCta = false,
  isFullHeight = false,
  isFullWidth = false,
  isFullWidthMobile = false,
  isIconCta = false,
  isInline = false,
  isInlineLeft = false,
  isLoading = false,
  isOvalBlackWhiteCta = false,
  isPrimaryCta = false,
  isPrimaryCtaFallback = false,
  isRound = false,
  isSecondaryCta = false,
  isSecondaryCtaFallback = false,
  isSecondaryLightCta = false,
  isSelected = false,
  isSmallCircle = false,
  isSmallSquare = false,
  isSmallSquareCta = false,
  isSplitButtonLeft = false,
  isSplitButtonRight = false,
  isThirdLevelCta = false,
  isThirdLevelCtaFallback = false,
  isThirdLevelCtaFallbackInverse = false,
  isThirdLevelCtaInverse = false,
  isThirdLevelPrimaryCta = false,
  isWithBottomMargin = false,
  itemScope,
  itemType,
  nativeProps,
  onClick = noop,
  onMouseEnter = noop,
  onMouseLeave = noop,
  onMouseOver = noop,
  spinnerClassName = '',
  target,
  text,
  trackData,
  trackLabel,
  trackName,
}: Props) {
  const { locale } = useLocale();
  const trackEvent = useTrackEvent({
    label: parseTrackLabel(text, iconName, trackLabel),
    name: trackName,
    data: trackData,
  });

  const eventHandler = (event: any, ...rest: any) => {
    switch (event.type) {
      case 'click':
        onClick?.(event, ...rest);
        break;
      case 'mouseover':
        onMouseOver?.(event, ...rest);
        break;
      case 'mouseleave':
        onMouseLeave?.(event, ...rest);
        break;
      case 'mouseenter':
        onMouseEnter?.(event, ...rest);
        break;
    }
  };

  const buttonClass = classNames(styles.button, className, {
    [styles.centered]: isCentered,
    [styles.bottomMargin]: isWithBottomMargin,
    [styles.active]: isActive,
    [styles.round]: isRound,
    [styles.bigCircle]: isBigCircle,
    [styles.bigRectangle]: isBigRectangle,
    [styles.bigRectangleFilled]: isBigRectangleFilled,
    [styles.bigSquare]: isBigSquare,
    [styles.bigSquareCta]: isBigSquareCta,
    [styles.bigPrimarySquare]: isBigPrimarySquare,
    [styles.bigSecondarySquare]: isBigSecondarySquare,
    [styles.colorButton]: isColorButton,
    [styles.bigSquareFilled]: isBigSquareFilled,
    [styles.dropShadow]: isDropShadow,
    [styles.counter]: counter,
    [styles.primaryCta]: isPrimaryCta,
    [styles.ovalBlackWhiteCta]: isOvalBlackWhiteCta,
    [styles.primaryCtaFallback]: isPrimaryCtaFallback,
    [styles.secondaryCta]: isSecondaryCta,
    [styles.secondaryLightCta]: isSecondaryLightCta,
    [styles.secondaryCtaFallback]: isSecondaryCtaFallback,
    [styles.thirdLevelCta]: isThirdLevelCta,
    [styles.thirdLevelCtaFallback]: isThirdLevelCtaFallback,
    [styles.thirdLevelCtaFallbackInverse]: isThirdLevelCtaFallbackInverse,
    [styles.thirdLevelCtaInverse]: isThirdLevelCtaInverse,
    [styles.thirdLevelPrimaryCta]: isThirdLevelPrimaryCta,
    [styles.flatPrimaryCta]: isFlatPrimaryCta,
    [styles.flatPrimaryCtaInverse]: isFlatPrimaryCtaInverse,
    [styles.flatSecondaryCta]: isFlatSecondaryCta,
    [styles.blackWhite]: isBlackWhite,
    [styles.disabled]: isDisabled,
    [styles.fullHeight]: isFullHeight,
    [styles.fullWidth]: isFullWidth,
    [styles.iconCta]: isIconCta,
    [styles.expandingIconCta]: isExpandingIconCta,
    [styles.inline]: isInline,
    [styles.inlineLeft]: isInlineLeft,
    [styles.iconAfter]: iconAfter,
    [styles.loading]: isLoading,
    [styles.selected]: isSelected,
    [styles.smallCircle]: isSmallCircle,
    [styles.smallSquare]: isSmallSquare,
    [styles.smallSquareCta]: isSmallSquareCta,
    [styles.splitButtonLeft]: isSplitButtonLeft,
    [styles.splitButtonRight]: isSplitButtonRight,
    [styles.fullWidthMobile]: isFullWidthMobile,
  });
  const iconClass = classNames(
    iconClassName,
    iconSmall ? styles.iconSmall : styles.icon
  );
  const textClass = classNames(styles.button__text);
  const safeHtml = I18nUtils.localize(locale, text);

  const icon =
    iconName || iconContent ? (
      <IconColor
        iconName={iconName}
        iconContent={iconContent}
        isInline={isInline || isInlineLeft}
        className={iconClass}
        iconContainerClass={classNames(
          styles.iconContainer,
          iconContainerClass
        )}
      />
    ) : (
      ''
    );

  const ButtonTag = href ? 'a' : 'button';

  return (
    <ButtonTag
      id={id}
      className={buttonClass}
      href={href}
      onClick={trackEvent.wrapOnClick(eventHandler)}
      onMouseOver={eventHandler}
      onMouseLeave={eventHandler}
      onMouseEnter={eventHandler}
      itemScope={itemScope}
      itemType={itemType}
      data-value={counter}
      target={target}
      {...nativeProps}
    >
      {!iconAfter ? icon : ''}

      {text && !children ? (
        <span
          className={textClass}
          dangerouslySetInnerHTML={{ __html: safeHtml }}
        />
      ) : (
        ''
      )}

      {children ? <span className={textClass}>{children}</span> : ''}

      {iconAfter ? icon : ''}

      {elementAfterLabel ? (
        <div className={styles.elementAfterLabelContainer}>
          {elementAfterLabel}
        </div>
      ) : (
        ''
      )}

      {isLoading ? (
        <div className={spinnerClassName}>
          <Loader
            isSmall
            isSmallInverse={isThirdLevelPrimaryCta || isThirdLevelCtaInverse}
          />
        </div>
      ) : (
        ''
      )}
    </ButtonTag>
  );
}

function parseTrackLabel(text: any, iconName?: string, trackLabel?: any) {
  if (trackLabel) {
    return trackLabel;
  }

  if (iconName) {
    let baseIconName = iconName;
    if (/^(https?:)?\/\//.test(iconName)) {
      // @ts-ignore
      baseIconName = last(iconName.split('/'));
    }
    return baseIconName.split('.')[0];
  }

  return text;
}
