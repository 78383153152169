import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { MycsURL } from '@mycs/edge-lambdas';

import DictionaryUtils from 'mycs/shared/utilities/DictionaryUtils/DictionaryUtils';

import cfg from 'mycs/config';

type ParsedPath = {
  steps: string[];
  search: {
    [key: string]: string | null;
  };
  anchor: string | null;
};

/**
 * Path Provider
 */
export default class PathUtils {
  //FIXME: test if it is a valid furniture type ?
  static getPathFurnitureType(url: string, locale: string): string {
    const { steps } = this.parse(url, locale);
    return steps[0];
  }

  //FIXME: test if it is a valid uuid?
  static getPathUuid(url: string, locale: string): string | undefined {
    const { steps } = this.parse(url, locale);
    return steps[1];
  }

  static updatePathUUID(url: string, uuid: string, locale: string): string {
    const parsed = this.parse(url, locale);
    parsed.steps[1] = uuid;
    return this.format(parsed, locale);
  }

  static toRelativePath(url: string): string {
    return url.replace(/^(https?:\/\/[^/]+)?/i, '');
  }

  static removeSearch(url: string): string {
    return url.split('?')[0];
  }

  /**
   * Parses the path into parts delimited by slash.
   */
  static parse(url: string, locale: string): ParsedPath {
    const relativeUrl = this.toRelativePath(url);
    const urlObject = new MycsURL(relativeUrl, 'https://mycs.com');
    const { pathname, hash, searchParams } = urlObject;

    let pathSegments = pathname.split('/').filter(Boolean);

    const lang = pathSegments[0]?.length === 2 ? pathSegments[0] : undefined;

    // Remove the lang from the path steps ([ 'fr', 'canapes' ] -> [ 'canapes' ])
    if (lang && lang in cfg.languages) {
      pathSegments.shift();
    }

    const parsed: ParsedPath = {
      steps: pathSegments ?? [],
      search: Object.fromEntries(searchParams),
      anchor: hash ? hash.slice(1) : null,
    };

    // Return the parsed path translated to English
    return this.translateParsed(parsed, locale, true);
  }

  /**
   * Translates and stringifies a parsed path object
   */
  private static format(
    parsedPath: ParsedPath,
    locale: string,
    reverse = false
  ): string {
    // Translate
    const parsed = this.translateParsed(parsedPath, locale, reverse);

    // Format path
    const urlLang = cfg.languageDirectories[locale] || '';
    let { steps } = parsed;

    // When there's a language, insert into the 1st place in the path steps
    if (urlLang) {
      if (steps.join('') === '') {
        // the '/' page
        steps = [urlLang];
      } else {
        steps = [urlLang, ...steps];
      }
    }

    let path = '/' + steps.join('/');

    // Format search
    if (!isEmpty(parsed.search)) {
      const pairs = map(parsed.search, (value, name) =>
        [name, value].filter(Boolean).join('=')
      );
      path += `?${pairs.join('&')}`;
    }

    // Format anchor
    if (parsed.anchor) {
      path += `#${parsed.anchor}`;
    }

    // Return the path
    return path;
  }

  /**
   * Parses and translates URL
   */
  static translate(path: string, locale: string): string {
    return this.format(this.parse(path, locale), locale);
  }

  /**
   * Parses and translates URL to EN
   */
  static translateReverse(path: string, locale: string) {
    return this.format(this.parse(path, locale), locale, true);
  }

  /**
   * Translate a path of the URL
   */
  private static translateWord(
    word: string,
    locale: string,
    reverse = false
  ): string {
    if (!word) {
      return word;
    }

    const translate = (word: string) =>
      word ? DictionaryUtils.lookUp(word, locale, reverse) || word : word;

    word = String(word);
    let translated = translate(word);

    const SEP = '-';
    //if the whole word was not translated, try to translate each part of the word
    //but only if the word contains the separator
    if (translated === word && word.includes(SEP)) {
      translated = map(word.split(SEP), translate).join(SEP);
    }

    return translated || word;
  }

  /**
   * Translates a parsed path
   */
  private static translateParsed(
    parsed: any,
    locale: string,
    reverse = false
  ): ParsedPath {
    const _translate = (word: string) =>
      this.translateWord(word, locale, reverse);

    const result: ParsedPath = {
      steps: parsed.steps.map(_translate),
      search: {},
      anchor: null,
    };

    parsed.search &&
      Object.keys(parsed.search).forEach((key) => {
        const value = parsed.search[key];
        result.search[_translate(key)] = value ? _translate(value) : null;
      });

    if (parsed.anchor) {
      result.anchor = _translate(parsed.anchor);
    }

    return result;
  }
}
