// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { Design } from 'mycs/shared/services/DesignApiService/DesignApiService';
import { RootState } from '../store';

type DesignsSlicePayload = {
  locale: string;
  pathname: string;
  designs: Design[];
  category?: string;
  primaryColorGroup?: string;
};

type DesignsSliceState = {
  designs: {
    [uuid: string]: Design;
  };
  designIdsByUrl: {
    [key: string]: string[];
  };
  designIdsByCategory: {
    [category: string]: string[];
  };
};

type PageKey = {
  locale: string;
  pathname: string;
};

const initialState: DesignsSliceState = {
  designs: {},
  designIdsByUrl: {},
  designIdsByCategory: {},
};

const getCategoryKey = (
  category: string,
  { primaryColorGroup }: { primaryColorGroup?: string }
) => [category, primaryColorGroup].filter(Boolean).join(':');

export const designsSlice = createSlice({
  name: 'designs',
  initialState,
  reducers: {
    setDesignsData(
      state,
      {
        payload: { locale, pathname, category, designs, primaryColorGroup },
      }: PayloadAction<DesignsSlicePayload>
    ) {
      let nextDesigns: Record<string, Design> = { ...state.designs };
      let nextDesignIdsByUrl = { ...state.designIdsByUrl };
      let nextDesignIdsByCategory = { ...state.designIdsByCategory };

      const key = getPageSliceKey({
        locale,
        pathname,
      });

      const nextDesignIds = new Set(nextDesignIdsByUrl[key] || []);

      designs.forEach((design) => {
        nextDesigns[design.uuid] = design;
        nextDesignIds.add(design.uuid);
      });

      nextDesignIdsByUrl[key] = Array.from(nextDesignIds);

      if (category) {
        const categoryKey = getCategoryKey(category, { primaryColorGroup });
        nextDesignIdsByCategory[categoryKey] = designs.map(({ uuid }) => uuid);
      }

      return {
        designs: nextDesigns,
        designIdsByUrl: nextDesignIdsByUrl,
        designIdsByCategory: nextDesignIdsByCategory,
      };
    },
  },
});

export const { setDesignsData } = designsSlice.actions;

export function getDesignsFromReduxState(
  state: RootState,
  uuids?: string[],
  category?: string,
  { primaryColorGroup }: { primaryColorGroup?: string } = {}
): Design[] {
  const selectedUuids = category
    ? state.designs.designIdsByCategory[
        getCategoryKey(category, { primaryColorGroup })
      ]
    : uuids;

  if (!selectedUuids) {
    return [];
  }

  return selectedUuids
    .map((uuid) => state.designs.designs?.[uuid])
    .filter(Boolean);
}

export function getPageSliceKey({ locale, pathname }: PageKey) {
  return `${locale}-${pathname}`;
}

export function getDesignStateByRequest(
  state: RootState,
  { pathname, locale }: { pathname: string; locale: string }
) {
  const pageKey = getPageSliceKey({ locale, pathname });

  const filteredDesigns = state.designs.designIdsByUrl[pageKey]?.reduce(
    (acc, uuid) => ({
      ...acc,
      [uuid]: state.designs.designs[uuid],
    }),
    {}
  );

  const filteredDesignIdsByCategory = Object.entries(
    state.designs.designIdsByCategory
  ).reduce((acc, [category, uuids]) => {
    const filteredUuids = uuids.filter((uuid) =>
      state.designs.designIdsByUrl[pageKey]?.includes(uuid)
    );

    return {
      ...acc,
      [category]: filteredUuids,
    };
  }, {});

  return {
    designs: filteredDesigns,
    designIdsByCategory: filteredDesignIdsByCategory,
  };
}
