import type { CloudFrontHeaders } from 'aws-lambda';
import config from '../config.json';
import { getCountryCode } from './headers';

const validCountryCodes = [
  ...new Set(Object.values(config.SUPPORTED_COUNTRY_CODES)),
];

export class MycsURL extends URL {
  get domainParts() {
    return this.hostname.split('.');
  }

  get subdomain() {
    return this.domainParts.length >= 3
      ? this.domainParts.slice(0, -2).join('.')
      : '';
  }

  get countryCode() {
    const subdomainParts = this.subdomain.split('.');

    const countryCode = subdomainParts[0];

    //we handle www as an invalid country code
    return countryCode.length === 2 || countryCode === 'www' ? countryCode : '';
  }

  set countryCode(value: string) {
    if (this.countryCode) {
      //replace all existing country codes (e.g. ch., de.fr., www.) with the new one
      this.hostname = this.hostname.replace(
        /^([A-Za-z]{2}\.|www.)+/g,
        `${value}.`
      );
    } else {
      this.hostname = `${value}.${this.hostname}`;
    }
  }

  needsCountryRedirection(passthroughUrls: string[]) {
    //ignore passthrough urls
    if (new RegExp(passthroughUrls.join('|'), 'i').test(this.pathname)) {
      return false;
    }

    //missing country code
    if (this.domainParts.length <= 2) {
      return true;
    }

    //www or invalid country code
    if (
      this.domainParts.length === 3 &&
      (this.domainParts[0] === 'www' ||
        !validCountryCodes.includes(this.countryCode))
    ) {
      return true;
    }

    //branch deployments should not have less than 3 characters
    if (this.domainParts.length === 4 && this.domainParts[1].length < 3) {
      return true;
    }

    //definitely invalid domain
    if (this.domainParts.length > 4) {
      return true;
    }

    //valid domain
    return false;
  }

  redirectToCountry({
    headers,
    passthroughUrls,
  }: {
    headers: CloudFrontHeaders;
    passthroughUrls: string[];
  }) {
    // Redirect to country specific url
    if (this.needsCountryRedirection(passthroughUrls)) {
      //remove initial www. in order to keep the country code
      this.hostname = this.hostname.replace(/^www\./, '');
      this.countryCode = this.selectCountryCode(headers);

      if (this.pathname.endsWith('/index.html')) {
        this.pathname = '/';
      }
      console.log('Country redirect applied');
    }

    return {
      redirectTo: this.toString(),
      countryCode: this.countryCode,
    };
  }

  /**
   * Will prioritize existing country code over the country code from the headers
   */
  selectCountryCode(headers: CloudFrontHeaders) {
    if (!this.countryCode || !validCountryCodes.includes(this.countryCode)) {
      return getCountryCode(headers);
    }

    return this.countryCode;
  }

  shouldRemoveTrailingSlashes() {
    return (
      !/[/]blog[/]/.test(this.pathname) &&
      /[/][^/]+[/]([?]|$)/.test(this.pathname)
    );
  }

  removeTrailingSlashes() {
    if (this.shouldRemoveTrailingSlashes()) {
      this.pathname = this.pathname.replace(/[/](?=[?]|$)/, '');
      console.log('Removing trailing slashes');
    }

    return this.toString();
  }

  shouldRedirectToClosedStore() {
    return (
      (this.countryCode === 'uk' || this.countryCode === 'nl') &&
      this.pathname !== '/closed-store' &&
      !this.pathname.includes('.') // Not robots, not sitemap or any other asset.
    );
  }

  redirectToClosedStore() {
    if (this.shouldRedirectToClosedStore()) {
      this.pathname = '/closed-store';
      console.log('Redirecting to closed store');
    }

    return this.toString();
  }

  shouldHandleSwissShowroomSpelling() {
    return this.pathname === '/showroom-zurich' && this.countryCode !== 'fr';
  }

  /**
   * Special Showroom redirect logic due to spelling differences in the URL
   */
  redirectToSwissShowroom() {
    if (this.shouldHandleSwissShowroomSpelling()) {
      this.pathname = '/showroom-zuerich';
      console.log('Fixing Swiss showroom spelling');
    }

    return this.toString();
  }

  redirectIfListed(redirects: Record<string, string | undefined>[]) {
    const pathname = this.pathname;

    let shouldRedirectTo;

    for (let i = 0; i < redirects.length; i++) {
      const toRedirectUrl = Object.keys(redirects[i])[0];
      const regexRule = new RegExp(toRedirectUrl, 'i');

      if (toRedirectUrl && regexRule.test(pathname)) {
        let redirectTo = pathname.replace(
          regexRule,
          (redirects[i] as any)[toRedirectUrl]
        );

        shouldRedirectTo = redirectTo;
        break;
      }
    }

    if (shouldRedirectTo) {
      this.pathname = shouldRedirectTo;
      console.log('Applying redirect rule from config file');
    }

    return this.toString();
  }
}
