// Config
import pyllowConfig from '../config/pyllow-config';
import pyllowOttomanConfig from '../config/pyllow-ottoman-config';
import joynConfig from '../config/joyn-config';
import joynOttomanConfig from '../config/joyn-ottoman-config';
import flayrConfig from '../config/flayr-config';
import flayrOttomanConfig from '../config/flayr-ottoman-config';
import shelfConfig from '../config/shelf-config';
import wardrobeConfig from '../config/wardrobe-config';
import cushionConfig from '../config/cushion-config';

// Helper to get the correct config file according to the furniture type
export function getConfig(furnitureType) {
  return {
    joyn: joynConfig,
    pyllow: pyllowConfig,
    flayr: flayrConfig,
    shelf: shelfConfig,
    cushion: cushionConfig,
    wardrobe: wardrobeConfig,
  }[furnitureType];
}

export function getConfigModification(furnitureType) {
  return {
    'joyn-ottoman': joynOttomanConfig,
    'pyllow-ottoman': pyllowOttomanConfig,
    'flayr-ottoman': flayrOttomanConfig,
  }[furnitureType];
}
