import LocalStorageUtils from 'mycs/shared/utilities/LocalStorageUtils/LocalStorageUtils';
import WindowLocationUtils from 'mycs/shared/utilities/WindowLocationUtils/WindowLocationUtils';
import cfg from 'mycs/config';

export type Mode = {
  text: string;
  cookie: string;
  showFlag: boolean;
  showUserInfo: boolean;
};

const isServer = Boolean(MYCS_IS_SSR_BUILD);

/**
 * Manage website flags lik mx-notrack, mx-showroom, and others
 */
export default class FlagModeService {
  static init() {
    //This service is not supposed to do anything on server side
    if (isServer) {
      return;
    }

    // Set cookie based on the url
    Object.keys(cfg.flagModes).forEach((key) => {
      const mode = cfg.flagModes[key];

      if (this.hasFlag(key)) {
        LocalStorageUtils.setCookie(mode.cookie, true);
      }
    });

    // Either notrack or showroom, not both
    if (FlagModeService.isNoTrack() && !this.hasFlag('showroom')) {
      this.unsetFlag(cfg.flagModes.showroom);
    }

    if (FlagModeService.isShowroom() && !this.hasFlag('notrack')) {
      this.unsetFlag(cfg.flagModes.notrack);
    }

    //After seting the flags clean the URL
    Object.keys(cfg.flagModes).forEach((key) => {
      const mode = cfg.flagModes[key];

      WindowLocationUtils.removeSearchParam(mode.cookie);
    });
  }

  static getFlags() {
    return Object.values(cfg.flagModes).filter(
      (mode) => LocalStorageUtils.getCookie(mode.cookie) && mode.showFlag
    );
  }

  /**
   * Check whether `mx-notrack` cookie has been set
   */
  static isNoTrack() {
    return isFlagEnabled(cfg.flagModes.notrack.cookie);
  }

  /**
   * Check whether `mx-showroom` cookie has been set
   */
  static isShowroom() {
    return isFlagEnabled(cfg.flagModes.showroom.cookie);
  }

  static hasFlag(flagName: string) {
    const search = WindowLocationUtils.getSearch();

    const mode = cfg.flagModes[flagName];

    return mode.cookie in search;
  }

  static unsetFlag(mode: Mode) {
    LocalStorageUtils.remove(mode.cookie);
    LocalStorageUtils.setCookie(mode.cookie, '', -1);
    WindowLocationUtils.removeSearchParam(mode.cookie);
  }
}

function isFlagEnabled(cookie: string) {
  return Boolean(
    LocalStorageUtils.get(cookie) || LocalStorageUtils.getCookie(cookie)
  );
}
