/* eslint-disable no-console */
import Bugsnag from '@bugsnag/js';
import Logger from 'mycs/shared/services/Logger';
import SessionService from 'mycs/shared/services/SessionService/SessionService';

const DISABLED = process.env.APP_STAGE === 'dev';

export function startBugsnag() {
  if (DISABLED) {
    return;
  }

  Bugsnag.start({
    apiKey: '63b8d54ea2f6d3ffff0917365d218eeb',
    appVersion: process.env.APP_VERSION || 'unknown',
    releaseStage: process.env.APP_STAGE || 'unknown',
    enabledReleaseStages: ['production'],
    collectUserIp: false, // GDPR compliance.
    generateAnonymousId: false, // GDPR compliance.
    user: {
      id: SessionService.getSID(),
    },
    onError: (event) => {
      const { errorMessage } = event.errors[0];

      if (Logger.shouldDiscardLog([errorMessage])) {
        // Prevents an event from being sent to Bugsnag.
        return false;
      }

      // Override the default grouping on the dashboard.
      event.groupingHash = errorMessage;

      return true;
    },
  });

  // NOTE Uncaught errors in console listeners
  // will be propagated to window.onerror
  // and passed to Bugsnag eventually.

  // console.error listener
  const _error = console.error;
  console.error = (...args: any[]) => {
    _error.apply(console, args);
    notifyBugsnag('error', ...args);
  };

  // console.warn listener
  const _warn = console.warn;
  console.warn = (...args: any[]) => {
    _warn.apply(console, args);
    notifyBugsnag('warning', ...args);
  };
}

/**
 * Build error from logs.
 * Preserve the original error stack trace, if possible.
 *
 * @param {any[]} logArgs
 * @returns {Error}
 */
function buildNotifiableErrorFromLogs(logArgs: any[]) {
  const errorMessage = logArgs
    .map((arg) => {
      if (typeof arg === 'string') {
        return arg;
      } else if (arg instanceof Error) {
        return arg.message;
      }
      return JSON.stringify(arg);
    })
    .join(' ');

  const error = logArgs.find((arg) => arg instanceof Error);
  if (error instanceof Error) {
    error.message = errorMessage;
    return error;
  }

  return errorMessage;
}

/**
 * By default, Bugsnag client listens to window.onerror
 * and window.fetch, reporting unhandled exceptions.
 * Handled errors should be reported additionally.
 */
export function notifyBugsnag(
  severity: 'error' | 'warning',
  ...logArgs: any[]
) {
  if (DISABLED) {
    return;
  }
  const error = buildNotifiableErrorFromLogs(logArgs);
  Bugsnag.notify(error, (event) => {
    event.severity = severity;
  });
}
