import { SofaFurnitureConfig } from './joyn-config';
import flayrconfig from './flayr-config';

const config: SofaFurnitureConfig = {
  ...flayrconfig,
  defaultDimensions: {
    ...flayrconfig.defaultDimensions,
    regular_depth: 600,
    height: 420,
  },
  hasAssembly: false,
  viewModes: {
    ...flayrconfig.viewModes,
  },
  isOttoman: true,
};

export default config;
