const modules = [
  {
    module: ['armrest_left', 'normal'],
    dimensions_expand: [
      [120, 240],
      [780, 1020, 1260],
      [560, 620],
    ],
    color: ['fabric'],
  },
  {
    module: ['armrest_right', 'normal'],
    dimensions_expand: [
      [120, 240],
      [780, 1020, 1260],
      [560, 620],
    ],
    color: ['fabric'],
  },
  {
    module: ['backrest', 'normal'],
    dimensions_expand: [[540, 780, 1020, 1260], [240], [560, 620]],
    color: ['fabric'],
  },
  {
    module: ['back_cushion', 'normal'],
    dimensions_expand: [[540, 780, 1020], [340], [440]],
    color: ['fabric'],
  },
  {
    module: ['back_roll_cushion', 'normal'],
    dimensions_expand: [[540, 780, 1020], [640], [530]],
    color: ['fabric'],
  },
  // Variation property for back and seat cushions?: [['var1'], ['var2'], ['var3']]
  {
    // NO SKUs exported for legs in the structure
    module: ['leg', 'normal'],
    dimensions: [[50, 50, 25]],
    color: ['black'],
  },
  // Bases
  // rotation possible: TO BE DEFINED...
  {
    module: ['base', 'normal'],
    dimensions: [
      // Base with a backrest * 2 for 240 heights…
      [540, 540, [180, 240]],
      [540, 780, [180, 240]], // OBJ: same shape [780, 540, [180, 240]]
      [540, 1020, [180, 240]], // OBJ: same shape [1020, 540, [180, 240]]
      [780, 540, [180, 240]],
      [780, 780, [180, 240]],
      [780, 1020, [180, 240]], // OBJ: same shape [1020, 780, [180, 240]]
      [1020, 540, [180, 240]],
      [1020, 780, [180, 240]],
      [1020, 1020, [180, 240]],

      // Base without a backrest (can be used as edge)
      // [540, 780, [180, 240]],
      // [540, 1020, [180, 240]],
      [540, 1260, [180, 240]], // OBJ: same shape - SKU: same shape [1260, 540, [180, 240]]
      // [780, 780, [180, 240]],
      // [780, 1020, [180, 240]],
      [780, 1260, [180, 240]],
      // [1020, 780, [180, 240]],
      // [1020, 1020, [180, 240]],
      [1260, 1260, [180, 240]], // logic dimension: [1020, 1260, [180, 240]],

      // Extensions in front of a sofas (lounge) with 24 armrest
      // [780, 540, [180, 240]],
      // [780, 780, [180, 240]],
      // [1020, 540, [180, 240]],
      // [1020, 1020, [180, 240]],
      [1260, 540, [180, 240]],
      [1260, 1260, [180, 240]],

      // Extensions in front of a sofa (lounge) with 12 armrest
      [660, 540, [180, 240]],
      [660, 660, [180, 240]],
      [900, 540, [180, 240]],
      [900, 900, [180, 240]],
      [1140, 540, [180, 240]],
      [1140, 1140, [180, 240]],

      // Ottoman
      // [540, 780, [180, 240]], // 1st release
      // [540, 1020, [180, 240]],
      // [540, 1260, [180, 240]],
      // [780, 780, [180, 240]],
      // [780, 1020, [180, 240]],
      // [780, 1260, [180, 240]],
      // [1020, 780, [180, 240]],
      // [1020, 1020, [180, 240]],
      // [1260, 1260, [180, 240]],

      // 2nd release (UX limitation for now)
      // [660, 540, [180, 240]],
      // [660, 660, [180, 240]],
      // [900, 540, [180, 240]],
      // [900, 900, [180, 240]],
      // [1140, 540, [180, 240]],
      // [1140, 1140, [180, 240]],
    ],
    color: ['fabric'],
  },
  {
    // Use the same shape as ‘base’
    module: ['storage_base', 'normal'],
    dimensions: [
      // Base without a backrest (can be used as edge)
      [780, 780, [180, 240]],
      [1020, 1020, [180, 240]],
      [1260, 1260, [180, 240]], // logic dimension: [1020, 1260, [180, 240]],

      // Extensions in front of a sofas (lounge) with 12 armrest
      [660, 660, [180, 240]],
      [900, 900, [180, 240]],
      [1140, 1140, [180, 240]],

      // 2nd release (UX limitation for now)
      // [540, 540, [180, 240]],
    ],
    color: ['fabric'],
  },
  // Seating cushions
  // Use one height for all seating cushion
  // [ ] height TO BE DEFINED by @Alex3D…
  // All seating cushion have + 1 cm on all sides (length, width)
  // 2nd release:
  // - considered to use unique height by seating cushions
  //
  {
    module: ['seating_cushion', 'normal'],
    dimensions: [
      // Base with a backrest
      [540, 540, 260],
      [540, 780, 260], // same shape SKU: [780, 540, 260]
      [540, 1020, 260], // same shape SKU: [1020, 540, 260]
      [780, 540, 260],
      [780, 780, 260],
      [780, 1020, 260], // same shape SKU: [1020, 780, 260]
      [1020, 540, 260],
      [1020, 780, 260],
      [1020, 1020, 260],

      // Base without a backrest (can be used as edge)
      // [540, 780, 260],
      // [540, 1020, 260],
      [540, 1260, 260], // same shape SKU: [1260, 540, 260]
      // [780, 780, 260],
      // [780, 1020, 260],
      [780, 1260, 260],
      // [1020, 780, 260],
      // [1020, 1020, 260],
      [1260, 1260, 260], // logic dimension: [1020, 1260, 260],

      // Extensions in front of a sofas (lounge) with 24 armrest
      // [780, 540, 260],
      // [780, 780, 260],
      // [1020, 540, 260],
      // [1020, 1020, 260],
      [1260, 540, 260],
      // [1260, 1260, 260],

      // Extensions in front of a sofas (lounge) with 12 armrest
      [660, 540, 260],
      [660, 660, 260],
      [900, 540, 260],
      [900, 900, 260],
      [1140, 540, 260],
      [1140, 1140, 260],

      // Ottoman
      // [540, 780, 260], // 1st release
      // [540, 1020, 260],
      // [540, 1260, 260],
      // [780, 780, 260],
      // [780, 1020, 260],
      // [780, 1260, 260],
      // [1020, 780, 260],
      // [1020, 1020, 260],
      // [1260, 1260, 260],

      // 2nd release (UX limitation for now)
      // [660, 540, 260],
      // [660, 660, 260],
      // [900, 540, 260],
      // [900, 900, 260],
      // [1140, 540, 260],
      // [1140, 1140, 260],
    ],
    color: ['fabric'],
  },
  // {
  // module: ['sofa_bed_backrest', 'normal'],
  // 2nd Release ???
  // dimensions_expand: [[2040], [240], [560, 620]],
  // color: ['fabric'],
  // },
  // {
  // module: ['sofa_bed_base', 'normal'],
  // dimensions_expand: [[2040], [540, 780, 1020], [180, 240]],
  // color: ['fabric'],
  // },
];

export default modules;
