import ElementApiService from 'mycs/shared/services/ElementApiService/ElementApiService';
import ContentfulService from 'mycs/shared/services/ContentfulService/ContentfulService';

/**
 * Group all the samplebox data in one call
 */
class SampleboxDataService {
  getLandingPage(
    path: string,
    locale: string,
    countryCode: string
  ): Promise<any> {
    return ContentfulService.getSampleboxPage(path, locale, countryCode);
  }

  getElements(): Promise<any> {
    return ElementApiService.getElements('sample');
  }

  async getData(
    path: string,
    locale: string,
    countryCode: string
  ): Promise<any> {
    const [lp, elements] = await Promise.all([
      this.getLandingPage(path, locale, countryCode),
      this.getElements(),
    ]);
    lp.page.elements = elements;

    return lp;
  }
}

export default new SampleboxDataService();
