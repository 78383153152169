import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { CfFooterGroup, CfNavLink } from '@mycs/contentful';
import { useDevice } from 'mycs/router/DeviceContext';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import { useLocation } from 'react-router-dom';
import { calculateAverage } from 'mycs/shared/utilities/RatingUtils/RatingUtils';
import Alink from 'mycs/shared/components/Alink/Alink';
import ContentfulService from 'mycs/shared/services/ContentfulService/ContentfulService';
import StarsRating from 'mycs/shared/components/StarsRating/StarsRating';
import CollapsingPanel from 'mycs/shared/components/CollapsingPanel/CollapsingPanel';
import CookieService from 'mycs/shared/services/CookieService/CookieService';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import SmartImage from 'mycs/shared/components/SmartImage/SmartImage';

import styles from './MenuGroups.scss';

type Props = {
  groups: CfFooterGroup[];
  onActiveItemChange?: (link: CfNavLink | null) => void;
};

export default function MenuGroups({ onActiveItemChange, groups }: Props) {
  const { hasPhoneDimensions } = useDevice();
  const { locale, countryCode } = useLocale();
  const { pathname } = useLocation();
  const activeItem = useMemo(
    () => findActiveItem(pathname, groups),
    [pathname, groups]
  );
  const [activeGroup, setActiveGroup] = useState<CfFooterGroup | null>(null);
  const [avgScore, setAvgScore] = useState<number | null>(null);

  useEffect(() => {
    onActiveItemChange?.(activeItem);
  }, [activeItem, onActiveItemChange]);

  useEffect(() => {
    ContentfulService.getCustomerTestimonialsList(locale, countryCode).then((data) => {
      //ignore invalid data
      if (!data || !Array.isArray(data)) {
        return;
      }

      const average = calculateAverage(data.filter((review: any) => review.showOnWebsite).map((review: any) => review.rating));
      setAvgScore(average);
    });
  }, [locale, countryCode]);

  const classes = classNames(styles.container, {
    [styles.phoneContainer]: hasPhoneDimensions, //FIXME: could be done through CSS
  });

  function updateCookieConsent() {
    CookieService.renewCookieDialog();
  }

  return (
    <div className={classes}>
      {groups.map(renderGroup)}
      <div
        className={classNames(styles.group, styles.cookieConsent)}
        key="update-consent-cookies"
      >
        <div className={styles.groupHeading}>
          <div>
            <Alink
              className={classNames(styles.link)}
              onClick={updateCookieConsent}
            >
              {I18nUtils.localize(locale, 'Update consent')}
            </Alink>
          </div>
        </div>
      </div>
    </div>
  );

  function renderLink(link: any): React.ReactNode {
    //FIXME: could the link be hidden through CSS?
    if (hasPhoneDimensions && link.hideOnPhone) return null;
    const isActive = activeItem === link;
    const rel = link.url
      ? link.url.includes('http')
        ? 'noopener'
        : 'prefetch'
      : undefined;

    const content = link.image ? (
      <SmartImage src={link.image.url} alt={link.title} aspectRatio="1/1" />
    ) : (
      link.title
    );

    const classes = classNames(styles.link, {
      [styles.activeLink]: isActive,
    });

    return link.url ? (
      <Alink
        className={classes}
        href={link.url}
        target={link.target}
        rel={rel}
        text={link.title}
      >
        {content}
      </Alink>
    ) : (
      <span className={classes}>{content}</span>
    );
  }

  /**
    * This method is used for displaying rating
    */
  function getAdditionalText(additionalTextField: any): React.ReactNode {
    if (additionalTextField === undefined) return <></>

    return (
      <div className={styles.rating}>
        <span className={styles.ratingText}>{additionalTextField}: {avgScore}/5</span>
        <StarsRating rating={avgScore} starColor='-white' />
      </div>
    )
  }

  function renderGroup(group: CfFooterGroup): React.ReactNode {
    const linkItems =
      (hasPhoneDimensions
        ? group.links?.filter((item) => !item.hideOnPhone)
        : group.links) || [];

    const isImageGroup =
      Boolean(linkItems.length) && linkItems.every((link) => link.image);
    const isSingleLink = linkItems.length === 0;

    const links = linkItems.map((link, key) => (
      <li className={styles.item} key={key}>
        {renderLink(link)}
      </li>
    ));

    const linksList = links.length ? <ul>{links}</ul> : '';

    const groupBlock =
      isImageGroup || !hasPhoneDimensions ? (
        <div>
          <div className={styles.groupHeading}>{renderLink(group)}</div>
          <div>{getAdditionalText(group.additionalTextField)}</div>
          {linksList}
        </div>
      ) : isSingleLink ? (
        <div className={styles.headlineLink}>{renderLink(group)}</div>
      ) : (
        <CollapsingPanel
          iconName="general/arrow-chevron-down.svg"
          defaultCollapsed={activeGroup !== group}
          onExpand={() => setActiveGroup(group)}
          isCompactLayout
          isTransparent
          contentClassName={styles.panel}
          headline={group.title}
          content={linksList}
        />
      );

    const classes = classNames(styles.group, styles[`group_${group._id}`], {
      [styles.imageGroup]: isImageGroup,
    });

    return (
      <div className={classes} key={group._id}>
        {groupBlock}
      </div>
    );
  }
}

/**
 * Find the item that matches a URL path
 */
function findActiveItem(
  urlPath: string,
  groups: CfFooterGroup[]
): CfNavLink | null {
  //FIXME: could be simplified with Array.find
  for (const group of groups) {
    const item =
      group.links && group.links.find((item) => item.url === urlPath);
    if (item) return item;
  }

  return null;
}
