import { useLocation } from 'react-router-dom';
import { ReactEventHandler } from 'react';

import { useLocale } from 'mycs/shared/state/LocaleContext';
import AnalyticsService from 'mycs/shared/services/AnalyticsService/AnalyticsService';

type Props = {
  name?: string;
  label?: string;
  data?: object;
};

const DEFAULT_EVENT_NAME = 'click';

export function useTrackEvent({
  name = DEFAULT_EVENT_NAME,
  data = {},
  label,
}: Props = {}) {
  const { locale } = useLocale();
  const { pathname, search } = useLocation();
  const currentPageUrl = `${pathname}${search}`;

  return {
    wrapProps,
    wrapOnClick,
  };

  function wrapOnClick(
    onClick?: ReactEventHandler,
    { trackLabel }: { trackLabel?: string } = {}
  ): ReactEventHandler {
    return (e) => {
      track(e, { trackLabel });
      onClick?.(e);
    };
  }

  function wrapProps(
    props: object & { onClick?: any } = {},
    { trackLabel }: { trackLabel?: string } = {}
  ) {
    return {
      ...props,
      onClick(e: any) {
        track(e, { trackLabel });
        props.onClick?.(e);
      },
    };
  }

  function track(e: any, { trackLabel }: { trackLabel?: string }) {
    const componentPath = AnalyticsService.getComponentPath(
      e?.target,
      trackLabel ?? label
    );

    // Don't track if neither name nor path are specified
    if (!componentPath && name === DEFAULT_EVENT_NAME) {
      return;
    }

    const payload = Object.assign(
      {
        componentPath,
        pageURL: currentPageUrl,
      },
      data
    );

    AnalyticsService.eventTrack(name, locale, payload);
  }
}
