import capitalize from 'lodash/capitalize';
import { FurnitureConfig } from 'furniture-engine';

import { ConfiguratorSpecs } from 'mycs/framework/store/ConfiguratorSpecs';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';

export class ShelfSpecs extends ConfiguratorSpecs {
  getSpecString(_furnitureConfig: FurnitureConfig, specs: any, colors: any) {
    let res: string[] = [];
    res = res.concat(this._getColorsString(colors.wall, 'Wall'));
    res = res.concat(this._getColorsString(colors.normalBoard, 'Board'));
    res = res.concat(
      this._getColorsString(colors.reinforcedBoard, 'Heavy duty board')
    );
    res = res.concat(this._getColorsString(colors.metalBar, 'MetalBar'));

    res = res.concat(this._getColorsString(colors.drawer, 'Drawer'));
    res = res.concat(this._getColorsString(colors.door, 'Door'));
    res = res.concat(this._getColorsString(colors.handle, 'Handle'));
    res = res.concat(this._getColorsString(colors.backwall, 'Backwall'));
    res = res.concat(this._getColorsString(colors.hanging_feature, 'Hanger'));

    res = res.concat(
      this._getColorsString(colors.leg, 'Foot', capitalize(specs.leg_type))
    );
    res = res.concat(
      this._getColorsString(colors.support_leg, 'Foot', 'shelf_legs_support')
    );
    const nbPTOs = specs.regular_push_to_open + specs.glass_doors_push_to_open;
    if (nbPTOs > 0) {
      res = res.concat(
        `${nbPTOs} ${I18nUtils.localize(
          this.locale,
          this._pluralChecker('Push-to-open', nbPTOs)
        )}`
      );
    }
    return res.join(', ');
  }
}
