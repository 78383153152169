import { PropsWithChildren, PureComponent } from 'react';
import classNames from 'classnames';
import throttle from 'lodash/throttle';

import styles from './Sticky.scss';

type Props = PropsWithChildren<{
  isDisabled: boolean;
  dockOnScrollDown?: boolean;
  dockOnScrollUp?: boolean;
  className?: string;
  dockedClassName?: string;
  onToggle?: (docked: boolean) => void;
  offset: number;
  undockElement?: any;
  undockElementOffset: number;
}>;

type State = {
  docked: boolean;
};

export default class Sticky extends PureComponent<Props, State> {
  static defaultProps = {
    isDisabled: false,
    dockOnScrollDown: false,
    dockOnScrollUp: false,
    offset: 0,
    undockElementOffset: 0,
  };
  dockedStyles: {};
  placeholderStyles: {};
  sticky: any;
  container: any;

  constructor(props: Props) {
    super(props);

    this.dockedStyles = {};
    this.placeholderStyles = {};

    this.state = {
      docked: false,
    };
  }

  /**
   * Dock the main element
   */
  dock(docked: boolean) {
    if (this.state.docked !== docked) {
      this.props.onToggle?.(docked);

      // Update styles
      this.dockedStyles = this.props.dockOnScrollUp
        ? {}
        : { top: `${this.props.offset}px` };
      if (this.sticky) {
        this.placeholderStyles = { height: `${this.sticky.offsetHeight}px` };
      }

      this.setState({ docked });
    }
  }

  onScroll = throttle(() => {
    const {
      isDisabled,
      dockOnScrollDown,
      dockOnScrollUp,
      offset,
      undockElement,
      undockElementOffset,
    } = this.props;
    let lastTop, currentTop;
    let isScrollingDown = false;

    if (isDisabled || !this.container) return;

    const bbox = this.container.getBoundingClientRect();
    currentTop = bbox.top;

    // On iOS, bouncing overscrolling creates a scroll even w/o any actual scroll
    if (currentTop === lastTop) return;

    //@ts-ignore
    isScrollingDown = lastTop !== null && currentTop < lastTop;
    lastTop = currentTop;

    let shouldDock =
      currentTop < offset && (!dockOnScrollDown || !isScrollingDown);

    if (dockOnScrollUp) {
      shouldDock = !isScrollingDown;
    }

    // Undock after scrolling past the element
    if (undockElement) {
      const undockBbox = undockElement.getBoundingClientRect();
      // Add offset for hiding the sticky if provided
      const undockBoxBottom = undockBbox.bottom - undockElementOffset;
      if (undockBoxBottom < offset) {
        shouldDock = false;
      }
    }

    this.dock(shouldDock);
  }, 16);

  /**
   * Create onScroll fn
   */
  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onScroll);

    this.onScroll();
  }

  /**
   * Remove listeners after unmount
   */
  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onScroll);

    //@ts-ignore
    this.onScroll = () => null;
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.undockElement &&
      this.props.undockElement !== prevProps.undockElement
    ) {
      this.onScroll();
    }
  }

  render() {
    const { props, state } = this;
    const isDocked = !props.isDisabled && state.docked;

    const classes = classNames(
      styles.container,
      props.className,
      {
        [styles.docked]: isDocked,
        [styles.animated]:
          !props.isDisabled && (props.dockOnScrollDown || props.dockOnScrollUp),
        [styles.bottom]: props.dockOnScrollUp,
      },
      isDocked && props.dockedClassName
    );

    return (
      <div
        ref={(el) => (this.container = el)}
        style={isDocked ? this.placeholderStyles : {}}
      >
        <div
          ref={(el) => (this.sticky = el)}
          className={classes}
          style={this.dockedStyles}
        >
          {props.children}
        </div>
      </div>
    );
  }
}
