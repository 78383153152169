import { SofaFurnitureConfig } from './joyn-config';
import pyllowConfig from './pyllow-config';

const config: SofaFurnitureConfig = {
  ...pyllowConfig,
  defaultDimensions: {
    ...pyllowConfig.defaultDimensions,
    regular_depth: 600,
    height: 420,
  },
  hasAssembly: false,
  viewModes: {
    ...pyllowConfig.viewModes,
  },
  options: [
    // Bases ottoman
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: '80 x 64 cm',
      length: 800,
      iconName: 'configurator/pyllow/ottoman_pyllow_75',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'base',
      ottomanMode: true,
      direction: 'none',
      text: '100 x 64 cm',
      length: 1000,
      iconName: 'configurator/pyllow/ottoman_pyllow_100',
    },
    // Fetch all options except module options
    ...pyllowConfig.options.filter((option) => option.group !== 'module'),
  ],
  isOttoman: true,
};

export default config;
