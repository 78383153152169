import classNames from 'classnames';

import cfg from 'mycs/config';
import Icon from 'mycs/shared/components/Icon/Icon';

import styles from 'mycs/shared/components/Icon/Icon.scss';

type Props = {
  iconName?: string;
  iconContent?: string;
  isInline?: boolean;
  className?: string;
  iconContainerClass?: string;
  onClick?: () => void;
};

export default function IconColor(props: Props) {
  const { className, onClick, iconContainerClass, iconName } = props;

  const rgbColor = getColor(iconName);

  if (rgbColor) {
    return (
      <div
        className={classNames(styles.iconContainer, iconContainerClass)}
        onClick={onClick}
      >
        <div
          style={{ backgroundColor: rgbColor }}
          className={classNames(className, styles.icon)}
        />
      </div>
    );
  }

  return <Icon {...props} />;
}

/**
 * Get a simple background color if an icon is in the RAL-to-RGB config
 */
function getColor(iconName?: string): string | null {
  if (!iconName) return null;

  const lastPart = iconName.split('/').pop();
  return lastPart ? cfg.RALtoRGB[lastPart] : null;
}
