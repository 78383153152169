import useSWR from 'swr';

import { useLocale } from 'mycs/shared/state/LocaleContext';
import Button from 'mycs/shared/components/Button/Button';
import NewsletterForm from 'mycs/shared/components/NewsletterForm/NewsletterForm';
import SafeText from 'mycs/shared/components/SafeText/SafeText';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import ContentfulService from 'mycs/shared/services/ContentfulService/ContentfulService';
import Logger from 'mycs/shared/services/Logger';
import { SignupSource } from 'mycs/api/UserAPI';
import styles from './ExitNewsletterForm.scss';

type Props = {
  onClose: () => void;
};

export default function ExitNewsletterForm({ onClose }: Props) {
  const { locale, countryCode } = useLocale();

  const assetId = '5pN1goWZl4OXn0CfplCnwj'; // Background image asset ID
  const fetchAsset = async (assetId: string) => {
    try {
      const asset = await ContentfulService.getCFAsset(assetId, countryCode);
      return asset ? asset.url : null;
    } catch (error) {
      Logger.error('Failed to fetch asset from Contentful:', error);
    }
  };

  const { data: backgroundImage } = useSWR(assetId, fetchAsset);

  const inlineStyles = backgroundImage
    ? { backgroundImage: `url(${backgroundImage})` }
    : {};

  const buttonProps = {
    isPrimaryCta: true,
    isFullWidth: true,
    text: 'Send',
  };

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.header} style={inlineStyles}>
          <Button
            iconName="general/close.svg"
            onClick={onClose}
            className={styles.closeButton}
          />
        </div>

        <div className={styles.content}>
          <p className={styles.subtitle}>
            {I18nUtils.localize(
              locale,
              'static_content/exit-intent-discount/subtitle'
            )}
          </p>

          <div className={styles.form}>
            <NewsletterForm
              formClassName={styles.form}
              inputClassName={styles.input}
              buttonClassName={styles.button}
              buttonProps={buttonProps}
              isWithLabel
              errorMessageBottom
              onSubmit={onClose}
              source={SignupSource.PopUp}
            />

            <p className={styles.info}>
              {I18nUtils.localize(
                locale,
                'static_content/exit-intent-discount/notification'
              )}
            </p>
          </div>
        </div>
        <div className={styles.footer}>
          <p className={styles.footNote}>
            <SafeText
              content={I18nUtils.localize(
                locale,
                'static_content/exit-intent-discount/footer'
              )}
            />
          </p>
        </div>
      </div>
    </div>
  );
}
