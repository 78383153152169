import { asyncRequest } from 'mycs/shared/utilities/FetchUtils/FetchUtils';
import UrlProviderService from 'mycs/shared/services/UrlProviderService/UrlProviderService';

type Translations = {
  [key: string]: string;
};

export default class TranslationService {
  static translations: { [locale: string]: Translations } = {};

  /**
   * Returns translations
   */
  static async getTranslations(
    locale: string,
    countryCode: string
  ): Promise<Translations> {
    if (TranslationService.translations[locale]) {
      return TranslationService.translations[locale];
    }

    const url = UrlProviderService.getTranslationsUrl(locale, countryCode);

    try {
      TranslationService.translations[locale] = await asyncRequest(url);
    } catch (e) {
      return {};
    }

    return TranslationService.translations[locale];
  }
}
