import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Intercept clicks on all links in the document to prevent the default action
 * and trigger a route change instead. Used inside the React router.
 */
export function LinkInterceptor({ children }: PropsWithChildren<{}>) {
  const navigate = useNavigate();

  return <div onClick={onClick}>{children}</div>;

  /**
   */
  function isModifierClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    return e.altKey || e.ctrlKey || e.metaKey || e.shiftKey;
  }

  /**
   * Filter out external URLs
   */
  function isInternalLink(href: string, link: HTMLAnchorElement) {
    return (
      href && !/^#/.test(href) && !/^(https?:|\/\/)/.test(href) && !link.target
    );
  }

  /**
   * Change the current route
   */
  function changeRoute(url: string) {
    navigate(url);
  }

  /**
   * Intercept clicks on internal links in the document
   */
  function onClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    // Ignore clicks with modifier keys
    if (isModifierClick(e)) return;

    //@ts-ignore
    const link = e.target.closest('a');
    const href = link ? link.getAttribute('href') : '';

    // Ignore external links
    if (!isInternalLink(href, link)) return;

    // Prevent the default action and change the route
    e.preventDefault();
    changeRoute(href);
  }
}
