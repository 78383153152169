import classNames from 'classnames';

import { RelativeUrlService } from 'mycs/shared/services/RelativeUrlService';
import { useDevice } from 'mycs/router/DeviceContext';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import Alink from 'mycs/shared/components/Alink/Alink';
import Icon from 'mycs/shared/components/Icon/Icon';
import IconLink, { LinkType } from 'mycs/shared/components/IconLink/IconLink';
import LanguageDropdown from 'mycs/shared/components/LanguageDropdown/LanguageDropdown';
import MainNav from 'mycs/shared/components/MainNav/MainNav';
import NavigationMenu from 'mycs/shared/components/NavigationMenu/NavigationMenu';

import logoSvg from 'mycs-img/mycs-logos/logo.svg';
import heartSvg from 'mycs-img/icons/navigation/heart.svg';
import cartSvg from 'mycs-img/icons/navigation/cart.svg';

import styles from './HeaderMenu.scss';

export default function HeaderMenu() {
  const { locale } = useLocale();
  const { hasPhoneDimensions } = useDevice();

  return (
    <div className={styles.wrapper}>
      {hasPhoneDimensions ? (
        <div className={styles.mobileContainer}>
          <NavigationMenu />
        </div>
      ) : (
        <div className={styles.desktopContainer}>
          <div className={styles.left}>
            <Alink
              href={RelativeUrlService.getHomeUrl(locale)}
              className={styles.logo}
              itemProp="logo"
            >
              <Icon iconContent={logoSvg} />
            </Alink>
          </div>

          <div className={classNames(styles.menu, 'hidden-print')}>
            <div>
              <div className={styles.menuWrapper}>
                <MainNav />
              </div>
            </div>
          </div>

          <div className={classNames(styles.rightLinks, 'hidden-print')}>
            <div className={styles.iconLink}>
              <IconLink
                to={LinkType.MyDesigns}
                iconContent={heartSvg}
                useOnClick
              />
            </div>
            <div className={styles.iconLink}>
              <IconLink to={LinkType.ShoppingCart} iconContent={cartSvg} />
            </div>
            <LanguageDropdown />
          </div>
        </div>
      )}
    </div>
  );
}
