import {
  deleteUserDesign,
  postLogin,
  postUser,
  postUserDesign,
  SignupSource,
  User,
} from 'mycs/api/UserAPI';
import {
  trackNewLeadEvent,
  trackSignUp,
} from 'mycs/services/UserAnalyticsService';

import * as UserService from 'mycs/services/UserService';
import FlagModeService from 'mycs/shared/services/FlagModeService/FlagModeService';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import { useCallback } from 'react';
// eslint-disable-next-line import/named
import { KeyedMutator } from 'swr';

export function useUserTools(
  user: undefined | User,
  mutateUser: KeyedMutator<User | undefined>
) {
  const { countryCode, locale } = useLocale();

  const signUp = useCallback(
    async function ({
      email,
      newsletterSignUpMeta,
      shouldStaySignedIn,
    }: {
      email: string;
      newsletterSignUpMeta?: {
        isSubscriptionAdded: boolean;
        source?: SignupSource;
      };
      shouldStaySignedIn?: boolean;
    }) {
      const resp = await postUser(
        UserService.toPostUserPayload(
          countryCode,
          locale,
          email,
          UserService.getReferrer(FlagModeService.isShowroom()),
          newsletterSignUpMeta
        )
      );

      trackSignUp(resp.id, resp.token);

      if (resp.new) {
        trackNewLeadEvent({ locale, countryCode, user: resp });
      }

      if (!shouldStaySignedIn) {
        return resp;
      }

      await postLogin(resp.id, resp.token);

      UserService.setUserID(resp.id);

      mutateUser();

      return resp;
    },
    [countryCode, locale, mutateUser]
  );

  const signUpAndAddDesigns = useCallback(
    async function ({
      email,
      uuids,
      shouldStaySignedIn,
    }: {
      email: string;
      uuids: string[];
      shouldStaySignedIn?: boolean;
    }) {
      const resp = await signUp({ email, shouldStaySignedIn });

      await Promise.all(
        uuids.map((uuid: string) => postUserDesign(resp.id, { uuid }))
      );

      return mutateUser();
    },
    [signUp, mutateUser]
  );

  const signOut = useCallback(
    async function () {
      UserService.removeUserID();
      mutateUser();
    },
    [mutateUser]
  );

  const addDesign = useCallback(
    async function (uuid: string) {
      if (!user) {
        throw Error('user is not signed in');
      }

      const resp = await postUserDesign(user.id, { uuid });
      mutateUser();

      return resp;
    },
    [user, mutateUser]
  );

  const removeDesign = useCallback(
    async function (uuid: string) {
      if (!user) {
        throw Error('user is not signed in');
      }

      const resp = await deleteUserDesign(user.id, uuid);
      mutateUser();

      return resp;
    },
    [user, mutateUser]
  );

  return {
    signUp,
    signUpAndAddDesigns,
    signOut,
    addDesign,
    removeDesign,
  };
}
