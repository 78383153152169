import { PureComponent } from 'react';
import classNames from 'classnames';
import { Subscription } from 'rxjs';

import { RelativeUrlService } from 'mycs/shared/services/RelativeUrlService';
import { updateUrlWithoutReload } from 'mycs/router/history';
import { useDevice } from 'mycs/router/DeviceContext';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import Alink from 'mycs/shared/components/Alink/Alink';
import AnalyticsService from 'mycs/shared/services/AnalyticsService/AnalyticsService';
import ClickOutside, {
  MouseOrTouch,
} from 'mycs/shared/components/ClickOutside/ClickOutside';
import Button from 'mycs/shared/components/Button/Button';
import cfg from 'mycs/config';
import ContactForm from 'mycs/shared/components/ContactForm/ContactForm';
import helpCfg from './config';
import HelpStore from 'mycs/shared/stores/HelpStore/HelpStore';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import Icon from 'mycs/shared/components/Icon/Icon';
import LiveChatService from 'mycs/shared/services/LiveChatService/LiveChatService';
import MiniFaq from 'mycs/shared/components/MiniFaq/MiniFaq';

import closeSvg from 'mycs-img/icons/general/arrow-chevron-down.svg';
import phoneSvg from 'mycs-img/icons/support/phone.svg';
import mailSvg from 'mycs-img/icons/support/mail.svg';
import faqSvg from 'mycs-img/icons/support/faq.svg';
import communicationSvg from 'mycs-img/icons/usp-ribbon/communication.svg';
import arrowLeftSvg from 'mycs-img/icons/general/arrow-left.svg';
import styles from './Help.scss';

type Props = {
  onClose: () => void;
  isVisible: boolean;
  phoneNumber: string;
  email: string;
  delay?: number;
  elementIdsWithinScope?: string[];
};

export default function Help(props: Props) {
  const { locale, countryCode } = useLocale();
  const { hasPhoneDimensions } = useDevice();

  return (
    <InnerHelp
      {...props}
      locale={locale}
      countryCode={countryCode}
      isSmallScreen={hasPhoneDimensions}
    />
  );
}

type InnerProps = Props & {
  locale: string;
  countryCode: string;
  isSmallScreen: boolean;
};

type State = {
  showContactForm: boolean;
  showChat: boolean;
  areAgentsOnline: boolean;
};

class InnerHelp extends PureComponent<InnerProps, State> {
  subscriptions: Subscription[];
  liveChatGroupId: number;
  text: typeof helpCfg.optionsText;
  chatIframeUrl: string;

  static defaultProps = {
    delay: 3000,
  };

  constructor(props: InnerProps) {
    super(props);

    this.state = {
      showContactForm: false,
      showChat: false,
      areAgentsOnline: false,
    };

    const { countryCode } = props;
    this.liveChatGroupId = cfg.livechat.groups[countryCode];
    this.text = helpCfg.optionsText;
    this.text.phone[1] = cfg.mycs.telephone[countryCode];
    this.chatIframeUrl = `${cfg.livechat.iframeUrl}?groups=${this.liveChatGroupId}`;
    this.subscriptions = [];
  }

  /**
   * Click handler for phone
   */
  onPhoneClick = () => {
    const link = `tel:${this.text.phone[1].split(' ').join('')}`;
    this.track('phone');
    window.location.href = link;
  };

  /**
   * Click handler for chat
   */
  toggleChat = () => {
    HelpStore.toggleChat();
    this.track('chat');
  };

  /**
   * Toggle the contact form modal
   */
  toggleModal = (showContactForm: boolean) => {
    this.checkAgentsStatus();
    this.setState({ showContactForm });
    this.track('email');
  };

  renderModal(): React.ReactNode {
    return (
      <ContactForm
        isOpen={this.state.showContactForm}
        requestClose={() => this.toggleModal(false)}
        showFooter
      />
    );
  }

  renderPhoneButton(): React.ReactNode {
    return (
      <Button
        className={styles.option}
        onClick={this.onPhoneClick}
        trackLabel={this.text.phone[0]}
      >
        <Icon iconContent={phoneSvg} iconContainerClass={styles.icon} />
        <em>
          {I18nUtils.localize(this.props.locale, 'Working days')} 8:00-16:00
        </em>
        <small>{this.text.phone[1]}</small>
      </Button>
    );
  }

  renderEmailButton(): React.ReactNode {
    return (
      <Button
        className={styles.option}
        onClick={() => this.toggleModal(true)}
        trackLabel={this.text.email[0]}
      >
        <Icon iconContent={mailSvg} iconContainerClass={styles.icon} />
        <em>{I18nUtils.localize(this.props.locale, this.text.email[0])}</em>
        <small>{this.text.email[1]}</small>
      </Button>
    );
  }

  renderChatButton(): React.ReactNode {
    if (!this.state.areAgentsOnline || this.state.showChat) return;
    return this.props.isSmallScreen ? (
      <Alink
        className={styles.option}
        href={this.chatIframeUrl}
        target="_blank"
        text={this.text.chat[0]}
      >
        <Icon iconContent={communicationSvg} iconContainerClass={styles.icon} />
        <em>{I18nUtils.localize(this.props.locale, this.text.chat[0])}</em>
        <small>{I18nUtils.localize(this.props.locale, 'Live chat')}</small>
      </Alink>
    ) : (
      <Button
        className={styles.option}
        onClick={this.toggleChat}
        trackLabel={this.text.chat[0]}
      >
        <em>{I18nUtils.localize(this.props.locale, this.text.chat[0])}</em>
        <small>{I18nUtils.localize(this.props.locale, 'Live chat')}</small>
        <span className={styles.inlineIconWrapper}>
          <Icon
            iconContent={communicationSvg}
            isInline
            iconContainerClass={classNames(styles.icon, styles.inlineIcon)}
          />
        </span>
      </Button>
    );
  }

  renderFaqLink(): React.ReactNode {
    const { locale, isSmallScreen, onClose } = this.props;
    if (!isSmallScreen && this.state.areAgentsOnline) return;

    const faqUrl = RelativeUrlService.getFaqUrl(locale);

    const onClick = () => {
      updateUrlWithoutReload(faqUrl);
      this.track('faq');
      onClose();
    };

    return (
      <Alink
        className={styles.option}
        href={faqUrl}
        onClick={onClick}
        text={this.text.faq[0]}
      >
        <Icon iconContent={faqSvg} iconContainerClass={styles.icon} />
        <em>{I18nUtils.localize(locale, this.text.faq[0])}</em>
        <small>{I18nUtils.localize(locale, this.text.faq[1])}</small>
      </Alink>
    );
  }

  renderBackButton(): React.ReactNode {
    const classes = classNames(styles.option, styles.backButton);
    return (
      <Button
        className={classes}
        onClick={this.toggleChat}
        trackLabel="Chat/Go back"
      >
        <span className={styles.backButtonContent}>
          <em>{I18nUtils.localize(this.props.locale, 'Go back')}</em>
          <Icon iconContent={arrowLeftSvg} iconContainerClass={styles.icon} />
        </span>
      </Button>
    );
  }

  /**
   * Send help panel interactions to GTM
   */
  track(eventName: string) {
    let tag;
    if (eventName === 'open') {
      tag = {
        event: 'helpPanelOpen',
        areAgentsOnline: this.state.areAgentsOnline,
      };
    } else {
      tag = {
        event: 'helpPanelAction',
        areAgentsOnline: this.state.areAgentsOnline,
        action: eventName,
      };
    }

    AnalyticsService.eventTrack('HelpPanel', this.props.locale, tag);
  }

  /**
   * Livechat service check
   */
  async checkAgentsStatus() {
    const agents = await LiveChatService.getAvailableAgents(
      this.liveChatGroupId
    );
    this.setState({
      areAgentsOnline: agents.length > 0,
    });
  }

  /**
   * Check agent status and subscribe to events
   */
  componentDidMount() {
    setTimeout(() => {
      this.checkAgentsStatus();
    }, this.props.delay);

    this.subscriptions.push(
      HelpStore.subscribe((ev) =>
        this.setState({
          showChat: ev.showChat || false,
        })
      )
    );
  }

  /**
   * Dispose of subscriptions
   */
  componentWillUnmount() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  componentDidUpdate(prevProps: InnerProps) {
    const { isVisible, email, phoneNumber } = this.props;
    // Track the open event
    if (isVisible && isVisible !== prevProps.isVisible) {
      this.track('open');
    }

    if (phoneNumber && phoneNumber !== prevProps.phoneNumber) {
      this.text.phone[1] = phoneNumber;
    }

    if (email && email !== prevProps.email) {
      this.text.email[1] = email;
    }
  }

  onClickOutside = (event: MouseOrTouch) => {
    const { elementIdsWithinScope } = this.props;
    const { target } = event;
    const { onClose, isVisible } = this.props;
    // @ts-ignore
    if (target.id && elementIdsWithinScope?.includes(target.id)) return;
    isVisible && onClose();
  };

  render() {
    const containerClasses = classNames(styles.container, {
      [styles.slimLayout]: !this.state.areAgentsOnline,
      [styles.hidden]: !this.props.isVisible,
    });

    const shouldRenderChat =
      !this.props.isSmallScreen && this.state.areAgentsOnline;

    return (
      <ClickOutside onClickOutside={this.onClickOutside}>
        <div className={containerClasses}>
          <div className={styles.header}>
            <span className={styles.title}>
              {I18nUtils.localize(this.props.locale, 'Contact us')}
            </span>
            <Button
              iconContent={closeSvg}
              iconSmall
              className={styles.closeIcon}
              onClick={this.props.onClose}
            />
          </div>
          <div className={styles.body}>
            <div className={styles.options}>
              {this.renderPhoneButton()}
              {this.renderEmailButton()}
              {/* {this.renderChatButton()} */}
              {this.renderFaqLink()}
              {this.state.showChat && this.renderBackButton()}
            </div>
            {shouldRenderChat && (
              <div className={styles.content}>
                <div
                  className={classNames(styles.miniFaq, {
                    [styles.hidden]: this.state.showChat,
                  })}
                >
                  {this.props.isVisible && <MiniFaq />}
                </div>
                {this.state.showChat && (
                  <iframe
                    src={this.chatIframeUrl}
                    width="610"
                    height="320"
                    scrolling="no"
                    frameBorder="0"
                    marginHeight={0}
                    marginWidth={0}
                  />
                )}
              </div>
            )}
          </div>
          {this.renderModal()}
        </div>
      </ClickOutside>
    );
  }
}
