import { asyncRequest } from 'mycs/shared/utilities/FetchUtils/FetchUtils';
import UrlProviderService from 'mycs/shared/services/UrlProviderService/UrlProviderService';

/**
 * Service to communicate with our third-party chat provider
 */
export default class LiveChatService {
  /**
   * Get all `available` agents for a specific group ID
   * (`available` = accepting chat requests)
   *
   * @returns  Array with available agents
   */
  static getAvailableAgents(groupId: number) {
    return asyncRequest(
      `${UrlProviderService.getLivechatApiUrl()}/agents/available?group_id=${groupId}`
    );
  }
}
