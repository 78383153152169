import { FurnitureConfig, SofaFurnitureConfig } from 'furniture-engine';

import { ConfiguratorSpecs } from 'mycs/framework/store/ConfiguratorSpecs';
import { CfMaterial } from '@mycs/contentful';

export class SofaSpecs extends ConfiguratorSpecs {
  getSpecString(
    furnitureConfig: FurnitureConfig,
    specs: any,
    colors: any,
    materials: CfMaterial[]
  ) {
    const res: string[] = [];
    const colorStructure: any = this.getColorStructure(
      colors,
      furnitureConfig as SofaFurnitureConfig
    );
    Object.keys(colorStructure).forEach((element: any) => {
      res.push(
        ...this._getColorsString(
          colorStructure[element],
          element,
          '',
          materials
        )
      );
    });

    if (specs.legsType) {
      const legTypes = furnitureConfig.options.filter(
        (option: any) =>
          option.type === 'leg' && option.value.type === specs.legsType
      );
      const legsType = legTypes.length === 0 ? '' : legTypes[0].text;
      if (specs.legsHeight === '170') {
        res.push(
          ...this._getColorsString(colors.leg_high, 'Foot', legsType).map(
            (str) => `${str} 17cm`
          )
        );
      } else {
        res.push(
          ...this._getColorsString(colors.leg, 'Foot', legsType).map(
            (str) => `${str} 11cm`
          )
        );
      }
    }
    return res.join(', ');
  }

  /**
   * Compute color structure from colors attribute from design api
   */
  private getColorStructure(colors: any, furnitureConfig: SofaFurnitureConfig) {
    const cushion: any = {};
    const edge: any = {};
    const backCushion: any = {};
    const armrest: any = {};
    const baseBackrest: any = {};
    // for flayr we have separate color for base and backrest
    const backrest: any = {};
    // for ottomans we don't have backrest
    const base: any = {};
    const sleeper: any = {};
    const { furnitureType, isOttoman } = furnitureConfig;

    Object.keys(colors).forEach((color: any) => {
      switch (color) {
        case 'l_cushion_right':
        case 'l_cushion_left':
        case 'end_cushion_right':
        case 'end_cushion_left':
          this.addColor(edge, colors[color]);
          break;
        case 'armrest_left':
        case 'armrest_right':
          this.addColor(armrest, colors[color]);
          break;
        case 'sofa_bed':
          this.addColor(sleeper, colors[color]);
          break;
        case 'backrest':
        case 'corner_backrest':
        case 'sofa_bed_backrest':
          if (furnitureType === 'pyllow') {
            this.addColor(backCushion, colors[color]);
          } else if (furnitureType === 'flayr') {
            this.addColor(backrest, colors[color]);
          } else {
            this.addColor(baseBackrest, colors[color]);
          }
          break;
        case 'back_cushion':
        case 'back_roll_cushion':
          this.addColor(backCushion, colors[color]);
          break;
        case 'cushion':
        case 'corner_cushion':
        case 'pouf_cushion':
        case 'seating_cushion':
          this.addColor(cushion, colors[color]);
          break;
        case 'base':
        case 'corner':
        case 'base_pouf':
        case 'storage_base':
          if (furnitureType === 'pyllow') {
            this.addColor(cushion, colors[color]);
          }
          if (furnitureType === 'joyn' && isOttoman) {
            this.addColor(base, colors[color]);
          }
          if (furnitureType === 'flayr') {
            this.addColor(base, colors[color]);
          }
          break;
      }
    });

    return {
      cushion,
      edge,
      backCushion,
      armrest,
      baseBackrest,
      backrest,
      base,
      sleeper,
    };
  }

  /**
   * Add a color in a specific category
   */
  private addColor(category: any, colors: any) {
    Object.keys(colors).forEach((color: any) => {
      if (category[color]) {
        category[color] = category[color] + colors[color];
      } else {
        category[color] = colors[color];
      }
    });
  }
}
