import { useEffect, useRef } from 'react';
import {
  useLocation,
  Routes,
  Route,
  BrowserRouter,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { StaticRouter } from 'react-router-dom/server';

import {
  getRedirectUrl,
  RouteHandlerType,
  storeStateInRedux,
  trackPageView,
} from './Utils';
import { DeviceContext, useDevice } from 'mycs/router/DeviceContext';
import { getPageContentByKeyFromReduxState } from 'mycs/shared/state/slices/pageSlice';
import { LocaleContext, useLocale } from 'mycs/shared/state/LocaleContext';
import { RelativeUrlService } from 'mycs/shared/services/RelativeUrlService';
import { store } from 'mycs/shared/state/store';
import App from 'mycs/shared/components/App/App';
import LocationUtils from 'mycs/shared/utilities/LocationUtils/LocationUtils';
import Logger from 'mycs/shared/services/Logger';
import routesConfig from './config';
import { MycsURL } from '@mycs/edge-lambdas';
import { CookieContextProvider } from 'mycs/shared/state/CookieContext';
import { SWRConfig } from 'swr';
import { addScript, isTrustedshopsScriptLoaded, shouldDisplayTrustedshopsBadge } from './TrustedShops';

type AppWithRoutesProps = {
  url: string;
  userAgent: string;
  cookieMap: Record<string, string>;
  swrFallback: Record<string, unknown>;
};

function AppWithRoutes({
  url,
  userAgent,
  cookieMap,
  swrFallback,
}: AppWithRoutesProps) {
  const { pathname } = useLocation();
  const prevPathname = useRef(pathname);
  const locale = LocationUtils.getLocaleFromUrl(url);
  const countryCode = LocationUtils.getCountryCodeFromUrl(url);
  const { origin } = new MycsURL(url);

  useEffect(() => {
    //If pathname changed scroll to top
    if (prevPathname.current !== pathname) {
      window.scrollTo(0, 0);
    }

    updateTrustedshopsBadge(pathname);

    prevPathname.current = pathname;
  }, [pathname]);

  function updateTrustedshopsBadge(pathname: string): void {

    if(shouldDisplayTrustedshopsBadge(pathname, locale)) {
      // show badge
      addScript(true, locale);
    }
    else if (isTrustedshopsScriptLoaded(locale)){
      // hide badge
      addScript(false, locale);
    }
  }

  return (
    <DeviceContext.Provider value={userAgent}>
      <LocaleContext.Provider value={{ locale, countryCode, baseUrl: origin }}>
        <CookieContextProvider cookieMap={cookieMap}>
          <SWRConfig value={{ fallback: swrFallback }}>
            <App
              locale={locale}
              countryCode={countryCode}
              pathname={pathname}
              url={url}
            >
              <Routes>
                {Object.keys(routesConfig).map((pattern) => (
                  <Route
                    key={pattern}
                    path={RelativeUrlService.getTranslatedUrl(pattern, locale)}
                    element={<PageLoader handler={routesConfig[pattern]} />}
                  />
                ))}
              </Routes>
            </App>
          </SWRConfig>
        </CookieContextProvider>
      </LocaleContext.Provider>
    </DeviceContext.Provider>
  );
}

function PageLoader({ handler }: { handler: RouteHandlerType }) {
  const { pathname } = useLocation();
  const { locale, countryCode, baseUrl } = useLocale();
  const navigate = useNavigate();
  const params = useParams();
  const { hasPhoneDimensions } = useDevice();

  //This useEffect is meant to load data in CSR, while SSR data loading is handled by fetchSSRData
  useEffect(() => {
    if (!handler.resolve) {
      // Track VPV synchronously
      if (!handler.noPageView) {
        setTimeout(() => {
          trackPageView(pathname, locale, countryCode);
        }, 0);
      }
      return;
    }

    // test data from SSR
    const page = getPageContentByKeyFromReduxState<any>(store.getState(), {
      locale,
      pathname: pathname,
    });
    const dataFromSSR = page?.ssrLocation && pathname === page.ssrLocation;

    // Manage data from the redux app
    if (dataFromSSR) {
      // Track VPV asynchronously
      if (!handler.noPageView) {
        setTimeout(() => {
          trackPageView(
            pathname,
            locale,
            countryCode,
            page?.originalUrl,
            page?.product
          );
        }, 0);
      }
      return;
    }

    // Launch some parallel async actions
    const resolved = handler.resolve({
      pathname,
      locale,
      countryCode,
      params,
    });

    // Redirect on errors
    resolved.catch((err) => {
      const redirectUrl = getRedirectUrl({
        handler,
        location,
        isSmallScreen: hasPhoneDimensions,
        locale,
      });

      if (redirectUrl) {
        navigate(redirectUrl);
        return;
      }

      // Otherwise throw an error (page not found)
      throw err;
    });

    // Track VPV asynchronously
    if (!handler.noPageView) {
      resolved.then((data) => {
        const pageData = Array.isArray(data)
          ? data.find(({ slice }) => slice === 'page')?.payload.data
          : data;

        if (!pageData) {
          Logger.error(
            new Error(`track virtual page view failed due to missing page data`)
          );
        }

        trackPageView(
          pathname,
          locale,
          countryCode,
          pageData.page.originalUrl,
          pageData.page.product
        );
      });
    }

    // Dispatch a Redux action
    resolved.then((data) => {
      storeStateInRedux({
        data,
        locale,
        pathname,
        baseUrl,
      });
    });
  }, [
    countryCode,
    handler,
    hasPhoneDimensions,
    navigate,
    locale,
    params,
    pathname,
    baseUrl,
  ]);

  return handler.render;
}

export function CsrRouter({ url }: { url: string }) {
  const cookieMap = window.__COOKIE_MAP__;
  const swrFallback = window.__SWR_FALLBACK__;

  return (
    <BrowserRouter>
      <AppWithRoutes
        url={url}
        userAgent={window.navigator.userAgent}
        cookieMap={cookieMap}
        swrFallback={swrFallback}
      />
    </BrowserRouter>
  );
}

type ServerRouterProps = {
  matchingRoute: {
    pattern?: string;
  };
  url: string;
  userAgent: string;
  cookieMap: Record<string, string>;
  swrFallback: Record<string, unknown>;
};

export function SsrRouter({
  url,
  userAgent,
  cookieMap,
  swrFallback,
}: ServerRouterProps) {
  const { pathname, search } = new URL(url);

  return (
    <StaticRouter location={pathname + search}>
      <AppWithRoutes
        url={url}
        userAgent={userAgent}
        cookieMap={cookieMap}
        swrFallback={swrFallback}
      />
    </StaticRouter>
  );
}
