import { BehaviorSubject } from 'rxjs';

class TimerStore extends BehaviorSubject<any> {
  timers: {
    [key: string]: { time: number; parent: string };
  };

  constructor() {
    super([]);
    this.timers = {};
  }

  addTime(key: string, parent = '') {
    this.timers[key] = {
      time: Date.now(),
      parent,
    };

    this.next(this._result());
  }

  addFirstTime(key: string, parent: string) {
    // First Time compare to parent
    if (
      this.timers[key] === undefined ||
      (this.timers[key] !== undefined &&
        this.timers[parent] !== undefined &&
        this.timers[key].time < this.timers[parent].time)
    ) {
      this.timers[key] = {
        time: Date.now(),
        parent,
      };
      this.next(this._result());
    }
  }

  _result() {
    return Object.keys(this.timers)
      .filter((key) => {
        const parent = this.timers[key].parent;
        return parent !== '' && this.timers[parent] !== undefined;
      })
      .map((key) => {
        const time = this.timers[key].time;
        const parent = this.timers[key].parent;
        const delay = time - this.timers[parent].time;
        return { key, time, parent, delay };
      })
      .sort((a, b) => a.key.localeCompare(b.key));
  }
}

export default new TimerStore();
