import groupBy from 'lodash/groupBy';
import filter from 'lodash/filter';
import flatten from 'lodash/flatten';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import omit from 'lodash/omit';
import memoize from 'lodash/memoize';

import colors from '../config/color-config';

import RendererService from '../services/RendererService';

import cushionElements from '../elements/cushion-elements';
import joynElements from '../elements/joyn-elements';
import pyllowElements from '../elements/pyllow-elements';
import flayrElements from '../elements/flayr-elements';
import shelfElements from '../elements/shelf-elements';
import wardrobeElements from '../elements/wardrobe-elements';

//import CushionElement from '../components/cushion/CushionElement';
//import JoynSofaElement from '../components/joyn/JoynSofaElement';
//import PyllowSofaElement from '../components/pyllow/PyllowSofaElement';
//import FlayrSofaElement from '../components/flayr/FlayrSofaElement';
//import ShelfElement from '../components/shelf/ShelfElement';
//import WardrobeElement from '../components/wardrobe/WardrobeElement';

/**
 * Service to manage elements
 */
class ElementService {
  elements: any;

  constructor() {
    this.elements = [];
    this.filter = memoize(this.filter, JSON.stringify);
  }

  setElements(elements) {
    this.elements = groupBy([...elements], 'furniture_type');
  }

  filter(partialElement) {
    return filter(
      this.elements[partialElement['furniture_type']],
      omit(partialElement, 'furniture_type')
    );
  }

  /**
   *
   */
  getGeneratedConfig() {
    const modules = [
      {
        modules: cushionElements,
        furniture_type: 'cushion',
        //component: CushionElement
      },
      {
        modules: joynElements,
        furniture_type: 'joyn',
        //component: JoynSofaElement
      },
      {
        modules: pyllowElements,
        furniture_type: 'pyllow',
        //component: PyllowSofaElement
      },
      {
        modules: flayrElements,
        furniture_type: 'flayr',
        //component: FlayrSofaElement
      },
      {
        modules: shelfElements,
        furniture_type: 'shelf',
        //component: ShelfElement
      },
      {
        modules: wardrobeElements,
        furniture_type: 'wardrobe',
        //component: WardrobeElement
      },
    ];
    const generatedConfig = {};
    for (const module of modules) {
      generatedConfig[module.furniture_type] = module;
    }

    return generatedConfig;
  }

  /**
   * Generate the list of modules from config file & furniture type
   *
   */
  generateAllModules(expandColorGroup = false) {
    let modules = flatten(
      Object.values(this.getGeneratedConfig()).map(
        ({
          modules,
          furniture_type,
        }: {
          modules: any;
          furniture_type: string;
        }) => {
          return modules.map((module: any) => {
            return { ...module, furniture_type };
          });
        }
      )
    );

    if (expandColorGroup) {
      modules = flatten(
        modules.map((module) => {
          return module.color.map((colorGroup) => {
            return { ...module, color: [colorGroup] };
          });
        })
      );
    }

    // modules = modules.slice(0, 30);

    return modules;
  }

  /**
   *
   */
  *generateAssets() {
    let elements = this.generateAllElement();
    const generatedConfig = this.getGeneratedConfig();

    for (const element of elements) {
      const { furniture_type } = element;
      const { component } = generatedConfig[furniture_type];
      // generatedConfig[furniture_type];

      try {
        const structure = RendererService.createContainer(
          component,
          element,
          []
        );

        let shape = structure.props.scene_object_name;
        if (
          structure.props.shape !== undefined &&
          structure.props.shape !== structure.props.scene_object_name
        ) {
          shape = `${shape} ${structure.props.shape} custom`;
        }

        let material = structure.props.color;
        if (
          structure.props.material !== undefined &&
          structure.props.material !== structure.props.color
        ) {
          material = `${material} ${structure.props.material} custom`;
        }

        const result = { material, shape };
        yield result;
      } catch (error) {
        yield { shape: 'NOT FOUND', material: 'NOT FOUND' };
      }
    }
  }

  /**
   *
   */
  *generateUniqShapes() {
    const assets = {};
    for (const result of this.generateAssets()) {
      const asset = result.shape;
      if (!(asset in assets)) {
        assets[asset] = true;
        yield asset;
      }
    }
  }

  /**
   *
   */
  *generateUniqMaterials() {
    const assets = {};
    for (const result of this.generateAssets()) {
      const asset = result.material;
      if (!(asset in assets)) {
        assets[asset] = true;
        yield asset;
      }
    }
  }

  /**
   * Scan the list of SKU to extract a pattern of similar version
   *
   * @param {array} SKUList
   * @return {array} of module & versions of the module
   */
  *scanVersion(SKUList: Array<any>, expandColorGroup = false) {
    const modules = this.generateAllModules(expandColorGroup);

    const SKUListBySceneObjectName = groupBy(SKUList, 'scene_object_name');
    for (const module of modules) {
      const elements = this.generateElement(
        module.furniture_type,
        module,
        colors
      );

      const versions = uniq(
        elements.map((element) => {
          let skus: Array<any> = filter(
            SKUListBySceneObjectName[element.scene_object_name],
            element
          );
          skus = sortBy(skus, ['version']);

          const version = skus.length > 0 ? skus[skus.length - 1].version : -1;
          // const sku = (skus.length > 0) ? skus[skus.length - 1].sku : '';
          return version;
        })
      );

      // const versions = elements.map(element => element.version);
      const result = {
        module,
        versions,
      };

      yield result;
    }
  }

  /**
   *
   */
  generateAllElementWithSKU(SKUList) {
    let elements = this.generateAllElement();

    // elements = elements.slice(0, 10);

    const SKUListBySceneObjectName = groupBy(SKUList, 'scene_object_name');

    return elements.map((element) => {
      let skus: Array<any> = filter(
        SKUListBySceneObjectName[element.scene_object_name],
        element
      );
      skus = sortBy(skus, ['version']);
      const sku = skus.length > 0 ? skus[skus.length - 1].sku : -1;

      return { ...element, sku };
    });
  }

  /**
   * Scan the list of SKU to extract a pattern of similar version
   *
   * @param {array} SKUList
   * @return {array} of module & versions of the module
   */
  *skuValidation(SKUList) {
    let elements = this.generateAllElement();

    const SKUListBySceneObjectName = groupBy(SKUList, 'scene_object_name');
    // elements = elements.slice(0, 10);

    for (const element of elements) {
      let skus: Array<any> = filter(
        SKUListBySceneObjectName[element.scene_object_name],
        element
      );
      skus = sortBy(skus, ['version']);

      yield { element, skus };
    }
  }

  /**
   * Generate all element from module description
   *
   * @return {array} of elements
   */
  generateAllElement() {
    return flatten(
      this.generateAllModules().map((module) =>
        this.generateElement(module.furniture_type, module, colors)
      )
    );
  }

  /**
   * Generate a scene object name
   *
   * @return {string} scene object name
   */
  generateSceneObjectName(element) {
    const { length, width, height } = element;
    const dimensions = [length, width, height].join('x');
    const { furniture_type, section, type } = element;
    return [furniture_type, section, type, dimensions, 'obj'].join('_');
  }

  /**
   * Generate the full list of elements
   *
   */
  generateElement(furnitureType, module, colors) {
    //{
    //"module": ["armrest", "normal_composite"],
    //"dimensions_expand": [
    //[181],
    //[52],
    //[86]
    //],
    //"color": ["lacquered_chair"]
    //},
    const {
      module: [section, type],
    } = module;

    // Manage main properties
    const element = {
      furniture_type: furnitureType,
      section,
      type,
      // version: 0
      // length: 20,
      // width: 20,
      // height: 450,
      // color: 'grey_7024',
      // "scene_object_name": "couchtable_legs_medium_20x20x450_obj",
    };

    // Manage a list of dimensions
    const dimensionList = [];
    if (module.dimensions_expand) {
      for (const length of module.dimensions_expand[0]) {
        for (const width of module.dimensions_expand[1]) {
          for (const height of module.dimensions_expand[2]) {
            dimensionList.push({
              length,
              width,
              height,
            });
          }
        }
      }
    }

    if (module.dimensions) {
      for (let [
        lengthDimensions,
        widthDimensions,
        heightDimensions,
      ] of module.dimensions) {
        lengthDimensions = Array.isArray(lengthDimensions)
          ? lengthDimensions
          : [lengthDimensions];
        for (const length of lengthDimensions) {
          widthDimensions = Array.isArray(widthDimensions)
            ? widthDimensions
            : [widthDimensions];
          for (const width of widthDimensions) {
            heightDimensions = Array.isArray(heightDimensions)
              ? heightDimensions
              : [heightDimensions];
            for (const height of heightDimensions) {
              dimensionList.push({
                length,
                width,
                height,
              });
            }
          }
        }
      }
    }

    // Manage a list of colors
    const colorList = [];
    for (const colorGroup of module.color) {
      for (const color of colors[colorGroup]) {
        colorList.push({ color });
      }
    }

    // Generate elements
    const elements = [];
    for (const dimension of dimensionList) {
      for (const color of colorList) {
        const result = {
          ...element,
          ...dimension,
          ...color,
        };
        result.scene_object_name = this.generateSceneObjectName(result);
        elements.push(result);
      }
    }

    return elements;
  }
}

const elementService = new ElementService();

export default elementService;
