// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CfRibbon } from '@mycs/contentful';
import { RootState } from '../store';

type RibbonsSlicePayload = { ribbons: CfRibbon[]; locale: string };

type RibbonsSliceState = {
  [locale: string]: CfRibbon[];
};

const initialState: RibbonsSliceState = {};

export const ribbonsSlice = createSlice({
  name: 'ribbons',
  initialState,
  reducers: {
    setRibbons(
      state,
      { payload: { ribbons, locale } }: PayloadAction<RibbonsSlicePayload>
    ) {
      return {
        ...state,
        [locale]: ribbons,
      };
    },
  },
});

export const { setRibbons } = ribbonsSlice.actions;

export function getRibbonsFromReduxState(
  state: RootState,
  locale: string
): CfRibbon[] {
  return state.ribbons[locale];
}
