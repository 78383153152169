import cushionConfig from '../../config/cushion-config';
import Component from '../../components/Component';
import RendererService from '../../services/RendererService';
import colorConfig from '../../config/color-config';

/**
 * CushionElement
 *
 * @class CushionElement
 * @extends {Component}
 */
export default class CushionElement extends Component {
  /**
   * Add/Update props based on their characteristic
   *
   * @param {any} props
   * @param {any} element
   * @returns {any} new props
   */
  static hardcode(_props, element) {
    const newProps: any = {};
    newProps.shape = element.scene_object_name.replace(
      'cushion',
      'sofa_accessory'
    );
    return newProps;
  }

  static mappingShape(props) {
    if (!colorConfig.textile.includes(props.color)) {
      return { ...props };
    }

    return { ...props, color: 'default_shd' };
  }

  static mappingCover(props) {
    return {
      ...props,
      type: 'cover',
    };
  }

  /**
   * Mapping of some properties in order to compute
   * - shape
   * - material
   *
   * @param {object} element
   * @returns {object} assets override
   */
  static mappingAsset(element) {
    const newProps: any = {};

    newProps.shape = Component.generateSceneObjectName({
      ...element,
      furniture_type: element.furniture_type.replace(
        'cushion',
        'sofa_accessory'
      ),
    });

    return newProps;
  }

  /**
   * Render an element
   *
   * @returns {JSX.Element}
   */
  render() {
    const props = Component.decorateElement(
      this.props,
      cushionConfig.furnitureType,
      CushionElement,
      {
        custom: true,
        element: true,
        assets: false,
        hardcode: true,
        skus: false,
        extend: false,
      }
    );
    return <shape {...props} />;
  }
}
