import FlagModeService, {
  Mode,
} from 'mycs/shared/services/FlagModeService/FlagModeService';

import styles from './FlagMode.scss';
import { useUser } from 'mycs/hooks/useUser';
import { useUserTools } from 'mycs/hooks/useUserTools';

function FlagMode() {
  const modes = FlagModeService.getFlags();
  const { user, mutateUser } = useUser();
  const { signOut } = useUserTools(user, mutateUser);

  const disableFlag =
    (mode: Mode): React.MouseEventHandler<HTMLAnchorElement> =>
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (confirm('Are you sure you want to disable this flag?')) {
        FlagModeService.unsetFlag(mode);
        window.location.reload();
      }
    };

  const shouldShowUserInfo = modes.reduce(
    (acc, { showUserInfo }) => acc || showUserInfo,
    false
  );

  function handleSignOut() {
    signOut();
  }

  return (
    <div className={styles.container}>
      {shouldShowUserInfo && user?.email && (
        <div className={styles.item} key="user-info">
          <div className={styles.userMenu}>
            <div className={styles.email}>{user.email}</div>
            <div className={styles.signOut} onClick={handleSignOut}>
              sign out
            </div>
          </div>
        </div>
      )}
      {modes.map((mode) => (
        <div className={styles.item} key={mode.text}>
          <a href="" className={styles.modeText} onClick={disableFlag(mode)}>
            {mode.text}
          </a>
        </div>
      ))}
    </div>
  );
}

export default FlagMode;
