import FetchAPI from 'mycs/shared/utilities/FetchAPI/FetchAPI';

interface CreateRequestPayload {
  name: string;
  email: string;
  subject: string;
  comment: string;
  locale?: string;
  phone?: string;
  pageURL?: string;
  type?: string;
  origin?: string;
}

interface CreateRequestResponse {
  message: string;
}

export function createRequest(
  payload: CreateRequestPayload
): Promise<CreateRequestResponse> {
  return FetchAPI.post('/apigw/zendesk', payload);
}
