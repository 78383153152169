import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import {
  ElementApiService as furnitureElementApiService,
  ElementService as furnitureElementService,
} from 'furniture-engine';

import { asyncRequest } from 'mycs/shared/utilities/FetchUtils/FetchUtils';
import { getPageContentByKeyFromReduxState } from 'mycs/shared/state/slices/pageSlice';
import { mandatory } from 'mycs/shared/utilities/GeneralUtils/GeneralUtils';
import { store } from 'mycs/shared/state/store';
import UrlProviderService from 'mycs/shared/services/UrlProviderService/UrlProviderService';

type Bbox = object;

type Element = {
  sku: string;
  name: string;
  bbox: Bbox;
  furniture_type: string;
};

/**
 * class ElementApi
 * Service to retrieve data from ElementAPI
 */
class ElementApi {
  assetApiUrl = UrlProviderService.getAssetApiUrl();
  unrealBboxesUrl = UrlProviderService.getUnrealBboxesApiUrl();
  elements: { [furnitureType: string]: Element[] } = {}; // { furnitureType: Array<element> };
  bboxes: { [key: string]: Bbox } = {}; // bboxes are attached to skus according to the scene-lib model;

  init(locale: string, pathname: string) {
    // attempts to populate the elements from the store
    // TODO (Valentyn) there should be only one source of data
    const page = getPageContentByKeyFromReduxState<any>(store.getState(), {
      locale,
      pathname,
    });
    if (!page) return;

    const { configuratorData } = page;
    if (!configuratorData) return;

    const { elements, bboxes } = configuratorData;
    if (elements && elements.length) this.setElements(elements);
    if (bboxes) this.bboxes = bboxes;
  }

  /**
   * Get the element url according to furniture type
   */
  elementApiEndPoint(furnitureType: string): string {
    let apiUrl = `${this.assetApiUrl}/elements`;
    if (furnitureType) {
      apiUrl += `/${furnitureType}`;
    }
    return apiUrl;
  }

  /**
   * Set SKUs for all types
   */
  setElements(elements: Element[] = mandatory(), furnitureType?: string) {
    if (furnitureType) {
      this.elements[furnitureType] = elements;
    } else {
      const groupedElements = groupBy(elements, 'furniture_type');
      this.elements = { ...this.elements, ...groupedElements };
    }

    const generatedElements = furnitureElementService.generateAllElement();
    furnitureElementService.setElements(generatedElements);

    furnitureElementApiService.setElements(elements);
  }

  /**
   * Wait for the elements to be fetched, set and return the elements
   */
  async getElements(furnitureType: string = mandatory()): Promise<Element[]> {
    let elements = this.elements[furnitureType];

    if (!elements) {
      elements = await asyncRequest(this.elementApiEndPoint(furnitureType));
      this.setElements(elements, furnitureType);
    }

    return elements;
  }

  async getBboxes(): Promise<{ [name: string]: Bbox }> {
    if (this.bboxes && Object.keys(this.bboxes).length > 0) {
      return this.bboxes;
    }

    const elements = (await asyncRequest(this.unrealBboxesUrl)) as Element[];
    const bboxes = elements.reduce((acc, element) => {
      return {
        ...acc,
        [element.name]: {
          ...element.bbox,
        },
      };
    }, {} as { [name: string]: Bbox });

    this.bboxes = bboxes;
    return bboxes;
  }

  hasBboxes() {
    return !isEmpty(this.bboxes);
  }
}

const ElementApiService = new ElementApi();

export default ElementApiService;
