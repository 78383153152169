import { useEffect, useCallback } from 'react';
import { ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { reactSafeRenderer } from 'mycs/shared/utilities/reactSafeRenderer';
import ExitNewsletterForm from './ExitNewsletterForm';
import ExitSaveDesignForm from './ExitSaveDesignForm';

// CrossEngage's DOM elements
const xngModalSelector = '#ce-modal-contents';
const xngCloseButtonSelector = '#ce-modal-close-button';

// CrossEngage triggers an event on this subject
const xngSubject = new ReplaySubject(1);

if (typeof window !== 'undefined') {
  window.exitIntent$ = xngSubject;
  window.exitIntent$.onNext = window.exitIntent$.next;
}

type Props = {
  locale: string;
  pathname: string;
  countryCode: string;
};

export default function ExitIntentModal(props: Props) {
  /**
   * Close
   */
  const close = useCallback(() => {
    // since we close layer the component attached to, it must be done with timeout
    setTimeout(() => {
      const xngClose = document.querySelector<HTMLButtonElement>(
        xngCloseButtonSelector
      );
      if (xngClose) xngClose.click();
    }, 10);
  }, []);

  const renderContent = useCallback(
    (modalType: string) => {
      if (modalType === 'newsletter') {
        return <ExitNewsletterForm onClose={close} />;
      }

      if (modalType === 'saveDesign') {
        return (
          <ExitSaveDesignForm
            onClose={close}
            locale={props.locale}
            pathname={props.pathname}
            countryCode={props.countryCode}
          />
        );
      }

      return <></>;
    },
    [props, close]
  );

  /**
   * Attach to the CrossEngage layer
   */
  const attachToXng = useCallback(
    (modalType: string) => {
      // Hide the CrossEngage close button
      const xngClose = document.querySelector<HTMLButtonElement>(
        xngCloseButtonSelector
      );
      if (xngClose) xngClose.style.display = 'none';

      const crossEngageLayer = document.querySelector(xngModalSelector);

      reactSafeRenderer(renderContent(modalType), crossEngageLayer);
    },
    [renderContent]
  );

  useEffect(() => {
    const subscription = xngSubject
      .pipe(filter((data: any) => data && data.modalType))
      .subscribe((data: any) => attachToXng(data.modalType));

    return () => {
      subscription.unsubscribe();
    };
  }, [attachToXng]);

  return null;
}
