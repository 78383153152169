import MobileDetect from 'mobile-detect';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import config from 'mycs/config/deviceDimensions';

export const DeviceContext = createContext('');

export function useDevice() {
  const userAgent = useContext(DeviceContext);
  const md = useMemo(() => {
    return new MobileDetect(userAgent, 500);
  }, [userAgent]);

  const isPhone = Boolean(md.phone());
  // This function doesn't work on iPad Safari browsers, use hasTabletDimensions additionally
  const isTablet = Boolean(md.tablet());

  const dimensions = getWindowDimensions(isPhone, isTablet);
  const [innerWidth, setInnerWidth] = useState(dimensions.innerWidth);
  const [innerHeight, setInnerHeight] = useState(dimensions.innerHeight);

  const { isPortrait, isLandscape } = getScreenOrientation(
    innerWidth,
    innerHeight
  );
  const isTabletPortrait = isTablet && !isLandscape;

  const hasPhoneDimensions = innerWidth < 768;
  // Not always accounted for currently
  // so we check for wide tablet dimensions separately
  const hasWideTabletDimensions = innerWidth > 993 && innerWidth < 1025;
  // Check for tablet screen resolution
  // Alternative to isTablet for iPad Safari browsers
  const hasTabletDimensions = innerWidth > 767 && innerWidth < 993;
  const hasDesktopDimensions = innerWidth > 992;
  const isMobile = md.mobile() != null;
  const isIos = md.tablet() === 'iPad' || md.phone() === 'iPhone';

  useEffect(() => {
    function resizeListener() {
      const { innerWidth: w, innerHeight: h } = getWindowDimensions(
        isPhone,
        isTablet
      );
      setInnerWidth(w);
      setInnerHeight(h);
    }

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [isPhone, isTablet]);

  const shouldDisableTouchMoveRef = useRef(false);

  const freezePageScroll = useCallback(() => {
    // Healthy browsers.
    document.body.style.overflow = 'hidden';
    // Safari iOS.
    if (isIos) {
      shouldDisableTouchMoveRef.current = true;
    }
  }, [isIos]);

  const unfreezePageScroll = useCallback(() => {
    document.body.style.removeProperty('overflow');
    shouldDisableTouchMoveRef.current = false;
  }, []);

  useEffect(() => {
    function touchMoveListener(e: Event) {
      if (shouldDisableTouchMoveRef.current) {
        e.preventDefault();
      }
    }

    window.addEventListener('touchmove', touchMoveListener, {
      passive: false,
    });

    return () => {
      window.removeEventListener('touchmove', touchMoveListener);
    };
  }, []);

  return {
    isPortrait,
    isLandscape,
    isPhone,
    hasPhoneDimensions,
    hasTabletDimensions,
    hasWideTabletDimensions,
    hasDesktopDimensions,
    isMobile,
    isTablet,
    isTabletPortrait,
    isIos,

    freezePageScroll,
    unfreezePageScroll,
  };
}

function getScreenOrientation(innerWidth: number, innerHeight: number) {
  if (typeof window === 'undefined' || !window.screen.orientation?.type) {
    return {
      isPortrait: innerHeight > innerWidth,
      isLandscape: innerWidth > innerHeight,
    };
  }

  return {
    isPortrait: window.screen.orientation.type === 'portrait-primary',
    isLandscape: window.screen.orientation.type === 'landscape-primary',
  };
}

function getWindowDimensions(isPhone: boolean, isTablet: boolean) {
  if (MYCS_IS_SSR_BUILD) {
    const { innerWidth, innerHeight } = isPhone
      ? config.phone
      : isTablet
        ? config.tablet
        : config.default;

    return { innerWidth, innerHeight };
  }

  return {
    innerWidth:
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth,
    innerHeight:
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight,
  };
}
