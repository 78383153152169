import { configureStore } from '@reduxjs/toolkit';
// eslint-disable-next-line import/named
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { designsSlice, getDesignStateByRequest } from './slices/designsSlice';
import { getPageSliceKey, pagesSlice } from './slices/pageSlice';
import { translationsSlice } from './slices/translationsSlice';
import { ribbonsSlice } from './slices/ribbonsSlice';
import LocationUtils from '../utilities/LocationUtils/LocationUtils';
import { headersSlice } from './slices/headersSlice';

export const store = configureStore({
  reducer: {
    [designsSlice.name]: designsSlice.reducer,
    [headersSlice.name]: headersSlice.reducer,
    [pagesSlice.name]: pagesSlice.reducer,
    [ribbonsSlice.name]: ribbonsSlice.reducer,
    [translationsSlice.name]: translationsSlice.reducer,
  },
  preloadedState: getPreloadedState() || {},
  middleware: [],
});

export const dispatch = store.dispatch;

function getPreloadedState() {
  if (typeof document === 'undefined' || !document.querySelector) return null;

  const scriptTag = document.querySelector('#react-state');

  if (!scriptTag) return null;

  try {
    return JSON.parse(scriptTag.innerHTML);
  } catch (e) {
    return null;
  }
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function getStateByUrl(url: string) {
  const state = store.getState();

  const { pathname } = new URL(url);
  const locale = LocationUtils.getLocaleFromUrl(url);
  const pageKey = getPageSliceKey({ locale, pathname });

  return {
    designs: getDesignStateByRequest(state, { locale, pathname }),
    ribbons: {
      [locale]: state.ribbons[locale],
    },
    headers: {
      [locale]: state.headers[locale],
    },
    pages: {
      [pageKey]: state.pages[pageKey],
    },
    translations: {
      [locale]: state.translations[locale],
    },
  };
}
