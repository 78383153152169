import { fromByteArray } from 'base64-js';
import flatten from 'lodash/flatten';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isString from 'lodash/isString';
import uniq from 'lodash/uniq';
import { deflate } from 'pako';
import { CfGridBanner, FurnitureTypeEnum } from '@mycs/contentful';

import {
  Description,
  Design as DesignAPIDesignModel,
  Dimensions,
  GetBreadcrumbsByUuidResponse,
  PostDesignPayload,
  PostLinkPayload,
  PostLinkResponse,
  Price,
  descriptionsByUuid,
  designFiltersRanges,
  designInfo,
  designsFiltered,
  getDesigns,
  getDesignsByFilter,
  getDesignsByUuids,
  getRelatedColorsByUuid,
  getRelatedProductsByUuid,
  getRelatedShapesByUuid,
  postDesign,
  postLink,
  priceCalculateByStructure,
  NewImage,
  OldImage,
  getDesignsByCategory,
} from 'mycs/api/DesignAPI';
import cfg from 'mycs/config';
import StructureService from 'mycs/configurators/shared/services/StructureService';
import UrlProviderService from 'mycs/shared/services/UrlProviderService/UrlProviderService';
import { asyncRequest } from 'mycs/shared/utilities/FetchUtils/FetchUtils';
import LocationUtils from 'mycs/shared/utilities/LocationUtils/LocationUtils';

export interface Design extends Omit<DesignAPIDesignModel, 'price'> {
  price: Price;
  shipping_duration: number;
  dimensions: Dimensions[];
}

class DesignApiService {
  imageApiUrl: string;
  designApiUrl: string;
  explanation: {
    protocol: string;
    host: string;
    path: string;
    explanation?: boolean | undefined;
  };

  constructor() {
    this.imageApiUrl = UrlProviderService.getImageApiUrl();
    this.designApiUrl = UrlProviderService.getDesignApiUrl();

    // Flag to display shipping details computation explanation
    // for debug
    const {
      API: { designApi: explanation },
    } = cfg;
    this.explanation = explanation;
  }

  /**
   * Compress structure and get object data
   */
  getCompressedStructure(data: any): string {
    const f = deflate(JSON.stringify(data));
    return fromByteArray(f);
  }

  flattenDesignMaterials(materials: Design['colors']) {
    return uniq(
      flatten(
        Object.values(materials).map((color) => {
          if (typeof color === 'object' && !Array.isArray(color)) {
            return Object.keys(color || {});
          } else {
            return color;
          }
        })
      )
    );
  }

  /**
   * Check if the product contains strengthening bars
   */
  hasStrengtheningBars(product: any): boolean {
    return Boolean(product.specs && product.specs.metalBar);
  }

  /**
   * Indicates whether a design has fronts (drawers, doors)
   */
  hasFronts(design: Design) {
    return !!design.specs.doors || !!design.specs.drawers;
  }

  /**
   * Add additional information to designs:
   *
   *  - url to square image
   *  - price (depending on current country code)
   *  - shipping_duration
   */
  _extendDesigns(
    designs: DesignAPIDesignModel[],
    countryCode: string
  ): Design[] {
    return designs.map((design) => {
      const price = design.price[countryCode];
      const { dimensions } = design;

      return {
        ...design,
        price,
        shipping_duration: this._setCustomShippingDuration(design.furniture_type, price.shippingDuration),
        dimensions: isArray(dimensions) ? dimensions : [dimensions],
      };
    });
  }

  // TODO: Change shipping duration of gryd(shelf) on BE. This is a temporary fix. 
  _setCustomShippingDuration(furniture_type: string, shipping_duration: number | null | undefined): number {
    let shippingDuration;
    switch (furniture_type) {
      case FurnitureTypeEnum.Shelf:
        shippingDuration = 63;
        break;
      case FurnitureTypeEnum.Pyllow:
        shippingDuration = 35;
        break;
      default:
        shippingDuration = shipping_duration || 0;
        break;
    }
    return shippingDuration;
  }

  /**
   * Generate data to be passed to Design API
   */
  _generateFurnitureData(): { furniture_type: string; structure: any } {
    if (!StructureService.getStructure()) {
      throw new Error('structure is undefined');
    }

    return {
      furniture_type: StructureService.getCurrentFurnitureType(),
      structure: StructureService.getStructure(),
    };
  }

  /**
   * Request a rendering(s) from Design API.
   * In case of success, returns a mapped object with all designs
   *
   * @param uuids Separated by coma
   */
  getRendering(
    uuids: string,
    countryCode: string,
    withDiscounts = false,
    withShippingDetails = true,
    isAssembly = false,
    isWorkshop = false
  ) {
    if (!uuids.length) return Promise.resolve([]);

    const params = {
      is_cart: withDiscounts,
      with_shipping_details: withShippingDetails,
      assembly: isAssembly,
      workshop: isWorkshop,
      explanation: !!this.explanation,
    };

    return this._getRendering(uuids, countryCode, params);
  }

  /**
   * Same as `this.getRendering()` but without specifying the `workshop` querystring.
   * In that case Design API is going to compute whether Workshop is needed by
   * using Furniture-Engine.
   *
   * In case of success, returns a mapped object with all designs
   *
   * @param uuids - Separated by coma
   */
  async getRenderingWithoutWorkshop(
    uuids: string,
    countryCode: string,
    withDiscounts = false,
    withShippingDetails = true,
    isAssembly = false
  ) {
    if (!uuids.length) return Promise.resolve([]);

    const params = {
      is_cart: withDiscounts,
      with_shipping_details: withShippingDetails,
      assembly: isAssembly,
      explanation: !!this.explanation,
    };

    return await this._getRendering(uuids, countryCode, params);
  }

  /**
   * Helper method used by getRendering() and getRenderingWithoutWorkshop()
   *
   * @param {string} uuids - Separated by coma
   */
  async _getRendering(uuids: string, countryCode: string, params: any) {
    const data = await getDesignsByUuids(countryCode, uuids, params);

    return this._extendDesigns(data, countryCode);
  }

  getDesignsByUuids(
    uuids: string[],
    countryCode: string,
    withShippingDetails = true
  ) {
    return this.getRenderingWithoutWorkshop(
      uuids.join(','),
      countryCode,
      false,
      withShippingDetails
    ).then((designs) => {
      return uuids
        .map((uuid) => designs.find((item) => item.uuid === uuid))
        .filter((design): design is Design => !!design);
    });
  }

  async getDesignsByCategory(
    category: string,
    countryCode: string,
    params?: Record<string, unknown>
  ) {
    const { designs } = await getDesignsByCategory(
      category,
      countryCode,
      params
    );

    return this._extendDesigns(designs, countryCode);
  }

  /**
   * Get Title and Description by UUID
   */
  async getDescriptionsByUuid(
    uuid: string,
    countryCode: string,
    locale: string
  ): Promise<Description[]> {
    const languageCode = LocationUtils.getDomainLanguageShort(
      countryCode,
      locale
    );
    return descriptionsByUuid(countryCode, uuid, languageCode);
  }

  /**
   * Get Breadcrumbs by UUID
   */
  async getBreadcrumbsByUuid(
    uuid: string,
    countryCode: string
  ): Promise<GetBreadcrumbsByUuidResponse> {
    const url = `${this.designApiUrl}/${uuid}/breadcrumbs/${countryCode}`;
    return asyncRequest(url);
  }

  /**
   * Request the prices per country for any passed data structure
   *
   * @param furnitureType
   * @param dataStructure
   * @param isAssembly - Forces a response with an Assembly Shipping Method
   * @param quantity
   * @param baseboardCut - Will be used to compute if the Workshop backlog if available
   * @returns Object containing the pricing for every country
   */
  getPriceByDataStructure(
    furnitureType: string,
    dataStructure: any,
    countryCode: string,
    isAssembly = false,
    quantity = 1,
    baseboardCut = false
  ) {
    const with_shipping_details = true;
    const workshop = false;

    let explanation = false;
    if (this.explanation) explanation = true;

    return priceCalculateByStructure(
      countryCode,
      furnitureType,
      this.getCompressedStructure(dataStructure),
      quantity,
      with_shipping_details,
      isAssembly,
      explanation,
      workshop,
      baseboardCut
    );
  }

  /**
   * Find all designs according to params
   *
   * @param params Filter options
   */
  getDesigns(
    countryCode: string,
    params: any = { limit: 100, offset: 0 }
  ): Promise<any> {
    return getDesigns(countryCode, params);
  }

  /**
   * Find all designs according to params
   *
   * @param {any} [params] Filter options
   * @returns {Promise<any>}
   */
  async getDesignsByFilter(
    params: any = { limit: 100, offset: 0 },
    countryCode: string
  ): Promise<any> {
    const responseData = await getDesignsByFilter(countryCode, params);

    // for consistency across all furniture types
    responseData.uuids.forEach((design: any) => {
      if (!isArray(design.dimensions)) {
        design.dimensions = [design.dimensions];
      }
    });

    return responseData.uuids;
  }

  /**
   * Request information (specs, colors and dimensions) for a passed data structure
   *
   * @returns Object containing the information
   */
  getInfoByDataStructure(
    furnitureType: string,
    dataStructure: any,
    countryCode: string
  ): Promise<any> {
    return designInfo(
      countryCode,
      furnitureType,
      this.getCompressedStructure(dataStructure)
    );
  }

  /**
   * @deprecated use postDesign instead
   * Request to the Design API to generate a new image
   * depending on the passed data object
   */
  saveCurrentDesign(): Promise<any> {
    // Get the (final) image information for the current data structure
    const design = this._generateFurnitureData();

    return postDesign(design);
  }

  /**
   * Get similar designs for a uuid
   */
  async getRelatedProductsByUuid(countryCode: string, uuid: string) {
    if (!isString(uuid)) {
      throw new Error('Invalid parameter');
    }
    const responseData = await getRelatedProductsByUuid(countryCode, uuid);

    return this._extendDesigns(responseData, countryCode);
  }

  async getRelatedShapesByUuid(countryCode: string, uuid: string) {
    const responseData = await getRelatedShapesByUuid(countryCode, uuid);

    return this._extendDesigns(responseData, countryCode);
  }

  async getRelatedColorsByUuid(countryCode: string, uuid: string) {
    const responseData = await getRelatedColorsByUuid(countryCode, uuid);

    return this._extendDesigns(responseData, countryCode);
  }

  /**
   * Get a product image URL by UUID
   */
  getProductImageUrl(uuid: string, count = 0): string {
    return `${this.designApiUrl}/${uuid}/images/${count}`;
  }

  /**
   * Get the frontal view image.
   */
  getFaceImageUrl(images: (NewImage | OldImage)[]) {
    const image = images.find((image) =>
      this.isNewImage(image)
        ? // New image
        image.position === 1
        : // Old image
        image.type === 'FRONTAL_VIEW_DESIGN_LINES' ||
        image.type === 'PLAIN_FACE' ||
        (image.type === 'CATALOGUE' &&
          image.status !== 'failed' &&
          image.camera &&
          image.camera.width === 1440 &&
          image.camera.height === 1440 &&
          image.processing &&
          image.processing.floor &&
          isEqual(image.processing.floor, [227 / 255, 227 / 255, 227 / 255]))
    );

    return image && this.cleanUrl(image.url);
  }

  /**
   * Get closeUp images
   */
  getCloseUpImagesUrls(
    images: (NewImage | OldImage)[],
    limit?: number
  ): string[] {
    const closeUpImages = images.filter((image) =>
      this.isNewImage(image)
        ? // New image
        image.type.includes('closeup')
        : // Old image
        image.stage &&
        image.camera &&
        image.status !== 'failed' &&
        image.stage.indexOf('closeup') > -1 &&
        image.camera.width === 1440 &&
        image.camera.height === 1440 &&
        image.url
    );

    const closeUpImagesUrls = closeUpImages.map((image) =>
      this.cleanUrl(image.url)
    );

    return limit ? closeUpImagesUrls.slice(0, limit) : closeUpImagesUrls;
  }

  /**
   * We can only enable it for DE right now due to missing translations
   */
  shouldRenderCategoryData(
    grid: { category: string | undefined },
    countryCode: string
  ) {
    return !!grid?.category && countryCode === 'de';
  }

  getGridBannersUUIDs(gridBanners: CfGridBanner[]): string[] {
    return (
      gridBanners
        ?.filter((i) => i.type === 'Featured product')
        .map((i) => {
          const subtype = i.gridBannerSubtype[0];
          if (subtype._contentType === 'featuredProduct' && subtype.uuid) {
            return subtype.uuid;
          }

          return '';
        })
        .filter(Boolean) || []
    );
  }

  /**
   * Get scene images
   */
  getSceneImagesUrls(
    images: (NewImage | OldImage)[],
    limit?: number
  ): string[] {
    const sceneImages = images.filter((image) =>
      this.isNewImage(image)
        ? // New image
        !image.type.includes('studio')
        : // Old image
        image.stage &&
        image.camera &&
        image.status !== 'failed' &&
        image.stage !== 'default' &&
        image.stage.indexOf('closeup') < 0 &&
        image.camera.width === 1440 &&
        image.camera.height === 1440 &&
        image.url
    );

    const sceneImagesUrls = sceneImages.map((image) =>
      this.cleanUrl(image.url)
    );

    return limit ? sceneImagesUrls.slice(0, limit) : sceneImagesUrls;
  }

  isNoFrontsImage(image: NewImage | OldImage) {
    return this.isNewImage(image)
      ? // New image
      image.type.includes('no_doors')
      : // Old image
      image.type.includes('MAIN_WITHOUT_DOORS');
  }

  isStudioMainImage(image: NewImage | OldImage) {
    return this.isNewImage(image)
      ? // New image
      image.type === 'studio_main'
      : // noop
      false;
  }

  /**
   * Post a link between 2 designs
   */
  postLink(data: PostLinkPayload): Promise<PostLinkResponse> {
    return postLink(data);
  }

  postDesign(data: PostDesignPayload) {
    return postDesign(data);
  }

  /**
   * Get filtered designs
   */
  async getFilteredDesigns(params: any, countryCode: string): Promise<any> {
    const responseData = await designsFiltered(params);

    return this._extendDesigns(responseData, countryCode);
  }

  /**
   * Get the min & max ranges for prices and dimensions per furniture group
   */
  async getPricesDimensionsRanges(
    furnitureGroup: string,
    countryCode: string
  ): Promise<any> {
    return designFiltersRanges(countryCode, furnitureGroup);
  }

  cleanUrl(url: string) {
    return url && url.startsWith('http') ? url : `https:${url}`;
  }

  isNewImage(image: NewImage | OldImage): image is NewImage {
    return 'position' in image;
  }
}

const service = new DesignApiService();

export default service;
