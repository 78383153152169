// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CfHeaderMenu } from '@mycs/contentful';
import { RootState } from '../store';

type HeaderActionPayload = {
  locale: string;
  data: CfHeaderMenu;
};

type HeadersSliceState = {
  [key: string]: CfHeaderMenu;
};

const initialState: HeadersSliceState = {};

export const headersSlice = createSlice({
  name: 'headers',
  initialState,
  reducers: {
    setHeader(state, action: PayloadAction<HeaderActionPayload>) {
      const { locale, data } = action.payload;

      return {
        ...state,
        [locale]: data,
      };
    },
  },
});

export const { setHeader } = headersSlice.actions;

export function getHeaderFromByLocaleReduxState(
  state: RootState,
  locale: string
): CfHeaderMenu {
  return state.headers[locale];
}
