import invert from 'lodash/invert';

import translations from 'mycs/config/translations';

const ASCII = 'ascii';

/**
 * Hard coded translations (urls, etc)
 */
export default class DictionaryUtils {
  // Inverted Dictionary.
  // e.g. { fr_FR: { tous: 'all'  } ... }
  static invertedDictionary: {
    [locale: string]: {
      [word: string]: string;
    };
  } = {};

  /**
   * Look Up.
   * Looks up a definition in the ASCII dictionary.
   *
   * @param {string} word
   * @param {string} locale e.g. "fr_CH"
   * @param {boolean} invertDictionary look up in the inverted dictionary.
   * @returns {string|null} returns "null" if no definition was found.
   */
  static lookUp(word: string, locale: string, invertDictionary = false) {
    if (!word) {
      throw Error(`no word was provided for lookup`);
    }

    const dictionaryCode = `${ASCII}-${locale}`;
    const dictionary = translations[dictionaryCode];
    if (!dictionary) {
      throw Error(`no dictionary for language ${locale}`);
    }

    if (!invertDictionary) {
      return dictionary[word] || null;
    }

    let invertedDictionary = DictionaryUtils.invertedDictionary[dictionaryCode];
    if (!invertedDictionary) {
      invertedDictionary = invert(dictionary);
      DictionaryUtils.invertedDictionary[dictionaryCode] = invertedDictionary;
    }

    return invertedDictionary[word] || null;
  }
}
