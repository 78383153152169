import { PureComponent } from 'react';
import { Provider } from 'react-redux';

import { ContentfulContextProvider } from 'mycs/shared/services/ContentfulService/ContentfulContext';
import { LinkInterceptor } from 'mycs/shared/components/LinkInterceptor/LinkInterceptor';
import { store } from 'mycs/shared/state/store';
import { ToastContainer } from 'mycs/hooks/useToast';
import { useAnalyticsTools } from 'mycs/hooks/useAnalyticsTools';
import { useUserURLSignin } from 'mycs/hooks/useUserURLSignin';
import AnalyticsService from 'mycs/shared/services/AnalyticsService/AnalyticsService';
import CookieService from 'mycs/shared/services/CookieService/CookieService';
import EcondaAnalyticsService from 'mycs/shared/services/EcondaAnalyticsService';
import ElementApiService from 'mycs/shared/services/ElementApiService/ElementApiService';
import FlagModeService from 'mycs/shared/services/FlagModeService/FlagModeService';
import Footer from 'mycs/shared/components/Footer/Footer';
import GeolocationService from 'mycs/shared/services/GeolocationService/GeolocationService';
import Header from 'mycs/shared/components/Header/Header';
import LocalStorageUtils from 'mycs/shared/utilities/LocalStorageUtils/LocalStorageUtils';
import MetaTags from 'mycs/shared/components/MetaTagsRenderer/MetaTagsRenderer';
import ReportingAPIService from 'mycs/shared/services/AnalyticsService/ReportingAPIService';
import RibbonService from 'mycs/shared/services/RibbonService/RibbonService';
import SessionService from 'mycs/shared/services/SessionService/SessionService';
import TrustPilotService from 'mycs/shared/services/TrustPilotService/TrustPilotService';

// Components that must have low-priority CSS
// Importing them all here makes their styles appear on top of all other CSS
import 'mycs/shared/components/Icon/Icon.scss';
import 'mycs/shared/components/Button/Button.scss';
import 'mycs/shared/components/Input/Input.scss';

type Props = {
  url: string;
  locale: string;
  countryCode: string;
  pathname: string;
  children: React.ReactNode;
};

function Hooks() {
  useAnalyticsTools();
  useUserURLSignin();

  return null;
}

export default class App extends PureComponent<Props> {
  /**
   * Method to load all the services and Utils needed before Rendering the App
   */
  constructor(props: Props) {
    super(props);

    LocalStorageUtils.removeTmpItems(); // Clear items from previous storage version
    LocalStorageUtils.setStorageVersion(); // Set storage version
    FlagModeService.init(); // Set "flag modes"
    RibbonService.init();
    RibbonService.incrementVisits(); // Init the ribbon service
    SessionService.init(); // Init Session
    ElementApiService.init(this.props.locale, this.props.pathname);
  }

  /**
   * Method to load all the services and Utils needed after Rendering the App
   */
  componentDidMount() {
    CookieService.init();

    EcondaAnalyticsService.init();
    GeolocationService.init();
    ReportingAPIService.init();
    AnalyticsService.init();
    SessionService.initEvents();
    TrustPilotService.init();
  }

  render() {
    return (
      <LinkInterceptor>
        <ToastContainer />
        <Hooks />
        <Provider store={store}>
          <ContentfulContextProvider>
            <Header />
            <MetaTags />

            {this.props.children}

            <Footer />
          </ContentfulContextProvider>
        </Provider>
      </LinkInterceptor>
    );
  }
}
