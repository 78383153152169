import { InputHTMLAttributes, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { getNextCheckboxName } from './checkbox-utils';
import { noop } from 'mycs/shared/utilities/GeneralUtils/GeneralUtils';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import { useTrackEvent } from '../TrackEvent/useTrackEvent';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';

import styles from './Checkbox.scss';

type Props = {
  className?: string;
  checkboxClass?: string;
  labelClass?: string;
  label?: string;
  children?: React.ReactNode;
  onChange?: (checked: boolean, event: any) => void;
  checked?: boolean;
  disabled?: boolean;
  name?: string;
  isBig?: boolean;
  nativeProps?: InputHTMLAttributes<HTMLInputElement>;
  modernLayout?: boolean;
  isLeftAlignedLayout?: boolean;
  isErrorHighlighted?: boolean;
  isToggleClickDisabled?: boolean;
};

type State = {
  checked?: boolean;
  disabled?: boolean;
};

export default function Checkbox({
  checked = undefined,
  disabled = false,
  isBig = false,
  onChange = noop,
  modernLayout = false,
  isLeftAlignedLayout = false,
  isErrorHighlighted = false,
  isToggleClickDisabled = false,
  children,
  label,
  labelClass,
  nativeProps,
  className,
  checkboxClass,
  ...props
}: Props) {
  const { locale } = useLocale();
  const [state, setState] = useState<State>({
    checked,
    disabled,
  });
  const name = useMemo(() => {
    return (
      props.name || (nativeProps && nativeProps.name) || getNextCheckboxName()
    );
  }, [props.name, nativeProps]);
  const trackEvent = useTrackEvent({ name: 'click', data: { label } });

  /**
   * Set state when receiving new props
   */
  useEffect(() => {
    setState({ checked, disabled });
  }, [checked, disabled]);

  const onChangeHandler = (event: any) => {
    if (isToggleClickDisabled) return;

    const checked = !state.checked;
    setState({ ...state, checked: !state.checked });
    onChange?.(checked, event);
  };

  const classes = classNames(styles.container, className, {
    [styles.checked]: state.checked,
    [styles.disabled]: state.disabled,
    [styles.big]: isBig,
    [styles.modernLayout]: modernLayout,
    [styles.leftAlignedLayout]: isLeftAlignedLayout,
    [styles.errorHighlight]: isErrorHighlighted,
  });

  return (
    <label className={classes}>
      <div
        className={classNames(styles.checkbox, checkboxClass, 'hidden-print')}
      >
        <input
          className={styles.checkboxInput}
          onChange={onChangeHandler}
          type="checkbox"
          name={name}
          defaultChecked={state.checked}
          disabled={state.disabled}
          {...trackEvent.wrapProps(nativeProps)}
        />
      </div>
      <div className={classNames(styles.label, labelClass)}>
        {children || I18nUtils.localize(locale, label)}
      </div>
    </label>
  );
}
