import { PureComponent } from 'react';
import { ReactSVG } from 'react-svg';
//@ts-ignore
import SVGInline from 'react-svg-inline';
import classNames from 'classnames';

import { noop } from 'mycs/shared/utilities/GeneralUtils/GeneralUtils';
import cfg from 'mycs/config';

import styles from './Icon.scss';

type Props = {
  iconName?: string;
  iconPath?: string;
  iconContent?: string;
  className?: string;
  isInline?: boolean;
  onClick?: (event: any) => void;
  iconContainerClass?: string;
};

/**
 * SVG Icon
 */
export default class Icon extends PureComponent<Props> {
  static defaultProps = {
    onClick: noop,
  };

  /**
   * Returns the path to the icon
   */
  getIconPath(): string {
    const { iconPath, iconName } = this.props;
    if (iconPath) return iconPath;
    if (!iconName) return '';
    // A full URL
    if (/^(https?:)?\/\//.test(iconName)) return iconName;

    const { iconBasePath } = cfg;
    const split = iconName.split('.');
    const name = split[0];
    const extension = split[1] || 'svg';
    return `${iconBasePath + name}.${extension}`;
  }

  /**
   * Render an SVG icon by path
   */
  renderSVG(path: string, classes: string): React.ReactNode {
    return (
      <ReactSVG
        src={path}
        beforeInjection={(svg) => {
          svg.classList.add(...classes.split(' '));
        }}
      />
    );
  }

  /**
   * Render an inline SVG icon
   */
  renderInlineSVG(iconContent: string, classes: string): React.ReactNode {
    return <SVGInline className={classes} svg={iconContent} />;
  }

  /**
   * Renders the Icon Svg
   */
  render() {
    const { className, onClick, iconContainerClass, iconContent, isInline } =
      this.props;
    const classes = classNames(styles.iconContainer, iconContainerClass, {
      [styles.iconContainerInline]: isInline,
    });
    const iconClasses = classNames(className, styles.icon);
    const path = !iconContent ? this.getIconPath() : '';
    const isSvg = path && path.endsWith('.svg');

    return (
      <div className={classes} onClick={onClick} data-testid="icon" role="img">
        {iconContent ? (
          this.renderInlineSVG(iconContent, iconClasses)
        ) : isSvg ? (
          this.renderSVG(path, iconClasses)
        ) : (
          <img className={iconClasses} src={path} />
        )}
      </div>
    );
  }
}
