import { getTranslationsFromReduxState } from 'mycs/shared/state/slices/translationsSlice';
import { store } from 'mycs/shared/state/store';

const FORMATTERS: {
  [locale: string]: {
    currency: string;
    symbol: string;
    decimal: string;
    thousand: string;
    precision: number;
    format: string;
  };
} = {
  default: {
    currency: 'EUR',
    symbol: '€',
    decimal: ',',
    thousand: '.',
    precision: 2,
    format: '%v %s',
  },
  de_DE: {
    currency: 'EUR',
    symbol: '€',
    decimal: ',',
    thousand: '.',
    precision: 2,
    format: '%v %s',
  },
  de_CH: {
    currency: 'CHF',
    symbol: 'CHF',
    decimal: '.',
    thousand: "'",
    precision: 2,
    format: '%s %v',
  },
  fr_CH: {
    currency: 'CHF',
    symbol: 'CHF',
    decimal: '.',
    thousand: "'",
    precision: 2,
    format: '%s %v',
  },
  en_GB: {
    currency: 'GBP',
    symbol: '£',
    decimal: '.',
    thousand: ',',
    precision: 2,
    format: '%s %v',
  },
};
/**
 * Internationalization Utilities
 */
export default class I18nUtils {
  static _getTranslations(locale: string) {
    return getTranslationsFromReduxState(store.getState(), locale) || {};
  }

  static formatDate(date: Date | string, _locale: string, options?: object) {
    const locale = _locale.replace(/_/, '-');
    return new Date(date).toLocaleDateString(locale, options);
  }

  /**
   * It does the actual translation
   */
  static localize(locale: string, key?: string) {
    if (!key) {
      return '';
    }

    let value = I18nUtils._getTranslations(locale)[key];
    if (value == null) value = key;

    return value;
  }

  /**
   * Returns the currency format
   */
  static getCurrencyFormat(locale: string, precision?: number) {
    const formatter = FORMATTERS[locale] || FORMATTERS.default;

    if (typeof precision !== 'undefined') {
      formatter.precision = precision;
    }

    return formatter;
  }

  static localizePrice(price: number, precision: number, locale: string) {
    return formatMoney(price, precision, locale);
  }
}

function formatMoney(value: number, precision: number, locale: string) {
  const format = I18nUtils.getCurrencyFormat(locale);

  const formattedValue = new Intl.NumberFormat('de-DE', {
    maximumFractionDigits: precision,
    minimumFractionDigits: precision,
  }).format(value);

  const price = format.format
    .replace('%v', formattedValue)
    .replace('%s', format.symbol)
    .replace(/[.]/g, format.thousand)
    .replace(/[,]/g, format.decimal);

  return price;
}
