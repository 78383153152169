import './polyfills';
import 'mycs/app.scss';

import ReactDOM from 'react-dom';
import { loadableReady } from '@loadable/component';

import { CsrRouter } from 'mycs/router/Router';
import { dispatch } from 'mycs/shared/state/store';
import { setTranslations } from 'mycs/shared/state/slices/translationsSlice';
import { startBugsnag } from 'mycs/shared/services/BugsnagService/BugsnagService';
import LocationUtils from 'mycs/shared/utilities/LocationUtils/LocationUtils';
import Logger from 'mycs/shared/services/Logger';
import ReactModal from 'mycs/shared/utilities/ReactModal';
import TranslationService from 'mycs/shared/services/TranslationService/TranslationService';
import WindowLocationUtils from 'mycs/shared/utilities/WindowLocationUtils/WindowLocationUtils';
import ErrorBoundary from 'mycs/landing-page/components/ErrorBoundary/ErrorBoundary';
import ContentfulService from './shared/services/ContentfulService/ContentfulService';
import { setHeader } from './shared/state/slices/headersSlice';

startBugsnag();
Logger.init();
WindowLocationUtils.initBrowser();

loadableReady(() => {
  renderApp();
});

if (module.hot) {
  module.hot.accept();
}

function renderApp() {
  const url = window.location.href;

  const jsx = (
    <ErrorBoundary>
      <CsrRouter url={url} />
    </ErrorBoundary>
  );

  const reactRoot = document.querySelector('#react-root');
  if (reactRoot) {
    //react-modal needs a node element to attach the dialog containers
    ReactModal.setAppElement(reactRoot as any);
  }
  const isServerRendered = reactRoot && reactRoot.innerHTML.trim().length;

  if (isServerRendered) {
    try {
      // FIXME: This is deprecated and should be replaced with hydrateRoot
      // eslint-disable-next-line react/no-deprecated
      ReactDOM.hydrate(jsx, reactRoot);
    } catch (e) {
      Logger.error(e);
    }
  } else {
    const locale = LocationUtils.getLocaleFromUrl(url);
    const countryCode = LocationUtils.getCountryCodeFromUrl(url);

    const translations = TranslationService.getTranslations(
      locale,
      countryCode
    ).then((translations) =>
      dispatch(setTranslations({ locale, data: translations }))
    );

    const headerData = ContentfulService.getHeader(locale, countryCode).then(
      (data) => dispatch(setHeader({ data, locale }))
    );

    Promise.all([translations, headerData]).then(() => {
      // FIXME: This is deprecated and should be replaced with createRoot
      // eslint-disable-next-line react/no-deprecated
      ReactDOM.render(jsx, reactRoot);
    });
  }
}
