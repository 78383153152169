import { getUser } from 'mycs/api/UserAPI';
import { getUserID } from 'mycs/services/UserService';
import Logger from 'mycs/shared/services/Logger';
import useSWR from 'swr';

export function useUser() {
  const {
    data: user,
    error: mutateUserError,
    mutate: mutateUser,
    isValidating: isValidatingUser,
  } = useSWR('user', () => {
    const userID = getUserID();
    if (!userID) {
      return;
    }

    return getUser(userID).catch((error) => {
      Logger.error('failed to get user:', error);

      throw error;
    });
  });

  return {
    user,
    mutateUser,
    mutateUserError,
    isValidatingUser,
  };
}
