/* eslint-disable no-console */
import config from 'mycs/config';
import { notifyBugsnag } from 'mycs/shared/services/BugsnagService/BugsnagService';
import SessionService from 'mycs/shared/services/SessionService/SessionService';

/**
 * R7Logger interface.
 */
export interface R7Logger {
  init: (input: {
    token: string; // Mandatory.
    region: 'us' | 'eu'; // Mandatory.
    catchall?: boolean; // Log any uncaught JavaScript exceptions.
    trace?: boolean; // Adds a randomly generated trace code.
    print?: boolean; // Echo events to the screen via the console object.
    page_info?: 'never' | 'per-page' | 'per-entry'; // Append basic information about browser capabilities.
  }) => void;
  log: (...args: any[]) => void;
  info: (...args: any[]) => void;
  warn: (...args: any[]) => void;
  error: (...args: any[]) => void;
}

type LogArg = string | boolean | number | Error | Record<string, any>;

/**
 * Logger.
 * Rapid7 insightOps API client wrapper.
 * https://github.com/rapid7/r7insight_js/wiki/API
 */
class Logger {
  private isSSR = Boolean(MYCS_IS_SSR_BUILD);
  private appVersion = process.env.APP_VERSION || 'unknown';
  private logger: R7Logger | null = null;

  discardedLogs = [
    'Fetching description for UUID',
    'Heap is not loaded after',
    'Contentful samples invalid',
  ];

  async init() {
    // TODO Set up SSR logging with 'r7insight_node'.
    if (this.isSSR || !window.R7Insight) {
      return;
    }

    this.logger = window.R7Insight;
    this.logger.init({
      token: config.rapid7Token,
      region: 'eu' as const,
      catchall: false, // see custom window.onerror listener below.
      trace: false, // GDPR compliance.
      print: false,
      page_info: 'per-page' as const,
    });

    window.addEventListener('error', (e) => {
      // We are purposely bypassing error() interface,
      // not to pass the logs to Bugsnag
      // (it has built-in onerror listener).
      const args = [
        'window.onerror',
        {
          message: e.message,
          location: window.location.href,
        },
      ];

      if (this.shouldDiscardLog(args)) {
        return;
      }

      this.logger?.error(this.formatLog(args));
    });
  }

  log(...args: LogArg[]) {
    if (this.shouldDiscardLog(args)) {
      return;
    }

    this.logger?.log(this.formatLog(args));
  }

  info(...args: LogArg[]) {
    if (this.shouldDiscardLog(args)) {
      return;
    }

    this.logger?.info(this.formatLog(args));
  }

  error(...args: LogArg[]) {
    if (this.shouldPrintToConsole()) {
      console.error(...args);
    }

    if (this.shouldDiscardLog(args)) {
      return;
    }

    this.logger?.error(this.formatLog(args));
    this.notifyBugsnag('error', ...args);
  }

  warn(...args: LogArg[]) {
    if (this.shouldPrintToConsole()) {
      console.warn(...args);
    }

    if (this.shouldDiscardLog(args)) {
      return;
    }

    this.logger?.warn(this.formatLog(args));
    this.notifyBugsnag('warning', ...args);
  }

  /**
   * Should discard log.
   * Determines if a log should be sent to Rapid7/Bugsnag.
   * @param {Event} event
   */
  shouldDiscardLog(args: LogArg[]) {
    let discardLog = false;

    args.forEach((arg) => {
      let logArg = '';

      if (arg instanceof Error) {
        logArg = arg.message;
      } else if (typeof arg === 'string') {
        logArg = arg;
      } else if (typeof arg === 'number') {
        logArg = arg.toString();
      } else if (typeof arg === 'boolean') {
        logArg = arg.toString();
      } else if (arg.message) {
        logArg = arg.message;
      }

      if (!logArg) {
        return;
      }

      this.discardedLogs.forEach((discardedLog) => {
        if (logArg.includes(discardedLog)) {
          discardLog = true;
        }
      });
    });

    return discardLog;
  }

  private shouldPrintToConsole() {
    return process.env.APP_STAGE === 'dev' || this.isSSR;
  }

  private formatLog(logArgs: LogArg[]) {
    // Rapid7 flattens Objects and arrays to make them searchable
    // LE.log({ args: ['Could not get server time diff:', 'Oops'] });
    // => args.0='Could not get server time diff:' args.1='Oops'
    const log: LogArg[] = [];
    logArgs.forEach((arg) => {
      // Rapid7 does not parse Error
      if (arg instanceof Error) {
        log.push(arg.message);
      } else {
        log.push(arg);
      }
    });

    return {
      app: this.appVersion,
      ssr: this.isSSR,
      sid: SessionService.getSID(),
      log,
    };
  }

  private notifyBugsnag(severity: 'error' | 'warning', ...args: LogArg[]) {
    if (this.isSSR) {
      return;
    }

    notifyBugsnag(severity, ...args);
  }
}

export default new Logger();
