import _flatten from 'lodash/flatten';
import { Utils } from 'furniture-engine';

import { CfMaterial, CfMetaTags, CfPagePdp } from '@mycs/contentful';
import type { Design } from 'mycs/shared/services/DesignApiService/DesignApiService';
import { isString } from 'mycs/shared/types/type-guards';
import { RelativeUrlService } from 'mycs/shared/services/RelativeUrlService';
import cfg from 'mycs/config';
import ContentfulService, {
  CfEnhancedMetaTags,
} from 'mycs/shared/services/ContentfulService/ContentfulService';
import DesignApiService from 'mycs/shared/services/DesignApiService/DesignApiService';
import Logger from 'mycs/shared/services/Logger';
import StructureSpecsService from 'mycs/shared/services/StructureSpecsService/StructureSpecsService';
import UrlProviderService from 'mycs/shared/services/UrlProviderService/UrlProviderService';

let materialsCache: CfMaterial[];

type IMaterialPreview = {
  thumbnail: string;
  preview: string;
  image: string;
  specsFilter?: {
    specsAttribute: string;
    specsValue: any;
  };
};

export type PDPData = CfPagePdp & {
  subtype?: string;
  product: Design;
  descriptions?: { title: string; description: string };
  materials: CfMaterial[];
  materialPreview: IMaterialPreview[];
  specs: string;
  customImages: string[];
  h1?: string;
  descriptionText?: string;
  breadcrumbs?: any;
};

/**
 * Get the CF page
 */
export function getContentful(
  product: Design,
  locale: string,
  countryCode: string
) {
  const { furniture_type, image_url, specs } = product;
  const subtype = getSubtype(furniture_type, specs);

  return ContentfulService.getPdp(
    furniture_type,
    subtype,
    locale,
    countryCode
  ).then((data) => {
    if (data.page.metaTags) {
      const metaTags: CfEnhancedMetaTags = {
        ...data.page.metaTags,
        fb_image: UrlProviderService.getAbsoluteAssetUrl(image_url),
        locUrl: getLocalizedUrls(product.uuid),
      };
      data.page.metaTags = metaTags;
    }
    return data;
  });
}

function getLocalizedUrls(uuid: string) {
  return Object.values(cfg.languages).reduce<Record<string, string>>(
    (acc, countryLangs) => {
      for (const lang of Object.keys(countryLangs)) {
        acc[lang.replace('_', '-')] = RelativeUrlService.getPdpUrl(uuid, lang);
      }

      return acc;
    },
    {}
  );
}

function getMaterials(locale: string, countryCode: string) {
  if (materialsCache) return Promise.resolve(materialsCache);

  return ContentfulService.getMaterials(locale, countryCode).then((data) => {
    materialsCache = data;
    return data;
  });
}

/**
 * Get the design from DesignApi
 */
export function getDesign(uuid: string, countryCode: string) {
  return DesignApiService.getRenderingWithoutWorkshop(uuid, countryCode).then(
    (data) => data[0]
  );
}

/**
 * Get Title and Description from DesignApi
 */
function getDescriptions(
  uuid: string,
  countryCode: string,
  locale: string
): Promise<{ title: string; description: string } | undefined> {
  return DesignApiService.getDescriptionsByUuid(uuid, countryCode, locale)
    .then((data) => {
      data[0].description = data[0].description.replace(/\$[\d]+/g, '');
      return data[0];
    })
    .catch((e) => {
      // Fail silently: only UUIDs from the Masterfeed have descriptions
      Logger.warn(`Fetching description for UUID ${uuid} failed`, e);
      return undefined;
    });
}

function getBreadcrumbs(uuid: string, countryCode: string) {
  return DesignApiService.getBreadcrumbsByUuid(uuid, countryCode)
    .then((data) => data)
    .catch((e) => {
      Logger.warn(`Fetching breadcrumbs for UUID ${uuid} failed`, e);
      return [];
    });
}
/**
 * Get the subtype, if any
 */
function getSubtype(furnitureType: string, specs: any): string | undefined {
  return StructureSpecsService.getSubtype(furnitureType, specs) ?? undefined;
}

/**
 * Get the material image from CF
 */
function getImagePreview(
  product: Design,
  locale: string,
  countryCode: string
): Promise<IMaterialPreview[]> {
  const { furniture_type, colors } = product;
  if (!colors.primary) return Promise.resolve([]);
  return ContentfulService.getImagePDP(
    furniture_type,
    Utils.getColorFromMaterial(colors.primary),
    locale,
    countryCode
  ).then((data) => {
    return data.map((data) => {
      return {
        thumbnail: ContentfulService.getThumbUrl(data.picture.url, 70),
        preview: ContentfulService.getThumbUrl(data.picture.url, 750),
        image: ContentfulService.getThumbUrl(data.picture.url, 1440),
        specsFilter: data.specsFilter,
      };
    });
  });
}

/**
 * Prepare metatags object to assign
 *
 * @param descriptions - Descriptions we got from DesignAPI.
 * @param descriptions.title - Title line for UUID
 * @param descriptions.description - Description text for UUID
 */
function getUpdatedMetaTags(descriptions?: {
  title: string;
  description: string;
}): CfMetaTags | undefined {
  if (!descriptions) {
    return;
  }

  const { title, description } = descriptions;
  return {
    _id: `product description meta tags`,
    _contentType: '1G8frnK44cCuC2m8iOecsM',
    robots: 'noindex, follow',
    title: title,
    description: description,
  };
}

/**
 * Source H1 tag from metaTags.title but don't overwrite if already defined
 */
function setH1Tag(
  globalData: {
    page: CfPagePdp & { h1?: string };
  },
  metaTags?: CfMetaTags
) {
  if (globalData.page.h1 || !metaTags?.title) {
    return;
  }

  globalData.page.h1 = metaTags.title;
}

/**
 * Add <p> for DescriptionText but don't overwrite if already defined
 */
function setProductDescription(globalData: any, metaTags: any) {
  if (globalData.page.descriptionText || !metaTags?.description) {
    return;
  }

  globalData.page.descriptionText = `<p>${metaTags.description}</p>`;
}

/**
 * Get product specs
 */
export function getSpecs(
  product: Design,
  materials: CfMaterial[] = [],
  { locale }: { locale: string }
): string {
  return StructureSpecsService.getSpecs(product, materials, {
    locale,
  });
}

/**
 * Get custom images from CF
 */
function getCustomImages(
  uuid: string,
  furnitureType: string,
  locale: string,
  countryCode: string
): Promise<string[]> {
  return ContentfulService.getCustomPdpImages(locale, countryCode).then(
    (data) => {
      let pdpImages: string[] = [];
      if (data) {
        const pdpItems = data.filter((item) => {
          return (
            (item.uuids && item.uuids.includes(uuid)) ||
            item.furnitureType === furnitureType
          );
        });
        pdpImages = _flatten(
          pdpItems.map((item) =>
            (item.images?.map((image) => image.url) ?? []).filter(isString)
          )
        );
      }
      return pdpImages;
    }
  );
}

/**
 * Get the CF page and the design
 */
export function getData(
  uuid: string,
  locale: string,
  countryCode: string
): Promise<any> {
  return Promise.all([
    getDesign(uuid, countryCode),
    getDescriptions(uuid, countryCode, locale),
    getMaterials(locale, countryCode),
    getBreadcrumbs(uuid, countryCode),
  ])
    .then(([product, descriptions, materials, breadcrumbs]) => {
      return Promise.all([
        Promise.resolve(product),
        Promise.resolve(descriptions),
        getContentful(product, locale, countryCode),
        getSubtype(product.furniture_type, product.specs),
        getImagePreview(product, locale, countryCode),
        getSpecs(product, materials, { locale }),
        getCustomImages(uuid, product.furniture_type, locale, countryCode),
        materials,
        breadcrumbs,
      ]);
    })
    .then(
      ([
        product,
        descriptions,
        globalData,
        subtype,
        materialPreview,
        specs,
        customImages,
        materials,
        breadcrumbs,
      ]) => {
        const updatedMetaTags = getUpdatedMetaTags(descriptions);

        const page: PDPData = {
          ...globalData.page,
          subtype: subtype,
          product: product,
          descriptions: descriptions,
          materials: materials,
          materialPreview: materialPreview,
          specs: specs,
          customImages: customImages,
          breadcrumbs: breadcrumbs,
        };

        const finalMetaTags: CfEnhancedMetaTags = {
          _id: 'page meta tags',
          _contentType: '1G8frnK44cCuC2m8iOecsM',
          ...page.metaTags,
          ...updatedMetaTags,
        };

        page.metaTags = finalMetaTags;

        setH1Tag(globalData, updatedMetaTags);
        setProductDescription(globalData, updatedMetaTags);

        return { ...globalData, page };
      }
    );
}
