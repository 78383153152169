import range from 'lodash/range';
import invert from 'lodash/invert';
import { screenFactor } from '../utils/configUtils';
import nyceConfig from './nyce-config';
import { FurnitureConfig } from './types';

export interface WardrobeFurnitureConfig extends FurnitureConfig {
  defaultColor: string;
  primarySections: {
    [key: string]: number;
  };
  colors: any;
  laminateMapping: { [index: string]: string };
  woodLaminateMapping: { [index: string]: string };
  woodLacquerMapping: { [index: string]: string };
  lacquerMapping: { [index: string]: string };
  validColor: any;
  corpusNumber: any;
  doorNumber: any;
  firstDrilling: number;
  spaceDrilling: number;
  drawerFrontLength: { [index: number]: number };
  interiors: any;
  walls: any;
  drawers: any;
  lengthsMapping: any;
  doorSpacing: number;
  fyrmShortSpacingZ: number;
  fyrmSpacingX: number;
  fyrmSpacingY: number;
  slidingDoorSpacingX: Array<number>;
  doorSpacingY: { [index: string]: number };
  backwallSpacingY: number;
  maximumLength: number;
  wallLacqueredDepth: number;
  interiorsHasColor: any;
  maxPositionZ: number;
}
const colors = {
  other: ['mirror'],
  minimalWood: ['oak', 'walnut'],
  walnut: ['walnut'],
  wenge: ['wenge'],
  wood: ['wenge'],
  minimalLacquered: ['white_9003', 'grey_7024'],
  minimalLaminate: ['white_u11209', 'graphite_u12233'],
  lacquered: [
    'grey_7037',
    'black_9004',
    'yellow_1021',
    'blue_5009',
    'red_3016',
  ],
  laminate: [
    'white_u11209',
    'light_grey_u12044',
    'grey_u12091',
    'graphite_u12233',
    'black_u12001',
    // 'burgundy_red_u17031',
    'terracotta_u16166',
    'taupe_u15133',
    'yellow_u15579',
    'mint_green_u19006',
    'forest_green_u19008',
    'petrol_green_u18006',
    'blue_u18004',
  ],
  metal_aluminum: ['aluminum_brushed', 'aluminum_polished'],
  metal_gold: ['matt_gold'],
};

const laminateMapping = {
  white_9003: 'white_u11209',
  black_9004: 'black_u12001',
  light_grey_7004: 'light_grey_u12044',
  grey_7037: 'grey_u12091',
  grey_7024: 'graphite_u12233',
  green_grey_7009: 'taupe_u15133',
  stone_grey_7030: 'taupe_u15133',
  light_yellow_1016: 'yellow_u15579',
  yellow_1021: 'yellow_u15579',
  red_3016: 'terracotta_u16166',
  violet_4009: 'terracotta_u16166',
  dark_red_3005: 'terracotta_u16166',
  dark_blue_5013: 'blue_u18004',
  blue_2_5000: 'petrol_green_u18006',
  blue_5009: 'petrol_green_u18006',
  mint_6033: 'mint_green_u19006',
  dark_green_6009: 'forest_green_u19008',
  green_6029: 'forest_green_u19008',
};

const woodLaminateMapping = {
  oak: 'beech',
};

const woodLacquerMapping = invert(woodLaminateMapping);

const lacquerMapping = invert(laminateMapping);

const config = {
  defaultColor: 'white_u11209',
  colors,
  laminateMapping,
  woodLaminateMapping,
  woodLacquerMapping,
  lacquerMapping,
  validColor: {
    door: ['laminate', 'other', 'minimalWood', 'wood'],
    corpus: ['minimalLaminate', 'minimalWood'],
    interior: {
      board: ['minimalLaminate', 'minimalWood'],
      solidDrawer: ['minimalLaminate', 'minimalWood'],
      smallDrawer: ['minimalLaminate', 'minimalWood'],
      glassDrawer: ['minimalLaminate', 'minimalWood'],
      shoesDrawer: ['minimalLaminate', 'minimalWood'],
      pantHanger: ['minimalLaminate', 'minimalWood'],
    },
    handle: {
      ryft: ['laminate', 'minimalWood', 'wood'],
      fyrm_long: ['laminate', 'metal_aluminum', 'metal_gold'],
      fyrm_short: ['laminate', 'metal_aluminum', 'metal_gold'],
      fyx: [],
    },
    wall: ['laminate', 'minimalWood', 'wood'],
  },
  // Number of corpus regarding the length of a module
  corpusNumber: {
    500: 1,
    800: 1,
    1000: 1,
    1600: 2,
    2000: 2,
    2400: 3,
    3000: 3,
  },
  // Number of corpus regarding the length of a module
  doorNumber: {
    500: 1,
    800: 2,
    1000: 2,
    1600: 2,
    2000: 2,
    2400: 3,
    3000: 3,
  },
  firstDrilling: 46,
  spaceDrilling: 32,
  drawerFrontLength: {
    464: 386,
    764: 686,
    964: 886,
  },
  interiors: {
    board: {
      section: 'board',
      oldSection: 'board',
      positions: range(4, 62).filter((p, i) => i % 6 === 0 && p !== 16),
      up: 1,
      down: 0,
      positionFromHoles: -4.8,
      labelMode: 'board',
      heightPlaceholderExisting: 2.8,
      heightPlaceholderNonExisting: 2.8,
      sectionShiftZ: 0,
      labelHeight: 6,
      labelGap: 6,
      height: 28,
    },
    glassDrawer: {
      section: 'drawer_front_glass_module',
      oldSection: 'drawer_front_glass',
      positions: range(0, 46).filter((_p, i) => i % 6 === 0),
      up: 3,
      down: 0,
      positionFromHoles: 25,
      labelMode: 'glassDrawer',
      heightPlaceholderExisting: 14.6,
      heightPlaceholderNonExisting: 14.6,
      sectionShiftZ: 0,
      labelHeight: 6,
      labelGap: 6,
      height: 146,
    },
    solidDrawer: {
      section: 'drawer_front_module',
      oldSection: 'drawer_front',
      positions: range(0, 46).filter((_p, i) => i % 6 === 0),
      up: 3,
      down: 0,
      positionFromHoles: 25,
      labelMode: 'solidDrawer',
      heightPlaceholderExisting: 14.6,
      heightPlaceholderNonExisting: 14.6,
      sectionShiftZ: 0,
      labelHeight: 6,
      labelGap: 6,
      height: 146,
    },
    smallDrawer: {
      section: 'drawer_front_module',
      oldSection: 'drawer_front',
      positions: range(0, 46).filter((_p, i) => i % 3 === 0),
      up: 0,
      down: 0,
      positionFromHoles: 25,
      labelMode: 'smallDrawer',
      heightPlaceholderExisting: 5.0,
      heightPlaceholderNonExisting: 5.0,
      sectionShiftZ: 0,
      labelHeight: 3,
      labelGap: 3,
      height: 50,
    },
    lift: {
      section: 'lift_module',
      oldSection: 'lift',
      positions: range(12, 65).filter((_p, i) => i % 6 === 0),
      up: 26,
      down: 0,
      positionFromHoles: -2,
      labelMode: 'garmentLift',
      heightPlaceholderExisting: 92.8,
      heightPlaceholderNonExisting: 14.6,
      sectionShiftZ: -27,
      lastSectionShiftZ: -29,
      labelHeight: 30,
      labelGap: 6,
      lastPositionZ: 213.5,
      lastZIdx: 36,
    },
    rail: {
      section: 'cloth_rail',
      oldSection: 'cloth_rail',
      positions: range(0, 65).filter((_p, i) => i % 6 === 0),
      up: 26,
      down: 0,
      positionFromHoles: -825,
      labelMode: 'closetRod',
      heightPlaceholderExisting: 92.8,
      heightPlaceholderNonExisting: 14.6,
      sectionShiftZ: -1,
      lastSectionShiftZ: -4,
      labelHeight: 30,
      labelGap: 6,
      lastPositionZ: 213.5,
      lastZIdx: 36,
    },
    shoesDrawer: {
      section: 'shoe_bar_module',
      oldSection: 'shoe_bar',
      positions: range(0, 46).filter((_p, i) => i % 3 === 0),
      up: 6,
      down: 0,
      positionFromHoles: 25,
      labelMode: 'shoesDrawer',
      heightPlaceholderExisting: 22.4,
      heightPlaceholderNonExisting: 5.0,
      sectionShiftZ: 0,
      labelHeight: 9,
      labelGap: 3,
      height: 50,
    },
    pantHanger: {
      section: 'pant_hanger_module',
      oldSection: 'pant_hanger',
      positions: range(0, 46).filter((_p, i) => i % 3 === 0),
      up: 10,
      down: 0,
      positionFromHoles: -231,
      labelMode: 'pantHanger',
      heightPlaceholderExisting: 32.0,
      heightPlaceholderNonExisting: 5.0,
      sectionShiftZ: -1,
      labelHeight: 12,
      labelGap: 3,
      height: 50,
    },
  },
  walls: {
    deep: {
      section: 'wall',
      type: 'deep',
      width: 647,
    },
    shallow: {
      section: 'wall',
      type: 'shallow',
      width: 617,
    },
  },
  drawers: {
    smallDrawer: {
      height: 50,
    },
    solidDrawer: {
      height: 146,
    },
  },
  // Elements lengths per module length
  lengthsMapping: {
    backwall: {
      500: 498,
      800: 798,
      1000: 998,
    },
    board: {
      500: 464,
      800: 764,
      1000: 964,
    },
    door: {
      sliding: {
        800: 823,
        1000: 1023,
      },
      turning: {
        400: 397,
        500: 497,
      },
    },
    glassDrawer: {
      500: 464,
      800: 764,
      1000: 964,
    },
    lift: {
      500: 464,
      800: 764,
      1000: 964,
    },
    pantHanger: {
      500: 464,
      800: 764,
      1000: 964,
    },
    rail: {
      500: 464,
      800: 764,
      1000: 964,
    },
    shoesDrawer: {
      500: 350,
      800: 650,
      1000: 850,
    },
    smallDrawer: {
      500: 464,
      800: 764,
      1000: 964,
    },
    solidDrawer: {
      500: 464,
      800: 764,
      1000: 964,
    },
  },
  doorSpacing: 0.15,
  fyrmShortSpacingZ: 57.6,
  fyrmSpacingX: -0.1,
  fyrmSpacingY: -2.45,
  slidingDoorSpacingX: [0, -5, -2.5],
  doorSpacingY: {
    back: -0.7,
    front: -3.95,
  },
  backwallSpacingY: -1.5,
  maximumLength: 5040,
  wallLacqueredDepth: 80,
  interiorsHasColor: [
    'board',
    'pantHanger',
    'solidDrawer',
    'glassDrawer',
    'smallDrawer',
    'shoesDrawer',
  ],
  maxPositionZ: 218.6,
};

const wardrobeConfig: WardrobeFurnitureConfig = {
  ...config,
  heightWarningLimit: 2440,
  hasScene: false,
  scenesOptions: [],
  hasAssembly: true,
  hasViewInterior: true,
  showRelatedProduct: false,
  noBackgroundCarousel: false,
  defaultDimensions: {},
  furnitureType: 'wardrobe',
  primarySections: {
    door: 10,
    wall: 1,
    handles: 1,
  },
  default: {
    wallLeft: 'white_u11209',
    modules: [
      {
        corpus: [
          {
            color: 'white_u11209',
            interiors: [],
          },
        ],
        doors: {
          type: 'turning',
          handle: 'ryft',
          colors: [
            {
              body: 'white_u11209',
              handles: ['oak'],
            },
            {
              body: 'white_u11209',
              handles: ['oak'],
            },
          ],
        },
        length: 1000,
      },
    ],
    wallRight: 'white_u11209',
  },
  label: 'SVG',
  stage: 'default',
  invalidUuidFallback: 'zwOXIj4TZ',
  notifications: {},
  translationDimensionMapping: true,
  backUrl: '/storage/wardrobes',
  nyce: {
    animation: {
      camera: {
        fov: 60,
        angle: 0,
        vAngle: 0,
        coreAngle: 0,
        coreVAngle: 0,
        autozoom: true,
        bboxScalingFactor: [1, 1, 1, 1, 1, 1],
        ...screenFactor(1200, 700, 133, 20, 203, 203),
      },
      processing: {
        noise: 0.1,
        samples: 200,
        no_intermediate: true,
        floor: [0.8863, 0.8902, 0.898],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.shelf,
      },
    },
    normal: {
      camera: {
        fov: 60,
        angle: 0,
        vAngle: 0,
        coreAngle: 0,
        coreVAngle: 0,
        autozoom: true,
        bboxScalingFactor: [1, 1, 1, 1, 1, 1],
        ...screenFactor(1500, 875, 166.25, 29.17, 253.75, 253.75),
      },
      processing: {
        noise: 0.1,
        samples: 200,
        no_intermediate: false,
        floor: [0.8863, 0.8902, 0.898],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.shelf,
      },
    },
    mobile: {
      camera: {
        fov: 60,
        angle: 0,
        vAngle: 0,
        coreAngle: 0,
        coreVAngle: 0,
        autozoom: true,
        bboxScalingFactor: [1, 1, 1, 1, 1, 1],
        ...screenFactor(800, 900, 50, 150, 50, 50),
      },
      processing: {
        noise: 0.1,
        samples: 200,
        no_intermediate: false,
        floor: [0.8863, 0.8902, 0.898],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.shelf,
      },
    },
    hires: {
      camera: {
        fov: 40,
        angle: 30,
        vAngle: 0,
        coreAngle: 0,
        coreVAngle: -4,
        autozoom: true,
        bboxScalingFactor: [1.2, 1.2, 1.2, 1.2, 1.2, 1.2],
        ...screenFactor(1440, 1440, 0, 0, 0, 0),
      },
      processing: {
        noise: 0.1,
        samples: 700,
        no_intermediate: true,
        floor: [1.0, 1.0, 1.0],
        floor_luminance: 0.75,
        srgb: true,
        ...nyceConfig.presets.shelf,
      },
    },
  },
  tabs: {
    desktop: ['default'],
    mobile: ['elements', 'colorsMobile', 'review'],
  },
  tabsNextText: [
    'To doors',
    'To handles',
    'To interiors',
    'To colors',
    'To review',
  ],
  viewModes: {
    'wardrobe-doors': {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'single',
      resetSelection: true,
      group: 'module',
      groupStructure: '',
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      labelMode: 'modules',
    },
    elements: {
      zoomMax: 1.8,
      subTabsName: ['wardrobe-doors', 'handles', 'interiors'],
      defaultSubTabName: 'wardrobe-doors',
      text: 'tops',
      iconName: '',
      selectionMode: 'none',
      resetSelection: false,
      group: 'elements',
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      labelMode: 'elements',
    },
    interiors: {
      zoomMax: 1,
      subTabsName: [
        'board',
        'closetRod',
        'garmentLift',
        'pantHanger',
        'solidDrawer',
        'glassDrawer',
        'smallDrawer',
        'shoesDrawer',
      ],
      text: '',
      iconName: '',
      selectionMode: 'none',
      resetSelection: true,
      group: 'interiors',
      groupStructure: '',
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      labelMode: 'interiors',
    },
    handles: {
      zoomMax: 1,
      subTabsName: [
        'changeHandles',
        'changeTurningHandles',
        'changeSlidingHandles',
      ],
      text: '',
      iconName: 'fronts',
      selectionMode: 'multi',
      resetSelection: true,
      group: 'handle',
      groupStructure: '',
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      labelMode: '',
    },
    changeHandles: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: 'fronts',
      selectionMode: 'multi',
      resetSelection: true,
      group: 'handle',
      groupStructure: '',
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      labelMode: 'handles',
    },
    changeTurningHandles: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: 'fronts',
      selectionMode: 'multi',
      resetSelection: true,
      group: 'handle',
      groupStructure: '',
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      labelMode: 'handles_turning',
    },
    board: {
      zoomMax: 1,
      subTabsName: [],
      text: 'Board',
      iconName: 'configurator/wardrobe/wardrobe-board',
      selectionMode: 'multi',
      resetSelection: true,
      group: 'interior',
      groupStructure: '',
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      labelMode: 'board',
    },
    closetRod: {
      zoomMax: 1,
      subTabsName: [],
      text: 'Hanger Rail',
      iconName: 'configurator/wardrobe/wardrobe-hangers-rail',
      selectionMode: 'multi',
      resetSelection: true,
      group: 'interior',
      groupStructure: '',
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      labelMode: 'closetRod',
    },
    garmentLift: {
      zoomMax: 1,
      subTabsName: [],
      text: 'Garment Lift',
      iconName: 'configurator/wardrobe/wardrobe-garment-lift',
      selectionMode: 'multi',
      resetSelection: true,
      group: 'interior',
      groupStructure: '',
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      labelMode: 'garmentLift',
    },
    pantHanger: {
      zoomMax: 1,
      subTabsName: [],
      text: 'Pant Hanger',
      iconName: 'configurator/wardrobe/wardrobe-pants-hanger',
      selectionMode: 'multi',
      resetSelection: true,
      group: 'interior',
      groupStructure: '',
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      labelMode: 'pantHanger',
    },
    solidDrawer: {
      zoomMax: 1,
      subTabsName: [],
      text: 'Solid Drawer',
      iconName: 'configurator/wardrobe/wardrobe-solid-drawer',
      selectionMode: 'multi',
      resetSelection: true,
      group: 'interior',
      groupStructure: '',
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      labelMode: 'solidDrawer',
    },
    glassDrawer: {
      zoomMax: 1,
      subTabsName: [],
      text: 'Glass Drawer',
      iconName: 'configurator/wardrobe/wardrobe-glass-drawer',
      selectionMode: 'multi',
      resetSelection: true,
      group: 'interior',
      groupStructure: '',
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      labelMode: 'glassDrawer',
    },
    smallDrawer: {
      zoomMax: 1,
      subTabsName: [],
      text: 'Sliding Board',
      iconName: 'configurator/wardrobe/wardrobe-sliding-board',
      selectionMode: 'multi',
      resetSelection: true,
      group: 'interior',
      groupStructure: '',
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      labelMode: 'smallDrawer',
    },
    shoesDrawer: {
      zoomMax: 1,
      subTabsName: [],
      text: 'Shoes Drawer',
      iconName: 'configurator/wardrobe/wardrobe-shoe-drawer',
      selectionMode: 'multi',
      resetSelection: true,
      group: 'interior',
      groupStructure: '',
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      labelMode: 'shoesDrawer',
    },
    // Colors outer walls, doors and ryft handles
    // Will later color every element, when we support it on the furniture production side
    colorsWhole: {
      zoomMax: 1.8,
      camera: {
        low: { angle: 0, vAngle: 0 },
        high: { angle: 0, vAngle: 0 },
      },
      group: 'color',
      groupStructure: '',
      iconName: 'configurator/wardrobe/wardrobe-colors-corpus',
      labelMode: 'whole',
      selectionMode: 'all',
      resetSelection: false,
      subTabsName: [],
      text: 'Outside',
    },
    colorsCorpuses: {
      zoomMax: 1,
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      group: 'color',
      groupStructure: '',
      iconName: 'configurator/wardrobe/wardrobe-colors-corpus',
      labelMode: 'color_corpuses',
      selectionMode: 'multi',
      resetSelection: true,
      subTabsName: [],
      text: 'Corpuses',
    },
    colorsDrawers: {
      zoomMax: 1,
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      group: 'color',
      groupStructure: '',
      iconName: 'configurator/wardrobe/wardrobe-colors-fronts',
      labelMode: 'color_drawers',
      selectionMode: 'multi',
      resetSelection: true,
      subTabsName: [],
      text: 'Drawers',
    },
    colorsBoards: {
      zoomMax: 1,
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      group: 'color',
      groupStructure: '',
      iconName: 'configurator/wardrobe/wardrobe-colors-boards',
      labelMode: 'color_boards',
      selectionMode: 'multi',
      resetSelection: true,
      subTabsName: [],
      text: 'Boards',
    },
    colorsDoors: {
      zoomMax: 1,
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      group: 'color',
      groupStructure: '',
      iconName: 'configurator/wardrobe/wardrobe-colors-doors',
      labelMode: 'color_doors',
      selectionMode: 'multi',
      resetSelection: true,
      subTabsName: [],
      text: 'Doors',
    },
    colorsHandles: {
      zoomMax: 1,
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      group: 'color',
      groupStructure: '',
      iconName: 'configurator/wardrobe/wardrobe-colors-handles',
      labelMode: 'color_handles',
      selectionMode: 'multi',
      resetSelection: true,
      subTabsName: [],
      text: 'Handles',
    },
    colorsOuterWalls: {
      zoomMax: 1,
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      group: 'color',
      groupStructure: '',
      iconName: 'configurator/wardrobe/wardrobe-colors-walls',
      labelMode: 'color_walls',
      selectionMode: 'multi',
      resetSelection: true,
      subTabsName: [],
      text: 'Outer Walls',
    },
    colors: {
      zoomMax: 1.8,
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      group: '',
      groupStructure: '',
      iconName: '',
      labelMode: '',
      selectionMode: 'none',
      resetSelection: true,
      subTabsName: [
        'colors',
        'colorsWhole',
        'colorsCorpuses',
        'colorsDrawers',
        'colorsBoards',
        'colorsDoors',
        'colorsHandles',
        'colorsOuterWalls',
      ],
      defaultSubTabName: 'colors',
      URLTabName: 'colors',
      text: '',
    },
    colorsMobile: {
      zoomMax: 1.8,
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      group: 'color',
      groupStructure: '',
      iconName: 'configurator/wardrobe/modules-color',
      labelMode: '',
      selectionMode: 'none',
      resetSelection: true,
      subTabsName: [
        'colorsWhole',
        'colorsCorpuses',
        'colorsDrawers',
        'colorsBoards',
        'colorsDoors',
        'colorsHandles',
        'colorsOuterWalls',
      ],
      defaultSubTabName: 'colorsWhole',
      URLTabName: 'colors',
      text: '',
    },
    review: {
      zoomMax: 1,
      subTabsName: [],
      text: '',
      iconName: '',
      selectionMode: 'single',
      resetSelection: true,
      group: '',
      groupStructure: '',
      camera: {
        default: { angle: 15, vAngle: 0 },
      },
      labelMode: '',
    },
    default: {
      zoomMax: 1.8,
      camera: {
        default: { angle: 0, vAngle: 0 },
      },
      group: 'color',
      groupStructure: '',
      iconName: '',
      labelMode: 'whole',
      selectionMode: 'all',
      resetSelection: true,
      subTabsName: ['wardrobe-doors', 'handles', 'interiors', 'colorsMobile'],
      text: '',
    },
  },
  options: [
    // Module options
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: 500,
      type: 'turning',
      text: '50 cm Turning',
      iconName: 'configurator/wardrobe/wardrobe-widths-50',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: 800,
      type: 'turning',
      text: '80 cm Turning',
      iconName: 'configurator/wardrobe/wardrobe-widths-80',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: 1000,
      type: 'turning',
      text: '100 cm Turning',
      iconName: 'configurator/wardrobe/wardrobe-widths-100',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: 1600,
      type: 'sliding',
      text: '160 cm Sliding',
      iconName: 'configurator/wardrobe/wardrobe-widths-160',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: 2000,
      type: 'sliding',
      text: '200 cm Sliding',
      iconName: 'configurator/wardrobe/wardrobe-widths-200',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: 2400,
      type: 'sliding',
      text: '240 cm Sliding',
      iconName: 'configurator/wardrobe/wardrobe-widths-240',
    },
    {
      action: 'update',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: 3000,
      type: 'sliding',
      text: '300 cm Sliding',
      iconName: 'configurator/wardrobe/wardrobe-widths-300',
    },
    {
      action: 'remove',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      value: '',
      type: 'any',
      text: 'Remove',
      iconName: 'configurator/trash-bin',
    },
    {
      action: 'duplicate',
      group: 'module',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'any',
      value: '',
      text: 'Duplicate',
      iconName: 'configurator/duplicate',
    },
    /* Interiors */
    {
      action: 'update',
      group: 'interior',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'interior',
      value: '',
      text: '',
      iconName: '',
    },
    /* Handles */
    {
      action: 'update',
      group: 'handle',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'handle',
      value: 'fyrm_short',
      text: 'FYRM 42 cm',
      iconName: 'configurator/wardrobe/handle-fyrm-42',
    },
    {
      action: 'update',
      group: 'handle',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'handle',
      value: 'fyrm_long',
      text: 'FYRM 100 cm',
      iconName: 'configurator/wardrobe/handle-fyrm-100',
    },
    {
      action: 'update',
      group: 'handle',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'handle',
      value: 'fyx',
      text: 'FYX',
      iconName: 'configurator/wardrobe/handle-fyx',
    },
    {
      action: 'update',
      group: 'handle',
      isDisabled: null,
      isSelected: null,
      message: '',
      type: 'handle',
      value: 'ryft',
      text: 'RYFT',
      iconName: 'configurator/wardrobe/handle-ryft',
    },
    /* Colors */
    ...[...config.colors.laminate].map((color) => {
      return {
        action: 'update',
        group: 'color',
        isDisabled: null,
        isSelected: null,
        message: '',
        type: 'color',
        text: '',
        value: color,
        iconName: `materials/${color}.png`,
        categoryName: '',
      };
    }),
    ...[...config.colors.wood].map((color) => {
      return {
        action: 'update',
        group: 'color',
        isDisabled: null,
        isSelected: null,
        message: '',
        type: 'color',
        text: '',
        value: color,
        iconName: `materials/${color}.png`,
        categoryName: '',
      };
    }),
    ...[...config.colors.minimalWood].map((color) => {
      return {
        action: 'update',
        group: 'color',
        isDisabled: null,
        isSelected: null,
        message: '',
        type: 'color',
        text: '',
        value: color,
        iconName: `materials/${color}.png`,
        categoryName: '',
      };
    }),
    ...[...config.colors.metal_aluminum].map((color) => {
      return {
        action: 'update',
        group: 'color',
        isDisabled: null,
        isSelected: null,
        message: '',
        type: 'color',
        text: '',
        value: color,
        iconName: `materials/${color}.png`,
        categoryName: '',
      };
    }),
    ...[...config.colors.metal_gold].map((color) => {
      return {
        action: 'update',
        group: 'color',
        isDisabled: null,
        isSelected: null,
        message: '',
        type: 'color',
        text: '',
        value: color,
        iconName: `materials/${color}.png`,
        categoryName: '',
      };
    }),
    ...[...config.colors.other].map((color) => {
      return {
        action: 'update',
        group: 'color',
        isDisabled: null,
        isSelected: null,
        message: '',
        type: 'color',
        text: '',
        value: color,
        iconName: `materials/${color}.png`,
        categoryName: '',
      };
    }),
  ],
  productDetailText: 'About Our Wardrobes',
  dimensionsFilters: ['length', 'width', 'height'],
};

export default wardrobeConfig;
