import assignIn from 'lodash/assignIn';

//
// Translations config
//
const translations: {
  [ascii: string]: {
    [key: string]: string;
  };
} = {};

// German ASCII translations for URLs
translations['ascii-de_DE'] = {
  'about-our-configurators': 'ueber-unsere-konfiguratoren',
  'about-us': 'ueber-uns',
  accessories: 'accessoires',
  address: 'adresse',
  all: 'alle',
  architects: 'architekten',
  armchairs: 'sessel',
  attachments: 'ansteckplatten',

  barstools: 'barhocker',
  backwalls: 'rueckwaende',
  beech: 'buche',
  black_9004: 'schwarz',
  blue_2_5000: 'marineblau',
  blue_5009: 'blau',
  boards: 'boeden',
  bookshelf: 'buecherregale',
  buffets: 'buffetschraenke',

  career: 'career',
  checkout: 'checkout',
  clear_colorless: 'kristallglas-klar',
  closet: 'schrankwaende',
  closets: 'schraenke',
  'color*': 'farbe*',
  colors: 'farben',
  configurator: 'konfigurator',
  contact: 'kontakt',
  'corner-couches': 'ecksofas',
  cushions: 'kissen',
  cushion: 'kissen',

  custom: 'massanfertigungen',

  'dimensions-legs': 'masse-und-fuesse',
  'drawer-frames': 'schubladen-rahmen',
  drawersFrames: 'schubladen-rahmen',
  dark_blue_5013: 'nachtblau',
  dark_green_6009: 'tannengruen',
  dark_red_3005: 'burgundrot',
  dimensions: 'masse',
  doors: 'tueren',
  drawers: 'schubladen',
  dressers: 'kommoden',

  elements: 'elemente',

  faq: 'faq',
  'file-folder-shelves': 'aktenregale',
  'filing-cabinet': 'aktenschraenke',
  feet: 'fuesse',
  fronts: 'fronten',

  gallery: 'galerie',
  green_6029: 'flaschengruen',
  green_grey_7009: 'nebelgruen',
  green_guatemala: 'gruener-guatemala-marmor',
  grey_7024: 'anthrazit',
  grey_7037: 'grau',

  heights: 'hoehen',
  highboards: 'highboards',

  imprint: 'impressum',
  images: 'bildmaterial',
  information: 'informationen',
  interiors: 'ausstattung',

  joyn: 'sofa-tyme',
  joyn_old: 'sofa-joyn',
  pyllow: 'sofa-pyllow',
  flayr: 'sofa-flayr',

  lacquer: 'lackierung',
  last: 'letzt',
  legs: 'beine',
  library: 'bibliotheken',
  light_grey_7004: 'lichtgrau',
  light_yellow_1016: 'zitronengelb',
  login: 'anmelden',
  lowboards: 'lowboards',
  'lp-shelves': 'schallplattenregale',

  matt_gold: 'mattgold',
  'my-account': 'mein-konto',
  'my-designs': 'meine-designs',
  mint_6033: 'seegruen',
  'mobile-pedestal': 'rollcontainer',
  modules: 'module',

  'office-furnishing': 'bueroeinrichtung',
  oak_wenge_stain: 'wenge',
  oak: 'eiche',
  opaque_colorless: 'kristallglas-satiniert',
  opaque_grey: 'rauchglas-schwarz-satiniert',
  orders: 'bestellubersicht',
  'order-tracking': 'auftragsverfolgung',

  'private-policy': 'datenschutz',
  page: 'seite',
  press: 'presse',
  'press-coverage': 'mycs-in-der-presse',
  'press-releases': 'presse-mitteilungen',
  'brand-ambassador-program': 'brand-ambassador-programm',
  product: 'produkt',

  red_3016: 'rot',
  returns: 'ruecksendungen',
  review: 'uebersicht',
  store: 'store',
  'shelf-system': 'regalsysteme',
  'shelves-and-storage': 'regale-und-schraenke',
  'shipping-and-payment': 'versand-und-zahlung',
  'showroom-munich': 'showroom-muenchen',
  'showroom-nice': 'showroom-nizza',
  'showroom-zuerich': 'showroom-zuerich',
  samplebox: 'materialproben',
  sections: 'aufteilung',
  settings: 'einstellungen',
  shapes: 'formen',
  shelf: 'regal',
  shelves: 'regale',
  shopping_cart: 'warenkorb',
  shops: 'einzelhandel-hotels-gastro',
  showroom: 'showroom',
  sideboards: 'sideboards',
  sleepers: 'schlafsofas',
  small: 'klein',
  sofas: 'sofas',
  'sofas-and-armchair': 'sofas-und-sessel',
  storage: 'schraenke',
  campaign: 'kampagne',
  specs: 'details',
  stone_grey_7030: 'sandgrau',

  'terms-of-use': 'agb',
  'tv-cabinet': 'tv-schraenke',
  extensionsDesktop: 'platten',

  veneered_anthracite: 'eichenfurnier-anthrazit-lackiert',
  veneered_black: 'eichenfurnier-schwarz-lackiert',
  veneered_blue: 'eichenfurnier-blau-lackiert',
  veneered_grey: 'eichenfurnier-grau-lackiert',
  veneered_red: 'eichenfurnier-rot-lackiert',
  veneered_white: 'eichenfurnier-weiss-lackiert',
  veneered_yellow: 'eichenfurnier-gelb-lackiert',
  violet_4009: 'altrosa',

  'wall-to-wall-shelf': 'wohnwaende',
  'wardrobe-doors': 'tueren',
  walnut: 'nussbaum',
  wardrobe: 'kleiderschrank',
  wardrobes: 'kleiderschraenke',
  white_9003: 'weiss',
  wood: 'holz',

  yellow_1021: 'gelb',

  'couches-and-armchairs': 'sofas-und-sessel',
};

// Frensh ASCII translations for URLs
translations['ascii-fr_FR'] = {
  // TODO: use acutal translations, these ones are just temporary
  campaign: 'campagne',
  'about-our-configurators': 'a-propos-de-nos-configurateurs',
  'about-us': 'a-propos-de-nous',
  accessories: 'accessoires',
  address: 'adresse',
  all: 'tous',
  architects: 'architecte',
  armchairs: 'fauteuils',

  backwalls: 'panneaux-de-fond',
  beech: 'hetre',
  black_9004: 'noir',
  blue_2_5000: 'bleu-marine',
  blue_5009: 'bleu',
  boards: 'tablettes',
  bookshelf: 'rangements-muraux',
  buffets: 'vaisseliers',

  career: 'career',
  checkout: 'checkout',
  sleepers: 'canapes-convertibles',
  chests: 'bahuts',
  clear_colorless: 'verre-clair-transparent',
  closet: 'placards',
  closets: 'armoires',
  'color*': 'couleurs*',
  colors: 'couleurs',
  configurator: 'configurateur',
  contact: 'contact',
  'corner-couches': 'canapes-d-angle',
  cushion: 'coussin',
  cushions: 'coussins',
  custom: 'sure-mesure',

  'dimensions-legs': 'dimensions-et-pieds',
  'drawer-frames': 'cadres-tiroirs',
  drawersFrames: 'cadres-tiroirs',
  dark_blue_5013: 'bleu-nuit',
  dark_green_6009: 'vert-sapin',
  dark_red_3005: 'bordeaux',
  dimensions: 'dimensions',
  drawers: 'tiroirs',
  dressers: 'commodes',
  store: 'store',
  faq: 'faq',
  feet: 'les-pieds',
  'file-folder-shelves': 'etageres-bureau',
  'filing-cabinet': 'armoires-bureau',
  fronts: 'facades',

  gallery: 'galerie',
  green_6029: 'vert-bouteille',
  green_grey_7009: 'vert-de-gris',
  grey_7024: 'anthracite',
  grey_7037: 'gris',

  heights: 'hauteurs',
  highboards: 'buffets',

  imprint: 'mentions-legales',
  information: 'informations',
  interiors: 'accessoires',

  joyn: 'canape-tyme',
  joyn_old: 'canape-joyn',
  pyllow: 'canape-pyllow',
  flayr: 'canape-flayr',

  lacquer: 'laque',
  last: 'dernier',
  legs: 'pieds',
  library: 'bibliotheque',
  light_grey_7004: 'gris-clair',
  light_yellow_1016: 'jaune-citron',
  login: 'login',
  lowboards: 'buffets-bas',
  'lp-shelves': 'ranges-cd',

  matt_gold: 'doré matt',
  'my-account': 'mon-compte',
  'my-designs': 'mes-conceptions',
  mint_6033: 'vert-viride',
  'mobile-pedestal': 'caissons-bureau',
  modules: 'module',

  'office-furnishing': 'materiel-de-bureau',
  oak_wenge_stain: 'wenge',
  oak: 'chene',
  opaque_colorless: 'verre-clair-depoli',
  opaque_grey: 'verre-fume-depoli',
  orders: 'ordres',
  'order-tracking': 'suivi-de-commande',

  'private-policy': 'protection-des-donnees',
  page: 'page',
  press: 'presse',
  'press-coverage': 'couvertures-presse',
  'press-releases': 'communiques-presse',
  'brand-ambassador-program': 'programme-représenter-MYCS',
  product: 'produit',

  red_3016: 'rouge',
  returns: 'retours',
  review: 'verification',

  'shelf-system': 'systemes-d-etageres',
  'shelves-and-storage': 'etageres-et-armoires',
  'shipping-and-payment': 'expedition-et-paiement',
  'showroom-hamburg': 'showroom-hambourg',
  'showroom-munich': 'showroom-munich',
  'showroom-nice': 'showroom-nice',
  'showroom-zuerich': 'showroom-zurich',
  samplebox: 'echantillons',
  sections: 'portes',
  settings: 'parametres',
  shapes: 'formes',
  shelf: 'etagere',
  shelves: 'etageres',
  shopping_cart: 'panier',
  shops: 'commerce-hotels-gastronomie',
  showroom: 'showroom',
  sideboards: 'enfilades',
  small: 'petit',
  sofas: 'canapes',
  'sofas-and-armchair': 'canapes-et-fauteuils',
  stone_grey_7030: 'grege',
  storage: 'armoires',

  'terms-of-use': 'cgv',
  'tv-cabinet': 'meubles-tv',
  extensionsDesktop: 'plateaux',

  veneered_anthracite: 'chene-laque-anthracite',
  veneered_black: 'chene-laque-noir',
  veneered_blue: 'chene-laque-bleu',
  veneered_grey: 'chene-laque-gris',
  veneered_red: 'chene-laque-rouge',
  veneered_white: 'chene-laque-blanc',
  veneered_yellow: 'chene-laque-jaune',
  violet_4009: 'violet-poudre',

  'wall-to-wall-shelf': 'bibliotheques-murales',
  'wardrobe-doors': 'portes',
  walnut: 'noyer',
  wardrobe: 'dressing',
  wardrobes: 'dressings',
  white_9003: 'blanc',
  wood: 'bois',

  yellow_1021: 'jaune',

  'couches-and-armchairs': 'canapes-et-fauteuils',
};

// Austrian and Swiss sites
translations['ascii-de_AT'] = translations['ascii-de_DE'];
translations['ascii-de_CH'] = translations['ascii-de_DE'];
translations['ascii-fr_CH'] = translations['ascii-fr_FR'];

// English URL translation
translations['ascii-en_GB'] = {};
Object.keys(translations['ascii-de_DE']).forEach((key) => {
  translations['ascii-en_GB'][key] = key;
});

assignIn(translations['ascii-en_GB'], {
  'shelf-system': 'shelving-systems',
  'showroom-zuerich': 'showroom-zurich',
  'tv-cabinet': 'tv-cabinets',
  'wall-to-wall-shelf': 'shelving-units',
  'wardrobe-doors': 'doors',
  'wood-shelf': 'wooden-shelves',
  black_9004: 'black',
  blue_2_5000: 'navy-blue',
  blue_5009: 'blue',
  brown_emperador: 'brown-emperador-marble',
  clear_grey: 'clear-grey-glass',
  closet: 'cupboards',
  closets: 'storage',
  color: 'colour',
  colors: 'colours',
  custom: 'special-orders',
  dark_blue_5013: 'dark-blue',
  dark_green_6009: 'dark-green',
  dark_red_3005: 'dark-red',
  fronts: 'front-panels',
  green_6029: 'bottle-green',
  green_grey_7009: 'green-grey',
  grey_7024: 'anthracite',
  store: 'store',
  grey_7037: 'grey',
  joyn: 'sofa-tyme',
  joyn_old: 'sofa-joyn',
  light_grey_7004: 'light-grey',
  light_yellow_1016: 'light-yellow',
  mint_6033: 'mint',
  opaque_colorless: 'opaque-colorless-glass',
  opaque_grey: 'opaque-grey-glass',
  pyllow: 'sofa-pyllow',
  flayr: 'sofa-flayr',
  red_3016: 'brick-red',
  samplebox: 'material-samples',
  shopping_cart: 'basket',
  stone_grey_7030: 'stone-grey',
  storage: 'wardrobes-drawers',
  veneered_anthracite: 'veneered-anthracite',
  veneered_black: 'veneered-black',
  veneered_blue: 'veneered-blue',
  veneered_grey: 'veneered-grey',
  veneered_red: 'veneered-red',
  veneered_white: 'veneered-white',
  veneered_yellow: 'veneered-yellow',
  violet_4009: 'violet',
  white_9003: 'white',
  yellow_1021: 'yellow',
});

translations['ascii-nl_NL'] = translations['ascii-en_GB'];

export default translations;
