import classNames from 'classnames';
import Icon from 'mycs/shared/components/Icon/Icon';
import styles from './StarsRating.scss';

interface StarsRatingProps {
    rating: number | null;
    className?: string;
    starClassName?: string;
    starColor?: string;
}

export default function StarsRating(props: StarsRatingProps) {
    const { rating, className, starColor, starClassName } = props;

    if (!rating) return null;

    const fullStars = Math.floor(rating);
    const halfStars = +(rating - fullStars !== 0);

    const starsArray = [];
    const color = starColor || ''

    for (let i = 1; i <= 5; i++) {
        const isFullStar = i <= fullStars;
        const isHalfStar = i === fullStars + 1 && halfStars === 1;

        starsArray.push(
            <Icon
                key={i}
                iconPath={
                    isFullStar
                        ? `/img/icons/footer/star-filled${color}.svg`
                        : isHalfStar
                            ? `/img/icons/footer/star-half-filled${color}.svg`
                            : `/img/icons/footer/star-empty${color}.svg`
                }
                className={classNames(styles.star, starClassName)}
            />
        );
    }

    return (
        <div className={classNames(styles.stars, className)}>
            {starsArray}
        </div>
    );
}
