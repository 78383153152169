import urlProviderService from 'mycs/shared/services/UrlProviderService/UrlProviderService';
import FetchAPI from 'mycs/shared/utilities/FetchAPI/FetchAPI';

// Sign-up form source, that needs to be sent to Mailchimp.
export enum SignupSource {
  Configurator = 'SD_NL_config',
  PDP = 'SD_NL_PDP',
  ExitIntentConfigurator = 'SD_NL_EI_config',
  ExitIntentPDP = 'SD_NL_EI_PDP',
  Checkout = 'Checkout',
  PopUp = 'PopUp',
  Banner = 'Banner_SignUp',
}

interface PostUserResponse {
  id: number;
  referrer: string;
  created_at: string;
  updated_at: string;
  designs: UserDesign[];
  email: string;
  storeCode: string;
  meta?: {
    [key: string]: any;
  };
  token: string;
  new: boolean;
}

type GetUserResponse = Omit<PostUserResponse, 'token' | 'new'>;

export type User = GetUserResponse;

export interface PostUserRequest {
  email: string;
  storeCode: string;
  referrer?: string;
  meta?: {
    source?: SignupSource;
    isSubscriptionAdded?: boolean;
    language?: string;
  };
}

interface PostUserDesignResponse {
  uuid: string;
  created_at: string;
  updated_at: string;
}

export type UserDesign = PostUserDesignResponse;

interface DeleteUserDesignResponse {
  success: boolean;
}

interface TrustPilotScoreResponse {
  score: {
    stars: number;
    trustScore: number;
  };
  numberOfReviews: {
    total: number;
  };
}

interface TrustPilotReviewsResponse {
  reviews: TrustPilotReview[];
}

// Add interface for Trustpilot review
export interface TrustPilotReview {
  id: string;
  title: string;
  text: string;
  stars: number;
  date: string;
  name: string;
  isVerified: boolean;
  createdAt: string;
  consumer: {
    displayName: string;
  };
  companyReply: {
    text: string;
    createdAt: string;
  };
}

const apiURL = urlProviderService.getUserApiUrl();

export function getUser(userID: number): Promise<GetUserResponse> {
  return FetchAPI.get(`${apiURL}/${userID}`);
}

export function postUser(payload: PostUserRequest): Promise<PostUserResponse> {
  return FetchAPI.post(apiURL, payload);
}

export function postUserDesign(
  userID: number,
  payload: {
    uuid: string;
  }
): Promise<PostUserDesignResponse> {
  return FetchAPI.post(`${apiURL}/${userID}/designs`, payload);
}

export function deleteUserDesign(
  userID: number,
  designUUID: string
): Promise<DeleteUserDesignResponse> {
  return FetchAPI.delete(`${apiURL}/${userID}/designs/${designUUID}`);
}

export function postLogin(userID: number, token: string) {
  return FetchAPI.post(`${apiURL}/login`, {
    userID,
    token,
  });
}

// Fetch Trustpilot company score
export function getTrustpilotScore(): Promise<TrustPilotScoreResponse> {
  return FetchAPI.get(`${apiURL}/reviews/company-score`);
}

// Fetch Trustpilot company reviews
export function getTrustpilotReviews(
  language: string
): Promise<TrustPilotReviewsResponse> {
  return FetchAPI.get(`${apiURL}/reviews`, {
    language,
  });
}
