import { PostUserRequest, SignupSource, User } from 'mycs/api/UserAPI';
import config from 'mycs/config';

const storageKeyUserID = config.storageSettings.keys.userID;

export function getUserID() {
  const userIDString = localStorage.getItem(storageKeyUserID);
  return userIDString ? parseInt(userIDString) : null;
}

export function setUserID(userID: number) {
  localStorage.setItem(storageKeyUserID, userID.toString());
}

export function removeUserID() {
  localStorage.removeItem(storageKeyUserID);
}

export function getReferrer(isShowroom: boolean) {
  return isShowroom ? config.flagModes.showroom.text : document.referrer;
}

export function isMycsInternalUser(
  user: undefined | User,
  hasNoTrackCookie: boolean,
  hasShowroomCookie: boolean
) {
  const hasMycsCookie = hasNoTrackCookie || hasShowroomCookie;
  const hasMycsEmail = !!user?.email.includes('@mycs.com');
  return hasMycsCookie || hasMycsEmail;
}

export function toPostUserPayload(
  storeCode: string,
  locale: string,
  email: string,
  referrer: string,
  newsletterSignUpMeta?: {
    source?: SignupSource;
    isSubscriptionAdded: boolean;
  }
): PostUserRequest {
  return {
    email,
    storeCode,
    referrer,
    meta: {
      language: locale,
      ...newsletterSignUpMeta,
    },
  };
}
