import MobileDetect from 'mobile-detect';

class MobileDetectService {
  md?: MobileDetect;

  constructor() {
    if (typeof window !== 'undefined') {
      this.init(window.navigator.userAgent);
    }
  }

  init(userAgent: string) {
    this.md = new MobileDetect(userAgent, 500);
  }

  /**
   * Check for mobile device
   */
  isMobile(): boolean {
    return this.md?.mobile() != null;
  }

  /**
   * Check for tablet
   * This function doesn't work on iPad Safari browsers, use isTabletScreen() additionally
   */
  isTablet(): boolean {
    return this.md?.tablet() != null;
  }

  /**
   * Check for iOs
   */
  isIos(): boolean {
    return this.md?.tablet() === 'iPad' || this.md?.phone() === 'iPhone';
  }
}

/**
 * MobileDetect Service
 * @deprecated Instead of using this service prefer responsive CSS only or useDevice hooks
 */
export default new MobileDetectService();
