import classNames from 'classnames';

import { RelativeUrlService } from 'mycs/shared/services/RelativeUrlService';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import Alink from 'mycs/shared/components/Alink/Alink';
import Icon from 'mycs/shared/components/Icon/Icon';
import IconLink, { LinkType } from 'mycs/shared/components/IconLink/IconLink';
import NavigationMenuOverlay from 'mycs/shared/components/NavigationMenuOverlay/NavigationMenuOverlay';

import styles from './NavigationMenu.scss';

export default function NavigationMenu() {
  const { locale } = useLocale();

  return (
    <div className={styles.wrapper}>
      <div className={classNames('container', styles.container)}>
        <div className={classNames(styles.menuContainer, 'hidden-print')}>
          <NavigationMenuOverlay />
        </div>

        <div className={styles.logoContainer}>
          <Alink
            href={RelativeUrlService.getHomeUrl(locale)}
            className={styles.logo}
            itemProp="logo"
          >
            <Icon iconPath="/img/mycs-logos/logo.svg" />
          </Alink>
        </div>

        <div className={classNames(styles.linksContainer, 'hidden-print')}>
          <div className={styles.linkIcon}>
            <IconLink
              to={LinkType.MyDesigns}
              iconPath="/img/icons/navigation/heart.svg"
              activeClassName={styles.linkIconActive}
              useOnClick
            />
          </div>
          <div className={styles.linkIcon}>
            <IconLink
              to={LinkType.ShoppingCart}
              iconPath="/img/icons/navigation/cart.svg"
              iconPathActive="/img/icons/navigation/cart-active.svg"
              activeClassName={styles.linkIconCartActive}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
