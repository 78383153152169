export function removeHashWithoutReload() {
  updateUrlWithoutReload(window.location.pathname + window.location.search);
}

export function updateUrlWithoutReload(url: string, stateObj = {}) {
  let currentUrl =
    window.location.pathname + window.location.search + window.location.hash;

  //Do not update the url if it has not changed
  if (url.endsWith(currentUrl)) {
    return;
  }

  //According to https://developer.mozilla.org/en-US/docs/Web/API/History/pushState most browsers ignore the title (2nd argument) and an empty string should be safe
  window.history.pushState(stateObj, '', url);
}
