import { Document } from '@contentful/rich-text-types';

type CfRichText = Document;

export enum FurnitureTypeEnum {
  Pyllow = 'pyllow',
  Shelf = 'shelf',
  Closet = 'closet',
  Wardrobe = 'wardrobe',
  Table = 'table',
  Couchtable = 'couchtable',
  Sydetable = 'sydetable',
  Couch = 'couch',
  Joyn = 'joyn',
  Chayr = 'chayr',
  Pryme = 'pryme',
  Styng = 'styng',
  Cushion = 'cushion',
  Flayr = 'flayr',
}

export enum CustomParagraphTypeEnum {
  ModulesSpecifications = 'Modules & Specifications',
  SleepingModule = 'Sleeping module',
  SeatComfort = 'Seat comfort',
}

export enum SpecialParagraphTypes {
  Fabric = 'fabrics',
  Legs = 'legs',
  Manual = 'manual',
  StairShelves = 'stair shelves',
}

export enum ParagraphTypes {
  Height = 'height',
  Backwalls = 'backwalls',
  WeightCapacity = 'weight-capacity',
}

export enum CfProductDetailsParagraphCustomType {
  modulesAndSpecifications = 'Modules & Specifications',
  seatComfort = 'Seat comfort',
  colorsSamplebox = 'Colors samplebox',
  sleepingModule = 'Sleeping module',
  stackedElements = 'Stacked Elements',
  missingBackwalls = 'Missing Backwalls',
  partlyMissingBackwalls = 'Partly Missing Backwalls',
  weightCapacity = 'Weight capacity',
  minimumHeight = 'Minimum Height',
}

export enum CfProductDetailsChapterType {
  Description = 'description',
  Dimensions = 'dimensions',
}

//A union type of all supported components (each one should extend ComponentBlock)
export type CfComponent =
  | CfCountdownRibbon
  | CfBreadcrumbs
  | CfHeroBanner
  | CfHeroBlock
  | CfSellingPoints
  | CfTextBlock
  | CfProductGrid
  | CfFurnitureFeatureList
  | CfNewsletterFormBanner
  | CfShowroomUsps
  | CfCategoriesPreviews
  | CfReviewsBlock
  | CfSimpleNav
  | ComponentBlock;

export interface CfPageWithLayout {
  content?: CfComponent[];
}

interface ComponentBlock {
  _id: string;
  _contentType: string;
}

export interface CfAbTestConfig {
  _contentType: 'abTestConfig';
  _id: string;
  experimentIdList: string[];
  experimentName: string;
}

export interface CfAnchors {
  _contentType: 'printAdPage';
  _id: string;
  anchorId?: string;
  anchors?: CfAnchors[];
  fieldType?: boolean;
  title?: string;
}

export interface CfAnnouncement {
  _contentType: 'announcement';
  _id: string;
  announcementText?: string;
  title?: string;
}

export interface CfAssetList {
  _contentType: 'assetList';
  _id: string;
  assets?: CfAsset[];
  listName: string;
}

export interface CfBreadcrumbs {
  _contentType: 'wizardStep';
  _id: string;
  hideOnMobile?: boolean;
  links: CfNavLink[];
  staticLayout?: boolean;
  title?: string;
}

export interface CfButtonLink {
  _contentType: 'buttonLink';
  _id: string;
  anchorTarget?: string;
  buttonClasses?: string[];
  contactCustomerService?:
  | 'CustomerServiceCustomizeFurniture'
  | 'CustomerServiceCustomizeShelf'
  | 'CustomerServiceCustomizeTable'
  | 'CustomerServiceCustomizeWardrobe';
  iconName?: string;
  isAnchor?: boolean;
  mobileButtonClasses?: string[];
  slug?: string;
  target?: 'none' | '_self' | '_blank';
  text?: string;
  url?: string;
}

export interface CfCarouselGalleryGrid {
  _contentType: 'blackFridayConfig';
  _id: string;
  isGalleryGrid?: boolean;
  items?: CfGalleryItem[];
  slug?: string;
  title?: string;
}

export interface CfCategoriesPreviews {
  _contentType: 'wizardFurnitureType';
  _id: string;
  bigTitle?: boolean;
  categories: CfSubcategory[];
  darkText?: boolean;
  slug: string;
  textAlign?: 'left' | 'center' | 'right';
  title?: string;
  useLinkedCategoriesView?: boolean;
}

export interface CfAsset {
  _id: string;
  assetUrl?: string;
  description: string;
  height: number;
  title: string;
  url?: string;
  width: number;
}

export interface CfResponsiveImageLike {
  image?: CfAsset;
  imagePhone?: CfAsset;
  imageTablet?: CfAsset;
}

export interface CfCountdownRibbon {
  _contentType: 'b2bPage';
  _id: string;
  animationSpeed?: number;
  backgroundColor?: 'green' | 'red' | 'grey' | 'yellow' | 'blue';
  title?: string;
}

export interface CfCustomerTestimonial {
  _contentType: 'seoProductsList';
  _id: string;
  date?: string;
  furnitureType:
  | 'shelf'
  | 'closet'
  | 'wardrobe'
  | 'table'
  | 'couchtable'
  | 'sydetable'
  | 'couch'
  | 'joyn'
  | 'pyllow'
  | 'chayr'
  | 'pryme'
  | 'styng'
  | 'cushion';
  photo?: CfAsset;
  rating: number;
  showOnWebsite?: boolean;
  testimonialText?: string;
  title: string;
  uuids?: string[];
  customerName?: string;
}

export interface CfCustomerTestimonialsSection {
  _contentType: 'productComparisonCard';
  _id: string;
  title?: string;
}

export interface CfDesignsCarousel {
  _contentType: 'products';
  _id: string;
  recentPurchaseDisplayQuantity?: number;
  showSavedDesigns?: boolean;
  slidesToShow?: number;
  title?: string;
  uuids?: string[];
}

export interface CfFaqAssemblyInstructionLink {
  _contentType: 'assemblyInstructionLink';
  _id: string;
  icon?: CfAsset;
  name?: string;
  pdf?: CfAsset;
  title?: string;
}

export interface CfFooter {
  _contentType: 'footer';
  _id: string;
  footerGroups?: CfFooterGroup[];
  isAnnouncementEnabled?: boolean;
  slug?: string;
}

export interface CfFooterGroup {
  _contentType: 'footerGroup';
  _id: string;
  links?: CfNavLink[];
  title?: string;
  url?: string;
  additionalTextField?: CfRichText;
}

export interface CfFurnitureType {
  _contentType: 'furnitureType';
  _id: string;
  headerImagesCarousel?: CfHeaderImage[];
  image?: CfAsset;
  name?: string;
  uuid?: string;
  uuids?: string[];
}

export interface CfFurnitureTypeCard {
  _contentType: 'furnitureTypeCard';
  _id: string;
  furnitureType: 'shelf' | 'wardrobe' | 'joyn' | 'pyllow' | 'cushion' | 'flayr';
  furnitureTypes?: CfFurnitureType[];
  text?: string;
  title?: string;
}

export interface CfFurnitureFeature {
  _contentType: 'furnitureFeature';
  _id: string;
  button?: CfButtonLink;
  description?: string;
  filler?: boolean;
  helpButton?: boolean;
  hideLinkOnMobile?: boolean;
  id?: string;
  link?: CfButtonLink;
  linkMobile?: CfButtonLink;
  mobilePicture?: CfAsset;
  mobilePictureThumbnail?: CfAsset;
  mobileVideo?: CfAsset;
  picture: CfAsset;
  pictures?: CfFurnitureFeaturePicture[];
  shortDescription?: string;
  simpleButton?: CfButtonLink;
  slug?: string;
  subtitle?: string;
  thumbnailIcons?: CfAsset[];
  thumbnails?: CfAsset[];
  title?: string;
  video?: CfAsset;
  widePicture?: CfAsset;
}

export interface CfFurnitureFeatureList {
  _contentType: 'furnitureFeatureList';
  _id: string;
  additionalOptions?: CfFurnitureFeature[];
  additionalOptionsTitle?: string;
  anchor?: string;
  features?: CfFurnitureFeature[];
  furnitureType?:
  | 'general'
  | 'shelf'
  | 'shelf ayr'
  | 'wardrobe'
  | 'couch'
  | 'sofa'
  | 'joyn'
  | 'pyllow'
  | 'flayr'
  | 'cushion';
  layout?: 'tabs' | 'circles' | 'grid' | 'old_grid';
  mobileVideo?: CfVideo;
  productVideo?: CfAsset;
  slug?: string;
  title?: string;
  video?: CfVideo;
}

export interface CfFurnitureFeaturePicture {
  _contentType: 'furnitureFeaturePicture';
  _id: string;
  mobilePicture?: CfAsset;
  picture?: CfAsset;
  thumbnail?: CfAsset;
  title?: string;
  widePicture?: CfAsset;
}

export interface CfGalleryItem {
  _contentType: 'galleryItem';
  _id: string;
  bannerLinks?: string;
  category?:
  | 'shelves'
  | 'closets'
  | 'tables'
  | 'couchtables'
  | 'custom'
  | 'sofas';
  credits?: string;
  fullImage?: CfAsset;
  longDescription?: string;
  metaTags?: CfMetaTags;
  shortDescription?: string;
  slug?: string;
  smallImage?: CfAsset;
  title?: string;
  uuids?: string[];
}

export interface CfGridBanner {
  _contentType: 'gridBanner';
  _id: string;
  ctaLink?: CfButtonLink;
  customImage?: CfResponsiveImage;
  customSubtitle?: string;
  customTitle?: string;
  gridBannerSubtype: (
    | CfGridBannerSubtypeFeaturedProduct
    | CfGridBannerSubtypeNewsletterFormModal
  )[];
  slug: string;
  type: 'Featured product' | 'Newsletter form' | 'Split CTA Banner';
}

export interface CfGridBannerSubtypeFeaturedProduct {
  _contentType: 'featuredProduct';
  _id: string;
  hideImagesCarousel?: boolean;
  uuid?: string;
}

export interface CfGridBannerSubtypeNewsletterFormModal {
  _contentType: 'newsletterFormModal';
  _id: string;
  mailingListId?: string;
  slug?: string;
  text?: string;
  title?: string;
}

export interface CfHeaderImage {
  _contentType: 'headerImage';
  _id: string;
  anchors?: CfButtonLink[];
  bannerLinks?: string;
  blurb?: string;
  button?: CfButtonLink;
  gradientOverlay?: boolean;
  heading?: string;
  headlineBar?: boolean;
  headlineBarImage?: CfAsset;
  hideOnPhone?: boolean;
  image?: CfAsset;
  imageBase64?: string;
  imagePhone?: CfAsset;
  imageTablet?: CfAsset;
  slug?: string;
  textColour?: 'Dark' | 'Light';
  textPosition?: 'centered' | 'left';
}

export interface CfHeaderMenu {
  _contentType: 'headerMenu';
  _id: string;
  mobileSubmenus?: CfHeaderSubmenu[];
  submenus?: CfHeaderSubmenu[];
  title?: string;
}

export interface CfHeaderSubmenu {
  _contentType: 'headerSubmenu';
  _id: string;
  hideOnPhone?: boolean;
  hideOnTablet?: boolean;
  navGroups?: CfHeaderSubmenuColumn[];
  target?: 'none' | '_self' | '_blank';
  title: string;
  url?: string;
}

export interface CfHeaderSubmenuColumn {
  _contentType: 'headerSubmenuColumn';
  _id: string;
  links?: CfNavLink[];
  target?: 'none' | '_self' | '_blank';
  title?: string;
  url?: string;
  additionalTextField?: CfRichText;
}

export interface CfHelpConfig {
  _contentType: 'helpConfig';
  _id: string;
  data_dev: string;
  data_prod: string;
  docBlock?: CfRichText;
  email?: string;
  phoneNumber?: string;
  title?: string;
}

export interface CfHelpItem {
  _contentType: 'helpItem';
  _id: string;
  questions?: CfHelpQuestion[];
  slug?: string;
}

export interface CfHelpListing {
  _contentType: 'helpListing';
  _id: string;
  category?: 'table' | 'couchtable' | 'shelf' | 'wardrobe' | 'general';
  items?: CfHelpItem[];
}

export interface CfHelpQuestion {
  _contentType: 'helpQuestion';
  _id: string;
  answer?: string;
  question?: string;
}

export interface CfHeroBanner {
  _contentType: 'wizardPage';
  _id: string;
  anchor?: string;
  headerImage?: CfHeaderImage;
  headerImagesCarousel?: CfHeaderImage[];
  heroBlockPosition?: string[];
  heroBlocks?: CfHeroBlock[];
  layout: 'default' | 'centered';
  onlyDisplayTitlesFromHeroImage?: boolean;
  slug: string;
  videos?: CfVideo[];
}

export interface CfHeroSteps {
  _contentType: 'productComparisonList';
  _id: string;
  header?: string;
  mobileSteps?: CfStep[];
  steps?: CfStep[];
  title?: string;
}

export interface CfHeroBlock {
  _contentType: 'heroBlock';
  _id: string;
  buttonLink?: string;
  buttonText?: string;
  image: CfAsset;
  imagePhone?: CfAsset;
  imageTablet?: CfAsset;
  position?: 'Top' | 'Bottom';
  slug?: string;
  text?: string;
  textColour?: 'Light' | 'Dark';
  title?: string;
}

export interface CfInfoMessage {
  _contentType: 'infoMessage';
  _id: string;
  blurb?: string;
  button?: CfButtonLink;
  text?: string;
}

export interface CfJalousie {
  _contentType: 'jalousie';
  _id: string;
  items?: CfJalousieItem[];
  slug?: string;
  title?: string;
}

export interface CfJalousieItem {
  _contentType: 'jalousieItem';
  _id: string;
  darkText?: boolean;
  description?: string;
  hideLinkOnMobile?: boolean;
  image?: CfAsset;
  imageCenter: number;
  imagePhone?: CfAsset;
  link?: CfButtonLink;
  linkMobile?: CfButtonLink;
  rightSideText?: boolean;
  slug?: string;
  title: string;
}

export interface CfLaminatesConfig {
  _contentType: 'laminatesConfig';
  _id: string;
  ctaMessage?: string;
  deprecationDate?: string;
  furnitureTypes?: string[];
  infoMessage?: CfInfoMessage;
  infoMessageDisplayPages?: string[];
  labelDisplayPages?: string[];
  labelText: string;
  materials?: string[];
  priority?: number;
}

export interface CfMaterial {
  _contentType: 'material';
  _id: string;
  available?: boolean;
  category: CfMaterialCategoryOldVersion;
  color: string;
  description?: string;
  furnitureType: string[];
  name: string;
  picture: CfAsset;
  ralCode?: string;
  sku: string;
  thumbnail?: CfAsset;
  type: 'wood' | 'textile' | 'metal' | 'other' | 'fake-wood';
}

export interface CfMaterialPdpImage {
  _contentType: 'materialPdpImage';
  _id: string;
  furnitureType: string[];
  material: CfMaterial;
  materialName: string;
  picture: CfAsset;
  specsFilter?: CfSpecsFilter;
}

export interface CfMaterialCategoriesWithSubCategories {
  _contentType: 'wizardFurnitureSize';
  _id: string;
  categories?: CfMaterialCategoryForSubcategories[];
  slug: string;
}

export interface CfMaterialCategoryOldVersion {
  _contentType: 'materialCategories';
  _id: string;
  description?: string;
  image?: CfAsset;
  label?: string;
  name: string;
  trackingSlug?: string;
}

export interface CfMaterialCategoryForSubcategories {
  _contentType: 'materialCategoryForSubcategories';
  _id: string;
  description?: string;
  label?: string;
  name: string;
  subCategories: CfMaterialSubcategory[];
  trackingSlug?: string;
}

export interface CfMaterialSubcategory {
  _contentType: 'materialSubcategory';
  _id: string;
  description?: string;
  infoMessage?: CfInfoMessage;
  isDisabled?: boolean;
  label?: string;
  materials?: CfMaterial[];
  name: string;
  trackingSlug?: string;
}

export interface CfMetaTags {
  _contentType: '1G8frnK44cCuC2m8iOecsM';
  _id: string;
  canonical?: string;
  description?: string;
  hasHreflang?: boolean;
  keywords?: string[];
  robots?: 'index, follow' | 'noindex, nofollow' | 'noindex, follow';
  route?: string;
  title?: string;
}

export interface CfModelFinderCarousel {
  _contentType: 'infoBannerCarousel';
  _id: string;
  models: CfTextMedia[];
  slug?: string;
  title: string;
}

export interface CfNavLink {
  _contentType: 'footerLink';
  _id: string;
  hideOnPhone?: boolean;
  highlighted?: boolean;
  icon?: CfAsset;
  image?: CfAsset;
  target?: 'none' | '_self' | '_blank';
  title?: string;
  url?: string;
}

export interface CfNewsletterFormBanner {
  _contentType: 'serviceSpec';
  _id: string;
  title?: string;
}

export interface CfPdpImages {
  _contentType: 'pdpImages';
  _id: string;
  furnitureType?:
  | 'shelf'
  | 'closet'
  | 'wardrobe'
  | 'couch'
  | 'joyn'
  | 'pyllow'
  | 'cushion'
  | 'flayr';
  images?: CfAsset[];
  slug: string;
  uuids?: string[];
}

export interface CfPageConfigurator {
  _contentType: 'pageConfigurator';
  _id: string;
  accessories?: CfSubcategory[];
  assemblyInstructionLinks?: CfFaqAssemblyInstructionLink[];
  discountExplainer?: CfQuantityDiscountsExplainer;
  footer?: CfFooter;
  furnitureFeatures?: CfFurnitureFeatureList[];
  furnitureFeaturesLists?: CfFurnitureFeatureList[];
  furnitureType?:
  | 'shelf'
  | 'closet'
  | 'wardrobe'
  | 'joyn'
  | 'pyllow'
  | 'flayr'
  | 'cushion';
  locUrl?: Record<string, string>;
  materialCategories?: CfMaterialCategoryOldVersion[];
  materialCategoriesWithSubCategories?: (
    | CfMaterialCategoryForSubcategories
    | CfMaterialColorCategory
  )[];
  metaTags?: CfMetaTags;
  nyceConfig?: CfPaymentProvidersConfig;
  productDetails?: CfProductDetailsConfigurator[];
  productDetailsLayouts?: CfProductDetailsLayout[];
  productDetailsList?: CfProductDetailsListConfigurator[];
  steps?: CfStep[];
  url: string;
  variantFurnitureFeatureList?: CfFurnitureFeatureList;
  volumeDiscountsExplainer?: CfQuantityDiscountsExplainer;
}

export interface CfPageMain {
  _contentType: 'mainPage';
  _id: string;
  campaignImages?: CfHeaderImage[];
  canonical?: string;
  content?: (
    | CfBreadcrumbs
    | CfCategoriesPreviews
    | CfCountdownRibbon
    | CfFurnitureFeatureList
    | CfFurnitureTypeCard
    | CfHeroSteps
    | CfHeroBanner
    | CfHeroBlock
    | CfUnusedInfoBanner
    | CfJalousie
    | CfNewsletterFormBanner
    | CfProductGrid
    | CfQuantityDiscountsExplainer
    | CfReviewsBlock
    | CfSellingPoints
    | CfShowroomUsps
    | CfSimpleNav
    | CfTextBlock
    | CfModelFinderCarousel
    | CfCarouselGalleryGrid
    | CfButtonLink
    | CfVideo
    | CfAnchors
    | CfMaterialCategoriesWithSubCategories
    | CfDesignsCarousel
    | CfMetaTags
    | CfStaticPageCategory
    | CfRibbon
    | CfCustomerTestimonialsSection
  )[];
  customData?: string;
  discountsExplainer?: CfQuantityDiscountsExplainer;
  discountsExplainers?: CfQuantityDiscountsExplainer[];
  features?: CfFurnitureFeatureList;
  footer: CfFooter;
  gridBanners?: CfGridBanner[];
  headerImages?: CfHeaderImage[];
  headerVideo?: CfVideo;
  heroBlockBottom?: CfHeroBlock;
  heroBlockTop?: CfHeroBlock;
  heroBlocks?: CfHeroBlock[];
  infoBanner?: CfHeroBlock;
  infoMessage?: CfInfoMessage;
  innerCss?: string;
  innerHtml?: string;
  innerJs?: string;
  jalousie?: CfJalousie;
  keywords?: string[];
  locUrl?: Record<string, string>;
  materialCategoriesWithSubCategories?: CfMaterialCategoryForSubcategories[];
  metaDescription?: string;
  metaTags?: CfMetaTags;
  metaTitle?: string;
  pageType: 'Landing' | 'PLP' | 'Showroom';
  productFilters?: CfProductFilters;
  robots?: 'index, follow' | 'noindex, nofollow' | 'noindex, follow';
  seoHtml?: string;
  showroomsList?: CfShowroomsList;
  subcategories?: CfSubcategory[];
  subcategoriesTitle?: string;
  url: string;
  usps?: CfStep[];
  uuids?: string[];
  visualSellingPoints?: CfTextMedia[];
  volumeDiscountsExplainer?: CfQuantityDiscountsExplainer;
}

export interface CfPagePdp {
  _contentType: 'pdp';
  _id: string;
  accessories?: CfSubcategory[];
  assemblyText?: string;
  careInstructionsText?: string;
  discountsExplainer?: CfQuantityDiscountsExplainer;
  dynamicMaterialsInfo?: string;
  features?: CfFurnitureFeatureList;
  flexibilityText?: string;
  flowboxKey?: string;
  flowboxTags?: string[];
  footer?: CfFooter;
  furnitureType?:
  | 'shelf'
  | 'closet'
  | 'wardrobe'
  | 'couch'
  | 'joyn'
  | 'pyllow'
  | 'cushion'
  | 'flayr';
  galleryVideos?: CfVideo[];
  infoBanner?: CfHeroBlock;
  linkedCategories?: CfSubcategory[];
  linkedCategoriesTitle?: string;
  materialsText?: string;
  metaTags?: CfMetaTags;
  paymentMethods?: CfFooterGroup;
  productDetailsLayout?: CfProductDetailsLayout;
  qualityText?: string;
  shippingAssemblyText?: string;
  shippingText?: string;
  showFinancingUsp?: boolean;
  staticMaterialsInfo?: string;
  steps?: CfStep[];
  subtype?: string;
  url?: string;
  variantFeatures?: CfFurnitureFeatureList;
  videoBlock?: CfVideo;
  videoBlockLeather?: CfVideo;
  volumeDiscountsExplainer?: CfQuantityDiscountsExplainer;
}

export interface CfPageSamplebox {
  _contentType: 'sampleboxPage';
  _id: string;
  blurb?: string;
  footer?: CfFooter;
  headerImage?: CfAsset;
  headerImageBase64?: string;
  headerImageMobile?: CfAsset;
  headerImage_ipad?: CfAsset;
  infoMessage?: string;
  locUrl?: Record<string, string>;
  materialCategories?: CfMaterialCategoryOldVersion[];
  materialCategoriesWithSubCategories?: CfMaterialCategoryForSubcategories[];
  metaTags?: CfMetaTags;
  mobileSteps?: CfStep[];
  steps?: CfStep[];
  textColour?: 'Dark' | 'Light';
  url: string;
}

export interface CfPaymentProvidersConfig {
  _contentType: 'nyceConfig';
  _id: string;
  description?: string;
  leftIcon?: CfAsset;
  option1?: string;
  option2?: string;
  option3?: string;
  rightIcons?: CfAsset[];
  slug?: string;
  text?: string;
  title?: string;
  value?: number;
}

export interface CfProductDetailsChapter {
  _contentType: 'productDetailsChapter';
  _id: string;
  chapterClarifyingText?: string;
  collapsed?: boolean;
  customParagraphs?: CfProductDetailsParagraphCustom[];
  headline: string;
  paragraphs?: CfProductDetailsParagraph[];
  type?: 'description' | 'dimensions';
}

export interface CfProductDetailsLayout {
  _contentType: 'productDetailsLayout';
  _id: string;
  customParagraphs?: CfProductDetailsParagraphCustom[];
  furnitureType?:
  | 'shelf'
  | 'shelf ayr'
  | 'wardrobe'
  | 'joyn'
  | 'pyllow'
  | 'flayr'
  | 'cushion';
  productDescription?: string;
  productDetailsChapters?: CfProductDetailsChapter[];
  title?: string;
}

export interface CfProductDetailsParagraph {
  _contentType: 'productDetailsParagraph';
  _id: string;
  autoGenerated?: boolean;
  buttons?: CfButtonLink[];
  clarifyingTexts?: CfProductDetailsParagraphCustom[];
  idEntry?: string;
  paragraphType?: 'height' | 'backwalls' | 'weight-capacity';
  specialParagraphType?: 'fabrics' | 'legs' | 'manual' | 'stair shelves';
  text: string;
  title: string;
}

export interface CfProductDetailsParagraphCustom {
  _contentType: 'productDetailsParagraphCustom';
  _id: string;
  buttons?: CfButtonLink[];
  idEntry?: string;
  text?: string;
  title?: string;
  type:
  | 'Modules & Specifications'
  | 'Seat comfort'
  | 'Colors samplebox'
  | 'Sleeping module'
  | 'Backwalls'
  | 'Weight capacity'
  | 'Stacked Elements'
  | 'Minimum Height'
  | 'Missing Backwalls'
  | 'Partly Missing Backwalls';
}

export interface CfProductDiscounts {
  _contentType: 'productDiscounts';
  _id: string;
  discounts?: number;
  ebayDiscount?: number;
  endDate: string;
  furnitureTypes?: string[];
  name: string;
  startDate: string;
}

export interface CfProductFilter {
  _contentType: 'productFilter';
  _id: string;
  filterName:
  | 'price'
  | 'dimensions'
  | 'color'
  | 'material'
  | 'shape'
  | 'model'
  | 'style';
  filterOptions: CfProductFilterOption[];
  label: string;
  slug?: string;
}

export interface CfProductFilterOption {
  _contentType: 'productFilterOption';
  _id: string;
  backgroundColor?: string;
  backgroundImage?: string;
  isBoolean?: boolean;
  label?: string;
  queryParam:
  | 'furniture_type'
  | 'color_group'
  | 'material'
  | 'kids_pets_friendly'
  | 'price_min'
  | 'price_max'
  | 'width_min'
  | 'width_max'
  | 'height_min'
  | 'height_max'
  | 'length_min'
  | 'length_max'
  | 'sofa_shape'
  | 'sofa_is_sleeping'
  | 'sofa_is_ottoman'
  | 'shelf_leg_type';
  queryParamUrl: string;
  queryParamValue: string;
  queryParamValueUrl: string;
  slug?: string;
}

export interface CfProductFilters {
  _contentType: 'productFilters';
  _id: string;
  filters: CfProductFilter[];
  furnitureGroup: 'others' | 'shelves' | 'sofas' | 'wardrobes';
  predefinedFilterOptions?: CfProductFilterOption[];
  slug: string;
}

export interface CfProductGrid {
  _contentType: 'wizardFurnitureColor';
  _id: string;
  category?:
  | '2-Sitzer Sofas'
  | '3-Sitzer Sofas'
  | 'Aktenregale'
  | 'Aktenschränke'
  | 'Bibliothek'
  | 'Bücherregale'
  | 'Ecksofas'
  | 'Hängeschränke'
  | 'Highboards'
  | 'Hochschränke'
  | 'Holzregale'
  | 'Kleiderschränke'
  | 'Kommoden'
  | 'Ledersofas'
  | 'Lowboards'
  | 'Nachtschränke'
  | 'Polsterhocker'
  | 'Regalsysteme'
  | 'Relaxsessel'
  | 'Rollcontainer'
  | 'Schallplattenregale'
  | 'Schlafsofas'
  | 'Schränke'
  | 'Schrankwände'
  | 'Sessel'
  | 'Sideboards'
  | 'Sofas'
  | 'Stufenregale'
  | 'TV-Schränke'
  | 'Vitrinen'
  | 'Wohnlandschaften'
  | 'Wohnwände';
  gridBanners?: CfGridBanner[];
  gridBannersVariant?: CfGridBanner[];
  gridTitle?: string;
  hideFurnitureTypeFilter?: boolean;
  hideTopMargin?: boolean;
  includeFilterOffset?: boolean;
  isABTestActivated?: boolean;
  primaryColorGroup?:
  | 'reds'
  | 'pinks_and_violets'
  | 'greys_and_charcoals'
  | 'blacks'
  | 'yellows'
  | 'browns'
  | 'natural_and_whites'
  | 'greens'
  | 'blues'
  | 'oranges'
  | 'violets';
  productFilters?: CfProductFilters;
  title?: string;
  uuids?: string[];
}

export interface CfProductDetailsListConfigurator {
  _contentType: 'productDetailsList';
  _id: string;
  furnitureType?:
  | 'shelf'
  | 'shelf ayr'
  | 'wardrobe'
  | 'joyn'
  | 'pyllow'
  | 'cushion';
  name?: string;
  productDetails?: CfProductDetailsConfigurator[];
}

export interface CfProductDetailsConfigurator {
  _contentType: 'productDetails';
  _id: string;
  content?: string;
  heading?: string;
}

export interface CfQuantityDiscountsExplainer {
  _contentType: 'discountsExplainer';
  _id: string;
  anchor?: string;
  description?: string;
  furnitureIcons?: CfAsset[];
  furnitureStackIcon?: CfAsset;
  furnitureType?: 'cushion';
  gridBannerLink?: string;
  picture?: CfAsset;
  quantityDiscounts?: CfSetDiscounts;
  quantityLabel?: string;
  setAlert?: string;
  setAlertLinkText?: string;
  setAlertLinkUrl?: string;
  setLabel?: string;
  title?: string;
  volumeDiscountCartText?: string;
  volumeDiscountConfiguratorText?: string;
  volumeDiscountLink?: CfButtonLink;
  volumeDiscountMaxShortText?: string;
  volumeDiscountMaxText?: string;
  volumeDiscountMinText?: string;
  volumeDiscountPdpText?: string;
  volumeDiscounts?: CfSetDiscounts;
}

export interface CfResponsiveImage {
  _contentType: 'responsiveImage';
  _id: string;
  image: CfAsset;
  imagePhone?: CfAsset;
  imageTablet?: CfAsset;
  slug?: string;
}

export interface CfReviewsBlock {
  _contentType: 'interiorPage';
  _id: string;
  hideDetails?: boolean;
  title?: string;
  type: 'Trust Pilot';
}

export interface CfRibbon {
  _contentType: 'ribbon';
  _id: string;
  activated: boolean;
  bannerBackgroundColour?: 'green' | 'red' | 'grey' | 'yellow' | 'blue';
  countdownSubheader?: string;
  countdownTimer?: string;
  countdownTitles?: string[];
  endDate?: string;
  firstTimeVisitorsOnly?: boolean;
  hideCountdownClock?: boolean;
  imageDesktop?: CfAsset;
  imagePhone?: CfAsset;
  imageTablet?: CfAsset;
  isPromoBannerActivated?: boolean;
  linkText?: string;
  mobileContent?: string;
  promoBannerBackgroundColour?: 'green' | 'red' | 'yellow' | 'grey' | 'blue';
  promoBannerButton?: CfButtonLink;
  promoBannerInfoText?: string;
  promoCountdownTitles?: string[];
  showTimes?: number;
  startDate?: string;
  title?: string;
  titlesAnimationSpeed?: number;
}

export interface CfSellingPoints {
  _contentType: 'responsiveVideo';
  _id: string;
  big?: boolean;
  hideOnMobile?: boolean;
  isFullWidth?: boolean;
  isTransparentBg?: boolean;
  noCollapse?: boolean;
  points: CfStep[];
  title?: string;
  underlined?: boolean;
  withText?: boolean;
}

export interface CfSetDiscounts {
  _contentType: 'setDiscounts';
  _id: string;
  discountType?: string[];
  discounts?: Record<string, number>;
  furnitureTypes: string[];
  name?: string;
}

export interface CfShippingFeeDiscounts {
  _contentType: 'imagePanelBanner';
  _id: string;
  discounts?: number;
  endDate: string;
  name: string;
  startDate: string;
}

export interface CfShowroomUsps {
  _contentType: 'serviceSpecsGroup';
  _id: string;
  anchor?: string;
  hideFooter?: boolean;
  hideHeader?: boolean;
  hideTrustpilotWidget?: boolean;
  showroomList: CfShowroomsList;
  title?: string;
  whiteLayout?: boolean;
}

export interface CfShowroomsList {
  _contentType: 'showroomsList';
  _id: string;
  buttonLink?: CfButtonLink;
  introduction?: string;
  pressLogos?: CfAsset[];
  pressLogosText?: string;
  responsiveImage?: CfResponsiveImage;
  sellingPoints?: CfStep[];
  showroomSellingPoints?: CfStep[];
  slug?: string;
  title?: string;
}

export interface CfSimpleNav {
  _contentType: 'wizardFurnitureTypes';
  _id: string;
  links: CfSimpleNavLink[];
  mobileMenuLabel: string;
  slug: string;
}

export interface CfSimpleNavLink {
  _contentType: 'furnitureTypeFilter';
  _id: string;
  slug?: string;
  title?: string;
  urlParameter?: string;
}

export interface CfSpecsFilter {
  _contentType: 'specsFilter';
  _id: string;
  filterName: string;
  specsAttribute: string;
  specsValue: string;
}

export interface CfStaticPageCategory {
  _contentType: 'faqCategory';
  _id: string;
  anchor?: string;
  icons?: CfAsset[];
  questions?: CfStaticPageCategoryItem[];
  title?: string;
}

export interface CfStaticPageCategoryItem {
  _contentType: 'faqQuestion';
  _id: string;
  anchor?: string;
  answer?: string;
  question?: string;
  slug?: string;
}

export interface CfStep {
  _contentType: 'step';
  _id: string;
  icon?: CfAsset;
  link?: string;
  longText?: string;
  slug?: string;
  text?: string;
}

export interface CfSubcategory {
  _contentType: 'subcategory';
  _id: string;
  configUrl?: string;
  image?: CfAsset;
  imagePdpDesktop?: CfAsset;
  imagePdpMobile?: CfAsset;
  text?: string;
  title?: string;
  url?: string;
}

export interface CfTextBlock {
  _contentType: 'pagepdpseo';
  _id: string;
  anchor?: string;
  backgroundColor?:
  | '#f1efed'
  | '#f4f4f4'
  | '#d9d9d9'
  | '#bababa'
  | '#fff'
  | '#e6e0cd'
  | '#ebebeb';
  button?: CfButtonLink;
  centralizeTitles?: boolean;
  code?: string;
  codeType?: 'CSS' | 'JS';
  htmlContent?: CfRichText;
  htmlSecondColumn?: CfRichText;
  layout: 'default' | 'seo' | 'categoryPage' | 'centered' | 'centeredButton';
  lineSpacing?: 'Small' | 'Medium' | 'Large';
  mobileLayout?: 'left';
  readMore: boolean;
  slug: string;
  title?: string;
  tokens?: string[];
}

export interface CfTextMedia {
  _contentType: 'textMedia';
  _id: string;
  image?: CfResponsiveImage;
  slug?: string;
  subtitle?: string;
  text?: string;
  title?: string;
  url?: string;
  video?: CfVideo;
}

export interface CfMaterialList {
  _contentType: 'materialList';
  _id: string;
  materialCategory: (CfMaterialCategoryOldVersion | CfOrderedMaterials)[];
  name?: string;
}

export interface CfOrderedMaterials {
  _contentType: 'orderedMaterials';
  _id: string;
  description?: string;
  image?: CfAsset;
  materials?: CfMaterial[];
  name: string;
  trackingSlug?: string;
}

export interface CfPageLayoutCategoryPage {
  _contentType: 'jobListing';
  _id: string;
  breadcrumbs?: CfNavLink[];
  footer?: CfFooter;
  hasNewsletterBanner?: boolean;
  hasSellingPoints?: boolean;
  hasTrustedShops?: boolean;
  headerImagesCarousel?: CfHeaderImage[];
  headerVideo?: CfVideo;
  imagesCarousel?: CfAsset[];
  imagesCarouselTitle?: string;
  modelDescriptions?: CfFurnitureType[];
  modelFinder?: CfTextMedia[];
  modelFinderTitle?: string;
  paragraphTop?: string;
  productFeatures?: CfFurnitureFeatureList;
  productFilters?: CfProductFilters;
  slug?: string;
  subcategories?: CfSubcategory[];
  subcategoriesBottom?: CfSubcategory[];
  subcategoriesTitle?: string;
  subcategoriesTitleBottom?: string;
  titleTop?: string;
  usps?: CfStep[];
  uuids?: string[];
  videoBlock?: CfVideo;
  videoTitle?: string;
  videoTop?: CfVideo;
}

export interface CfPageLayoutDefault {
  _contentType: 'pageLayout';
  _id: string;
  featuresCarousel?: CfFurnitureFeatureList;
  furnitureTypeCards?: CfFurnitureTypeCard[];
  headerImagesCarousel?: CfHeaderImage[];
  image?: CfResponsiveImage;
  slug?: string;
  subcategories?: CfSubcategory[];
  subcategoriesTitle?: string;
  textColumnLeft?: string;
  textColumnRight?: string;
  title?: string;
  videoBottom?: CfVideo;
  videoTop?: CfVideo;
}

export interface CfPageLayoutPreviewPage {
  _contentType: 'previewPage';
  _id: string;
  contactFormText?: string;
  contactUsButton?: boolean;
  contactUsText?: string;
  cta?: CfButtonLink;
  darkBackground?: boolean;
  footer?: CfFooter;
  headerImage?: CfHeaderImage;
  headerVideo?: CfVideo;
  locUrl?: Record<string, string>;
  metaTags?: CfMetaTags;
  newsletterForm?: boolean;
  newsletterText?: string;
  text?: string;
  title?: string;
  url?: string;
}

export interface CfPageLayoutVirtualShowroom {
  _contentType: 'virtualShowroomPage';
  _id: string;
  headerImagesCarousel?: CfHeaderImage[];
  heroBlockBottom?: CfHeroBlock;
  imagePanelBanner?: CfShippingFeeDiscounts;
  panoramaData?: CfAsset;
  productGridText?: string;
  productGridTitle?: string;
  setmoreId?: string;
  slug?: string;
  subcategoriesBottom?: CfSubcategory[];
  subcategoriesTitleBottom?: string;
  title?: string;
  uuids?: string[];
}

export interface CfQuantityDiscounts {
  _contentType: 'quantityDiscounts';
  _id: string;
  country_codes?: string[];
  discounts: Record<string, number>;
  furnitureTypes?: string[];
  name?: string;
}

export interface CfUnusedInfoBanner {
  _contentType: 'infoBanner';
  _id: string;
  bannerId?: string;
  buttonLink?: CfButtonLink;
  description?: string;
  responsiveImage?: CfResponsiveImage;
  textColor?: 'Light' | 'Dark';
  title: string;
}

export interface CfUnusedPageCareer {
  _contentType: 'career';
  _id: string;
  breadcrumbs?: CfNavLink[];
  cityHeading?: string;
  cityImage?: CfAsset;
  cityText?: string;
  cityTitle?: string;
  contactText?: string;
  contactTitle?: string;
  description?: string;
  footer?: CfFooter;
  headerImage?: CfAsset;
  headerImageMobile?: CfAsset;
  headerImageTablet?: CfAsset;
  headerSubtitle?: string;
  headerTitle?: string;
  jobsApplyText?: string;
  jobsHeading?: string;
  jobsLink?: string;
  locUrl?: Record<string, string>;
  metaTags?: CfMetaTags;
  presentation?: string;
  projectHeading?: string;
  projectImages?: CfAsset[];
  projectSteps?: CfStep[];
  projectText?: string;
  steps?: CfStep[];
  teamHeading?: string;
  teamImage?: CfAsset;
  teamText?: string;
  teamTitle?: string;
  textColour?: 'Dark' | 'Light';
  url: string;
}

export interface CfUnusedPageGallery {
  _contentType: 'galleryListing';
  _id: string;
  footer?: CfFooter;
  headerImage?: CfHeaderImage;
  items?: CfGalleryItem[];
  locUrl?: Record<string, string>;
  metaTags?: CfMetaTags;
  slug?: string;
  url: string;
}

export interface CfUnusedPagePress {
  _contentType: 'pagePress';
  _id: string;
  footer?: CfFooter;
  locUrl?: Record<string, string>;
  metaTags?: CfMetaTags;
  pressLogos?: CfAsset[];
  pressLogosText?: string;
  sections?: CfUnusedPressSection[];
  url?: string;
}

export interface CfUnusedPageShowroom {
  _contentType: 'showroom';
  _id: string;
  address?: string;
  announcement?: CfAnnouncement;
  appointments?: string;
  appoitmentBookingUrl?: string;
  bookedAppointmentInfoParagraph?: CfRichText;
  bookifyShowroomID?: string;
  carouselImages?: CfAsset[];
  city?: string;
  contactForm?: boolean;
  country: 'Germany' | 'France' | 'Switzerland' | 'Austria' | 'Luxembourg';
  footer: CfFooter;
  googleMapsAddress?: string;
  headerImage?: CfHeaderImage;
  headerParagraph?: string;
  headerTitle?: string;
  heroSteps?: CfStep[];
  heroStepsHeadline?: string;
  holidaysInformation?: string;
  image?: CfAsset;
  layout?: 'Showroom page';
  locUrl?: Record<string, string>;
  metaTags?: CfMetaTags;
  openingTimes?: string;
  parking?: string;
  phoneNumber?: string;
  productGridTitle?: string;
  scheduleAppointmentForm?: boolean;
  setmoreShowroomId?: string;
  showVirtualShowroomLink?: boolean;
  subtitle?: string;
  text?: string;
  title?: string;
  url?: string;
  uuids?: string[];
}

export interface CfMaterialColorCategory {
  _contentType: 'materialColorCategory';
  _id: string;
  name: string;
  subCategories: CfMaterialColorSubcategory[];
}

export interface CfMaterialColorSubcategory {
  _contentType: 'materialColorSubcategory';
  _id: string;
  color: string;
  isDisabled?: boolean;
  materials: CfMaterial[];
}

export interface CfUnusedB2BProfile {
  _contentType: 'b2bProfile';
  _id: string;
  avatar?: CfAsset;
  description?: string;
  diploma?: string;
  name?: string;
  studies?: string;
}

export interface CfUnusedPageLayoutCampaign {
  _contentType: 'campaignPage';
  _id: string;
  anchorImagesCarousel?: string;
  anchorJalousie?: string;
  anchorVideoBottom?: string;
  anchorVideoTop?: string;
  button?: CfButtonLink;
  buttonBottom?: CfButtonLink;
  buttonTop?: CfButtonLink;
  featuresCarousel?: CfFurnitureFeatureList;
  hasNewsletter?: boolean;
  headerImagesCarousel?: CfHeaderImage[];
  headerVideo?: CfVideo;
  imagesCarousel?: CfAsset[];
  jalousie?: CfJalousie;
  paragraphBottom?: string;
  paragraphMiddle?: string;
  paragraphTop?: string;
  sellingPoints?: CfJalousie[];
  slug?: string;
  subcategoriesBottom?: CfSubcategory[];
  subcategoriesTop?: CfSubcategory[];
  textColumnLeft?: string;
  textColumnRight?: string;
  title?: string;
  titleBottom?: string;
  titleImagesCarousel?: string;
  titleMiddle?: string;
  titleSellingPoints?: string;
  titleTop?: string;
  underParagraph?: string;
  underTitle?: string;
  usps?: CfStep[];
  videoBottom?: CfVideo;
  videoTop?: CfVideo;
}

export interface CfUnusedPageStaticAkaFaq {
  _contentType: 'faq';
  _id: string;
  assemblyInstructionLinks?: CfFaqAssemblyInstructionLink[];
  assemblyInstructionText?: string;
  breadcrumbs?: CfNavLink[];
  categories?: CfStaticPageCategory[];
  footer?: CfFooter;
  furnitureTypes?: CfFurnitureTypeCard[];
  headerImage?: CfAsset;
  headline?: string;
  jalousie?: CfJalousie;
  locUrl?: Record<string, string>;
  metaTags?: CfMetaTags;
  url: string;
}

export interface CfUnusedPressMedia {
  _contentType: 'pressMedia';
  _id: string;
  date?: string;
  image?: CfAsset;
  pdf?: CfAsset;
  slug?: string;
  text?: string;
  title?: string;
}

export interface CfUnusedPressSection {
  _contentType: 'pressSection';
  _id: string;
  contactProfile?: CfUnusedB2BProfile;
  contactTitle?: string;
  filter?: CfSimpleNavLink;
  heroBlock: CfHeroBlock;
  influencerFormUrl?: string;
  introductionText?: string;
  introductionTitle?: string;
  pressMedias?: CfUnusedPressMedia[];
  slug?: string;
  socialMediaLinks?: CfNavLink[];
  socialMediaTitle?: string;
}

export interface CfVideo {
  _contentType: 'video';
  _id: string;
  anchor?: string;
  buttonLink?: string;
  buttonText?: string;
  displayTitleAndTextOverlay?: 'Bellow video' | 'Overlay on video';
  hideOnMobile?: boolean;
  hideTitle?: boolean;
  slug?: string;
  text?: string;
  textColour?: 'Dark' | 'Light';
  title?: string;
  video: CfAsset;
  videoMobile?: CfAsset;
  videoPoster?: CfAsset;
  videoTablet?: CfAsset;
}
