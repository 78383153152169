import { useState, useEffect, useRef } from 'react';
import useSWR from 'swr';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import Button from 'mycs/shared/components/Button/Button';
import ContentfulService from 'mycs/shared/services/ContentfulService/ContentfulService';
import Logger from 'mycs/shared/services/Logger';
import styles from './PromotionalPopup.scss';
import { useDevice } from 'mycs/router/DeviceContext';

const ASSET_ID_MOBILE = '6VHt4L6GRiC0BPsgo8pl9N';
const ASSET_ID_DESKTOP = '6bYFnCmB9DtK7CopIkmpPr';

export default function PromotionalPopup() {
  const hasInitialized = useRef(false);
  const { locale, countryCode } = useLocale();
  const popupRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { hasPhoneDimensions } = useDevice();

  const assetId = hasPhoneDimensions ? ASSET_ID_MOBILE : ASSET_ID_DESKTOP;

  const fetchAsset = async ([assetId, locale]: [string, string]): Promise<
    string | null
  > => {
    try {
      const asset = await ContentfulService.getCFAssetByLocale(
        assetId,
        countryCode,
        locale
      );
      return asset && asset.url ? asset.url : null;
    } catch (error) {
      Logger.error('Failed to fetch asset from Contentful:', error);
      return null;
    }
  };

  const { data: imageUrl } = useSWR([assetId, locale], fetchAsset);

  useEffect(() => {
    if (hasInitialized.current) return;
    hasInitialized.current = true;

    if (!sessionStorage.getItem('hasSeenPopup')) {
      setIsVisible(true);
      sessionStorage.setItem('hasSeenPopup', 'true');
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible || !imageUrl) return null;

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popupContent} ref={popupRef}>
        <Button
          iconName="general/close.svg"
          onClick={handleClose}
          className={styles.closeButton}
        />
        <img
          src={imageUrl}
          alt="Promotional Popup"
          className={styles.popupImage}
        />
      </div>
    </div>
  );
}
