const envmaps = {
  airport: {
    envmap_name: 'airport',
    exposure: 1.4417740229476195,
  },
  airport_init_0: {
    envmap_name: 'airport_init',
    exposure: 0.14580108272216533,
  },
  airport_init_45: {
    envmap_name: 'airport_init',
    exposure: 0.28757293936381506,
  },
  airport_init_90: {
    envmap_name: 'airport_init',
    exposure: 0.45585323854789866,
  },
  env_mapping_darker_init: {
    envmap_name: 'env_mapping_darker_init',
    exposure: 0.3982187012569239,
  },
  env_mapping_joyn: {
    envmap_name: 'env_mapping_joyn',
    envmap_hangle: 0.15,
    exposure: 1.128977998836921,
  },
};

const nyceConfig = {
  presets: {
    // pyllow, joyn, cushion
    sofa: {
      ...envmaps.airport,
    },
    shelf: {
      // shelf, wardrobe
      // ...envmaps.env_mapping_darker_init
      // ...envmaps.env_mapping_joyn
      ...envmaps.airport_init_90,
    },
  },
};

export default nyceConfig;
