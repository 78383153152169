import { FurnitureConfig } from 'furniture-engine';
import { CfMaterial } from '@mycs/contentful';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';

export interface IConfiguratorSpecs {
  getSpecString(
    furnitureConfig: FurnitureConfig,
    specs: any,
    colors: any,
    materials: CfMaterial[]
  ): string;
}

export abstract class ConfiguratorSpecs implements IConfiguratorSpecs {
  locale: string;

  constructor(locale: string) {
    this.locale = locale;
  }

  abstract getSpecString(
    furnitureConfig: FurnitureConfig,
    specs: any,
    colors: any,
    materials: CfMaterial[]
  ): string;

  _getColorsString(
    element: any,
    elementString: string,
    additionnalString = '',
    materials: CfMaterial[] | undefined = undefined
  ) {
    const res = [];
    for (const color in element) {
      const amount = element[color];
      const elementName = I18nUtils.localize(
        this.locale,
        this._pluralChecker(elementString, amount)
      );
      const material = materials && materials.find((m) => m.color === color);
      const colorNiceName =
        (material && material.name) || I18nUtils.localize(this.locale, color);

      if (additionnalString !== '') {
        const additionnalName = I18nUtils.localize(
          this.locale,
          additionnalString
        );
        res.push(
          `${amount} ${elementName} (${additionnalName}) ${colorNiceName}`
        );
      } else if (materials !== undefined) {
        if (material && material.category) {
          const materialCategoryName = I18nUtils.localize(
            this.locale,
            material.category.name
          );
          res.push(
            `${amount} ${elementName} ${colorNiceName} (${materialCategoryName})`
          );
        } else {
          res.push(`${amount} ${elementName} ${colorNiceName}`);
        }
      } else {
        res.push(`${amount} ${elementName} ${colorNiceName}`);
      }
    }
    return res;
  }

  _pluralChecker(componentKey: string, amount: number) {
    if (amount > 1) {
      if (componentKey === 'Foot') {
        componentKey = 'Feet';
      } else {
        componentKey = componentKey.concat('s');
      }
    }
    return componentKey;
  }
}
