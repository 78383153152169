import { PureComponent } from 'react';
import isEmail from 'is-email';

import { noop } from 'mycs/shared/utilities/GeneralUtils/GeneralUtils';
import Input from 'mycs/shared/components/Input/Input';

type Props = {
  label?: string;
  isRequired: boolean;
  errorMessageBottom: boolean;
  onChange: (email: string) => void;
  /** Any valid HTML5 native attribute */
  nativeProps: object;
  /** Extra class for the Input */
  className?: string;
  containerClassName?: string;
  labelClassName?: string;
  value?: string;
};

type State = {
  email: string;
  errorMessage: string;
};

/**
 * Validates the input and displays the appropriate error message
 */
export default class EmailInput extends PureComponent<Props, State> {
  static defaultProps = {
    isRequired: false,
    errorMessageBottom: false,
    nativeProps: {},
    onChange: noop,
  };

  constructor(props: Props) {
    super(props);

    this.onEmailChange = this.onEmailChange.bind(this);
    this.state = {
      email: '',
      errorMessage: '',
    };
  }

  /**
   * Set email on the state
   */
  onEmailChange = (email: string) => {
    this.setState({ email });
    this.props.onChange(email);
  };

  /**
   * Validate onBlur
   */
  onBlur = () => {
    if (
      this.state.email &&
      !isEmail(this.state.email) &&
      !this.state.errorMessage
    ) {
      this.setState({ errorMessage: 'Not valid email' });
    }
  };

  /**
   * Clear errorMessage onFocus
   */
  onFocus = () => {
    if (this.state.errorMessage) {
      this.setState({ errorMessage: '' });
    }
  };

  render() {
    const { errorMessage } = this.state;
    const p = this.props;
    const nativeProps = Object.assign(
      { autoComplete: 'email', type: 'email' },
      p.nativeProps
    );

    return (
      <Input
        errorMessage={errorMessage}
        errorMessageBottom={p.errorMessageBottom}
        onChange={this.onEmailChange}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        className={p.className}
        containerClassName={p.containerClassName}
        nativeProps={nativeProps}
        isRequired={p.isRequired}
        label={p.label}
        labelClassName={p.labelClassName}
        value={p.value}
      />
    );
  }
}
