import MobileDetect from 'mobile-detect';
import config from 'mycs/config/deviceDimensions';

class BrowserServiceCtor {
  md?: MobileDetect;

  constructor() {
    if (typeof window !== 'undefined') {
      this.init(window.navigator.userAgent);
    }
  }

  init(userAgent: string) {
    this.md = new MobileDetect(userAgent, 500);
  }

  /**
   * Check for Safari
   *
   * https://stackoverflow.com/questions/7944460/detect-safari-browser
   * https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser#answer-9851769
   */
  isSafariBrowser(): boolean {
    if (typeof window === 'undefined') {
      return false;
    }

    return (
      navigator.vendor?.indexOf('Apple') > -1 &&
      navigator.userAgent?.indexOf('CriOS') == -1 &&
      navigator.userAgent?.indexOf('FxiOS') == -1
    );
  }

  /**
   * Check for landscape orientation
   */
  isLandscape(): boolean {
    if (typeof window === 'undefined') {
      return false;
    }

    return (
      window.screen.orientation?.type === 'landscape-primary' ||
      window.innerWidth > window.innerHeight
    );
  }

  /**
   * Check for portrait orientation
   */
  isPortrait(): boolean {
    if (typeof window === 'undefined') {
      return true;
    }

    return (
      window.screen.orientation?.type === 'portrait-primary' ||
      window.innerHeight > window.innerWidth
    );
  }

  /**
   * Return screen width
   */
  getWidth(): number {
    if (typeof window === 'undefined') {
      const isPhone = Boolean(this.md?.phone());
      const isTablet = Boolean(this.md?.tablet());

      const { innerWidth } = isPhone
        ? config.phone
        : isTablet
        ? config.tablet
        : config.default;

      return innerWidth;
    }

    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }

  /**
   * Check for small screen
   */
  isSmallScreen(): boolean {
    const maxPortraitWidth = 500;
    const maxLandscapeWidth = 767;
    const width = this.getWidth();
    const isPortrait = this.isPortrait();

    return (
      (isPortrait && width <= maxPortraitWidth) ||
      (!isPortrait && width <= maxLandscapeWidth)
    );
  }

  /**
   * TO DO: Refactor and use CSS
   * Check for phone dimensions
   */
  hasPhoneDimensions(): boolean {
    const width = this.getWidth();
    return width < 768;
  }

  /**
   * TO DO: Refactor and use CSS
   * Check for phone dimensions
   */
  hasTabletDimensions(): boolean {
    const width = this.getWidth();
    return width > 767 && width < 993;
  }
}

/**
 * BrowserService Service
 * @deprecated Instead of using this service prefer responsive CSS only or useDevice hooks
 */
export const BrowserService = new BrowserServiceCtor();
