import Component from '../../components/Component';
import RendererService from '../../services/RendererService';
import { convertToCM, convertToMM } from './ShelfUtils';
import shelfConfig from '../../config/shelf-config';
import isMatch from 'lodash/isMatch';

/**
 * ShelfElement
 *
 * @class ShelfElement
 * @extends {Component}
 */
export default class ShelfElement extends Component {
  /**
   * Add/Update props based on their characteristic
   *
   * @param {any} props
   * @param {any} element
   * @returns {any} new props
   */
  static hardcode(props, element) {
    let newProps: any = {};
    const handleNormalMarginZ = 0.19;
    if (element.section === 'board') {
      let type = element.type;
      if (type === 'legs_board') type = 'normal';
      if (type === 'accessory') {
        newProps.align_y = 'min';
        newProps.rotation_x = 180;
        newProps.y = 13;
        newProps.z = props.z - 0.2;
      } else {
        // For reinforced board we use normal shape
        type = type.replace(shelfConfig.reinforcedBoardSuffix, '');
        newProps.shape = `shelf_board_${type}_${element.length}x${element.width}x${element.height}_obj`;

        if (
          type === 'behind_front' ||
          type === 'front_backwall' ||
          type === 'front_between_backwall'
        ) {
          newProps.margin_y = [shelfConfig.frontBoardGap, 0];
        }
      }
    } else if (element.section === 'backwall') {
      (newProps.align_y = 'max'),
        (newProps.padding_z = shelfConfig.backwallPaddign);
    } else if (element.section === 'handles' && element.type === 'normal') {
      newProps.margin_y = [-shelfConfig.frontWidth, 0];
      if (props.rotation_y === 90) {
        newProps.margin_x = [-shelfConfig.handleHeight, 0];
      } else if (props.rotation_y === -90) {
        newProps.margin_x = [0, -shelfConfig.handleHeight];
      } else if (props.rotation_y === 180) {
        newProps.margin_z = [
          -shelfConfig.handleHeight - handleNormalMarginZ,
          0,
        ];
        newProps.size_z = shelfConfig.handleHeight + handleNormalMarginZ;
      } else {
        newProps.margin_z = [0, -handleNormalMarginZ];
        newProps.size_z = shelfConfig.handleHeight + handleNormalMarginZ;
      }
      if (['aluminum_brushed', 'aluminum_polished'].includes(element.color)) {
        newProps.material = 'metal_stainless';
      }
    } else if (element.section === 'handles' && element.type === 'knob') {
      if (['aluminum_brushed', 'aluminum_polished'].includes(element.color)) {
        newProps.material = 'metal_stainless';
      }
    } else if (element.section === 'handles' && element.type === 'accessory') {
      if (element.length === 40) {
        newProps.margin_x = [0, -shelfConfig.frontsGap];
        newProps.margin_z = [0, -shelfConfig.frontsGap];
      }
      newProps.shape = element.scene_object_name;
      newProps.material = element.color;
      newProps.y = -0.05;
    } else if (element.section === 'legs') {
      const Materials = {
        chrome: 'metal_stainless',
        aluminum_brushed: 'metal_stainless',
        aluminum_polished: 'metal_stainless',
        metal_black: 'black_u12001',
      };
      if (Materials[element.color]) {
        newProps.material = Materials[element.color];
      }

      if (
        shelfConfig.legTypes[element.type] &&
        shelfConfig.legTypes[element.type].JSXProps
      ) {
        newProps = Object.assign(
          newProps,
          shelfConfig.legTypes[element.type].JSXProps
        );
      }
      if (
        element.type === 'platform_profile' ||
        element.type === 'platform_plate' ||
        element.type === 'socle_profile'
      ) {
        newProps.hidden = true;
      } else if (element.type === 'support') {
        // because support legs can be higher then a regular one
        // and it can make parent container higher then it should be
        newProps.size_z = convertToCM(element.height);
        newProps.align_y = 'center';
      } else if (element.type === 'square_support') {
        newProps.hidden = true;
      } else if (
        element.type === 'outer_socle' ||
        element.type === 'inner_socle'
      ) {
        newProps.align_y = 'center';
      }
    } else if (element.section === 'door') {
      if (!shelfConfig.colors.glass.includes(element.color)) {
        newProps.margin_z = -shelfConfig.frontsGap;
        newProps.margin_x = -shelfConfig.frontsGap;
      }
      if (
        shelfConfig.colors.glass.includes(element.color) &&
        element.type === 'soft_close'
      ) {
        // In case of glass door, we need to force the shape
        newProps.shape = `shelf_${element.section}_normal_${element.length}x5x${element.height}_obj`;
      }
    } else if (element.section === 'drawer_front') {
      newProps.margin_x = -shelfConfig.frontsGap;
      newProps.margin_z = -shelfConfig.frontsGap;
    } else if (element.section === 'drawer_box') {
      newProps.align_z = 'center';
    } else if (element.section === 'wall_extension') {
      const height = shelfConfig.validHeightColumn[0];
      newProps.shape = `shelf_wall_${element.type}_${convertToMM(
        shelfConfig.boardHeight
      )}x${element.width}x${height}_obj`;
      newProps.size_z = convertToCM(element.height);
      newProps.margin_z = [0.2, 0];
    }

    // Randomize the wood textures
    const woodTextures = ['oak', 'wenge', 'walnut'];
    const woodTextureVariations = 5;
    if (woodTextures.indexOf(element.color) > -1 && props.getMaterialId) {
      // Pick the material ID in the decimal of PI
      const materialID = props.getMaterialId();
      const seed: any = Math.PI.toString().replace('.', '');
      const rnd = 1 + (seed[materialID % seed.length] % woodTextureVariations);
      newProps.material = `${element.color}_effect_shelves${rnd}`;
    }

    return newProps;
  }

  /**
   * Mapping of some properties in order to fetch the SKU related to the shape.
   *
   * @param {object} props
   * @returns {object} props to fetch the SKUs
   */
  static mappingShape(props) {
    if (
      props.section === 'handles' &&
      props.type === 'accessory' &&
      props.length === 80
    ) {
      return null;
    }
    // Mapping to fetch the new wood laminate
    if (['oak', 'walnut', 'wenge'].includes(props.color)) {
      props.color = `${props.color}_laminate`;
    }
    return props;
  }

  /**
   * Mapping of some properties in order to compute
   * - shape
   * - material
   *
   * @param {object} element
   * @returns {object} assets override
   */
  static mappingAsset(element, assetId) {
    const newProps: any = {};

    if (element.section === 'handles' && element.type === 'normal') {
      if (['aluminum_brushed', 'aluminum_polished'].includes(element.color)) {
        newProps.material = 'metal_stainless';
      }
    }

    if (element.section === 'handles' && element.type === 'glass') {
      if (['aluminum_brushed', 'aluminum_polished'].includes(element.color)) {
        newProps.material = 'metal_stainless';
      }
    }

    if (element.section === 'drawer_box' && element.type === 'plain') {
      newProps.shape = Component.generateSceneObjectName({
        ...element,
        type: 'normal',
      });
    }

    if (element.section === 'legs') {
      const Materials = {
        chrome: 'metal_stainless',
        aluminum_brushed: 'metal_stainless',
        aluminum_polished: 'metal_stainless',
        metal_black: 'black_u12001',
      };
      if (Materials[element.color]) {
        newProps.material = Materials[element.color];
      }
    }

    if (element.section === 'board') {
      if (element.type === 'legs_board') {
        newProps.shape = Component.generateSceneObjectName({
          ...element,
          type: 'normal',
        });
      }

      if (element.type.includes('reinforced')) {
        newProps.shape = Component.generateSceneObjectName({
          ...element,
          type: element.type.replace('_reinforced', ''),
        });
      }
    }

    if (element.section === 'door') {
      if (
        shelfConfig.colors.glass.includes(element.color) &&
        element.type === 'soft_close'
      ) {
        // In case of glass door, we need to force the shape
        newProps.shape = Component.generateSceneObjectName({
          ...element,
          type: 'normal',
          width: 5,
        });
      }
    }

    if (element.section === 'wall_extension') {
      const height = shelfConfig.validHeightColumn[0];
      const length = convertToMM(shelfConfig.boardHeight);
      newProps.shape = Component.generateSceneObjectName({
        ...element,
        section: 'wall',
        length,
        height,
      });
    }

    // Randomize the wood textures
    const woodTextures = ['oak', 'wenge', 'walnut'];
    const woodTextureVariations = 5;
    if (woodTextures.indexOf(element.color) > -1) {
      // No variation for drawer_box
      if (isMatch(element, { section: 'drawer_box' })) {
      }
      // No variation for legs square
      else if (isMatch(element, { section: 'legs', type: 'square_support' })) {
      } else {
        // Pick the material ID in the decimal of PI
        const seed: any = Math.PI.toString().replace('.', '');
        const rnd = 1 + (seed[assetId % seed.length] % woodTextureVariations);
        newProps.material = `${element.color}_effect_shelves${rnd}`;
      }
    }

    return newProps;
  }
  /**
   * Render an element
   *
   * @returns {JSX.Element}
   */
  render() {
    const props = Component.decorateElement(
      this.props,
      shelfConfig.furnitureType,
      ShelfElement,
      {
        custom: true,
        element: true,
        assets: false,
        hardcode: true,
        skus: false,
        extend: false,
      }
    );
    return <shape {...props} />;
  }
}
