let currentStructure: any = null;
let furnitureType = '';

/**
 * Set current structure
 */
function setStructure(structure: any, ft: string) {
  currentStructure = structure;
  furnitureType = ft;
}

export default {
  getCurrentFurnitureType() {
    return furnitureType;
  },
  getStructure() {
    return currentStructure;
  },
  setStructure,
};
