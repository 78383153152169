import flatten from 'lodash/flatten';
import { getConfig } from 'furniture-engine';

import { RelativeUrlService } from 'mycs/shared/services/RelativeUrlService';
import ContentfulService from 'mycs/shared/services/ContentfulService/ContentfulService';
import DesignApiService from 'mycs/shared/services/DesignApiService/DesignApiService';
import DictionaryUtils from 'mycs/shared/utilities/DictionaryUtils/DictionaryUtils';
import ElementApiService from 'mycs/shared/services/ElementApiService/ElementApiService';
import TimerStore from 'mycs/shared/stores/TimerStore/TimerStore';
import UrlProviderService from 'mycs/shared/services/UrlProviderService/UrlProviderService';

import cfg from 'mycs/config';

class ConfiguratorDataService {
  /**
   * Load elements
   */
  async getElements(furnitureType: string) {
    const reqs = [ElementApiService.getElements(furnitureType)];

    if (['joyn', 'pyllow', 'flayr'].includes(furnitureType)) {
      reqs.push(ElementApiService.getElements('sample'));
    }

    const elements = await Promise.all(reqs);
    return flatten(elements);
  }

  /**
   * Get bboxes
   */
  async getBboxes() {
    return ElementApiService.getBboxes();
  }

  /**
   * Load page data from Contentful and put it into the Redux store
   */
  loadPageData(furnitureType: string, locale: string, countryCode: string) {
    const pathname = RelativeUrlService.getConfiguratorUrl(
      furnitureType,
      undefined,
      locale
    );
    return ContentfulService.getConfiguratorPage(pathname, locale, countryCode);
  }

  /**
   * Load a design and elements
   */
  loadApiData(
    furnitureType: string,
    locale: string,
    countryCode: string,
    uuid?: string
  ) {
    //
    // Wait until elements and design are loaded
    //
    return Promise.all([
      // TODO: Revert
      this.getElements(furnitureType),
      this.getRendering(furnitureType, locale, countryCode, uuid),
      this.loadPageData(furnitureType, locale, countryCode),
      this.getBboxes(),
      ContentfulService.getNyceConfig(countryCode),
    ]).then(([elements, rendering, lpData, bboxes, nyceConfig]) => {
      /**
       * Configurator pages relied on route resolve being triggered in CSR,
       * because the ConfiguratorDataService.loadApiData call has side effects
       * that update local state into the ElementApiService (this.bboxes).
       * To proper handle SSR redirects we need to trigger the above data fetches
       * but need to ignore the state in order to force avoid Configurator state
       * not being properly initialized if state is preloaded in SSR
       */
      if (MYCS_IS_SSR_BUILD) {
        return;
      }

      const configuratorData = { elements, rendering, bboxes, nyceConfig };

      // make the local static configuration override the one from Contentful
      // this is useful for development purpose
      const {
        API: { renderingApi },
      } = cfg;
      if (renderingApi) {
        const url = UrlProviderService.getRenderingApiUrl();
        configuratorData.nyceConfig = { url };
      }

      const { page } = lpData as any;

      page.configuratorData = configuratorData;

      // remove disabled materials
      if (page.materialCategoriesWithSubCategories) {
        page.materialCategoriesWithSubCategories.forEach((cat: any) => {
          cat.subCategories = cat.subCategories.filter(
            (subCat: any) => !subCat.isDisabled
          );
        });
        // hide empty category
        page.materialCategoriesWithSubCategories =
          page.materialCategoriesWithSubCategories.filter(
            (cat: any) => cat.subCategories.length > 0
          );
      }

      // Add Fb meta tags
      if (page.metaTags && rendering.images) {
        const image = rendering.images.find(
          (item: any) => item.type === 'MAIN'
        );
        const imageUrl = image ? image.url : rendering.image_url;
        page.metaTags.fb_image =
          UrlProviderService.getAbsoluteAssetUrl(imageUrl);
      }

      return lpData;
    });
  }

  /**
   * Request a rendering from Design API.
   * When we click on an inspiration image. We should get a fresh render
   * without any modifications. This is done by clearing the cache in the
   * mx-image_preview file else we render the saved structure. If no saved
   * structure is found we render the default structure using the invalidUuidFallback.
   */
  getRendering(
    furnitureType: string,
    locale: string,
    countryCode: string,
    uuid?: string
  ) {
    TimerStore.addTime('start_design');

    const configuratorTranslation =
      DictionaryUtils.lookUp('configurator', locale) || 'configurator';
    // If the uuid is 'configurator' we'll let the next method choose a fallback design
    const finalUUID =
      !uuid || configuratorTranslation === uuid
        ? getConfig(furnitureType).invalidUuidFallback
        : uuid;

    return DesignApiService.getRendering(finalUUID, countryCode).then(
      (response) => {
        TimerStore.addTime('GetDesign', 'start_design');
        return response[0];
      }
    );
  }
}

const service = new ConfiguratorDataService();

export default service;
