import uuidV4 from 'uuid/v4';
import LocalStorage from 'mycs/shared/utilities/LocalStorageUtils/LocalStorageUtils';
import cfg from 'mycs/config';
import debounce from 'lodash/debounce';
import WindowLocationUtils from 'mycs/shared/utilities/WindowLocationUtils/WindowLocationUtils';

const storageKey = {
  id: 'session.id',
  campaign: 'session.utmCampaign',
  timestamp: 'session.timestamp',
};

/**
 * Session service class handling session IDs
 *
 * @class SessionService
 */
class SessionService {
  /**
   * Attach event listeners to refresh session. Route change also causes refresh, see app.routes.
   */
  init() {
    // refresh timestamp from local storage
    if (!this.getTime()) {
      this.setSID();
      this.setTime(Date.now());
    }

    // check if utm_campaign exists and store it in localStorage
    const params = WindowLocationUtils.getSearch();
    if (params.utm_campaign && this.getCampaign() !== params.utm_campaign) {
      this.setCampaign(params.utm_campaign);
      this.setSID();
    }

    // create SID when there's none saved
    if (!this.getSID()) this.setSID();

    // Update because we've just refreshed or returned to page
    this.refresh();
  }

  /**
   * Init DOM events
   */
  initEvents() {
    // Update timer or reset session on defined actions
    const debouncedRefresh = debounce(() => this.refresh(), 1000);

    window.addEventListener('scroll', debouncedRefresh);
    window.addEventListener('mouseup', debouncedRefresh);
    window.addEventListener('touchstart', debouncedRefresh);
  }

  /**
   * Check if the last action occured before timeout
   */
  refresh() {
    const currentTime = Date.now();
    const prevTime = this.getTime();
    const currentDay = new Date(currentTime).getDate();
    const prevDay = new Date(prevTime).getDate();
    const didTimeout = currentTime - prevTime > cfg.session.timeout;
    const passingMidnight = prevDay < currentDay;

    if (!prevTime || didTimeout || passingMidnight) {
      this.setSID();
    }

    this.setTime(currentTime);
  }

  /**
   * Retrive timestamp of the last action
   */
  getTime(): number {
    return LocalStorage.get(storageKey.timestamp);
  }

  setTime(timestamp: number = Date.now()) {
    LocalStorage.set(storageKey.timestamp, timestamp);
  }

  /**
   * Generate and attempt to set new session id in both
   * LocalStorage and Cookie
   */
  setSID() {
    const newSessionID = uuidV4();
    this.setLocalStorageSID(storageKey.id, newSessionID);
    this.setCookieSID(storageKey.id, newSessionID);
  }

  /**
   * Set newSessionID in LocalStorage if user gave consent
   * @param {string} key
   * @param {string} newSessionID
   */
  setLocalStorageSID(key: string, newSessionID: string) {
    LocalStorage.set(key, newSessionID);
  }

  /**
   * Set newSessionID in Cookie if user gave consent
   * @param {string} key
   * @param {string} newSessionID
   */
  setCookieSID(key: string, newSessionID: string) {
    LocalStorage.setCookie(key, newSessionID);
  }

  /**
   * Retrieve current session's id
   *
   * @returns {string}
   */
  getSID() {
    this.refresh();
    return LocalStorage.get(storageKey.id);
  }

  /**
   * Return saved campaign name
   *
   * @returns {string}
   */
  getCampaign() {
    return LocalStorage.get(storageKey.campaign);
  }

  /**
   * Set campaign if user gave consent
   * @param {string} name
   */
  setCampaign(name: string) {
    LocalStorage.set(storageKey.campaign, name);
  }

  /**
   * Remove campaign
   */
  rmCampaign() {
    LocalStorage.remove(storageKey.campaign);
  }
}

export default new SessionService();
