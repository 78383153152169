// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

type Translations = {
  [key: string]: string;
};

type TranslationsSliceState = {
  [locale: string]: Translations;
};

const initialState: TranslationsSliceState = {};

type TranslationPayload = {
  locale: string;
  data: Translations;
};

export const translationsSlice = createSlice({
  name: 'translations',
  initialState,
  reducers: {
    setTranslations(state, { payload }: PayloadAction<TranslationPayload>) {
      const { locale, data } = payload;

      return {
        ...state,
        [locale]: data,
      };
    },
  },
});

export const { setTranslations } = translationsSlice.actions;

export function getTranslationsFromReduxState(
  state: RootState,
  locale: string
) {
  return state.translations[locale];
}
