import { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { CfHelpItem } from '@mycs/contentful';
import { RelativeUrlService } from 'mycs/shared/services/RelativeUrlService';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import Alink from 'mycs/shared/components/Alink/Alink';
import Button from 'mycs/shared/components/Button/Button';
import ContentfulService from 'mycs/shared/services/ContentfulService/ContentfulService';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import SafeText from 'mycs/shared/components/SafeText/SafeText';

import styles from './MiniFaq.scss';

export default function MiniFaq() {
  const [content, setContent] = useState<CfHelpItem[] | undefined>(undefined);
  const [activeItem, setActiveItem] = useState(0);
  const { locale, countryCode } = useLocale();
  const { pathname } = useLocation();

  /**
   * Change the active item in the list
   */
  const onFaqClick = (activeItem: number) => {
    setActiveItem(activeItem);
  };

  /**
   * Get the FAQ content
   */
  const getContent = async () => {
    const configuratorCategory = RelativeUrlService.getConfiguratorCategory(
      pathname,
      locale
    );
    const helpCategory = configuratorCategory || 'general';

    const _content = await ContentfulService.getHelpContent(
      helpCategory,
      locale,
      countryCode
    );

    setContent(_content.items);
  };

  /**
   * Check agent status
   *
   */
  useEffect(() => {
    getContent();
  }, []);

  return (
    <div className={styles.container}>
      {content && (
        <div className={styles.faq}>
          <div className={styles.sidebar}>
            <div className={styles.faqTitle}>
              {I18nUtils.localize(locale, 'Frequently Asked Questions')}
            </div>
            <ul className={styles.menu}>
              {content.map((item, key) => {
                const classes = classNames(styles.faqListItem, {
                  [styles.active]: key === activeItem,
                });
                return (
                  <li key={`c-${key}`} className={classes}>
                    <Button
                      onClick={() => onFaqClick(key)}
                      text={item.slug}
                      isFullWidth
                      className={styles.faqListButton}
                    />
                  </li>
                );
              })}
              <li key="c-end" className={styles.faqListItem}>
                <Alink
                  href={RelativeUrlService.getFaqUrl(locale)}
                  text={I18nUtils.localize(locale, 'More questions')}
                  className={styles.faqListButton}
                />
              </li>
            </ul>
          </div>
          <div className={styles.content}>
            {content.map((item, index) => {
              if (index === activeItem) {
                return item.questions?.map((question, key) => (
                  <div key={`q-${key}`} className={styles.faqEntry}>
                    <p className={styles.faqQuestion}>{question.question}</p>

                    <div className={styles.faqAnswer}>
                      <SafeText content={question.answer} />
                    </div>
                  </div>
                ));
              }

              return <Fragment key={`q-frag-${index}`} />;
            })}
          </div>
        </div>
      )}
    </div>
  );
}
