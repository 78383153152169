import { getConfig } from 'furniture-engine';

import { CfMaterial } from '@mycs/contentful';
import cfg from 'mycs/config';
import { WardrobeSpecs } from 'mycs/configurators/wardrobes/store/WardrobeSpecs';
import { ShelfSpecs } from 'mycs/configurators/shelves/store/ShelfSpecs';
import { CushionSpecs } from 'mycs/configurators/cushions/store/CushionSpecs';
import { SofaSpecs } from 'mycs/configurators/sofas/store/SofaSpecs';

export default class StructureSpecsService {
  /**
   * Get the subtype if the furniture type allows it
   */
  static getSubtype(
    furnitureType: string,
    specs: { legs_type?: string; leg_type?: string }
  ) {
    switch (furnitureType) {
      case 'shelf':
        return specs.leg_type === 'HANGING'
          ? cfg.shelfLegTypes[specs.leg_type!.toLowerCase()]
          : null;
      default:
        return null;
    }
  }

  /**
   * Get the furniture type service
   */
  private static getService(furnitureType: string) {
    switch (furnitureType) {
      case 'wardrobe':
        return WardrobeSpecs;
      case 'shelf':
        return ShelfSpecs;
      case 'cushion':
        return CushionSpecs;
      case 'joyn':
      case 'pyllow':
      case 'flayr':
        return SofaSpecs;

      default:
        throw new Error('Invalid furnitureType: ' + furnitureType);
    }
  }

  /**
   * Get specs for a product
   */
  static getSpecs(
    product: any,
    materials: CfMaterial[] = [],
    { locale }: { locale: string }
  ): string {
    const { furniture_type } = product;

    const Service = this.getService(furniture_type);

    const { specs, colors } = product;
    let specString;

    const store = new Service(locale);
    const furnitureConfig = getConfig(furniture_type);
    specString = store.getSpecString(furnitureConfig, specs, colors, materials);

    return specString;
  }
}
