import { Component, Fragment } from 'react';
import classNames from 'classnames';

import {
  CfAsset,
  CfHeaderSubmenu,
  CfHeaderSubmenuColumn,
} from '@mycs/contentful';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import Alink from 'mycs/shared/components/Alink/Alink';
import Button from 'mycs/shared/components/Button/Button';
import cfg from 'mycs/config';
import Icon from 'mycs/shared/components/Icon/Icon';
import Localize from 'mycs/shared/components/Localize/Localize';
import Scrollable from 'mycs/shared/components/Scrollable/Scrollable';
import WindowLocationUtils from 'mycs/shared/utilities/WindowLocationUtils/WindowLocationUtils';

import styles from './NavigationMenuGroups.scss';

interface Link {
  _id: string;
  title?: string;
  url?: string;
  image?: CfAsset;
  target?: 'none' | '_self' | '_blank' | '_top';
}

interface Props {
  groups: CfHeaderSubmenu[];
  onSelectActiveItem: Function;
  onClose: Function;
}

interface State {
  activeTab: number;
  activeItem: Link | null;
  activeItemCategory: CfHeaderSubmenuColumn | null;
  activeItemCategoryDisplay: CfHeaderSubmenuColumn | null;
}

export default function NavigationMenuGroups(props: Props) {
  const { countryCode } = useLocale();

  return <InnerNavigationMenuGroups {...props} countryCode={countryCode} />;
}

type InnerProps = Props & {
  countryCode: string;
};

class InnerNavigationMenuGroups extends Component<InnerProps, State> {
  phoneNumber: string;
  subscription: any;

  static defaultProps = {
    groups: [],
    onSelectActiveItem: () => null,
  };

  constructor(props: InnerProps) {
    super(props);

    this.state = {
      activeTab: 0,
      activeItem: null,
      activeItemCategory: null,
      activeItemCategoryDisplay: null,
    };

    this.phoneNumber = cfg.mycs.telephone[this.props.countryCode];
  }

  getProductItems() {
    return this.props.groups[0];
  }

  getCustomizeItems() {
    return this.props.groups[1];
  }

  getFooterItems() {
    return this.props.groups[2];
  }

  setActiveTab = (activeTab: number) => {
    this.setState({ activeTab });
  };

  setActiveItemCategoryDisplay = (
    activeItemCategoryDisplay: CfHeaderSubmenuColumn
  ) => {
    this.setState({
      activeItemCategoryDisplay,
      activeTab: 1,
    });
  };

  /**
   * Set the actve item and category item according to the current URL path
   */
  setCurrentPathItem() {
    this.updateActiveItem(WindowLocationUtils.getPath(), this.props.groups);
  }

  /**
   * Mark the current link and link category as active
   */
  updateActiveItem(urlPath: string, groups: CfHeaderSubmenu[]) {
    let activeItem = null;
    let activeItemCategory = null;
    let activeItemCategoryDisplay = null;

    groups.forEach((group) => {
      group.navGroups &&
        group.navGroups.forEach((navGroup) => {
          if (navGroup.url === urlPath) {
            activeItem = navGroup;
            activeItemCategoryDisplay = navGroup;
            activeItemCategory = navGroup;
          }
          navGroup.links &&
            navGroup.links.forEach((link) => {
              if (link.url === urlPath) {
                activeItem = link;
                activeItemCategoryDisplay = navGroup;
                activeItemCategory = navGroup;
              }
            });
        });
    });

    this.setState({
      activeItem,
      activeItemCategoryDisplay,
      activeItemCategory,
    });
  }

  onClose = () => {
    this.props.onClose();
    this.setActiveTab(0);
  };

  onPhoneClick = () => {
    const link = `tel:${this.phoneNumber.split(' ').join('')}`;
    window.location.href = link;
  };

  renderLink(link: Link) {
    const isActive = this.state.activeItem === link;
    const rel = link.url
      ? link.url.includes('http')
        ? 'noopener'
        : 'prefetch'
      : undefined;
    const classes = classNames(styles.link, {
      [styles.activeLink]: isActive,
    });
    const icon = link.image ? (
      <Icon
        className={styles.linkIcon}
        iconContainerClass={styles.linkIconContainer}
        iconPath={link.image.url}
      />
    ) : (
      <Fragment />
    );

    return link.url ? (
      <Alink
        className={classes}
        href={link.url}
        target={link.target}
        rel={rel}
        text={link.title}
      >
        {icon}
        <span className={styles.linkText}>{link.title}</span>
      </Alink>
    ) : (
      <span className={classes}>{link.title}</span>
    );
  }

  renderProductLinks() {
    const productItems = this.getProductItems();
    return (
      <div>
        <div className={styles.productTitle}>{productItems.title}</div>

        <div className={styles.productLinksContainer}>
          {productItems?.navGroups?.map((group, groupIndex) => (
            <div
              className={classNames(styles.productLink, {
                [styles.activeProductLink]:
                  this.state.activeItemCategory === group,
              })}
              key={groupIndex}
              onClick={() => this.setActiveItemCategoryDisplay(group)}
            >
              <div className={styles.productText}>
                <Localize>{group.title ?? ''}</Localize>
              </div>
              <Button
                className={styles.productArrowIcon}
                iconName="general/arrow-chevron-right.svg"
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderProductSubLinks() {
    const { activeItemCategoryDisplay } = this.state;
    if (!(activeItemCategoryDisplay && activeItemCategoryDisplay.links))
      return <Fragment />;

    return (
      <div>
        <div className={styles.productTitleLink} onClick={this.onClose}>
          {this.renderLink(activeItemCategoryDisplay)}
        </div>
        <div className={styles.productSubLinksContainer}>
          {activeItemCategoryDisplay.links!.map((link, linkIndex) => (
            <div
              className={styles.productSubLinkContainer}
              key={linkIndex}
              onClick={this.onClose}
            >
              {this.renderLink(link)}
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderCustomizeLinks() {
    return (
      <div className={styles.customizeContainer}>
        <div className={styles.customizeTitle}>
          {this.getCustomizeItems().title}
        </div>
        <Scrollable wrap>
          {this.getCustomizeItems().navGroups?.map((group) =>
            group.links!.map((link, linkIndex) => (
              <div
                className={styles.customizeLinkContainer}
                key={linkIndex}
                onClick={this.onClose}
              >
                {this.renderLink(link)}
              </div>
            ))
          )}
        </Scrollable>
      </div>
    );
  }

  renderFooterLinks() {
    return (
      <div className={styles.footerLinksContainer}>
        {this.getFooterItems().navGroups?.map((group) =>
          group.links!.map((link, linkIndex) => (
            <div
              className={styles.footerLinkContainer}
              key={linkIndex}
              onClick={this.onClose}
            >
              {this.renderLink(link)}
            </div>
          ))
        )}
      </div>
    );
  }

  // renderPhoneButton() {
  //   return (
  //     <div className={styles.phoneButtonContainer}>
  //       <Button
  //         isThirdLevelCtaInverse
  //         isInline
  //         isFullWidth
  //         onClick={this.onPhoneClick}
  //         iconName="support/phone.svg"
  //         text="Contact us"
  //       />
  //     </div>
  //   );
  // }

  /**
   * After mount
   */
  componentDidMount() {
    this.subscription = WindowLocationUtils.onUrlChange(() =>
      this.setCurrentPathItem()
    );
  }

  /**
   * On props change
   */
  componentDidUpdate(prevProps: Props) {
    if (prevProps.groups !== this.props.groups) {
      this.updateActiveItem(WindowLocationUtils.getPath(), this.props.groups);
    }
  }

  /**
   * On unmount
   */
  componentWillUnmount() {
    this.subscription && this.subscription.unsubscribe();
  }

  render() {
    if (!this.props.groups || !this.props.groups.length) return <Fragment />;
    const { activeTab } = this.state;
    const parentTab = activeTab === 0;
    const headerElement = parentTab ? (
      <div className={styles.headerText}>
        <Localize>Menu</Localize>
      </div>
    ) : (
      <Button
        className={styles.headerTextIcon}
        iconContainerClass={styles.headerBackIcon}
        iconName="general/arrow-left"
        text="Go back"
        onClick={() => this.setActiveTab(0)}
      />
    );

    return (
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <div className={styles.headerContainer}>
            {headerElement}
            <Button
              className={styles.headerCloseIcon}
              onClick={this.onClose}
              iconName="general/close.svg"
            />
          </div>
          {parentTab ? this.renderProductLinks() : this.renderProductSubLinks()}
        </div>

        {parentTab && (
          <div className={styles.bottomContainer}>
            {this.renderCustomizeLinks()}
            {this.renderFooterLinks()}
            {/* {this.renderPhoneButton()} */}
          </div>
        )}
      </div>
    );
  }
}
