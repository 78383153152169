import isMatch from 'lodash/isMatch';
import flayrOptions from '../../config/flayr-config';
import Component from '../../components/Component';
import RendererService from '../../services/RendererService';

/**
 * FlayrSofaElement
 *
 * @class FlayrSofaElement
 * @extends {Component} */ export default class FlayrSofaElement extends Component {
  /**
   * Return an array containing first and second value, and eventually in addition of existing values of tab
   *
   * @param {array} array
   * @param {number} firstValue
   * @param {number} secondValue
   * @returns {array}
   */
  static addValuesToArray(array, firstValue, secondValue) {
    return array
      ? [array[0] + firstValue, array[1] + secondValue]
      : [firstValue, secondValue];
  }

  /**
   * Return a randomized shape variation number
   *
   * @param {number} nbVariations
   * @param {number} elementId
   * @returns {number}
   */
  static getRandomVariationNb(nbVariations, elementId) {
    const seed: any = Math.PI.toString().replace('.', '');
    return seed[elementId % seed.length] % nbVariations;
  }

  /**
   * Add/Update props based on their characteristic
   *
   * @param {any} props
   * @param {any} element
   * @returns {any} new props
   */
  static hardcode(props, element) {
    const newProps: any = {};
    const useHardcodedShapes = false;

    if (useHardcodedShapes) {
      newProps.shape = 'joyn_cushion_normal_600x820x220_obj';
      if (['armrest_left', 'armrest_right'].includes(element.section)) {
        newProps.shape = 'joyn_armrest_right_normal_120x940x500_obj';
      }
      if (element.section === 'backrest') {
        newProps.shape = 'joyn_backrest_normal_1200x160x420_obj';
      }
      if (['back_cushion', 'back_roll_cushion'].includes(element.section)) {
        newProps.shape = 'sofa_accessory_pillow_normal_400x600x240_obj';
      }
      if (['base', 'base_pouf', 'storage_base'].includes(element.section)) {
        newProps.shape = 'joyn_base_normal_800x940x80_obj';
      }
      if (element.section === 'sofa_bed_backrest') {
        newProps.shape = 'joyn_sofa_bed_backrest_normal_2000x160x500_obj';
      }
      if (element.section === 'sofa_bed_box') {
        newProps.shape = 'joyn_sofa_bed_box_normal_2000x720x120_obj';
      }
      if (element.section === 'leg') {
        newProps.shape = 'joyn_leg_straight_50x50x110_obj';
        newProps.size_z = 4;
      }
      if (element.length) {
        newProps.size_x = element.length / 10;
      }
      if (element.width) {
        newProps.size_y = element.width / 10;
      }
      if (
        element.height &&
        element.size_z === undefined &&
        !['back_cushion', 'back_roll_cushion'].includes(element.section)
      ) {
        newProps.size_z = element.height / 10;
      }
      if (element.color) {
        newProps.material = element.color;
      }
      if (['backrest', 'sofa_bed_backrest'].includes(element.section)) {
        newProps.size_y = 24;
      }
      if (['back_cushion', 'back_roll_cushion'].includes(element.section)) {
        newProps.size_y = element.height / 10;
      }
      if (element.section === 'seating_cushion') {
        newProps.size_z = 18;
      }
    } else {
      if (element.section === 'base') {
        const inversed_dimension = [[540, 1260]];

        for (const [length, width] of inversed_dimension) {
          if (isMatch(element, { length: width, width: length })) {
            newProps.shape = Component.generateSceneObjectName({
              ...element,
              length,
              width,
            });
            newProps.rotation_z = 90;
          }
        }
      }

      if (element.section === 'storage_base') {
        newProps.shape = Component.generateSceneObjectName({
          ...element,
          section: 'base',
        });
      }

      if (element.section === 'leg') {
        newProps.shape = Component.generateSceneObjectName(element);
        newProps.material = element.color;
      }

      // Overlap
      const length = element.length / 10;
      const width = element.width / 10;
      const height = element.height / 10;

      const overlapArmrest = 1.5;
      const overlapArmrestZ = 1;
      const overlapBigArmrestZ = 1.5;
      const overlapBigArmrestY = 0.5;
      const overlapBackrestZ = 1;
      const overlapBackrestY = 0.5;
      const overlapBaseZ = 0.5;
      const overlapSeatingCushion = 2;
      const overlapSeatingCushionZ = 4;
      const overlapBackCushionZ = 4.5;
      const overlapBackCushionY = 10;
      const overlapBackRollCushionY = 31;
      const overlapBackRollCushionZ = 3.5;
      const overlapBackRollCushionSmallY = 34.5;
      const overlapBackRollCushionSmallZ = 5.3;
      const overlapBackRollCushionBigY = 29.5;

      // Overlapping of armrests
      // - Use negative margin to let the shape overlap other shapes
      // - Strech the shape to have a correct dimension of the element
      // - In case of 240 armrest, we have to add a slight margin
      if (['armrest_left', 'armrest_right'].includes(element.section)) {
        newProps.margin_x = [0, -overlapArmrest];
        newProps.size_x = length + overlapArmrest;
        newProps.margin_z = [-overlapArmrestZ, 0];
        newProps.size_z = height + overlapArmrestZ;
        if (element.length === 240) {
          newProps.size_y = width + overlapBigArmrestY * 2;
          newProps.margin_y = [-overlapBigArmrestY, -overlapBigArmrestY];
          newProps.margin_z = [-overlapBigArmrestZ, 0];
          newProps.size_z = height + overlapBigArmrestZ;
        }
      }

      // Overlapping of backrest with legs
      if (['backrest'].includes(element.section)) {
        newProps.margin_y = [-overlapBackrestY, 0];
        newProps.size_y = width + overlapBackrestY;
        newProps.margin_z = [-overlapBackrestZ, 0];
        newProps.size_z = height + overlapBackrestZ;
      }

      // Overlapping of base with legs
      if (['base', 'storage_base'].includes(element.section)) {
        newProps.margin_z = [-overlapBaseZ, 0];
      }

      // Overlapping of seating cushions
      if (['seating_cushion'].includes(element.section)) {
        newProps.margin_x = FlayrSofaElement.addValuesToArray(
          props.margin_x,
          -overlapSeatingCushion,
          -overlapSeatingCushion
        );
        newProps.size_x = length + 2 * overlapSeatingCushion;
        newProps.margin_y = FlayrSofaElement.addValuesToArray(
          props.margin_y,
          -overlapSeatingCushion,
          -overlapSeatingCushion
        );
        newProps.size_y = width + 2 * overlapSeatingCushion;
        // Overlapping with base
        newProps.margin_z = [-overlapSeatingCushionZ, 0];
      }

      // Overlapping of back cushion with seating cushion and backrest
      if (['back_cushion'].includes(element.section)) {
        newProps.margin_y = [0, -overlapBackCushionY];
        newProps.margin_z = [-overlapBackCushionZ, 0];
      }

      // Overlapping of back roll cushion with seating cushion and backrest
      if (['back_roll_cushion'].includes(element.section)) {
        newProps.margin_y = [0, -overlapBackRollCushionY];
        newProps.margin_z = [-overlapBackRollCushionZ, 0];
        if (element.length === 540) {
          newProps.margin_y = [0, -overlapBackRollCushionSmallY];
          newProps.margin_z = [-overlapBackRollCushionSmallZ, 0];
        }
        if (element.length === 1020) {
          newProps.margin_y = [0, -overlapBackRollCushionBigY];
        }
      }

      // Inverse seating cushions shapes dimensions
      if (props.section === 'seating_cushion') {
        const inversed_dimension = [
          [540, 780],
          [540, 1020],
          [780, 1020],
          [540, 1260],
        ];
        for (const [length, width] of inversed_dimension) {
          if (isMatch(props, { length: width, width: length })) {
            newProps.shape = Component.generateSceneObjectName({
              ...element,
              length: width,
              width: length,
            });
          }
        }
      }

      // Shape variations for back cushions and seating cushions
      // Back cushions have 3 variations
      // Seating cushions have 4 variations
      // 0: variation 0
      // 1: variation 1
      // 2: variation 0 + rotation 180
      // 3: variation 1 + rotation 180
      if (
        ['back_cushion', 'back_roll_cushion'].includes(element.section) &&
        props.getAssetId
      ) {
        const nbVariations = 3;
        const randomVariationNb = this.getRandomVariationNb(
          nbVariations,
          props.getAssetId()
        );
        if (randomVariationNb > 0) {
          newProps.shape = Component.generateSceneObjectName(
            element,
            randomVariationNb
          );
        }
      }
      if (['seating_cushion'].includes(element.section) && props.getAssetId) {
        const nbVariations = 4;
        const randomVariationNb = this.getRandomVariationNb(
          nbVariations,
          props.getAssetId()
        );
        switch (randomVariationNb) {
          case 1:
            newProps.shape = Component.generateSceneObjectName(element, 1);
            break;
          case 2:
            newProps.rotation_z = 180;
            break;
          case 3:
            newProps.shape = Component.generateSceneObjectName(element, 1);
            newProps.rotation_z = 180;
            break;
        }
      }
    }

    return newProps;
  }

  /**
   * Mapping of some properties in order to fetch the SKU related to the shape.
   *
   * @param {object} props
   * @returns {object} props to fetch the SKUs
   */
  static mappingShape(props) {
    if (props.section === 'leg') {
      return null;
    }

    if (props.section === 'seating_cushion') {
      const inversed_dimension = [
        [540, 780],
        [540, 1020],
        [780, 1020],
        [540, 1260],
      ];
      for (const [length, width] of inversed_dimension) {
        if (isMatch(props, { length: width, width: length })) {
          return {
            ...props,
            length,
            width,
            color: 'default_shd',
          };
        }
      }
    }

    if (props.section === 'base') {
      const inversed_dimension = [[540, 1260]];
      for (const [length, width] of inversed_dimension) {
        if (isMatch(props, { length: width, width: length })) {
          return {
            ...props,
            length,
            width,
            color: 'default_shd',
          };
        }
      }
    }

    return {
      ...props,
      color: 'default_shd',
    };
  }

  /**
   * Mapping of some properties in order to fetch the SKU related to the cover.
   *
   * @param {object} props
   * @returns {object} props to fetch the SKUs
   */
  static mappingCover(props) {
    if (props.section === 'leg') {
      return null;
    }

    return {
      ...props,
      type: 'cover',
    };
  }

  /**
   * Mapping of some properties in order to compute
   * - shape
   * - material
   *
   * @param {object} element
   * @returns {object} assets override
   */
  static mappingAsset(element, assetId) {
    const newProps: any = {};
    if (element.section === 'base') {
      const inversed_dimension = [[540, 1260]];
      for (const [length, width] of inversed_dimension) {
        if (isMatch(element, { length: width, width: length })) {
          newProps.shape = Component.generateSceneObjectName({
            ...element,
            length,
            width,
          });
        }
      }
    }

    if (element.section === 'storage_base') {
      newProps.shape = Component.generateSceneObjectName({
        ...element,
        section: 'base',
      });
    }

    // Inverse seating cushions shapes dimensions
    if (element.section === 'seating_cushion') {
      const inversed_dimension = [
        [540, 780],
        [540, 1020],
        [780, 1020],
        [540, 1260],
      ];
      for (const [length, width] of inversed_dimension) {
        if (isMatch(element, { length: width, width: length })) {
          newProps.shape = Component.generateSceneObjectName({
            ...element,
            length: width,
            width: length,
          });
        }
      }
    }

    // Shape variations for back cushions and seating cushions
    // Back cushions have 3 variations
    // Seating cushions have 4 variations
    // 0: variation 0
    // 1: variation 1
    // 2: variation 0 + rotation 180
    // 3: variation 1 + rotation 180
    if (['back_cushion', 'back_roll_cushion'].includes(element.section)) {
      const nbVariations = 3;
      const randomVariationNb = FlayrSofaElement.getRandomVariationNb(
        nbVariations,
        assetId
      );
      if (randomVariationNb > 0) {
        newProps.shape = Component.generateSceneObjectName(
          element,
          randomVariationNb
        );
      }
    }
    if (['seating_cushion'].includes(element.section)) {
      const nbVariations = 4;
      const randomVariationNb = FlayrSofaElement.getRandomVariationNb(
        nbVariations,
        assetId
      );
      switch (randomVariationNb) {
        case 1:
          newProps.shape = Component.generateSceneObjectName(element, 1);
          break;
        case 2:
          newProps.rotation_z = 180;
          break;
        case 3:
          newProps.shape = Component.generateSceneObjectName(element, 1);
          newProps.rotation_z = 180;
          break;
      }
    }

    return newProps;
  }

  /**
   * Render an element
   *
   * @returns {JSX.Element}
   */
  render() {
    const props = Component.decorateElement(
      this.props,
      flayrOptions.furnitureType,
      FlayrSofaElement,
      {
        custom: true,
        element: true,
        assets: false,
        hardcode: true,
        skus: false,
        extend: false,
      }
    );
    return <shape {...props} />;
  }
}
