import DictionaryUtils from 'mycs/shared/utilities/DictionaryUtils/DictionaryUtils';
import PathUtils from 'mycs/shared/utilities/PathUtils/PathUtils';

import cfg from 'mycs/config';

/**
 * Provides relative urls for the website
 */
export class RelativeUrlService {
  static getTranslatedUrl(pathname: string, locale: string) {
    return PathUtils.translate(pathname, locale);
  }

  static getUntranslatedUrl(pathname: string, locale: string) {
    return PathUtils.translateReverse(pathname, locale);
  }

  static getConfiguratorUrl(
    furnitureType: string,
    uuid = 'configurator',
    locale: string,
    tab?: string
  ) {
    let pathname = this.getTranslatedUrl(`/${furnitureType}/${uuid}`, locale);

    if (tab) {
      const translatedTab = DictionaryUtils.lookUp(tab, locale) || tab;

      pathname = `${pathname}?tab=${translatedTab}`;
    }

    return pathname;
  }

  static getPdpUrl(uuid: string, locale: string) {
    return this.getTranslatedUrl(`/product/${uuid}`, locale);
  }

  static getHomeUrl(locale: string) {
    return this.getTranslatedUrl('/', locale);
  }

  static getFaqUrl(locale: string) {
    return this.getTranslatedUrl('/faq', locale);
  }

  static getCartUrl(locale: string) {
    return this.getTranslatedUrl(cfg.path.shoppingCartPage, locale);
  }

  static getImprintUrl(locale: string) {
    return this.getTranslatedUrl('/imprint', locale);
  }

  static getShippingAndPaymentUrl(locale: string) {
    return this.getTranslatedUrl('/shipping-and-payment', locale);
  }

  static getSampleboxUrl(locale: string) {
    return this.getTranslatedUrl('/samplebox', locale);
  }

  static getShowroomUrl(locale: string) {
    return this.getTranslatedUrl('/store', locale);
  }

  //FIXME: Isn't this page deprecated?
  static getVirtualShowroomUrl(locale: string) {
    return this.getTranslatedUrl('/virtual', locale);
  }

  static getCheckoutDeliveryUrl(locale: string) {
    return this.getTranslatedUrl(cfg.path.deliveryPage, locale);
  }

  static getCheckoutValidationUrl(locale: string) {
    return this.getTranslatedUrl(cfg.path.validationPage, locale);
  }

  static getCheckoutReceiptUrl(orderID: string, locale: string) {
    return `${this.getTranslatedUrl(
      cfg.path.receiptPage,
      locale
    )}?orderID=${orderID}`;
  }

  static getCheckoutPayUrl(orderID: string, locale: string) {
    return `${this.getTranslatedUrl(
      cfg.path.payPage,
      locale
    )}?orderID=${orderID}`;
  }

  static getConfiguratorCategory(pathname: string, locale: string) {
    const { steps } = PathUtils.parse(pathname, locale);

    // Check if the path is one of the configurators and if it is, return which one
    const configuratorCategory = Object.values(cfg.categoryPaths).find(
      (val) => val === steps[0]
    );

    return configuratorCategory;
  }

  /**
   * Create design pathname by using the current URL and a new UUID
   */
  static getUrlForUuid(pathname: string, designUuid: string, locale: string) {
    return PathUtils.updatePathUUID(pathname, designUuid, locale);
  }

  static isConfiguratorPage(pathname: string, locale: string) {
    const originalUrl = this.getUntranslatedUrl(pathname, locale);
    // The Swiss French website has a different url structure and contains /fr at the beginning of the path
    const firstPathSegment =
      locale === 'fr_CH'
        ? originalUrl.split('/')[2]
        : originalUrl.split('/')[1];
    return Object.values(cfg.furnitureTypes).includes(firstPathSegment);
  }

  static isProductCategoryPage(pathname: string, locale: string) {
    const originalUrl = this.getUntranslatedUrl(pathname, locale);
    const firstPathSegment = originalUrl.split('/')[1];
    return Object.values(cfg.furnitureTypes).includes(firstPathSegment);
  }

  static isPdpPage(pathname: string, locale: string) {
    const originalUrl = this.getUntranslatedUrl(pathname, locale);
    return originalUrl.split('/').includes('product');
  }

  static isCartPage(pathname: string, locale: string) {
    const originalUrl = this.getUntranslatedUrl(pathname, locale);
    return originalUrl.split('/').includes('shopping_cart');
  }

  static isHomePage(pathname: string, locale: string) {
    return this.getUntranslatedUrl(pathname, locale) === '/';
  }

  static isCheckoutPage(pathname: string, locale: string) {
    const originalUrl = this.getUntranslatedUrl(pathname, locale);
    return originalUrl.split('/').includes('checkout');
  }

  static isFaqPage(pathname: string, locale: string) {
    const originalUrl = this.getUntranslatedUrl(pathname, locale);
    return originalUrl.split('/').includes('faq');
  }

  static isShowroomPage(pathname: string, locale: string) {
    const originalUrl = this.getUntranslatedUrl(pathname, locale);
    return /^\/showroom-.*/.test(originalUrl);
  }
}
