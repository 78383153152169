import cloneDeep from 'lodash/cloneDeep';
import { CfMetaTags } from '@mycs/contentful';
import { MycsURL } from '@mycs/edge-lambdas';

import { RelativeUrlService } from '../RelativeUrlService';
import cfg from 'mycs/config';
import WindowLocationUtils from 'mycs/shared/utilities/WindowLocationUtils/WindowLocationUtils';
import PathUtils from 'mycs/shared/utilities/PathUtils/PathUtils';

type ExtendedMetaTags = Partial<CfMetaTags> & {
  canonical?: string;
  robots?: 'index, follow' | 'noindex, nofollow' | 'noindex, follow';
  metaTitle?: string;
  metaDescription?: string;
  keywords?: string[];
  hreflangs: {
    lang: string;
    url: string;
  }[];
  language?: string;
  languageCode?: string;
  placename?: string;
  // Facebook
  fb_url: string;
  fb_description?: string;
  fb_image: string;
  fb_title?: string;
  fb_locale: string;
  fb_app_id: string;
  locUrl?: any;
};

// Default meta-tags
const defaults: ExtendedMetaTags = {
  // SEO settings
  locUrl: {},
  title: '',
  description: '',
  keywords: [],
  robots: undefined,
  hreflangs: [],
  // Facebook
  fb_url: '',
  fb_description: '',
  fb_image: '',
  fb_locale: '',
  fb_app_id: '',
};

/**
 * Provide the default values for OpenGraph tags
 */
export function addDefaultFbTags(info: ExtendedMetaTags, locale: string) {
  if (!info.fb_description) {
    info.fb_description = info.description;
  }
  info.fb_app_id = cfg.facebookAppID;
  info.fb_locale = locale;
  info.fb_url = WindowLocationUtils.getUrlPathAndSearch();
  info.fb_title = info.title;
}

/**
 * Create hreflang links
 */
export function setHreflangLinks(info: ExtendedMetaTags) {
  info.hreflangs = [];
  const { locUrl, robots } = info;

  //We don't want to add hreflang links for pages that are not indexable
  if (!locUrl || !robots?.startsWith('index')) return;

  const excludedLocale = cfg.contentful.defaultLocale;

  Object.keys(locUrl)
    .filter((key) => key !== excludedLocale)
    .forEach((locale) => {
      // Trim the URL, because it can be entered in CF with trailing/leading spaces
      // Sometimes they can enter just a space or not fill the URL at all
      const url = (locUrl[locale] || '').trim();

      if (!url) return;

      // Find the domain corresponding to a locale
      const cfgLocaleKey = locale.replace(/-/, '_');
      const domain = Object.keys(cfg.languages).find((domain) => {
        return cfgLocaleKey in cfg.languages[domain];
      });

      if (!domain) return;

      info.hreflangs.push({
        lang: locale,
        url,
      });
    });

  // X-default link
  const defaultCode = cfg.geoSettings.defaultISOCode;
  const defaultLink = info.hreflangs.find((link) => link.lang === defaultCode);

  if (defaultLink) {
    info.hreflangs.push({
      lang: 'x-default',
      url: defaultLink.url,
    });
  }
}

/**
 * Set robots according to environment
 */
export function setRobots(info: ExtendedMetaTags, url: string) {
  const defaultRobots = 'noindex, follow';

  const parsedUrl = new MycsURL(url);

  //Don't index if the page has filters
  if (parsedUrl.search) {
    info.robots = defaultRobots;
    return;
  }

  info.robots = info.robots || defaultRobots;
}

export function adjustCanonical(info: ExtendedMetaTags) {
  // Canonical should not be set
  // if robots is set to 'noindex' and is configurator page
  // https://mycshq.atlassian.net/browse/MYCS-11857
  if (info.robots && info.robots.startsWith('noindex')) {
    info.canonical = undefined;
    return;
  }
}

/**
 * Set canonical URL
 */
export function setCanonical(
  info: ExtendedMetaTags,
  _locale: string,
  baseUrl: string
) {
  const locale = _locale.replace('_', '-');
  if (!info.locUrl || !info.locUrl[locale]) return;

  const url = new MycsURL(baseUrl);

  const filteredSearchParams = new URLSearchParams();
  ['seite', 'page'].forEach((key) => {
    const value = url.searchParams.get(key);
    if (value) {
      filteredSearchParams.set(key, value);
    }
  });

  url.pathname = PathUtils.removeSearch(
    PathUtils.toRelativePath(info.locUrl[locale])
  );
  url.search = filteredSearchParams.toString();

  info.canonical = url.toString();
}

/**
 * Set custom canonical url
 */
function setCustomCanonical(
  meta: ExtendedMetaTags,
  locale: string,
  url: string
) {
  if (meta.canonical) {
    return;
  }

  const parsedUrl = new MycsURL(url);
  if (RelativeUrlService.isPdpPage(parsedUrl.pathname, locale)) {
    parsedUrl.search = '';
    const customCanonical = parsedUrl.toString();

    if (customCanonical) {
      meta.canonical = customCanonical;
    }
  }
}

export function setNinjaOverrides(info: ExtendedMetaTags) {
  if (cfg.environment === 'production') {
    return;
  }

  info.robots = 'noindex, nofollow';
}

/**
 * Gather and process the meta information
 */
export const getMeta = function (
  data: ExtendedMetaTags | undefined,
  locale: string,
  countryCode: string,
  url: string
): ExtendedMetaTags {
  const meta = cloneDeep<ExtendedMetaTags>(defaults);

  Object.assign(meta, data ?? {});

  addDefaultFbTags(meta, locale);
  setCanonical(meta, locale, url);
  setCustomCanonical(meta, locale, url);
  setRobots(meta, url);
  adjustCanonical(meta);
  setHreflangLinks(meta);
  setNinjaOverrides(meta);

  // Set language variables
  meta.language = countryCode.toUpperCase();
  meta.languageCode = locale.split('_')[0];
  meta.placename = cfg.geoSettings.countryCodesToNames[countryCode];

  return meta;
};
