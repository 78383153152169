import { Subject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { getPageContentByKeyFromReduxState } from 'mycs/shared/state/slices/pageSlice';
import { LocationData } from 'mycs/shared/services/GeolocationService/types';
import { RelativeUrlService } from '../RelativeUrlService';
import { store } from 'mycs/shared/state/store';
import { User } from 'mycs/api/UserAPI';
import cfg from 'mycs/config';
import CookieService from 'mycs/shared/services/CookieService/CookieService';
import FlagModeService from 'mycs/shared/services/FlagModeService/FlagModeService';
import GeolocationService from 'mycs/shared/services/GeolocationService/GeolocationService';
import LocationUtils from 'mycs/shared/utilities/LocationUtils/LocationUtils';
import Logger from 'mycs/shared/services/Logger';
import ReportingAPIService, {
  Event,
} from 'mycs/shared/services/AnalyticsService/ReportingAPIService';
import WindowLocationUtils from 'mycs/shared/utilities/WindowLocationUtils/WindowLocationUtils';

type Product = {
  furniture_type?: string;
  furnitureType?: string;
  uuid?: string;
  price?: {
    totalPriceInclTaxes: number;
  };
  colors?: {
    primary: string;
  };
};

export class AnalyticsService {
  subject = new Subject();
  locationData?: LocationData = {} as any;
  userData: undefined | User;

  experimentVariant = 0;
  activeExperiments: Set<string> = new Set();

  get experiment() {
    return {
      name: this.activeExperiments.size
        ? Array.from(this.activeExperiments).join(',') // could be a list (names separated by comma).
        : '(none)',
      variant: this.experimentVariant,
    };
  }

  /**
   * Inits an instance of AnalyticsService
   */
  init() {
    // Set location data when preferences are enabled, to add them to events
    this.locationData = {} as any;
    CookieService.state$
      .pipe(
        map(({ cookieConsent }) => cookieConsent),
        filter((value) => !!value),
        take(1)
      )
      .subscribe((cookie) => {
        if (cookie?.preferences) {
          GeolocationService.getLocationData().then((locationData) => {
            this.locationData = locationData;
          });
        }
      });
  }

  setUserData(userData: undefined | User) {
    this.userData = userData;
  }

  setExperimentVariant(variant: number) {
    this.experimentVariant = variant;
  }

  addActiveExperiment(name: string, locale: string) {
    if (this.activeExperiments.has(name)) {
      return;
    }

    this.activeExperiments.add(name);

    this.eventTrack('setExperiment', locale);
  }

  private getPageData(
    locale: string,
    countryCode: string,
    originalPageURL: string
  ): {
    originalPageURL: string;
    pageCategory: string;
    pageCountry: string;
    language: string;
  } {
    const pageCategory = originalPageURL.split('/')[1];
    const pageCountry = countryCode.toUpperCase();

    return { originalPageURL, pageCategory, pageCountry, language: locale };
  }

  private getProductViewData(
    data: any,
    pageUrl: string,
    { furniture_type, furnitureType, uuid: id, price, colors }: Product,
    locale: string
  ): any {
    if (
      !RelativeUrlService.isPdpPage(pageUrl, locale) &&
      !RelativeUrlService.isConfiguratorPage(pageUrl, locale)
    ) {
      return data;
    }

    const category = furniture_type || furnitureType;
    const ecommerce = {
      detail: {
        products: [
          {
            id,
            price: price?.totalPriceInclTaxes,
            category,
            variant: colors?.primary,
          },
        ],
      },
    };
    return { productCategory: category, ecommerce, ...data };
  }

  trackVirtualPageView(
    locale: string,
    countryCode: string,
    pageUrl: string,
    product: Product = {}
  ) {
    if (!window.dataLayer) window.dataLayer = [];

    const originalPathUrl = RelativeUrlService.getUntranslatedUrl(
      pageUrl,
      locale
    );
    const virtualViews = window.dataLayer.filter(
      (vv) => vv.event === 'virtualPageView'
    );
    const lastVirtualView = virtualViews.at(-1);
    let data = this.getPageData(locale, countryCode, originalPathUrl);

    if (product.furniture_type || product.furnitureType) {
      data = this.getProductViewData(data, originalPathUrl, product, locale);
    }

    // Prevent double tracking of the same url
    if (
      lastVirtualView &&
      lastVirtualView.originalPageURL === data.originalPageURL
    ) {
      return;
    }

    this.eventTrack('virtualPageView', locale, data);
  }

  eventTrack(
    name: string,
    locale: string,
    data: Partial<Event> = {},
    withLoginData = false
  ) {
    data = this._decorateEventData(data, locale, withLoginData);

    if (data.mx_notrack) {
      // We keep sending tracking event if mx-notrack is present, logic to separate it from official
      // tracking event streams is implemented on GTM side
      // console.info(
      //   'The tracking is deactivated in your browser. Clear your cookies to change that!'
      // );
    }

    // Submit event
    if (!window.dataLayer) window.dataLayer = [];
    if (!data.event) data.event = name;

    // Send to Reporting API
    ReportingAPIService.handleEvent(data as Event);

    Logger.log({
      // Strip out sensitive data
      ...data,
      mycsUserEmail: '[REDACTED]',
    });

    // Remove data that should not be passed to Google Analytics
    const notGa = [...cfg.storageSettings.UTMs, 'mycsUserEmail'];
    const gaData = Object.keys(data).reduce((ga, key) => {
      if (notGa.indexOf(key) < 0) {
        //@ts-ignore
        ga[key] = data[key];
      }
      return ga;
    }, {} as { [key: string]: any });

    if (!gaData.loginUserEmail && data.mycsUserEmail) {
      gaData.loginUserEmail = data.mycsUserEmail;
    }

    // Set user location data (just for Datalayer)
    if (
      this.locationData &&
      this.locationData.user_ip &&
      this.locationData.latitude &&
      this.locationData.longitude
    ) {
      gaData.user_latitude = this.locationData.latitude;
      gaData.user_longitude = this.locationData.longitude;
    }

    // Push to Datalayer (content will be passed to GA)
    window.dataLayer.push(gaData);

    this.subject && this.subject.next(gaData);

    return { data, gaData };
  }

  /**
   * Add additional information to the payload of eventTrack()
   */
  _decorateEventData(
    data: Partial<Event>,
    locale: string,
    withLoginData = false
  ): Partial<Event> {
    data = { ...data, ...this._getEventData() };
    // Add pageURL to the custom dataLayer object
    data.pageURL = data.pageURL || WindowLocationUtils.getUrlPathAndSearch();
    // Add the original (translated) version of pageURL to data
    if (!data.originalPageURL) {
      const page =
        getPageContentByKeyFromReduxState<any>(store.getState(), {
          locale,
          pathname: data.pageURL!,
        }) || {};
      const { originalUrl } = page;
      data.originalPageURL =
        originalUrl ||
        RelativeUrlService.getUntranslatedUrl(data.pageURL!, locale);
    }

    // Add user id if available
    if (this.userData?.id) data.mycsUserId = this.userData.id;
    // Add user email if available
    if (this.userData?.email) data.mycsUserEmail = this.userData.email;

    if (withLoginData) {
      data.loginUserId = data.mycsUserId;
      data.loginUserEmail = data.mycsUserEmail;
    }

    return data;
  }

  /**
   * Accumulate event data
   */
  _getEventData() {
    const data: Partial<Event> = {};
    // Add mx-notrack
    data.mx_notrack = FlagModeService.isNoTrack();
    // Add mx-showroom
    data.mx_showroom = FlagModeService.isShowroom();

    // Add utm_... parameters
    const queryStrings = WindowLocationUtils.getSearch();
    Object.keys(queryStrings).forEach((key) => {
      //@ts-ignore
      if (/^utm_.*/.test(key)) data[key] = queryStrings[key];
    });

    // Track SEO traffic
    if (this.isSeoTraffic()) {
      const { hostname, search } = new URL(document.referrer);

      data.utm_medium = 'organic';
      data.utm_source = hostname;
      data.utm_term = search.replace(/^\?/, '');
    }

    // Add `(none)` to empty utm attributes
    cfg.storageSettings.UTMs.forEach((utm) => {
      //@ts-ignore
      if (!data[utm]) data[utm] = '(none)';
    });

    data.experiment = this.experiment.name;
    data.variant = this.experiment.variant;

    return data;
  }

  /**
   * Check whether the referrer belongs to a Search Engine
   */
  isSeoTraffic(): boolean {
    if (!document.referrer) return false;
    // The presence of UTM parameters in querystring indicates this was paid traffic
    const qs = WindowLocationUtils.getSearch();
    if (qs.utm_medium || qs.utm_source) return false;

    /**
     * Get the domain without TLD.
     * E.g. www.google.com -> www.google
     */
    const getDomain = (hostname: string): string => {
      return hostname.split('.').slice(0, -1).join('.');
    };

    // Only keep the domain name (without TLD)
    const searchEngines = cfg.searchEngines.map(getDomain);
    const { hostname } = new URL(document.referrer);
    const referrerDomain = getDomain(hostname);

    return searchEngines.some((domain) => referrerDomain.endsWith(domain));
  }

  /**
   * Facebook tracking
   */
  trackFacebook(name: string, locale: string, data?: any) {
    if (!window.dataLayer) window.dataLayer = [];
    data = this._decorateEventData(data, locale);
    window.dataLayer.push({ event: name, ...data });
  }

  /**
   * Product feed on Criteo listing tag
   * (max 3 uuids supported on the Criteo request)
   */
  productIDList(uuids: string[]) {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer.push({
      event: 'productIDList',
      productIDList: uuids.slice(0, 3),
    });
  }

  /**
   * Send the currently being viewed product list
   * to the dataLayer for FB and Criteo.
   */
  trackProductList(
    products: any[],
    locale: string,
    countryCode: string,
    pageUrl: string
  ) {
    const originalPageURL = RelativeUrlService.getUntranslatedUrl(
      pageUrl,
      locale
    );
    const { pageCategory } = this.getPageData(
      locale,
      countryCode,
      originalPageURL
    );
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    this.productIDList(products.map((p) => p.uuid));
    window.dataLayer.push({
      event: 'viewProductList',
      pageCategory,
      productList: products.map((p) => {
        const { uuid, price } = p;
        return {
          id: uuid,
          item_price: price ? price.totalPriceInclTaxes : 0,
          quantity: 1,
        };
      }),
    });
  }

  /**
   * Get the component's location in the component tree
   */
  getComponentPath(target?: HTMLElement, label?: string): string {
    const IgnoredComponents: { [elementType: string]: boolean } = {
      MainNav: true,
      Carousel: true,
      Icon: true,
    };

    const classNameRegex = /\b([A-Z]\w+?)__\w+?\b/;
    const componentTree: {
      type: string;
      el: HTMLElement;
      selector: string;
      index: number;
    }[] = [];
    let el: HTMLElement | null | undefined = target;

    // Extract a component tree from the DOM tree
    while (el) {
      // Try the explicit data attribute
      let type = el.getAttribute('data-track-path');
      let selector = `[data-track-path="${type}"]`;

      // Otherwise infer the type from the class name
      if (!type) {
        const className = el.className;
        const match =
          typeof className === 'string' && className.match(classNameRegex);
        if (match) {
          type = match[1];
          selector = `[class^="${match[0]}"]`;
        }
      }

      if (type && !IgnoredComponents[type]) {
        const existingNode = componentTree.find((x) => x.type === type);
        const lastNode = { type, el, selector, index: -1 };

        if (existingNode) {
          Object.assign(existingNode, lastNode);
        } else {
          componentTree.push(lastNode);
        }
      }

      el = el.parentElement;
    }

    // Exit early if no components in the DOM tree
    if (!componentTree.length) return '';

    // Find the count of each component within its siblings
    componentTree.reduce((prev, next) => {
      const siblings = next.el.querySelectorAll(prev.selector);
      if (siblings.length > 1) {
        prev.index = Array.prototype.indexOf.call(siblings, prev.el);
      }
      return next;
    });

    // XPath-like string
    const componentPath = componentTree.map((item) => {
      return item.type + (item.index > -1 ? `[${item.index + 1}]` : '');
    });

    // If there's label, remove the count and add the label
    if (label) {
      componentPath[0] = componentPath[0].replace(/(\[\d+\])?$/, `{${label}}`);
    }

    return componentPath.reverse().join('/');
  }

  /**
   * Send a GoogleAnalytic "welcomeMail" event
   */
  sendWelcomeMailEvent(locale: string) {
    const tag = {
      event: 'welcomeMail',
      langCode: locale,
    };

    this.eventTrack('welcomeMail', locale, tag, true);
  }

  /**
   * An attempt to make Pageview great again.
   */
  getPageviewEvent(
    type: 'Onsite' | 'GA',
    {
      countryCode,
      locale,
      locationPathname,
      urlSearchParams,
      documentReferrerURL,
      user,
      isNoTrack,
      isShowroom,
      locationData,
    }: {
      countryCode: string;
      locale: string;
      locationPathname: string;
      urlSearchParams: URLSearchParams;
      documentReferrerURL?: URL;
      user: undefined | User;
      isNoTrack: boolean;
      isShowroom: boolean;
      locationData: LocationData;
    }
  ) {
    const locationSearch = urlSearchParams.toString()
      ? `?${urlSearchParams.toString()}`
      : '';
    // Reverse the pathname translation.
    const originalPageURL = `${LocationUtils.translatePathname(
      locationPathname,
      locale,
      true
    )}${LocationUtils.translateSearch(locationSearch, locale, true)}`;

    return {
      event: 'Pageview',
      pageURL: locationPathname + locationSearch,
      originalPageURL,
      language: locale,
      pageCategory: originalPageURL.split('/')[1],
      pageCountry: countryCode.toUpperCase(),
      mycsUserId: user?.id,
      mx_notrack: isNoTrack,
      mx_showroom: isShowroom,
      experiment: this.experiment.name,
      variant: this.experiment.variant,

      ...(type === 'Onsite' && {
        mycsUserEmail: user?.email,
        ...this.getUTMParams(urlSearchParams, documentReferrerURL),
      }),

      ...(type === 'GA' && {
        loginUserEmail: user?.email,
        user_latitude: locationData.latitude,
        user_longitude: locationData.longitude,
      }),
    };
  }

  isSearchEngineReferrer(documentReferrerURL: URL) {
    // Includes the most popular search engines in Europe and worldwide.
    // https://gs.statcounter.com/search-engine-market-share/all/europe
    const searchEngines = [
      'ecosia',
      'google',
      'bing',
      'duckduckgo',
      'yandex',
      'yahoo',
      'baidu',
    ];

    return searchEngines.some((engine) =>
      documentReferrerURL.hostname.includes(engine)
    );
  }

  getUTMParams(urlSearchParams: URLSearchParams, documentReferrerURL?: URL) {
    const utmParamKeys = [
      'utm_medium',
      'utm_source',
      'utm_campaign',
      'utm_content',
      'utm_term',
    ] as const;

    let utmParams = utmParamKeys.reduce((prevValue, key) => {
      const value = urlSearchParams.get(key);
      if (value) {
        return {
          ...prevValue,
          [key]: value,
        };
      }

      return prevValue;
    }, undefined as undefined | { [key in (typeof utmParamKeys)[number]]: string });

    if (
      !utmParams &&
      documentReferrerURL &&
      this.isSearchEngineReferrer(documentReferrerURL)
    ) {
      utmParams = {
        utm_medium: 'organic',
        utm_source: documentReferrerURL.hostname,
        utm_term: documentReferrerURL.search.replace(/^\?/, ''),
      };
    }

    return {
      utm_medium: '(none)',
      utm_source: '(none)',
      utm_campaign: '(none)',
      utm_content: '(none)',
      utm_term: '(none)',
      ...utmParams,
    };
  }
}

const Analytics = new AnalyticsService();

export default Analytics;
