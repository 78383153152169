export function cookieStringToMap(cookieString: string) {
  const cookies = cookieString.split(';');
  const cookieObject: { [key: string]: string } = {};
  cookies.forEach((cookie) => {
    const [cookieName, cookieValue] = cookie.split('=');
    cookieObject[cookieName.trim()] = cookieValue;
  });

  return cookieObject;
}

export function formatCookie(
  cookieName: string,
  cookieValue: string,
  cookieMaxAge: number,
  path: string
) {
  return `${cookieName}=${cookieValue};max-age=${cookieMaxAge};path=${path}`;
}
