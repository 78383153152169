import { Component } from 'react';
import classNames from 'classnames';
import loadable from '@loadable/component';

import { AsyncLoader } from 'mycs/shared/components/AsyncLoader/AsyncLoader';
import { noop } from 'mycs/shared/utilities/GeneralUtils/GeneralUtils';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';

import styles from './Input.scss';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import Icon from 'mycs/shared/components/Icon/Icon';

const PhoneInput = loadable(
  () =>
    import(
      /* webpackChunkName: "phoneinput" */ 'mycs/shared/components/PhoneInput/PhoneInput'
    ),
  {
    fallback: <AsyncLoader />,
    ssr: false,
  }
);

interface Props {
  value?: string;
  label?: string;
  isRequired?: boolean;
  showAsterisk?: boolean;
  isDisabled?: boolean;
  errorMessage?: string;
  validCheck?: boolean;
  errorMessageBottom?: boolean;
  errorMessageClassName?: string;
  containerClassName?: string;
  disablePaste?: boolean;
  onChange: (value: string) => void;
  onClick?: (
    event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onFocus?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onKeyDown?: (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  isControlled?: boolean;
  /** Any valid HTML5 native attribute */
  nativeProps?:
    | React.DetailedHTMLProps<
        React.TextareaHTMLAttributes<HTMLTextAreaElement>,
        HTMLTextAreaElement
      >
    | React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >;
  name?: string;
  className?: string;
  labelClassName?: string;
  isMultiLine?: boolean;
  isPhoneInput?: boolean;
  modernLayout?: boolean;
}

type State = {
  value: string;
  errorMessage: string;
};

export default function Input(props: Props) {
  const { locale } = useLocale();
  return <InnerInput {...props} locale={locale} />;
}

type InnerProps = Props & { locale: string };

class InnerInput extends Component<InnerProps, State> {
  static defaultProps = {
    value: '',
    onChange: noop,
    onClick: noop,
    onKeyDown: noop,
    onFocus: noop,
    onBlur: noop,
    disablePaste: false,
    errorMessageBottom: false,
    isControlled: false,
    isMultiLine: false,
    isPhoneInput: false,
    modernLayout: false,
  };

  constructor(props: InnerProps) {
    super(props);

    const { value } = this.props;

    const initialState = {
      value: '',
      errorMessage: '',
    };

    if (value || value === '') {
      initialState.value = value as string;
    }

    this.state = initialState;
  }

  /**
   * Input change handler
   */
  onChangeHandler = (event: { target: { value: string } }) => {
    const {
      target: { value },
    } = event;
    if (this.props.isControlled) {
      this.props.onChange(value);
    } else {
      this.setState({ value }, () => {
        this.props.onChange(value);
      });
    }
  };

  /**
   * Generic event handler
   */
  eventHandler = (
    event:
      | React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.ClipboardEvent<HTMLInputElement>
  ) => {
    const { disablePaste } = this.props;
    const { type } = event;
    switch (type) {
      case 'click':
        return this.props.onClick?.(
          event as React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>
        );
      case 'blur':
        if (
          this.props.isRequired &&
          ((!this.props.isControlled && !this.state.value) ||
            (this.props.isControlled && !this.props.value))
        ) {                   
          this.setState({ errorMessage: 'Field required' });
        }
        return this.props.onBlur?.(
          event as React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
        );
      case 'focus':
        if (this.state.errorMessage) {
          this.setState({ errorMessage: '' });
        }
        return this.props.onFocus?.(
          event as React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
        );
      case 'paste':
        if (disablePaste) {
          event.preventDefault();
        }
        break;
      case 'keydown':
        this.props.onKeyDown?.(
          event as React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
        );
    }
  };

  getInput(inputClass: string) {
    const {
      isMultiLine,
      isPhoneInput,
      isControlled,
      nativeProps = {},
      value,
      name,
    } = this.props;

    let { placeholder = '' } = nativeProps;
    placeholder = I18nUtils.localize(this.props.locale, placeholder);

    const dynamicProps = isControlled
      ? { value: value || '' }
      : { defaultValue: this.state.value };

    if (isMultiLine) {
      return (
        <textarea
          className={inputClass}
          role="textbox"
          {...(nativeProps as React.DetailedHTMLProps<
            React.TextareaHTMLAttributes<HTMLTextAreaElement>,
            HTMLTextAreaElement
          >)}
          name={name || nativeProps.name}
          placeholder={placeholder}
          onChange={this.onChangeHandler}
          onClick={this.eventHandler}
          onKeyDown={this.eventHandler}
          onBlur={this.eventHandler}
          onFocus={this.eventHandler}
          {...dynamicProps}
        />
      );
    }

    if (isPhoneInput) {
      return (
        <PhoneInput
          {...{
            inputClass: inputClass,
            dynamicProps: dynamicProps,
            onChangeHandler: this.onChangeHandler,
            eventHandler: this.eventHandler,
            name: name || nativeProps.name,
          }}
        />
      );
    }

    return (
      <input
        className={inputClass}
        role="textbox"
        {...(nativeProps as React.DetailedHTMLProps<
          React.InputHTMLAttributes<HTMLInputElement>,
          HTMLInputElement
        >)}
        name={name || nativeProps.name}
        placeholder={placeholder}
        onChange={this.onChangeHandler}
        onClick={this.eventHandler}
        onKeyDown={this.eventHandler}
        onBlur={this.eventHandler}
        onFocus={this.eventHandler}
        onPaste={this.eventHandler}
        {...dynamicProps}
      />
    );
  }

  render() {
    const { value: inputValue } = this.props;
    const {
      labelClassName,
      label,
      errorMessageClassName,
      errorMessageBottom,
      className,
      isDisabled,
      isMultiLine,
      containerClassName,
      modernLayout,
      validCheck,
      showAsterisk
    } = this.props;
    
    const labelClasses = classNames(styles.label, labelClassName);
    const labelElement = label ? (
      <div className={labelClasses}>
        {I18nUtils.localize(this.props.locale, label as string) + (showAsterisk ? ' *' : '')}
      </div>
    ) : null;
    const errorMessage = this.state.errorMessage || this.props.errorMessage;
    const errorMessageClass = classNames(
      styles.errorMessage,
      errorMessageClassName,
      { [styles.errorMessageBottom]: errorMessageBottom }
    );
    const isValidCheck = !errorMessage && inputValue && validCheck;

    const inputClass = classNames(styles.input, className, {
      [styles.error]: Boolean(errorMessage),
      [styles.disabled]: isDisabled,
      [styles.textarea]: isMultiLine,
      [styles.validCheck]: isValidCheck,
    });

    return (
      <div
        className={classNames(styles.inputContainer, containerClassName, {
          [styles.modernLayout]: modernLayout,
        })}
      >
        {this.getInput(inputClass)}

        {errorMessage ? (
          <div className={errorMessageClass}>
            {I18nUtils.localize(this.props.locale, errorMessage as string)}
          </div>
        ) : (
          ''
        )}

        {isValidCheck ? (
          <div className={styles.validCheckMessage}>
            <Icon
              iconName={`checkout/confirmation_checkout.svg`}
              className={styles.validCheckIcon}
            />
          </div>
        ) : (
          ''
        )}

        {labelElement}
      </div>
    );
  }
}
