import classNames from 'classnames';

import styles from './Loader.scss';

type Props = {
  isDots?: boolean;
  isSmall?: boolean;
  isSmallInverse?: boolean;
};

export default function Loader({
  isDots = false,
  isSmall = false,
  isSmallInverse = false,
}: Props) {
  return isDots ? (
    <div className={styles.dots} data-testid="loader">
      <div className={styles.bounce1} />
      <div className={styles.bounce2} />
      <div className={styles.bounce3} />
    </div>
  ) : (
    <div
      className={classNames(styles.loader, {
        [styles.small]: isSmall,
        [styles.smallInverse]: isSmallInverse,
      })}
      data-testid="loader"
    />
  );
}
