import { matchPath } from 'react-router-dom';

import { setPage } from 'mycs/shared/state/slices/pageSlice';
import { RelativeUrlService } from 'mycs/shared/services/RelativeUrlService';
import { setHeader } from 'mycs/shared/state/slices/headersSlice';
import { setTranslations } from 'mycs/shared/state/slices/translationsSlice';
import { dispatch } from 'mycs/shared/state/store';
import ContentfulService from 'mycs/shared/services/ContentfulService/ContentfulService';
import LocationUtils from 'mycs/shared/utilities/LocationUtils/LocationUtils';
import Logger from 'mycs/shared/services/Logger';
import routesConfig, { componentProps } from './config';
import TranslationService from 'mycs/shared/services/TranslationService/TranslationService';
import AnalyticsService from 'mycs/shared/services/AnalyticsService/AnalyticsService';
import { CacheKey as ABTestConfigCacheKey } from 'mycs/hooks/useABTestConfigs';
import { MycsURL } from '@mycs/edge-lambdas';

/**
 * @param localizedPath what you see in the address bar
 * @param originalPath the English version of the above
 * @param product product object
 */
export function trackPageView(
  localizedPath: string,
  locale: string,
  countryCode: string,
  originalPath?: string,
  product = {}
) {
  const pageUrl = originalPath || localizedPath;
  AnalyticsService.trackVirtualPageView(locale, countryCode, pageUrl, product);
}

export type RouteHandlerType = {
  noPageView?: boolean;
  criteoFailRoute?: string;
  resolve?: (routeParams: componentProps) => Promise<any>;
  mobileFailRoute?: string;
  failRoute?: string;
  render: JSX.Element;
};

type MatchedRoute = {
  locale: string;
  countryCode: string;
  match?: any;
  pattern?: any;
  config?: RouteHandlerType;
  location?: any;
};
/**
 * Find a matching route config
 */
export function matchUrl(url: string): MatchedRoute {
  const { pathname } = new URL(url);
  const locale = LocationUtils.getLocaleFromUrl(url);
  const countryCode = LocationUtils.getCountryCodeFromUrl(url);
  const routes = Object.keys(routesConfig);

  let result: MatchedRoute = {
    locale,
    countryCode,
  };

  for (const pattern of routes) {
    const match = matchPath(
      {
        path: RelativeUrlService.getTranslatedUrl(pattern, locale),
        end: true,
      },
      pathname
    );

    if (match) {
      result = {
        locale,
        countryCode,
        match,
        pattern,
        // @ts-ignore
        config: routesConfig[pattern],
        location: { pathname },
      };
      break;
    }
  }

  return result;
}

export async function fetchSSRData(
  matchingRoute: MatchedRoute,
  isSmallScreen: boolean,
  baseUrl: string
) {
  const { match, config, location, locale, countryCode } = matchingRoute;
  const { pathname } = location;

  const translations = TranslationService.getTranslations(locale, countryCode)
    .then((data) => dispatch(setTranslations({ data, locale })))
    .catch((err) => Logger.error(err));

  const pageData =
    config
      ?.resolve?.({
        locale,
        countryCode,
        pathname,
        params: match.params,
      })
      ?.then((data) => {
        if (!data) return;

        storeStateInRedux({
          data,
          locale,
          pathname,
          isSSR: true,
          baseUrl,
        });
      })
      .catch((err: Error) => {
        Logger.error(err);

        const redirectUrl = getRedirectUrl({
          handler: config,
          location,
          isSmallScreen,
          locale,
        });

        if (redirectUrl) {
          storeStateInRedux({
            data: {
              statusCode: 302,
              redirectTo: redirectUrl,
            },
            locale,
            pathname,
            isSSR: true,
            baseUrl,
          });
        } else {
          // if route resolve fails page will be undefined
          if (err.message.match(/because not found/i)) {
            storeStateInRedux({
              data: {
                statusCode: 404,
              },
              locale,
              pathname,
              isSSR: true,
              baseUrl,
            });
          }
        }
      }) || Promise.resolve(null);

  const headerData = ContentfulService.getHeader(locale, countryCode)
    .then((data) => dispatch(setHeader({ data, locale })))
    .catch((err) => Logger.error(err));

  const abTestConfigsPromise = ContentfulService.getABTestConfigs(
    locale,
    countryCode
  );

  // Load all data needed to render the app
  const [abTestConfigs] = await Promise.all([
    abTestConfigsPromise,
    translations,
    pageData,
    headerData,
    ContentfulService.init(locale, countryCode).catch((error) => {
      Logger.error(`[ssr] Could not init ContentfulService: ${error}`);
    }),
  ]);

  return {
    swrFallback: {
      [ABTestConfigCacheKey]: abTestConfigs,
    },
  };
}

export function storeStateInRedux({
  data,
  locale,
  pathname,
  isSSR = false,
  baseUrl,
}: {
  data: any;
  locale: string;
  pathname: string;
  isSSR?: boolean;
  baseUrl: string;
}) {
  let dispatchQueue = [];

  if (!Array.isArray(data)) {
    dispatchQueue.push({
      slice: 'page',
      action: setPage,
      payload: { data, locale, pathname },
    });
  } else {
    dispatchQueue.push(...data);
  }

  dispatchQueue.forEach((queueItem) => {
    //if the queue item is empty (data loading failure) we should not dispatch an action
    if (!queueItem || !queueItem.slice) {
      return;
    }

    const { slice, action, payload } = queueItem;
    if (slice === 'page' && payload.data.page) {
      const locUrl = payload.data.page.metaTags?.locUrl; // will be deprecated and replaced by metaLocUrl
      const metaLocUrl = payload.data.page.metaLocUrl;
      if (metaLocUrl || locUrl) {
        const parsedLocUrl = mapLocUrlToFullUrls(metaLocUrl || locUrl, baseUrl);
        if (locUrl) {
          payload.data.page.metaTags.locUrl = parsedLocUrl;
        }
        payload.data.page.metaLocUrl = parsedLocUrl;
      }
      payload.data.page.ssrLocation = isSSR && pathname;
    }
    dispatch(action(payload));
  });
}

function mapLocUrlToFullUrls(locUrl: Record<string, string>, baseUrl: string) {
  const url = new MycsURL(baseUrl);

  return Object.keys(locUrl).reduce((acc, locale) => {
    const [, countryCode] = locale.split('-').map((s) => s.toLowerCase());
    url.countryCode = countryCode;
    url.pathname = locUrl[locale];
    if (!locUrl[locale]) return acc;
    return {
      ...acc,
      [locale]: url.toString(),
    };
  }, {});
}

export function getRedirectUrl({
  handler,
  location,
  isSmallScreen,
  locale,
}: {
  handler: RouteHandlerType;
  location: any;
  isSmallScreen: boolean;
  locale: string;
}) {
  // Look for a fallback route
  let failRoute = isSmallScreen ? handler.mobileFailRoute : handler.failRoute;
  const { pathname, search } = location;

  // Test query parametrs to identify criteo traffic [MYCS-10513]
  if (
    /(utm_source=criteo|utm_medium=rt)/.test(search) &&
    handler.criteoFailRoute
  ) {
    failRoute = handler.criteoFailRoute;
  }
  const redirectUrl =
    failRoute && RelativeUrlService.getTranslatedUrl(failRoute, locale);

  if (redirectUrl && pathname !== redirectUrl) {
    return redirectUrl;
  } else return null;
}
