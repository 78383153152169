const modules = [
  {
    module: ['armrest_left', 'normal'],
    dimensions_expand: [[120, 240], [940], [500]],
    color: ['textile'],
  },
  {
    module: ['armrest_right', 'normal'],
    dimensions_expand: [[120, 240], [940], [500]],
    color: ['textile'],
  },
  {
    module: ['back_cushion', 'normal'],
    dimensions_expand: [[600, 800, 1000], [180], [390]],
    color: ['textile'],
  },
  {
    module: ['backrest', 'normal'],
    dimensions_expand: [[800, 1200, 1600, 2000], [160], [420]],
    color: ['textile'],
  },
  {
    module: ['base', 'normal'],
    dimensions_expand: [[600, 1000], [940], [80]],
    color: ['default_shd'],
  },
  {
    module: ['base', 'normal'],
    dimensions_expand: [[800, 1200, 1600, 2000], [940], [80]],
    color: ['textile'],
  },
  {
    module: ['base_extension', 'normal'],
    dimensions_expand: [[720, 840, 920, 1040, 1120, 1240], [640], [80]],
    color: ['textile'],
  },
  {
    module: ['base_pouf', 'normal'],
    dimensions_expand: [[600, 800, 1000], [580], [80]],
    color: ['textile'],
  },
  {
    module: ['corner', 'normal'],
    dimensions_expand: [[940], [940], [80]],
    color: ['textile'],
  },
  {
    module: ['corner_backrest', 'normal'],
    dimensions_expand: [[940], [940], [420]],
    color: ['textile'],
  },
  {
    module: ['corner_cushion', 'normal'],
    dimensions: [
      [780, 180, 390],
      [780, 780, 220],
    ],
    color: ['textile'],
  },
  {
    module: ['cushion', 'normal'],
    dimensions_expand: [[600, 800, 1000], [820], [220]],
    color: ['textile'],
  },
  {
    module: ['end_cushion_left', 'normal'],
    dimensions_expand: [[1000, 1200], [970], [220]],
    color: ['textile'],
  },
  {
    module: ['end_cushion_right', 'normal'],
    dimensions_expand: [[1000, 1200], [970], [220]],
    color: ['textile'],
  },
  {
    module: ['l_cushion_left', 'normal'],
    dimensions_expand: [[720, 840, 920, 1040, 1120, 1240], [1460], [220]],
    color: ['textile'],
  },
  {
    module: ['l_cushion_right', 'normal'],
    dimensions_expand: [[720, 840, 920, 1040, 1120, 1240], [1460], [220]],
    color: ['textile'],
  },
  {
    module: ['leg', 'corner'],
    dimensions_expand: [[110], [110], [110]],
    color: ['laminate_black', 'metal_chrome', 'metal_stainless'],
  },
  {
    module: ['leg', 'frame_front'],
    dimensions_expand: [[690, 810, 890, 1010, 1090, 1210], [12], [110]],
    color: ['laminate_black', 'metal_chrome', 'metal_stainless'],
  },
  {
    module: ['leg', 'frame_side'],
    dimensions_expand: [[12], [550, 910], [110]],
    color: ['laminate_black', 'metal_chrome', 'metal_stainless'],
  },
  {
    module: ['leg', 'slanted'],
    dimensions_expand: [[90], [90], [110]],
    color: ['laminate_black', 'oak', 'walnut'],
  },
  {
    module: ['leg', 'square'],
    dimensions_expand: [[40], [40], [110]],
    color: ['laminate_black', 'oak', 'walnut'],
  },
  {
    module: ['leg_high', 'corner'],
    dimensions_expand: [[110], [110], [170]],
    color: ['laminate_black', 'metal_chrome', 'metal_stainless'],
  },
  {
    module: ['leg_high', 'frame_front'],
    dimensions_expand: [[690, 810, 890, 1010, 1090, 1210], [12], [170]],
    color: ['laminate_black', 'metal_chrome', 'metal_stainless'],
  },
  {
    module: ['leg_high', 'frame_side'],
    dimensions_expand: [[12], [550, 910], [170]],
    color: ['laminate_black', 'metal_chrome', 'metal_stainless'],
  },
  {
    module: ['leg_high', 'slanted'],
    dimensions_expand: [[50], [83], [170]],
    color: ['laminate_black', 'oak', 'walnut'],
  },
  {
    module: ['leg_high', 'square'],
    dimensions_expand: [[40], [40], [170]],
    color: ['laminate_black', 'oak', 'walnut'],
  },
  {
    module: ['legs', 'support'],
    dimensions_expand: [[25], [25], [120]],
    color: ['metal_black'],
  },
  {
    module: ['legs_high', 'support'],
    dimensions_expand: [[25], [25], [170]],
    color: ['metal_black'],
  },
  {
    module: ['pouf_cushion', 'normal'],
    dimensions_expand: [[600, 800, 1000], [600], [220]],
    color: ['textile'],
  },
  //{
  //module: ['sofa_bed', 'cover'],
  //dimensions_expand: [[2000], [780], [300]],
  //color: ['textile']
  //},
  {
    module: ['sofa_bed', 'normal'],
    dimensions_expand: [[2000], [820], [300]],
    color: ['textile'],
  },
  {
    module: ['sofa_bed_high', 'normal'],
    dimensions_expand: [[2000], [820], [300]],
    color: ['textile'],
  },
  {
    module: ['sofa_bed_backrest', 'normal'],
    dimensions_expand: [[2000], [160], [500]],
    color: ['textile'],
  },
  {
    module: ['sofa_bed_box', 'normal'],
    dimensions_expand: [[2000], [720], [120]],
    color: ['black'],
  },
  {
    module: ['sofa_bed_box_high', 'high'],
    dimensions_expand: [[1980], [676], [170]],
    color: ['black'],
  },
];
export default modules;
