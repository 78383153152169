const modules = [
  {
    module: ['armrest_left', 'normal'],
    dimensions_expand: [[340, 480], [1070], [520]],
    color: ['textile'],
  },
  {
    module: ['armrest_right', 'normal'],
    dimensions_expand: [[340, 480], [1070], [520]],
    color: ['textile'],
  },
  {
    module: ['backrest', 'normal'],
    dimensions_expand: [[620, 770, 1020], [360], [680]],
    color: ['textile'],
  },
  {
    module: ['base', 'normal'],
    dimensions_expand: [[620, 770, 1020], [770], [360]],
    color: ['textile'],
  },
  {
    module: ['base_pouf', 'normal'],
    dimensions_expand: [[800, 1000], [640], [420]],
    color: ['textile'],
  },
  {
    module: ['corner', 'normal'],
    dimensions_expand: [[770], [770], [360]],
    color: ['textile'],
  },
  {
    module: ['corner_backrest', 'normal'],
    dimensions_expand: [[1070], [1070], [680]],
    color: ['textile'],
  },
  {
    module: ['end_cushion_left', 'normal'],
    dimensions_expand: [[1360, 1620], [1070], [360]],
    color: ['textile'],
  },
  {
    module: ['end_cushion_right', 'normal'],
    dimensions_expand: [[1360, 1620], [1070], [360]],
    color: ['textile'],
  },
  {
    module: ['l_cushion_left', 'normal'],
    dimensions_expand: [[1110, 1250, 1360, 1500], [1380], [360]],
    color: ['textile'],
  },
  {
    module: ['l_cushion_right', 'normal'],
    dimensions_expand: [[1110, 1250, 1360, 1500], [1380], [360]],
    color: ['textile'],
  },
  {
    module: ['sofa_bed_left', 'normal'],
    dimensions_expand: [[1020], [770], [360]],
    color: ['textile'],
  },
  {
    module: ['sofa_bed_right', 'normal'],
    dimensions_expand: [[1020], [770], [360]],
    color: ['textile'],
  },
];

export default modules;
