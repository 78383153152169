import loadable from '@loadable/component';

import { AsyncLoader } from 'mycs/shared/components/AsyncLoader/AsyncLoader';
import ConfiguratorDataService from 'mycs/configurators/shared/services/ConfiguratorDataService';
import TimerStore from 'mycs/shared/stores/TimerStore/TimerStore';
import { componentProps } from './config';

function resolve(
  type: string,
  { params, locale, countryCode }: componentProps
) {
  TimerStore.addTime('start_route');
  TimerStore.addTime('RequireConfiguratorDataService', 'start_route');

  return ConfiguratorDataService.loadApiData(
    type,
    locale,
    countryCode,
    params.uuid
  );
}

const Sofa = loadable(
  () => {
    TimerStore.addTime('start_joyn_sofa');
    return import(
      /* webpackChunkName: "configurators" */ 'mycs/configurators/sofas/components/Sofa/Sofa'
    );
  },
  {
    fallback: <AsyncLoader />,
    resolveComponent: ({ SofaRoute }) => SofaRoute,
    ssr: false,
  }
);

const Shelf = loadable(
  () => {
    TimerStore.addTime('start_shelf');
    return import(
      /* webpackChunkName: "configurators" */ 'mycs/configurators/shelves/components/Shelf/Shelf'
    );
  },
  {
    fallback: <AsyncLoader />,
    resolveComponent: ({ ShelfRoute }) => ShelfRoute,
    ssr: false,
  }
);

const Cushion = loadable(
  () => {
    TimerStore.addTime('start_cushion');
    return import(
      /* webpackChunkName: "configurators" */ 'mycs/configurators/cushions/components/Cushion/Cushion'
    );
  },
  {
    fallback: <AsyncLoader />,
    resolveComponent: ({ CushionRoute }) => CushionRoute,
    ssr: false,
  }
);

const Wardrobe = loadable(
  () => {
    TimerStore.addTime('start_wardrobe');
    return import(
      /* webpackChunkName: "configurators" */ 'mycs/configurators/wardrobes/components/Wardrobe/Wardrobe'
    );
  },
  {
    fallback: <AsyncLoader />,
    resolveComponent: ({ WardrobeRoute }) => WardrobeRoute,
    ssr: false,
  }
);

export const sofaRoute = (type: string) => ({
  failRoute: '/about-our-configurators/sofas-and-armchair?msg=Design not found',
  mobileFailRoute: '/',
  noPageView: true, // Configurator store sends VPV event with each tab changes
  resolve: resolve.bind(null, type),
  render: <Sofa furnitureType={type} />,
});

export const shelfRoute = (type: string) => ({
  failRoute:
    '/about-our-configurators/shelves-and-storage?msg=Design not found',
  noPageView: true, // Configurator store sends VPV event with each tab changes
  resolve: resolve.bind(null, type),
  render: <Shelf furnitureType={type} />,
});

export const cushionRoute = (type: string) => ({
  failRoute: '/about-our-configurators?msg=Design not found',
  mobileFailRoute: '/',
  noPageView: true, // Configurator store sends VPV event with each tab changes
  resolve: resolve.bind(null, type),
  render: <Cushion furnitureType={type} />,
});

export const wardrobeRoute = (type: string) => ({
  failRoute: '/about-our-configurators?msg=Design not found',
  mobileFailRoute: '/',
  noPageView: true, // Configurator store sends VPV event with each tab changes
  resolve: resolve.bind(null, type),
  render: <Wardrobe furnitureType={type} />,
});
