import {
  BehaviorSubject,
  EMPTY,
  interval,
  merge,
  Observable,
  ReplaySubject,
  throwError,
} from 'rxjs';
import {
  bufferTime,
  catchError,
  defaultIfEmpty,
  delay,
  filter,
  last,
  map,
  mergeMap,
  retryWhen,
  share,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import uuidV4 from 'uuid/v4';

import { asyncRequest$ } from 'mycs/shared/utilities/FetchUtils/FetchUtils';
import CookieService from 'mycs/shared/services/CookieService/CookieService';
import FetchAPI from 'mycs/shared/utilities/FetchAPI/FetchAPI';
import GeolocationService from 'mycs/shared/services/GeolocationService/GeolocationService';
import LocalStorage from 'mycs/shared/utilities/LocalStorageUtils/LocalStorageUtils';
import Logger from 'mycs/shared/services/Logger';
import SessionService from 'mycs/shared/services/SessionService/SessionService';
import UrlProviderService from 'mycs/shared/services/UrlProviderService/UrlProviderService';
import { DesignGraphPath } from 'mycs/api/DesignAPI';

export interface Event {
  event: string;
  experiment: string;
  mx_notrack: boolean;
  mx_showroom: boolean;
  mycsUserEmail?: string;
  mycsUserId?: number;
  originalPageURL: string;
  pageURL: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
  uuid?: string;
  designGraph?: {
    parentUUID: string;
    path: DesignGraphPath;
  };
  variant: number;
  loginUserId?: number | null | undefined;
  loginUserEmail?: string;
  subject?: string;
  campaignName?: string;
  componentPath?: string;
  pageCountry?: string;
  action?: string;
  areAgentsOnline?: boolean;
  label?: string;
  box?: string;
  country_code?: string;
  furniture_type?: string;
  pageCategory?: string;
}

export interface Report {
  gaId: string;
  visitorId: string;
  sessionId: string;
  timestamp: number;
  eventName: string;
  eventData: {
    event: string;
    experiment: string;
    geo_city: string;
    geo_country_code: string;
    geo_country_name: string;
    geo_postal_code: string;
    geo_region_name: string;
    mx_notrack: boolean;
    mx_showroom: boolean;
    mycsUserEmail?: string;
    mycsUserId?: number;
    originalPageURL: string;
    pageURL: string;
    utm_campaign?: string;
    utm_content?: string;
    utm_medium?: string;
    utm_source?: string;
    utm_term?: string;
    uuid?: string;
    variant: number;
    referrer: string;
    gclid: null | string;
  };
}

export class ReportingAPIService {
  static readonly visitorIdStorageKey = 'visitorId';
  static get defaultState() {
    return {
      serverTimeDifference: 0,
      gaId: '',
      visitorId: '',
    };
  }

  gclid: null | string = null;

  state$ = new BehaviorSubject(ReportingAPIService.defaultState);
  get state() {
    return this.state$.value;
  }
  // the events are being buffered while
  // the service is initialising (it takes time)
  event$: ReplaySubject<Event> = new ReplaySubject(100);

  async init() {
    this.gclid = new URLSearchParams(location.search).get('gclid');

    const serverTimeDifference = await this.getServerTimeDiff().catch(
      (error) => {
        Logger.error('Could not get server time diffence:', error);

        return ReportingAPIService.defaultState.serverTimeDifference;
      }
    );

    this.setState({ serverTimeDifference });

    if (CookieService.state.cookieConsent?.statistics) {
      await this.initAnonymizedUserID();
    } else if (!CookieService.state.isInitialized) {
      CookieService.state$
        .pipe(
          filter((state) => state.isInitialized),
          take(1)
        )
        .subscribe((state) => {
          if (state.cookieConsent?.statistics) {
            this.initAnonymizedUserID();
          }
        });
    }

    // The service is initialized, initialize reporting.
    this.initReporting();
  }

  async initAnonymizedUserID() {
    const visitorId =
      LocalStorage.get(ReportingAPIService.visitorIdStorageKey) || uuidV4();
    LocalStorage.set(ReportingAPIService.visitorIdStorageKey, visitorId);

    const gaId = await this.getGaId()
      .toPromise()
      .catch((error) => {
        Logger.error('Could not get gaId:', error);

        return '';
      });

    this.setState({
      visitorId,
      gaId,
    });
  }

  initReporting() {
    // "createReportStream" logic was separated to improve testability.
    this.createReportStream()
      .pipe(
        bufferTime(500), // send the reports in chunks every 0.5s
        filter((reports) => reports.length > 0),
        mergeMap((reports) =>
          this.postReports(reports).pipe(
            catchError((error) => {
              Logger.error('Failed to post reports:', error);
              // an error should not break the pipeline
              return EMPTY;
            })
          )
        )
      )
      .subscribe({
        error: (error) =>
          Logger.error('An error occurred in report pipeline:', error),
      });
  }

  createReportStream(): Observable<Report> {
    return this.event$.pipe(
      withLatestFrom(this.state$, GeolocationService.getLocationSubject()),
      map(([event, state, locationData]) => ({
        gaId: state.gaId,
        visitorId: state.visitorId,
        sessionId: SessionService.getSID(),
        timestamp: Date.now() + state.serverTimeDifference,
        eventName: event.event,
        eventData: {
          ...event,
          gclid: this.gclid,
          geo_country_name: (locationData as any).country_name,
          geo_country_code: (locationData as any).country_code_iso3166alpha2,
          geo_region_name: (locationData as any).region_name,
          geo_city: (locationData as any).city,
          geo_postal_code: (locationData as any).postal_code,
          referrer: document.referrer,
        },
      }))
    );
  }

  async getServerTimeDiff() {
    const timestamp = await FetchAPI.get(
      UrlProviderService.getTimestampApiUrl()
    );
    const serverTime = parseInt(timestamp, 10);
    const clientTime = Date.now();
    const timeDiff = serverTime - clientTime;
    // discard the diff if it's less than 1 min
    return Math.abs(timeDiff) >= 60000 ? timeDiff : 0;
  }

  getGaId(): Observable<string> {
    return interval(500).pipe(
      take(10), // trying for 5s
      filter(() => window.ga?.getAll?.().length > 0),
      take(1),
      map(() => window.ga.getAll()[0].get('clientId')),
      defaultIfEmpty('')
    );
  }

  handleEvent(e: Event) {
    this.event$.next(e);
  }

  setState(state: Partial<typeof ReportingAPIService.defaultState>) {
    this.state$.next({ ...this.state$.value, ...state });
  }

  /**
   * WARNING it retries to send a req on a failure (2 attempts)
   */
  postReports(reports: Report[]): Observable<{ message: string }> {
    return asyncRequest$({
      url: UrlProviderService.getReportingApiUrl(),
      method: 'POST',
      body: JSON.stringify(reports),
    }).pipe(
      retryWhen((error$) => {
        const retry$ = error$.pipe(
          // retry for 2 times with a second between the retries
          delay(1000),
          take(2),
          // "share" with lastRetry$
          share()
        );
        const lastRetry$ = retry$.pipe(
          last(),
          // In order to push the last error to the major stream it must be "thrown"
          // in the "retryWhen" cb stream. Will happen only if retry$ "completes"
          mergeMap((error) => throwError(error))
        );

        return merge(retry$, lastRetry$);
      })
    );
  }
}

export default new ReportingAPIService();
