import isMatch from 'lodash/isMatch';
import wardrobeConfig from '../../config/wardrobe-config';
import Component from '../../components/Component';
import RendererService from '../../services/RendererService';

/**
 * WardrobeElement
 *
 * @class WardrobeElement
 * @extends {Component}
 */
export default class WardrobeElement extends Component {
  /**
   * Add/Update props based on their characteristic
   *
   * @param {any} props
   * @param {any} element
   * @returns {any} new props
   */
  static hardcode(props, element) {
    const newProps: any = {};

    // Custom shape force assets
    if (['backwall', 'drawer_front_glass_glass'].includes(element.section)) {
      newProps.shape = element.scene_object_name;
      newProps.material = element.color;
    }

    // Add small gap around drawer front with distance
    if (
      ['drawer_front_module', 'drawer_front_glass_module'].includes(
        element.section
      )
    ) {
      newProps.margin_x = [0.3, 0.3];
    }

    if (
      isMatch(element, {
        section: 'handles',
        type: 'normal_wooden',
      })
    ) {
      newProps.size_z = 46.7;
      // If we have already set a size in x for the handle (label mode), we do not change the size
      newProps.size_x = props.size_x ? props.size_x : 5.32;
      // newProps.rotation_y = 180;
      newProps.rotation_z = 180;
    }

    if (['aluminum_brushed', 'aluminum_polished'].includes(element.color)) {
      newProps.material = 'metal_stainless';
    }

    // Mapping to fetch the new wood laminate
    if (['oak', 'walnut', 'wenge'].includes(element.color)) {
      newProps.material = element.color;
    }

    return newProps;
  }

  /**
   * Mapping of some properties in order to fetch the SKU related to the shape.
   *
   * @param {object} props
   * @returns {object} props to fetch the SKUs
   */
  static mappingShape(props) {
    // Custom shape: no sku
    if (['backwall', 'drawer_front_glass_glass'].includes(props.section)) {
      return null;
    }

    // Mapping to fetch the new wood laminate
    if (['oak', 'walnut', 'wenge'].includes(props.color)) {
      props.color = `${props.color}_laminate`;
    }

    return props;
  }

  /**
   * Mapping of some properties in order to compute
   * - shape
   * - material
   *
   * @param {object} element
   * @returns {object} assets override
   */
  static mappingAsset(element) {
    const newProps: any = {};

    if (['aluminum_brushed', 'aluminum_polished'].includes(element.color)) {
      newProps.material = 'metal_stainless';
    }

    // Mapping to fetch the new wood laminate
    if (['oak', 'walnut', 'wenge'].includes(element.color)) {
      newProps.material = `${element.color}_effect`;
    }

    return newProps;
  }

  /**
   * Render an element
   *
   * @returns {JSX.Element}
   */
  render() {
    const props = Component.decorateElement(
      this.props,
      wardrobeConfig.furnitureType,
      WardrobeElement,
      {
        custom: true,
        element: true,
        assets: false,
        hardcode: true,
        skus: false,
        extend: false,
      }
    );
    return <shape {...props} />;
  }
}
