import _isObject from 'lodash/isObject';
import { BehaviorSubject } from 'rxjs';

import {
  CfHelpListing,
  CfRibbon,
  CfShowroomsList,
  CfPaymentProvidersConfig,
  CfPdpImages,
  CfMaterialPdpImage,
  CfMaterial,
  CfPagePdp,
  CfPageMain,
  CfPageSamplebox,
  CfUnusedPageStaticAkaFaq,
  CfHeaderMenu,
  CfMetaTags,
  CfPageConfigurator,
  CfSetDiscounts,
  CfResponsiveImage,
  CfLaminatesConfig,
  CfAbTestConfig,
  CfCustomerTestimonial,
  CfAsset,
} from '@mycs/contentful';
import { ContentfulClients } from './ContentfulClients';
import cfg from 'mycs/config';
import TimerStore from 'mycs/shared/stores/TimerStore/TimerStore';
import UrlProviderService from 'mycs/shared/services/UrlProviderService/UrlProviderService';
import { Asset } from 'contentful';

export interface ShoppingCartPage extends CfPageMain {
  growMyTreeBanner?: CfResponsiveImage;
}

export interface CfChatData {
  validUrls: string[];
  retriggerDelay: number;
  autoDisplayAfter: number;
}

interface CfHelpConfigData {
  email: string;
  phoneNumber: string;
  chat: CfChatData;
}

type CfLocUrl = {
  [locale: string]: string;
};

export type CfEnhancedMetaTags = CfMetaTags & {
  fb_image?: string;
  locUrl?: CfLocUrl;
};

type GetEntriesParams = {
  locale: string;
  resolveLinks?: boolean;
  include?: number;
  limit?: number;
  content_type: string;
  [key: string]: any;
};

export class ContentfulServiceClass {
  cache: any;
  imagesUrlRe: RegExp;
  videosUrlRe: RegExp;
  private clients: ContentfulClients = new ContentfulClients();
  _state$ = null as null | BehaviorSubject<
    typeof ContentfulServiceClass.defaultState
  >;

  /**
   * Creates an instance of ContentfulService.
   */
  constructor() {
    this.cache = {};

    this.imagesUrlRe = new RegExp(
      `(https?:)?//(?:${cfg.contentful.imagesHosts
        .map((host) => host.split('.').join('\\.'))
        .join('|')})`,
      'g'
    );

    this.videosUrlRe = new RegExp(
      `(https?:)?//(?:${cfg.contentful.videosHosts
        .map((host) => host.split('.').join('\\.'))
        .join('|')})`,
      'g'
    );
  }

  get state$() {
    if (!this._state$) {
      // Attempts to restore the state generated by SSR app.
      const preloadedState:
        | undefined
        | typeof ContentfulServiceClass.defaultState =
        typeof window === 'undefined'
          ? undefined
          : window.__CONTENTFUL_SERVICE_PRELOADED_STATE__;
      this._state$ = new BehaviorSubject({
        ...ContentfulServiceClass.defaultState,
        ...preloadedState,
        preloaded: !!preloadedState,
      });
      if (preloadedState) {
        delete window.__CONTENTFUL_SERVICE_PRELOADED_STATE__;
      }
    }

    return this._state$;
  }

  /** Service default state */
  static get defaultState() {
    return {
      preloaded: false,
      quantityVolumeDiscounts: [] as CfSetDiscounts[],
    };
  }

  /** Service state getter */
  get state() {
    return this.state$.value;
  }

  async init(locale: string, countryCode: string) {
    await Promise.all([this.initQuantityVolumeDiscounts(locale, countryCode)]);
  }

  /**
   * Convert ISO code to Contentful format.
   * E.g. de_DE -> de-DE
   */
  convertLocale(locale: string): string {
    return locale.replace('_', '-');
  }

  /**
   * Replace asset URLs with our CDN URLs
   */
  _proxyImage(field: string): string {
    const imageCacheUrl = UrlProviderService.getCfImagesUrl();
    return field.replace(this.imagesUrlRe, imageCacheUrl);
  }

  /**
   * Replace video assets URLs with our CDN URLs
   */
  _proxyVideo(field: string): string {
    const videosCacheUrl = UrlProviderService.getCfVideosUrl();

    return field.replace(this.videosUrlRe, videosCacheUrl);
  }

  /**
   * Parse a JSON when needed
   */
  _parseJsonField(field: string): object | null {
    if (field[0] === '{' || field[0] === '[') {
      try {
        return JSON.parse(field);
      } catch (e) {
        return null;
      }
    }
    return null;
  }

  /**
   * Check for an error entry (e.g. an unresolvable link)
   */
  _isErrorNode(node: any): boolean {
    return node == null || (node.sys && !(node.fields || node.items));
  }

  /**
   * Transform Cf's response
   */
  _transformCfData(node: any): any {
    // Clean up error entries
    if (this._isErrorNode(node)) return null;

    // Replace asset URLs with our CDN URLs
    if (typeof node === 'string') {
      if (this.videosUrlRe.test(node)) {
        return this._proxyVideo(node);
      }

      return this._parseJsonField(node) || this._proxyImage(node);
    }

    // Continue the recursion for each item in the array
    if (Array.isArray(node)) {
      return node
        .filter((item) => !this._isErrorNode(item))
        .map((node) => this._transformCfData(node));
    }

    // Continue the recursion on each object key
    if (_isObject(node)) {
      const { sys } = node as any;

      // get contentType
      if (sys && sys.contentType) {
        (node as any)._contentType = sys.contentType.sys.id;
      }

      node = Object.keys(node).reduce((acc, key) => {
        if (this.shouldIgnoreNode(key, node)) {
          return acc;
        }
        acc[key] = this._transformCfData(node[key]);
        return acc;
      }, {} as any);

      // Flatten fields
      const { fields } = node;
      if (fields) {
        Object.keys(fields).forEach((key) => (node[key] = fields[key]));
        delete node.fields;
      }

      // Flatten the sys object
      if (sys) {
        node._id = sys.id;
        delete node.sys;
      }

      // Flatten the file object
      const { file } = node;
      if (file) {
        node.url = file.url;
        if (file.details && file.details.image) {
          node.width = file.details.image.width;
          node.height = file.details.image.height;
        }
        delete node.file;
      }
    }

    return node;
  }

  private shouldIgnoreNode(key: string, node: any) {
    const isMetadataWithEmptyTags =
      key === 'metadata' &&
      Object.keys(node[key]).length == 1 &&
      'tags' in node[key] &&
      node[key].tags.length === 0;

    return isMetadataWithEmptyTags;
  }

  /**
   * Get a small image url from Contentful
   */
  getThumbUrl(imageUrl?: string, thumbSize = 95): string {
    if (!imageUrl) return '';

    const clearUrl = imageUrl.replace(/\?.+$/, '');
    return `${clearUrl}?q=90&fit=fill&w=${thumbSize}&h=${thumbSize}`;
  }

  /**
   * Get entries from Contentful
   */
  getEntries(
    params: GetEntriesParams,
    countryCode: string,
    cache = false
  ): Promise<any> {
    params = Object.assign(
      {
        resolveLinks: true,
        include: 10,
      },
      params
    );

    if (params.locale) {
      params.locale = this.convertLocale(params.locale);
    }

    let cacheKey = '';
    if (cache) {
      cacheKey = JSON.stringify(params);
      if (this.cache[cacheKey]) {
        return Promise.resolve(this.cache[cacheKey]);
      }
    }

    return this.clients
      .getFrontendSpaceClient(countryCode)
      .getEntries(params)
      .then((data: any) => this._transformCfData(data))
      .then((data: any) => {
        if (cache) {
          this.cache[cacheKey] = data;
        }
        return data;
      });
  }

  /**
   * Get entries from Contentful
   */
  getLatestEntry(
    params: any,
    countryCode: string,
    cache = false
  ): Promise<any> {
    params = Object.assign(
      {
        limit: 1,
        order: '-sys.updatedAt,-sys.createdAt',
      },
      params
    );

    return this.getEntries(params, countryCode, cache).then((data) =>
      data && data.items ? data.items[0] : null
    );
  }

  /**
   * Get oldest entry from Contentful
   */
  getOldestEntry(
    params: any,
    countryCode: string,
    cache = false
  ): Promise<any> {
    params = Object.assign(
      {
        limit: 1,
        order: 'sys.createdAt',
      },
      params
    );

    return this.getEntries(params, countryCode, cache).then((data) =>
      data && data.items ? data.items[0] : null
    );
  }

  /**
   * Get a specific asset from Contentful.
   */
  getCFAsset(
    assetId: string,
    countryCode: string,
    cache = false
  ): Promise<any> {
    let cacheKey = '';
    if (cache) {
      cacheKey = assetId;
      if (this.cache[cacheKey]) {
        return Promise.resolve(this.cache[cacheKey]);
      }
    }

    return this.clients
      .getFrontendSpaceClient(countryCode)
      .getAsset(assetId)
      .then((data: any) => this._transformCfData(data))
      .then((data: any) => {
        if (cache) {
          this.cache[cacheKey] = data;
        }
        return data;
      });
  }

  getCFAssetByLocale(
    assetId: string,
    countryCode: string,
    locale: string,
    cache = false
  ): Promise<CfAsset> {
    let cacheKey = '';
    if (cache) {
      cacheKey = assetId;
      if (this.cache[cacheKey]) {
        return Promise.resolve(this.cache[cacheKey]);
      }
    }
    const getLocaleCode = (locale: string) => locale.replace('_', '-');

    return this.clients
      .getFrontendSpaceClient(countryCode)
      .getAsset(assetId, { locale: getLocaleCode(locale) })
      .then((data: Asset) => this._transformCfData(data))
      .then((data: CfAsset) => {
        if (cache) {
          this.cache[cacheKey] = data;
        }
        return data;
      });
  }

  getHeader(locale: string, countryCode: string): Promise<CfHeaderMenu> {
    const headerParams = {
      locale,
      content_type: 'headerMenu',
    };

    return this.getLatestEntry(headerParams, countryCode, true);
  }

  getRibbons(locale: string, countryCode: string): Promise<CfRibbon[]> {
    const ribbonParams = {
      locale,
      content_type: 'ribbon',
      'fields.activated': true,
    };

    return this.getEntries(ribbonParams, countryCode, true).then(
      (d) => d.items
    );
  }

  /**
   * Make a request for an LP directly to CF
   */
  loadAllPageData<T>(
    url: string,
    type: string,
    locale: string,
    countryCode: string
  ): Promise<T> {
    return this.getLatestEntry(
      {
        locale,
        content_type: type,
        'fields.url': url.toLowerCase(),
      },
      countryCode
    ).then((page) => {
      if (!page) {
        throw new Error(`No page data for ${type} ${url}`);
      }

      return page;
    });
  }

  /**
   * Get landing page by URL
   */
  getLandingPage<T>(
    url: string,
    lpType = 'mainPage',
    locale: string,
    countryCode: string
  ): Promise<{ page: T; statusCode: number }> {
    TimerStore.addTime('start_getLandingPage');

    return this.loadAllPageData<T>(url, lpType, locale, countryCode).then(
      (
        page: T & {
          locUrl?: {
            [locale: string]: string;
          };
          metaLocUrl?: {
            [locale: string]: string;
          };
          originalUrl?: string;
          headerImages?: any;
          metaTags?: CfEnhancedMetaTags;
          metaTitle?: string;
          metaDescription?: string;
        }
      ) => {
        // Used for tracking
        if (
          page.locUrl &&
          !['faq', 'pageConfigurator', 'sampleboxPage'].includes(lpType)
        ) {
          page.originalUrl = page.locUrl[cfg.contentful.defaultLocale];
        }

        // Meta tags
        const { headerImages } = page;
        const { metaTags, metaTitle, metaDescription } = page;

        if (metaTitle || metaDescription) {
          page.metaLocUrl = page.locUrl;
        }

        if (metaTags) {
          metaTags.locUrl = page.locUrl;

          const firstImage =
            headerImages && headerImages[0] && headerImages[0].image;
          if (firstImage) {
            metaTags.fb_image = UrlProviderService.getAbsoluteAssetUrl(
              firstImage.url
            );
          }
        }

        // The HTTP status code
        const statusCode = 200;

        TimerStore.addTime('GetLandingPage', 'start_getLandingPage');

        return {
          page,
          statusCode,
        };
      }
    );
  }

  getCustomPdpImages(
    locale: string,
    countryCode: string
  ): Promise<CfPdpImages[]> {
    return this.getEntries(
      {
        locale,
        limit: 1000,
        content_type: 'pdpImages',
      },
      countryCode
    ).then((data) => data.items);
  }

  /**
   * Get a translation id by key.
   */
  getTranslationId(countryCode: string, key?: string): Promise<any> {
    return this.getLatestEntry(
      {
        content_type: '4bddVq9hEk0Yi2w4qWOwSi',
        'fields.key': key,
      },
      countryCode
    ).then((data) => data._id);
  }

  /**
   * Get the edit link by entry id.
   */
  getEditLink(id: string): string {
    return `https://app.contentful.com/spaces/${cfg.contentful.spaces.frontend.space}/entries/${id}`;
  }

  /**
   * Get all the materials
   */
  getMaterials(locale: string, countryCode: string): Promise<CfMaterial[]> {
    return this.getEntries(
      {
        locale,
        limit: 1000,
        content_type: 'material',
      },
      countryCode
    ).then((data) => data.items);
  }

  /**
   * Get the default showroom list content type (oldest created)
   */
  getShowroomsList(
    locale: string,
    countryCode: string
  ): Promise<CfShowroomsList> {
    return this.getOldestEntry(
      {
        locale,
        content_type: 'showroomsList',
      },
      countryCode
    );
  }

  /**
   * Get the default showroom list content type (oldest created)
   */
  getCustomerTestimonialsList(
    locale: string,
    countryCode: string
  ): Promise<CfCustomerTestimonial[]> {
    return this.getEntries(
      {
        locale,
        content_type: 'seoProductsList',
      },
      countryCode
    ).then(
      (resp) =>
        resp.items.sort(
          (item1: CfCustomerTestimonial, item2: CfCustomerTestimonial) =>
            new Date(item2.date || '').getTime() -
            new Date(item1.date || '').getTime()
        ) || []
    );
  }

  /**
   * Get image preview for PDP
   */
  getImagePDP(
    furnitureType: string,
    color: string,
    locale: string,
    countryCode: string
  ): Promise<CfMaterialPdpImage[]> {
    return this.getEntries(
      {
        locale,
        content_type: 'materialPdpImage',
        'fields.furnitureType': furnitureType,
        'fields.material.sys.contentType.sys.id': 'material',
        'fields.material.fields.color': color,
      },
      countryCode
    ).then((data) => data.items);
  }

  /**
   * Get Help content per category page (configurators or LPs)
   */
  getHelpContent(
    category: string,
    locale: string,
    countryCode: string
  ): Promise<CfHelpListing> {
    return this.getLatestEntry(
      {
        content_type: 'helpListing',
        'fields.category': category,
        locale,
      },
      countryCode
    );
  }

  /**
   * Get a product details page per furniture type
   */
  getPdp(
    furnitureType: string,
    subtype: string | undefined | null,
    locale: string,
    countryCode: string
  ) {
    let url = `${furnitureType}`;

    if (subtype) {
      url = `${furnitureType} ${subtype}`;
    }

    return this.getLandingPage<CfPagePdp>(url, 'pdp', locale, countryCode);
  }

  getStaticPage(url: string, locale: string, countryCode: string) {
    return this.getLandingPage<CfUnusedPageStaticAkaFaq>(
      url,
      'faq',
      locale,
      countryCode
    );
  }

  /**
   * Get a static page content
   * @param {string} url – URL of the static page
   */
  async getShoppingCartPage(
    url: string,
    locale: string,
    countryCode: string,
    withGrowMyTreeBanner = false
  ) {
    const shoppingCartpage = await this.getLandingPage<CfPageMain>(
      url,
      'mainPage',
      locale,
      countryCode
    );

    if (!withGrowMyTreeBanner) return shoppingCartpage;
    const growMyTreeBannerSlug = 'Grow My Tree Banner';

    const growMyTreeBanner: CfResponsiveImage = await this.getLatestEntry(
      {
        content_type: 'responsiveImage',
        'fields.slug': growMyTreeBannerSlug,
      },
      countryCode
    );

    return {
      ...shoppingCartpage,
      page: {
        ...shoppingCartpage.page,
        growMyTreeBanner: growMyTreeBanner,
      },
    };
  }

  /**
   * Get a static page content
   * @param {string} url – URL of the static page
   */
  getMainPage(url: string, locale: string, countryCode: string) {
    return this.getLandingPage<CfPageMain>(
      url,
      'mainPage',
      locale,
      countryCode
    );
  }

  /**
   * Get a configurator page
   */
  getConfiguratorPage(url: string, locale: string, countryCode: string) {
    return this.getLandingPage<CfPageConfigurator>(
      url,
      'pageConfigurator',
      locale,
      countryCode
    ).then((lp) => {
      // Delete deprecated materialCategories for test
      delete lp.page.materialCategories;
      return lp;
    });
  }

  /**
   * Get the Samplebox page
   */
  getSampleboxPage(url: string, locale: string, countryCode: string) {
    return this.getLandingPage<CfPageSamplebox>(
      url,
      'sampleboxPage',
      locale,
      countryCode
    );
  }

  get404Page(locale: string, countryCode: string) {
    return this.getLandingPage<CfPageMain>(
      '/404',
      'mainPage',
      locale,
      countryCode
    ).then((data) => ({ ...data, statusCode: 404 }));
  }

  /**
   * Get Nyce config.
   * Nyce config is stored in the "Internal" space
   */
  async getNyceConfig(countryCode: string) {
    const cfData = await this.clients
      .getInternalSpaceClient(countryCode)
      .getEntries({
        content_type: 'nyceConfig',
      });
    const data = this._transformCfData(cfData);
    // CF returns an array of entries per type
    const [config] = data.items;
    const env = ['staging', 'production'].includes(process.env.APP_STAGE || '')
      ? 'production'
      : 'dev';

    return config[env];
  }

  /**
   * Get A/B test configs
   */
  getABTestConfigs(
    locale: string,
    countryCode: string
  ): Promise<CfAbTestConfig[]> {
    return this.getEntries(
      {
        locale,
        content_type: 'abTestConfig',
      },
      countryCode
    ).then((resp) => resp?.items || []);
  }

  /**
   * Get Help config settings
   */
  getHelpConfig(
    locale: string,
    countryCode: string
  ): Promise<CfHelpConfigData> {
    return this.getLatestEntry(
      {
        locale,
        content_type: 'helpConfig',
      },
      countryCode
    ).then((data) => {
      const env = cfg.environment === 'production' ? 'prod' : 'dev';
      return {
        ...data[`data_${env}`],
        email: data.email,
        phoneNumber: data.phoneNumber,
      };
    });
  }

  getMaterialConfigs(locale: string, countryCode: string) {
    return this.getEntries(
      {
        locale,
        content_type: 'laminatesConfig',
      },
      countryCode
    ).then((resp) => resp?.items as CfLaminatesConfig[]);
  }

  async initQuantityVolumeDiscounts(locale: string, countryCode: string) {
    const discounts = await this.getQuantityVolumeDiscounts(
      locale,
      countryCode
    );
    this.state$.next({ ...this.state, quantityVolumeDiscounts: discounts });
  }

  /**
   * Filter active campaigns by start and end date
   */
  isActiveCampaign(campaign: any): boolean {
    const currentDate = new Date();
    return (
      currentDate > new Date(campaign.startDate) &&
      currentDate < new Date(campaign.endDate)
    );
  }

  /**
   * Get the payment providers for checkout
   */
  getPaymentProvidersConfig(
    locale: string,
    countryCode: string
  ): Promise<CfPaymentProvidersConfig[]> {
    return this.getEntries(
      {
        locale,
        limit: 1000,
        // an unused content model was reused on Contentful
        content_type: 'nyceConfig',
      },
      countryCode
    ).then((data) => data.items);
  }

  /**
   * Get quantity and volume discount entries.
   */
  getQuantityVolumeDiscounts(
    locale: string,
    countryCode: string
  ): Promise<Array<CfSetDiscounts>> {
    return this.getEntries(
      {
        locale,
        content_type: 'setDiscounts',
      },
      countryCode
    ).then((data) => data.items);
  }
}

export default new ContentfulServiceClass();
