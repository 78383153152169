import Logger from 'mycs/shared/services/Logger';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

declare global {
  interface Window {
    Trustpilot?: any;
  }
}

const trustPilotScriptId = 'trust-pilot-widget';

interface State {
  inited: boolean;
  canLoadTrustPilotScript: boolean;
}

class TrustPilotService {
  static get defaultState() {
    return {
      inited: false,
      canLoadTrustPilotScript: false,
    };
  }

  state$: BehaviorSubject<State> = new BehaviorSubject(
    TrustPilotService.defaultState
  );

  get state() {
    return this.state$.value;
  }

  private updateState(newState: Partial<State>) {
    this.state$.next({
      ...this.state,
      ...newState,
    });
  }

  init() {
    const trustPilotScript = document.createElement('script');
    trustPilotScript.type = 'text/javascript';
    trustPilotScript.id = trustPilotScriptId;
    trustPilotScript.src =
      '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    trustPilotScript.async = true;

    trustPilotScript.addEventListener('load', () => {
      this.updateState({
        inited: true,
        canLoadTrustPilotScript: Boolean(window.Trustpilot),
      });
    });

    trustPilotScript.addEventListener('error', (error) => {
      Logger.error('Failed to load TrustPilot: ', error);
      this.updateState({ inited: true, canLoadTrustPilotScript: false });
    });

    document.head.appendChild(trustPilotScript);
  }

  initWidget(element: HTMLDivElement) {
    this.state$
      .pipe(
        filter((state) => state.inited),
        take(1)
      )
      .subscribe((state) => {
        // Wait until script is inited and load widget if possible
        if (state.canLoadTrustPilotScript) {
          window.Trustpilot.loadFromElement(element);
        } else {
          Logger.error(
            'Failed to load TrustPilot widget because of missing script.'
          );
        }
      });
  }
}
export default new TrustPilotService();
