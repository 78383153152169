import { createContext, useContext, useMemo } from 'react';

import LocationUtils from 'mycs/shared/utilities/LocationUtils/LocationUtils';

export const LocaleContext = createContext({
  locale: 'de_DE',
  countryCode: 'de',
  baseUrl: 'https://mycs.com',
});

export function useLocale() {
  const { locale, countryCode, baseUrl } = useContext(LocaleContext);
  const languageCode = useMemo(
    () => LocationUtils.getDomainLanguageShort(countryCode, locale),
    [countryCode, locale]
  );

  return {
    locale,
    countryCode,
    languageCode,
    baseUrl,
  };
}
