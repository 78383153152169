import { RelativeUrlService } from 'mycs/shared/services/RelativeUrlService';
import cfg from 'mycs/config';
import DictionaryUtils from 'mycs/shared/utilities/DictionaryUtils/DictionaryUtils';

/**
 * Location Utils
 * Used to determine the user's location based on domain
 */
class LocationUtils {
  static getPageType(locale: string, pathname: string, pageData: any) {
    if (RelativeUrlService.isHomePage(pathname, locale)) {
      return 'homePage';
    }

    if (RelativeUrlService.isCartPage(pathname, locale)) {
      return 'cartPage';
    }

    if (RelativeUrlService.isCheckoutPage(pathname, locale)) {
      return 'checkoutPage';
    }

    if (RelativeUrlService.isFaqPage(pathname, locale)) {
      return 'faqPage';
    }

    if (RelativeUrlService.isShowroomPage(pathname, locale)) {
      return 'showroomPage';
    }

    if (RelativeUrlService.isPdpPage(pathname, locale)) {
      return 'productDisplayPage';
    }

    if (RelativeUrlService.isConfiguratorPage(pathname, locale)) {
      return 'configuratorPage';
    }

    if (LocationUtils.isSubCategoryPage(pageData)) {
      return 'subCategoryPage';
    }

    return;
  }

  private static isSubCategoryPage(pageData: any) {
    if (!pageData) return false;

    const { uuids, subcategories, features, categoryDetails, jalousie } =
      pageData;

    return !!(
      uuids &&
      !subcategories &&
      (features || categoryDetails || jalousie)
    );
  }

  /**
   * Format number according to locale
   */
  static toLocale(n: number, fractionDigits = 0, locale: string) {
    const shortLang = locale.split('_')[0];
    const toReturn = Number(n).toLocaleString(shortLang, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    });
    return toReturn;
  }

  /**
   * Translate Pathname.
   *
   * @param {string} locationPathname e.g. "/shelf/5FwOntzGb?tab=styles" or "/"
   * @param {string} languageCode e.g. "de_CH"
   * @param {boolean} reverseTranslation "/regal" -> "/shelf"
   * @returns {string} translated path e.g. "/regal/5FwOntzGb?tab=styles"
   */
  static translatePathname(
    locationPathname: string,
    languageCode: string,
    reverseTranslation = false
  ) {
    // Basic validaion.
    if (!locationPathname.includes('/')) {
      throw Error('invalid pathname');
    }

    if (locationPathname === '/') {
      return '/';
    }

    return locationPathname
      .split('/')
      .map((part) => {
        // '/shelf'.split('/') = ["", "shelf"]. Do not pass empty string for translation.
        if (!part) {
          return '';
        }

        const definition = DictionaryUtils.lookUp(
          part,
          languageCode,
          reverseTranslation
        );
        // Leave part as it is if no definition was found e.g. for uuid part "5FwOntzGb"
        return definition || part;
      })
      .join('/');
  }

  /**
   * Translate Search.
   *
   * @param {string} locationSearch e.g. "?tab=masse-und-fuesse" or ""
   * @param {string} languageCode e.g. "de_CH"
   * @param {boolean} reverseTranslation
   * @returns {string} translated path e.g. "?tab=dimensions-legs"
   */
  static translateSearch(
    locationSearch: string,
    languageCode: string,
    reverseTranslation = false
  ) {
    if (!locationSearch) {
      return '';
    }

    // Basic validaion.
    if (!locationSearch.includes('?') || !locationSearch.includes('=')) {
      throw Error('invalid search');
    }

    return `?${locationSearch
      .replace('?', '')
      .split('&')
      .map((part) => {
        const [key, value] = part.split('=');
        // Leave part as it is if no definition was found e.g. for uuid=5FwOntzGb
        const keyDefinition =
          (key &&
            DictionaryUtils.lookUp(key, languageCode, reverseTranslation)) ||
          key;
        const valueDefinition =
          (value &&
            DictionaryUtils.lookUp(value, languageCode, reverseTranslation)) ||
          value;
        return `${keyDefinition}=${valueDefinition}`;
      })
      .join('&')}`;
  }

  /**
   * Compute Country Code.
   * The code is inferred based on window.location.hostname.
   *
   * @param {string} locationHostname e.g. 'de.mycs.ninja'
   * @returns {string} Language code e.g. 'de'
   */
  static computeCountryCode(locationHostname: string) {
    if (['localhost', 'mycs.local'].includes(locationHostname)) {
      return 'de';
    }

    const [firstHostnamePart] = locationHostname.split('.');
    if (!cfg.languages[firstHostnamePart]) {
      throw Error('unrecognized domain');
    }

    return firstHostnamePart;
  }

  /**
   * Compute Language Code.
   * The language is inferred based on window.location.hostname and window.location.pathname
   *
   * @param {string} locationHostname e.g. 'de.mycs.ninja'
   * @param {string} locationPathname e.g. '/ueber-unsere-konfiguratoren'
   * @returns {string} Language code e.g. 'de_DE' - language_countryCode
   */
  static computeLanguageCode(
    locationHostname: string,
    locationPathname: string
  ) {
    const [firstHostnamePart] = locationHostname.split('.');
    const [, firstPathnamePart] = locationPathname.split('/');
    let domainLanguageConfig = cfg.languages[firstHostnamePart];
    if (!domainLanguageConfig) {
      if (['localhost', 'mycs.local'].includes(locationHostname)) {
        domainLanguageConfig = cfg.languages.de;
      } else {
        throw Error('unrecognized domain: ' + locationHostname);
      }
    }

    const domainLanguages = Object.keys(domainLanguageConfig);
    const language =
      // For now only CH domain has a pathname language param.
      domainLanguages.find(
        (languageCode) =>
          domainLanguageConfig[languageCode].short === firstPathnamePart
      ) || domainLanguages[0]; // Default to the 1st domain language.

    return language;
  }

  static getLocaleFromUrl(url: string) {
    const parsedUrl = new URL(url);

    return this.computeLanguageCode(parsedUrl.hostname, parsedUrl.pathname);
  }

  static getCountryCodeFromUrl(url: string) {
    const parsedUrl = new URL(url);

    return this.computeCountryCode(parsedUrl.hostname);
  }

  static hasLanguagePathname(pathname: string) {
    const pathStep = pathname.split('/').find((step) => step !== '');
    const lang = pathStep?.length === 2 ? pathStep : null;

    return lang && lang in cfg.languages;
  }

  static getDomainLanguageShort(countryCode: string, locale: string): string {
    return cfg.languages[countryCode][locale].short;
  }

  static getOrderIDFromSearch(searchString: string) {
    const searchParams = new URLSearchParams(searchString);

    return searchParams.get('orderID');
  }

  static getUserIDFromSearch(searchString: string) {
    const searchParams = new URLSearchParams(searchString);
    const userIDString = searchParams.get('mx');

    return userIDString ? parseInt(userIDString) : undefined;
  }

  static getUserTokenFromSearch(searchString: string) {
    const searchParams = new URLSearchParams(searchString);

    return searchParams.get('mxUserToken');
  }

  static getPaymentRedirectResult(searchString: string) {
    const searchParams = new URLSearchParams(searchString);

    return searchParams.get('paymentRedirectResult');
  }

  static removePaymentRedirectResult(searchString: string) {
    const searchParams = new URLSearchParams(searchString);
    searchParams.delete('paymentRedirectResult');

    return searchParams.toString();
  }

  static isMyDesignsPage(pathname: string, locale: string) {
    const originalPathname = LocationUtils.translatePathname(
      pathname,
      locale,
      true
    );

    return originalPathname === '/my-designs';
  }
}

export default LocationUtils;
