import { PureComponent } from 'react';
import classNames from 'classnames';
import _isEqual from 'lodash/isEqual';

import { useLocale } from 'mycs/shared/state/LocaleContext';
import Alink from 'mycs/shared/components/Alink/Alink';
import Button from 'mycs/shared/components/Button/Button';
import Input from 'mycs/shared/components/Input/Input';
import Localize from 'mycs/shared/components/Localize/Localize';
import Logger from 'mycs/shared/services/Logger';
import Modal from 'mycs/shared/components/Modal/Modal';
import { useUser } from 'mycs/hooks/useUser';
import { useUserTools } from 'mycs/hooks/useUserTools';
import { trackZendeskFormEvent } from 'mycs/services/UserAnalyticsService';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';

import styles from './ContactForm.scss';
import { createRequest as createZendeskRequest } from 'mycs/api/ZendeskAPI';

type ValidationRules = {
  [key: string]: {
    isRequired: boolean;
    minLength?: number;
    maxLength?: number;
    pattern?: RegExp;
    errorMessage?: string;
  };
};

type Props = {
  isOpen: boolean;
  requestClose: () => void;
  phoneNumber?: string;
  showFooter?: boolean;
  modalHeader?: string;
  formType?: string;
  formProps?: { [key: string]: object };
  successMessage?: React.ReactNode;
  introMessage?: string;
  submitText?: string;
  customText?: string;
  isModal?: boolean;
  onFormSubmit?: () => void;
  customFields?: ({
    getInputProps,
  }: {
    getInputProps: Function;
  }) => JSX.Element;
  formStateTransform?: (formState: any) => any;
};

type InnerProps = Props & {
  locale: string;
  countryCode: string;
  onSignUp: (email: string, subject: string) => void;
};

type State = {
  form: {
    name: string;
    email: string;
    phone: string;
    subject: string;
    message: string;
  };
  formSubmitted: boolean;
  submitAttempted: boolean;
  responseReceived: boolean;
  errorResponse: boolean;
};

export default function ContactForm(props: Props) {
  const { locale, countryCode } = useLocale();
  const { user, mutateUser } = useUser();
  const { signUp } = useUserTools(user, mutateUser);

  function handleSignUp(email: string, subject: string) {
    signUp({ email }).then((resp) => {
      trackZendeskFormEvent({ locale, countryCode, user: resp, subject });
    });
  }

  return (
    <InnerContactForm
      {...props}
      locale={locale}
      countryCode={countryCode}
      onSignUp={handleSignUp}
    />
  );
}

class InnerContactForm extends PureComponent<InnerProps, State> {
  form: HTMLFormElement | null = null;

  static defaultProps = {
    submitText: 'Submit',
    formProps: {},
    isModal: true,
    onFormSubmit: () => {},
  };

  initState: State = {
    form: {
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
    },
    formSubmitted: false,
    submitAttempted: false,
    responseReceived: false,
    errorResponse: false,
  };
  constructor(props: InnerProps) {
    super(props);

    this.state = {
      ...this.initState,
      form: this.getInitialFormState(),
    };
  }

  componentDidUpdate(prevProps: InnerProps) {
    // We need to update the state if the form is closed and opened again
    // otherwise the form won't pick up the right initial values
    if (!_isEqual(this.props, prevProps)) {
      this.setState({
        ...this.state,
        form: this.getInitialFormState(),
      });
    }
  }

  getInitialFormState() {
    let formState = {
      ...(this.state?.form ?? this.initState.form),
    };

    // init form state with default values
    for (const [_key, obj] of Object.entries(this.props.formProps ?? {})) {
      const value = (obj as any).value;
      const key = _key as keyof State['form'];
      if (typeof value !== 'undefined' && !formState[key]) {
        formState[key] = value;
      }
    }

    return formState;
  }

  getInputProps = (name: string): any => {
    const formProps = this.props.formProps?.[name] || null;
    return {
      ...formProps,
      name,
      errorMessage: this.getError(
        name,
        //@ts-ignore
        this.state.form[name],
        this.state.submitAttempted
      ),
      onChange: (value: any) => this.onFieldChange(value, name),
    };
  };

  /**
   * When a field in the form changes, update the state
   */
  onFieldChange = (value: string, name: string) => {
    const newForm = { ...this.state.form, [name]: value };
    this.setState({ form: newForm });
  };

  loginUser() {
    const { email, subject } = this.state.form;
    this.props.onSignUp(email, subject);
  }

  /**
   * Use the form info for a request to zendesk
   */
  onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const formValid = this.isFormValid();

    this.setState({ submitAttempted: true, formSubmitted: formValid });

    let formState = { ...this.state.form };

    if (this.props.formStateTransform) {
      formState = this.props.formStateTransform(formState);
    }

    if (formValid) {
      try {
        await createZendeskRequest({
          name: formState.name,
          email: formState.email,
          subject: formState.subject,
          comment: formState.message,
          locale: this.props.locale,
          phone: formState.phone,
          pageURL: window.location.href,
          type: this.props.formType,
          origin: 'contactForm',
        });

        this.props.onFormSubmit?.();
        this.setState({ responseReceived: true, errorResponse: false });
        this.loginUser();
      } catch (err) {
        this.props.onFormSubmit?.();
        Logger.error(err);
        this.setState({ responseReceived: true, errorResponse: true });
      }
    }
  };

  /**
   * Validate an input value
   *
   * @returns an error message or null
   */
  getError = (
    name: string,
    value: any,
    checkRequired?: boolean
  ): string | null => {
    if (!checkRequired) return null;

    const validationMessages = {
      requiredField: 'Field required',
      invalidFormat: 'Invalid format',
    };

    const validationRules: ValidationRules = {
      name: {
        isRequired: true,
        pattern: /^.*[^ ].*$/,
      },
      email: {
        isRequired: true,
        pattern: /^.*[^ ].*$/,
      },
      phone: {
        isRequired: false,
        minLength: 7,
        maxLength: 30,
        pattern: /^\+[ 0-9()\/-]+$/,
        errorMessage: 'Invalid format',
      },
      subject: {
        isRequired: true,
        pattern: /^.*[^ ].*$/,
      },
      message: {
        isRequired: true,
        pattern: /^.*[^ ].*$/,
      },
    };

    const rules = validationRules[name];

    // Required fields validation
    if (checkRequired && !value && rules && rules.isRequired) {
      return validationMessages.requiredField;
    }

    // Rule-based validation
    if (value && rules) {
      const len = value.length;
      if (
        (rules.minLength && len < rules.minLength) ||
        (rules.maxLength && len > rules.maxLength)
      ) {
        return rules.errorMessage || validationMessages.invalidFormat;
      }
      if (rules.pattern && !rules.pattern.test(value)) {
        return rules.errorMessage || validationMessages.invalidFormat;
      }
    }

    return null;
  };

  /**
   * Validate the form and return the first input with an error
   */
  isFormValid = (): boolean => {
    const { form } = this.state;

    for (const inputName of Object.keys(form)) {
      //@ts-ignore
      const err = this.getError(inputName, form[inputName], true);
      if (err) {
        return false;
      }
    }

    return true;
  };

  /**
   * Close the contact form modal, reset the state
   */
  onModalClose = () => {
    this.setState({ ...this.initState });
    this.props.requestClose();
  };

  renderCustomFields() {
    return this.props.customFields?.({
      getInputProps: this.getInputProps,
    });
  }

  /**
   * Render custom hint message
   */
  renderCustomText() {
    const { customText } = this.props;

    if (!customText) {
      return;
    }
    return (
      <div className={styles.textPanelContainer}>
        <div className={styles.panelText}>
          <Localize>{customText}</Localize>
        </div>
      </div>
    );
  }

  /**
   * render the text above the form
   */
  renderTextPanel(): React.ReactNode {
    const phoneNumberLink = this.props.phoneNumber
      ? `tel:${this.props.phoneNumber.split(' ').join('')}`
      : '';
    return (
      <div className={styles.textPanelContainer}>
        {this.props.phoneNumber ? (
          <div>
            <div className={classNames(styles.panelText, styles.panelTextBig)}>
              <Localize>
                Get a quote directly from our business experts.
              </Localize>
            </div>
            <Alink
              className={styles.phoneNumber}
              href={phoneNumberLink}
              target="_self"
            >
              {this.props.phoneNumber}
            </Alink>
            <div className={styles.panelText}>
              <Localize>
                Or give us your contact detail and we will be in touch.
              </Localize>
            </div>
          </div>
        ) : (
          <div className={styles.panelText}>
            {I18nUtils.localize(
              this.props.locale,
              this.props.introMessage ??
                'Give us your contact detail and we will be in touch.'
            )}
          </div>
        )}
      </div>
    );
  }

  /**
   * Render the message that shows after the form has been submitted
   * and a response has been received from zendesk.
   *
   */
  renderResponseMessage = () => {
    const { errorResponse } = this.state;
    const errorMessage = 'An error occurred, Please try again.';

    const thankYouMessage = this.props.successMessage || (
      <span>
        <h5>
          <Localize>Thank you very much.</Localize>
        </h5>
        <p>
          <Localize>
            Our customer service will get back to you as soon as possible.
          </Localize>
        </p>
      </span>
    );

    return (
      <div className={styles.messageWrapper}>
        {errorResponse ? errorMessage : thankYouMessage}
      </div>
    );
  };

  /**
   * Render the input form component and text panel on top
   */
  renderForm(): React.ReactNode {
    return (
      <form onSubmit={this.onSubmit} action="#" ref={(el) => (this.form = el)}>
        {this.renderCustomText()}
        {this.renderTextPanel()}
        <Input label="Firstname" {...this.getInputProps('name')} />
        <Input
          label="Email"
          {...this.getInputProps('email')}
          nativeProps={{
            type: 'email',
            autoComplete: 'email',
          }}
        />
        <Input
          label="Phone Number"
          isPhoneInput
          {...this.getInputProps('phone')}
        />
        <Input label="Subject" {...this.getInputProps('subject')} />
        {this.renderCustomFields()}
        <Input
          label="Message"
          {...this.getInputProps('message')}
          isMultiLine
          className={styles.textArea}
        />
        <Button
          className={styles.submit}
          isPrimaryCta
          text={this.props.submitText}
          nativeProps={{ type: 'submit' }}
          isLoading={this.state.formSubmitted}
        />
      </form>
    );
  }

  renderFormComponent(
    responseReceived: boolean,
    showFooter: boolean,
    isModal = true
  ) {
    return (
      <div
        className={classNames({
          [styles.modalContent]: !isModal,
          [styles.responseScreen]: responseReceived && !isModal,
        })}
      >
        <div className={styles.form}>
          {responseReceived ? this.renderResponseMessage() : this.renderForm()}
        </div>
        {showFooter ? this.renderFooter() : ''}
      </div>
    );
  }

  renderFooter() {
    return (
      <div className={classNames(styles.bottom, 'tc col-xs-12')}>
      </div>
    );
  }

  render() {
    const { showFooter, modalHeader, isModal } = this.props;
    const { responseReceived } = this.state;

    return isModal ? (
      <Modal
        contentLabel="ContactUsForm"
        contentClass={classNames(styles.modalContent, {
          [styles.responseScreen]: responseReceived,
        })}
        isOpen={this.props.isOpen}
        onClose={this.onModalClose}
        header={modalHeader || 'Customer Service'}
        overlayClass="contactFormModalOverlay"
        clickOutsideWhiteList={[
          '[class*="mediaModalOverlay"]',
          '.country-list',
        ]}
      >
        {this.renderFormComponent(responseReceived, showFooter!)}
      </Modal>
    ) : (
      this.renderFormComponent(responseReceived, showFooter!, false)
    );
  }
}
