const modules = [
  {
    module: ['pillow', 'normal'],
    dimensions: [
      [300, 500, 240],
      [400, 400, 240],
      [400, 600, 240],
      [400, 800, 240],
      [480, 650, 240],
      [500, 500, 240],
    ],
    color: ['textile'],
  },
];

export default modules;
