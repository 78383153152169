//
// Help panel config
//
const helpCfg = {
  optionsText: {
    chat: ['Chat with us', 'Agents are available'],
    email: ['Write us an email', 'support@mycs.com'],
    phone: ['Call us'],
    faq: ['Need more answers?', 'Check our FAQ'],
  },
};

export default helpCfg;
