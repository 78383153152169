import { Fragment, useState } from 'react';

import { useDevice } from 'mycs/router/DeviceContext';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import Button from 'mycs/shared/components/Button/Button';
import ClickOutside, {
  MouseOrTouch,
} from 'mycs/shared/components/ClickOutside/ClickOutside';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';
import LocalStorageUtils from 'mycs/shared/utilities/LocalStorageUtils/LocalStorageUtils';
import ReactModal from 'mycs/shared/utilities/ReactModal';
import SafeText from 'mycs/shared/components/SafeText/SafeText';

import styles from './AnnouncementModal.scss';

export default function AnnouncementModal() {
  const localStorageKey = 'announcementModal';
  const { hasPhoneDimensions } = useDevice();
  const [showModal, setShowModal] = useState(false);
  const [showComponent, setShowComponent] = useState(true);
  const { locale } = useLocale();
  const isSmallScreen = hasPhoneDimensions;

  // TODO Enable Me
  // useEffect(() => {
  //   // Don't trigger the modal more often than once every 4h
  //   const localStorageModal = LocalStorageUtils.get(localStorageKey);
  //   const triggerInterval = 4 * 60 * 60 * 1000; // in ms
  //   const now = Math.round(new Date().getTime()); // in ms
  //   const isModalOldEnough =
  //     localStorageModal &&
  //     localStorageModal.lastAutoDisplay &&
  //     now - localStorageModal.lastAutoDisplay > triggerInterval;

  //   // showComponent can't be initialized in the constructor
  //   // otherwise there will be issues with SSR
  //   if (!localStorageModal || isModalOldEnough) {
  //     setShowComponent(true);
  //   } else {
  //     setShowComponent(false);
  //   }
  // }, []);

  const onOpen = () => {
    setShowModal(true);
  };

  const onClose = (event: MouseOrTouch) => {
    event.stopPropagation();

    setShowModal(false);
    setShowComponent(false);
    setTimeout(() => {
      const now = Math.round(new Date().getTime()); // in ms
      LocalStorageUtils.set(localStorageKey, {
        lastAutoDisplay: now,
      });
    }, 0);
  };

  if (!showComponent) return <Fragment />;

  return isSmallScreen ? renderMobile() : renderDesktop();

  function renderMobile() {
    return (
      <div>
        <div className={styles.modalTrigger} onClick={onOpen}>
          <div className={styles.modalTitle}>
            {I18nUtils.localize(locale, 'FAQ')}
          </div>
          <div className={styles.modalTriggerClose} onClick={onClose}>
            <Button iconName="general/close.svg" />
          </div>
        </div>

        <ReactModal
          isOpen={showModal}
          className={styles.modal}
          overlayClassName={styles.modalOverlay}
        >
          <ClickOutside onClickOutside={onClose}>
            <div className={styles.modalContent}>
              <div className={styles.modalTitle}>
                {I18nUtils.localize(locale, 'FAQ')}
              </div>

              <div className={styles.modalCloseButton} onClick={onClose}>
                <Button iconName="general/close.svg" />
              </div>

              <SafeText
                className={styles.modalText}
                content={I18nUtils.localize(locale, 'faq-02-2024')}
              />
            </div>
          </ClickOutside>
        </ReactModal>
      </div>
    );
  }

  function renderDesktop() {
    return (
      <div className={styles.modalContainer}>
        <div className={styles.modalContent}>
          <div className={styles.modalTitle}>
            {I18nUtils.localize(locale, 'FAQ')}
          </div>

          <div className={styles.modalCloseButton} onClick={onClose}>
            <Button iconName="general/close.svg" />
          </div>

          <SafeText
            className={styles.modalText}
            content={I18nUtils.localize(locale, 'faq-02-2024')}
          />
        </div>
      </div>
    );
  }
}
