import isObject from 'lodash/isObject';
import { defer, Observable } from 'rxjs'; // eslint-disable-line
import { retry, shareReplay } from 'rxjs/operators';

// Export helper functions for testability
export const helpers = {
  createRequest,
  createFetch,
  memoizedFetch,
};

type ResponseType = 'json' | 'blob' | 'text';

type Request = {
  contentType?: string;
  method?: string;
  [key: string]: any;
};

function createRequest(request: Request | string): RequestInfo {
  const req = request;

  if (isObject(request)) {
    const { contentType = 'application/json', ...sanitizedRequest } = request;
    const headers = new Headers();
    headers.append('Content-Type', contentType);

    return new Request(request.url, {
      headers,
      ...sanitizedRequest,
    });
  }

  return req as string;
}

function createFetch(request: string | Request) {
  return fetch(helpers.createRequest(request));
}

const cache: { [id: string]: any } = {};
function memoizedFetch(request: string | Request, type: ResponseType = 'json') {
  const cacheId = JSON.stringify(request);
  const cachedFetch = cache[cacheId];

  if (cachedFetch) {
    return cachedFetch;
  }

  const fetchPromise = helpers.createFetch(request);

  fetchPromise.catch(() => (cache[cacheId] = null));

  cache[cacheId] = fetchPromise.then((resp) => {
    cache[cacheId] = null;

    if (resp.status >= 400) {
      throw Error(
        `Request ${cacheId} failed because ${resp.statusText || resp.status}`
      );
    }

    return resp[type]().catch(() => ({}));
  });

  return cache[cacheId];
}

/**
 * FetchAPI wrapper for the common case of json response
 */
export const asyncRequest = (
  request: string | Request,
  type: ResponseType = 'json'
) => {
  return helpers.memoizedFetch(request, type);
};

/**
 * Wrapps asyncRequest with observable
 */
export const asyncRequest$ = (request: Request): Observable<any> => {
  // fromPromise will not work, retry needs an actual callback to execute
  let req$ = defer(() => asyncRequest(request));

  // for safety reasons it retries only on GET req failures
  if (request.method === 'GET') {
    req$ = req$.pipe(retry(2));
  }

  // "share" will let to subscribe several times but do the actual req only once
  // "replay(1)" will let a late subscriber to recieve the data (after the response is recieved)
  return req$.pipe(shareReplay(1));
};

/**
 * Fetch wrapper for POST requests
 * @param url A USVString containing the direct URL of the resource that needs to be fetched
 * @param data A request payload
 * @param init An options object containing any custom settings that need to be applied to the request
 */
export const post = (url: string, data = {}, init: RequestInit = {}) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    ...init,
  }).then((response) => {
    const contentType = response.headers.get('content-type');

    if (contentType && contentType.includes('application/json')) {
      if (response.ok) {
        return response.json();
      } else {
        // response.body might contain useful info about error
        return response.json().then((error) => {
          throw error;
        });
      }
    }

    if (!response.ok) {
      throw Error(response.statusText);
    }

    return response;
  });
};

export default asyncRequest;
