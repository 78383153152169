import { FurnitureConfig } from 'furniture-engine';

import { ConfiguratorSpecs } from 'mycs/framework/store/ConfiguratorSpecs';

export class WardrobeSpecs extends ConfiguratorSpecs {
  getSpecString(_furnitureConfig: FurnitureConfig, specs: any, colors: any) {
    let res: string[] = [];
    const lift_color = specs.lifts > 0 ? { grey_7037: specs.lifts } : {};
    const cloth_rail_color =
      specs.cloth_rails > 0 ? { grey_7037: specs.cloth_rails } : {};
    res = res.concat(this._getColorsString(colors.outer_walls, 'Wall'));
    res = res.concat(this._getColorsString(colors.boards, 'Board'));
    res = res.concat(
      this._getColorsString(
        colors.drawers,
        'Drawer',
        'oak with the drawer front'
      )
    );
    res = res.concat(this._getColorsString(colors.pantHangers, 'Pant Hanger'));
    res = res.concat(this._getColorsString(colors.doors, 'Door'));
    res = res.concat(this._getColorsString(colors.handles, 'Handle'));
    res = res.concat(this._getColorsString(colors.corpuses, 'Backwall'));
    res = res.concat(this._getColorsString(colors.corpuses, 'Corpus'));
    res = res.concat(this._getColorsString(lift_color, 'Lift'));
    res = res.concat(this._getColorsString(cloth_rail_color, 'Cloth Rail'));

    return res.join(', ');
  }
}
