const modules = [
  {
    module: ['accessory', 'normal'],
    dimensions_expand: [[1], [1], [1]],
    color: ['metal_stainless'],
  },
  {
    module: ['accessory', 'soft_close'],
    dimensions_expand: [[1], [1], [1]],
    color: ['metal_stainless'],
  },
  {
    module: ['backwall', 'normal'],
    dimensions_expand: [[365, 726], [12], [173, 365, 750]],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['board', 'accessory'],
    dimensions_expand: [[360, 724], [26], [12]],
    color: ['laminate_black_white'],
  },
  {
    module: ['board', 'backwall'],
    dimensions_expand: [[365, 726], [342, 470], [19]],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['board', 'behind_front'],
    dimensions_expand: [[365, 726], [318, 445], [19]],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['board', 'front_backwall'],
    dimensions_expand: [[365, 726], [305, 433], [19]],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['board', 'front_between_backwall'],
    dimensions_expand: [[365, 726], [317, 445], [19]],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['board', 'legs_board'],
    dimensions_expand: [[365, 726], [342, 470], [19]],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['board', 'normal'],
    dimensions_expand: [[365, 726], [342, 470], [19]],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['board', 'top_backwall'],
    dimensions_expand: [[365, 726], [342, 470], [19]],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['board', 'backwall_reinforced'],
    dimensions_expand: [[365, 726], [342, 470], [19]],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['board', 'behind_front_reinforced'],
    dimensions_expand: [[365, 726], [318, 445], [19]],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['board', 'front_backwall_reinforced'],
    dimensions_expand: [[365, 726], [305, 433], [19]],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['board', 'front_between_backwall_reinforced'],
    dimensions_expand: [[365, 726], [317, 445], [19]],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['board', 'normal_reinforced'],
    dimensions_expand: [[365, 726], [342, 470], [19]],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['board', 'top_backwall_reinforced'],
    dimensions_expand: [[365, 726], [342, 470], [19]],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['door', 'normal'],
    dimensions_expand: [[359], [19], [359, 551, 743, 1127, 1511, 1895, 2279]],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['door', 'normal'],
    dimensions_expand: [[359], [5], [359, 551, 743, 1127, 1511, 1895, 2279]],
    color: ['glass_colorless'],
  },
  {
    module: ['drawer_box', 'plain'],
    dimensions_expand: [
      [359, 720],
      [270, 390],
      [110, 220],
    ],
    color: ['beech'],
  },
  {
    module: ['drawer_box', 'accessory_normal'],
    dimensions_expand: [[60], [305, 405], [40]],
    color: ['metal_stainless'],
  },
  {
    module: ['drawer_box', 'accessory_soft_close'],
    dimensions_expand: [[60], [305, 405], [40]],
    color: ['metal_stainless'],
  },
  {
    module: ['drawer_front', 'normal'],
    dimensions: [
      [359, 16, 359],
      [359, 19, 167],
      [720, 16, 359],
      [720, 19, 167],
    ],
    color: ['laminate', 'wood_3_shelves'],
  },
  /* Visual only for hinge */
  {
    module: ['handles', 'accessory'],
    dimensions: [[80, 52, 57]],
    color: ['metal_stainless'],
    custom: true,
  },
  {
    module: ['handles', 'accessory'],
    dimensions: [[40, 14, 15]],
    color: ['metal_stainless'],
  },
  {
    module: ['handles', 'glass'],
    dimensions: [[42, 42, 20]],
    color: ['laminate', 'metal_aluminum', 'metal_gold'],
  },
  {
    module: ['handles', 'normal'],
    dimensions: [[42, 40, 22]],
    color: ['laminate', 'metal_aluminum', 'metal_gold'],
  },
  {
    module: ['hanger', 'accessory'],
    dimensions_expand: [[365, 726], [60], [61]],
    color: ['laminate_black_white'],
  },
  {
    module: ['legs', 'corner_left'],
    dimensions: [[100, 100, 120]],
    color: ['laminate', 'metal_chrome', 'metal_stainless', 'metal_gold'],
  },
  {
    module: ['legs', 'corner_right'],
    dimensions: [[100, 100, 120]],
    color: ['laminate', 'metal_chrome', 'metal_stainless', 'metal_gold'],
  },
  {
    module: ['legs', 'flat_left'],
    dimensions: [[80, 105, 120]],
    color: ['laminate', 'metal_chrome', 'metal_stainless', 'metal_gold'],
  },
  {
    module: ['legs', 'flat_right'],
    dimensions: [[80, 105, 120]],
    color: ['laminate', 'metal_chrome', 'metal_stainless', 'metal_gold'],
  },
  {
    module: ['legs', 'frame_side'],
    dimensions: [
      [102, 340, 120],
      [102, 468, 120],
    ],
    color: ['laminate', 'metal_chrome', 'metal_stainless', 'metal_gold'],
  },
  {
    module: ['legs', 'hairpin_leg'],
    dimensions: [[96, 96, 121]],
    color: ['laminate', 'metal_chrome', 'metal_stainless', 'metal_gold'],
  },
  {
    module: ['legs', 'inner_socle'],
    dimensions: [
      [384, 264, 60],
      [384, 392, 60],
      [744, 264, 60],
      [744, 392, 60],
    ],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['legs', 'narrow_left'],
    dimensions: [[80, 105, 120]],
    color: ['laminate', 'metal_chrome', 'metal_stainless', 'metal_gold'],
  },
  {
    module: ['legs', 'narrow_right'],
    dimensions: [[80, 105, 120]],
    color: ['laminate', 'metal_chrome', 'metal_stainless', 'metal_gold'],
  },
  {
    module: ['legs', 'outer_socle'],
    dimensions: [
      [354, 264, 60],
      [354, 392, 60],
      [716, 264, 60],
      [716, 392, 60],
    ],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['legs', 'platform_plate'],
    dimensions: [
      [60, 300, 5],
      [74, 396, 5],
      [120, 300, 5],
      [148, 396, 5],
    ],
    color: ['metal_black'],
  },
  {
    module: ['legs', 'platform_profile'],
    dimensions_expand: [[332, 726], [20], [20]],
    color: ['metal_black'],
  },
  {
    module: ['legs', 'roll'],
    dimensions_expand: [[50], [50], [80]],
    color: ['roll'],
  },
  {
    module: ['legs', 'slanted'],
    dimensions: [[90, 90, 120]],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['legs', 'socle_profile'],
    dimensions: [
      [19, 300, 60],
      [19, 354, 60],
    ],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['legs', 'square'],
    dimensions: [[100, 100, 20]],
    color: [
      'laminate',
      'wood_3_shelves',
      'metal_gold',
      'metal_chrome',
      'metal_stainless',
    ],
  },
  {
    module: ['legs', 'square_support'],
    dimensions_expand: [[100], [100], [20]],
    color: ['beech'],
  },
  {
    module: ['legs', 'straight'],
    dimensions: [[50, 50, 120]],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['legs', 'support'],
    dimensions_expand: [[25], [25], [120]],
    color: ['metal_black'],
  },
  {
    module: ['legs_47', 'slanted'],
    dimensions: [[90, 90, 120]],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['legs_47', 'straight'],
    dimensions: [[50, 50, 120]],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['wall', 'inner'],
    dimensions_expand: [
      [19],
      [342, 470],
      [403, 595, 787, 1171, 1555, 1939, 2323],
    ],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['wall', 'outer'],
    dimensions_expand: [
      [19],
      [342, 470],
      [403, 595, 787, 1171, 1555, 1939, 2323],
    ],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['wall_extension', 'inner'],
    dimensions_expand: [[19], [342, 470], [384, 576]],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['wall_extension', 'outer'],
    dimensions_expand: [[19], [342, 470], [384, 576]],
    color: ['laminate', 'wood_3_shelves'],
  },
  {
    module: ['accessory', 'hinge_normal'],
    dimensions_expand: [[10], [10], [10]],
    color: ['metal_stainless'],
  },
  {
    module: ['accessory', 'hinge_soft_close'],
    dimensions_expand: [[10], [10], [10]],
    color: ['metal_stainless'],
  },
  {
    module: ['accessory', 'mounting_plate_hinges'],
    dimensions_expand: [[51], [42], [10]],
    color: ['metal_stainless'],
  },
  {
    module: ['accessory', 'screw_1'],
    dimensions_expand: [[11], [8], [8]],
    color: ['metal_stainless'],
  },
  {
    module: ['accessory', 'screw_2'],
    dimensions_expand: [[10], [10], [10]],
    color: ['metal_stainless'],
  },
  {
    module: ['accessory', 'normal_glass'],
    dimensions: [[40, 10, 10]],
    color: ['metal_stainless'],
  },
  {
    module: ['accessory', 'soft_close_glass'],
    dimensions: [[50, 48, 12]],
    color: ['metal_stainless'],
  },
  {
    module: ['accessory', 'glass_hinge'],
    dimensions: [[50, 40, 10]],
    color: ['metal_stainless'],
  },
  {
    module: ['accessory', 'hinge_normal_glass'],
    dimensions: [[72, 46, 26]],
    color: ['metal_stainless'],
  },
  {
    module: ['accessory', 'hinge_soft_close_glass'],
    dimensions: [[72, 46, 26]],
    color: ['metal_stainless'],
  },
  {
    module: ['baseboard_cut', 'board'],
    dimensions_expand: [[37], [20, 45], [19]],
    color: ['NOT_RENDERED'],
  },
  {
    module: ['baseboard_cut', 'inner_sidewall'],
    dimensions_expand: [[19], [20, 45], [60, 85, 100, 160]],
    color: ['NOT_RENDERED'],
  },
  {
    module: ['baseboard_cut', 'outer_sidewall_left'],
    dimensions_expand: [[19], [20, 45], [60, 85, 100, 160]],
    color: ['NOT_RENDERED'],
  },
  {
    module: ['baseboard_cut', 'outer_sidewall_right'],
    dimensions_expand: [[19], [20, 45], [60, 85, 100, 160]],
    color: ['NOT_RENDERED'],
  },
];
export default modules;
