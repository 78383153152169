import 'core-js';
import 'regenerator-runtime/runtime';

/**
 * Polyfills for `matches' and `closest'
 * Needed because of apps/website/app/src/shared/components/LinkInterceptor/LinkInterceptor.tsx
 */
if (typeof Element !== 'undefined') {
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      //@ts-ignore
      Element.prototype.msMatchesSelector ||
      Element.prototype.webkitMatchesSelector;
  }

  if (!Element.prototype.closest) {
    /**
     * Find closest matching ancestor element
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
     */
    Element.prototype.closest = function (selector: string) {
      let el = this;
      do {
        if (!el) return null;
        if (el.matches(selector)) return el;
        //@ts-ignore
        el = el.parentElement;
      } while (el !== null);
      return null;
    };
  }
}
