import { FurnitureConfig } from 'furniture-engine';

import { ConfiguratorSpecs } from 'mycs/framework/store/ConfiguratorSpecs';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';

export class CushionSpecs extends ConfiguratorSpecs {
  getSpecString(
    _furnitureConfig: FurnitureConfig,
    _specs: any,
    colors: any,
    materials: any
  ) {
    let res = '';

    const color = colors.cover || colors.primary;
    if (colors.cover) {
      const amount = 1;
      const elementName = I18nUtils.localize(this.locale, 'fyne_cushion');
      const material = materials.find((m: any) => m.color === color);
      const colorNiceName = material
        ? `${material.name.trim()} (${material.category.name})`
        : I18nUtils.localize(this.locale, color);
      res = `${amount} ${elementName} ${colorNiceName}`;
    }

    return res;
  }
}
