import classNames from 'classnames';

import { useLocale } from 'mycs/shared/state/LocaleContext';
import cfg from 'mycs/config';
import ContentfulService from 'mycs/shared/services/ContentfulService/ContentfulService';

import styles from './CfEdit.scss';

type Props = {
  entryKey?: string;
  entryId?: string;
  isStatic?: boolean;
};

export default function CfEdit({ entryId, entryKey, isStatic }: Props) {
  const { countryCode } = useLocale();

  if (!cfg.contentful.displayEditButton || !(entryId || entryKey)) {
    return null;
  }

  const link = entryId ? ContentfulService.getEditLink(entryId) : undefined;
  const onClick = entryKey ? onClickHandler : undefined;

  return (
    <a
      href={link}
      onClick={onClick}
      rel="noopener noreferrer"
      className={classNames(styles.cf, {
        [styles.cf__fixed]: !isStatic,
      })}
      target="_blank"
    >
      Edit
    </a>
  );

  /**
   * Open a popup with the Contentful edit page
   */
  function onClickHandler() {
    if (typeof window === 'undefined') return;
    const win = window.open();

    ContentfulService.getTranslationId(countryCode, entryKey).then(
      (id) => (win!.location.href = ContentfulService.getEditLink(id))
    );
  }
}
