import { useMemo } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { useDevice } from 'mycs/router/DeviceContext';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import cfg from 'mycs/config';
import FlagMode from 'mycs/shared/components/FlagMode/FlagMode';
import useUI from 'mycs/hooks/useUI';
import HeaderMenu from 'mycs/shared/components/HeaderMenu/HeaderMenu';
import MsgAlert from 'mycs/shared/components/MsgAlert/MsgAlert';
import PathUtils from 'mycs/shared/utilities/PathUtils/PathUtils';
import Sticky from 'mycs/shared/components/Sticky/Sticky';

import styles from './Header.scss';

export default function Header() {
  const { locale } = useLocale();
  const { hasPhoneDimensions } = useDevice();

  const { pathname } = useLocation();
  const furnitureType = useMemo(
    () => PathUtils.getPathFurnitureType(pathname, locale),
    [pathname, locale]
  );

  const { showHeader } = useUI(pathname);
  const isConfigurator = Object.values(cfg.furnitureTypes).includes(
    furnitureType
  );
  const isMobileConfigurator =
    isConfigurator && cfg.mobileConfiguratorTypes.includes(furnitureType);

  if (!showHeader) return <></>;

  return (
    <div
      className={classNames(styles.container, {
        [styles.hidden]: isMobileConfigurator,
      })}
    >
      <Sticky
        isDisabled={isConfigurator || !hasPhoneDimensions}
        dockedClassName={styles.dockedContainer}
      >
        <HeaderMenu />
      </Sticky>

      <MsgAlert />

      <FlagMode />
    </div>
  );
}
